import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'tp-pagination',
	templateUrl: './tp-pagination.component.html',
	styleUrls: ['./tp-pagination.component.scss']
})
export class TpPaginationComponent implements OnInit {
	@Output() setPage: EventEmitter<any> = new EventEmitter<any>();
	@Output() pageSize: EventEmitter<any> = new EventEmitter<any>();
	@Input() pagination: any;
	pageSizeOptions: number[] = [10, 50, 100, 250, 500];
	selectedPageSize: number = 10;
	isArabic = false;

	constructor() {
		this.isArabic = localStorage.getItem('lang') == 'ar' ? true : false;
	}

	ngOnInit() { }

	setPagination(page) {
		this.setPage.emit(page);
	}

	onPageSizeChange(newPageSize: number) {
		if (this.pagination.page > 1) {
			this.pagination.page = 1;
		}
		this.selectedPageSize = newPageSize;
		this.pageSize.emit(this.selectedPageSize);
	}

	getPaginationText() {
		let pagination = ': ';
		if (this.pagination.page < this?.pagination?.pages) {
			pagination += this.pagination.per_page * this?.pagination?.page + "/" + this?.pagination?.count;
		}
		else if (this?.pagination?.page == this?.pagination?.pages) {
			pagination += this?.pagination?.count + "/" + this?.pagination?.count;
		}
		else {
			pagination += "0/0";
		}

		return pagination;
	}

}

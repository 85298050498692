<div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
     <div fxLayout="row"
          fxLayoutAlign="space-between stretch">
          <div fxFlex="60"
               class=" pt-1">
               <!-- <p>{{tr.translation.home}} > {{tr.translation.Risk_Appetite_Survey}}</p> -->
               <!-- <h2>{{tr.translation.Risk_Appetite_Survey}}</h2> -->
          </div>
          <div fxFlex="8">
               <button
                       class="btn-disabled"
                       (click)="onBack()">{{'Back' | translate}}</button>
          </div>
     </div>
     <!-- <mat-divider></mat-divider> -->

     <div class="mt-4  profile-card">
          <mat-card class="mb-3">
               <div fxLayout="row wrap"
                    fxLayoutAlign="">
                    <div fxFlex="24">
                         <div>
                              <div class="chart mt-4">
                                   <canvas baseChart
                                           [data]="doughnutChartData"
                                           [labels]="doughnutChartLabels"
                                           [chartType]="doughnutChartType"
                                           [colors]="colors"
                                           [options]='chartOptions'>

                                   </canvas>

                              </div>
                         </div>
                    </div>
                    <div fxFlex="2">

                    </div>
                    <div fxFlex="18" style="margin-left: 60px; margin-top: 40px;">
                         <h2 style="margin-bottom: 20px;">{{'Risk_Type' | translate}}</h2>
                         <div style="margin-top: 10px;" class="ml-1  legends_text"
                              *ngIf="risk=='low'">{{'Low_Risk' | translate}}</div>
                         <div style="margin-top: 10px;" class="ml-1  legends_text"
                              *ngIf="risk=='medium'">{{'Medium_Risk' | translate}} </div>
                         <div style="margin-top: 10px;" class="ml-1  legends_text"
                              *ngIf="risk=='high'">{{'High_Risk' | translate}} </div>
                    </div>
                    <div fxFlex="48" style="margin-top: 40px;">
                         <h2 style="margin-bottom: 10px;">{{'Target Allocation by Fund' | translate}}</h2>
                         <div style="margin-top: 0; margin-left: 20px;" class=""
                              fxLayout="row wrap"
                              fxLayoutAlign="space-between center">
                              <div fxFlex="100"
                                   *ngFor="let val of fundAssets"
                                   style="margin-top: 10px;">
                                   <div class="round"
                                        style="background-color: #71C9FF;">
                                   </div>
                                   <div fxFlex
                                        class="ml-1 legends_text">
                                        <!-- {{val.asset_name}}
                                        {{tr.numberFormatter2(val?.funds_assets?.allocation)}}%  -->

                                        <span style="font-size: 16px;">{{val.asset_name}} </span>
                                        <span style="font-size: 16px;">{{val?.funds_assets?.allocation | number:'1.2-2' | slice:0:-2}}</span>
                                        <span style="font-size: 13px;">{{val?.funds_assets?.allocation | number:'1.2-2' | slice:-2}}%</span>
                                   </div>
                              </div>
                         </div>
                         <h2 style="margin-bottom: 10px; margin-top: 20px;">{{'Target Allocation by Asset Class' | translate}}</h2>
                         <div style="margin-top: 0; margin-left: 20px;" class=""
                              fxLayout="row wrap"
                              fxLayoutAlign="space-between center">
                              <div fxFlex="100"
                                   *ngFor="let val of assetClassArray"
                                   style="margin-top: 10px;">
                                   <div class="round"
                                        style="background-color: #71C9FF;">
                                   </div>
                                   <div fxFlex
                                        class="ml-1 legends_text">
                                        <!-- {{val.asset_class}}
                                        {{tr.numberFormatter2(val?.allocation)}}%  -->

                                        <span style="font-size: 16px;">{{val.asset_class}} </span>
                                        <span style="font-size: 16px;">{{val?.allocation | number:'1.2-2' | slice:0:-2}}</span>
                                        <span style="font-size: 13px;">{{val?.allocation | number:'1.2-2' | slice:-2}}%</span>
                                   </div>
                              </div>
                         </div>
                    </div>
                   
               </div>
               <div class="mx-5 mt-5 description">
                    <div fxLayout="row wrap"
                         fxLayoutAlign="space-between center">
                      <div fxFlex="48">
                        <h2>{{'Investment' | translate}}</h2>
                        <p>{{'paraA' | translate}}</p>
                      </div>
                      <div fxFlex="48">
                        <h2>{{'Risk' | translate}}</h2>
                        <p>{{'paraBA' | translate}} <span *ngIf="risk=='low'"
                                style="color: #00C435; font-weight: 600;">{{'low' | translate}}</span><span *ngIf="risk=='medium'"
                                style="color: #E69A5A;  font-weight: 600;">{{'medium' | translate}}</span><span
                                *ngIf="risk=='high'"
                                style="color: #EC4955; font-weight: 600;">{{'high' | translate}}</span>, {{'paraBB' | translate}}
                        </p>
                      </div>
                    </div>
                  </div>
          </mat-card>
     </div>
</div>
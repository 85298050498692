import { OnInit, Component } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { FormGroup, Validators, FormBuilder, FormControl, FormArray } from "@angular/forms";
import { MainService } from "../../services/main.service";
import { DecEncService } from "src/app/services/dec-enc";
import { Router } from "@angular/router";
import { TranslationService } from "../translation/translation.service";
import { AlertDialog } from "../alert-dialog/alert.dialog";
import { AlertService } from "src/app/services/alert.service";
import { ProfileService } from "src/app/services/profile.service";

@Component({
	selector: 'terminateAlert-dialog',
	templateUrl: './terminateAlert-dialog.html',
	styleUrls: ['./terminateAlert-dialog.scss']
})
export class TerminateAlertDialog implements OnInit
{
	isLoading: boolean;
	heading: string = '';
	message: string = '';
	type: 'ask' | 'success' | 'error' | 'info' | 'ask2';
	cancelButtonText: string;
	submitButtonText: string;
	submitResponceText: string;
	dataToSubmit: any;
	methodName: any;
	methodId: any;
	methodType: any;
	status: any;
	showLoading: boolean;
	Form: FormGroup;
	fund_id: any;
	fund: any = true;
	risk: boolean = false;
	contribution: any = false;
	FormA: FormGroup;
	currentDate: Date = new Date();
	email: string;
	showOtp: boolean = false;
	EmployeeId: any;
	TotalSettlementEmployer: any;
	TotalSettlement: any;
	timerRunning: boolean = true;
	timer: any;
	maxTime: number = 60;
	timerValue: string = '1:00';
	ShowMessage: boolean=true;
	constructor(protected mainApiService: MainService, 
		public enc_dec: DecEncService,
		protected dialogRef: MatDialogRef<AlertDialog>,
		protected dialog: MatDialog, 
		public fb: FormBuilder, 
		private dec_enc: DecEncService, 
		public route: Router, 
		public tr: TranslationService,
		protected alertService: AlertService,
		protected profileService: ProfileService)
	{
		// this.email = localStorage.getItem('employer_email');
		this.email = profileService.user.email;
		this.isLoading = false;
		this.showLoading = false;
		this.Form = this.fb.group({

			type: ['', [Validators.required]],
			last_date: ['', [Validators.required]],
			// employee_id: [this.EmployeeId],


		})

		this.FormA = this.fb.group({

			otp: ['', [Validators.required]],

		})
	}

	ngOnInit()
	{
		// console.log("TotalSatlement",this.TotalSatlement)
		this.otpTimerCheck();
	}

	onCancelClick(): void
	{
		this.dialogRef.close(false);
	}
	otpTimerCheck()
	{
	  this.timer = setTimeout(x => 
	  {
		this.maxTime -= 1;
  
		if (this.maxTime < 10)
		{
		  this.timerValue = '00:0' + this.maxTime;
		}
  
		if (this.maxTime > 10)
		{
		  this.timerValue = '00:' + this.maxTime;
		}
  
		if (this.maxTime > 0)
		{
		  this.timerRunning = true;
		  this.otpTimerCheck();
		}
		else
		{
		  this.timerRunning = false;
		}
	  }, 1000);
	}
	onResend()
	{
	  if (this.timerRunning)
	  {
		return;
	  }
	  this.ShowMessage=false;
	  this.onOkClick()
	}
	
	onOkClick()
	{
		let url = 'auth/terminateOTP'
		this.mainApiService.postData(url, {}).then(res =>
		{
			if (res.statusCode == 200)
			{
				this.maxTime = 60;
				clearTimeout(this.timer);
				this.otpTimerCheck();
				if(this.ShowMessage == true)
				{
					this.alertService.alert('Success', 'OTP has been sent to your registered email address successfully');
				}
				
				this.showOtp = true;
			}
			if (res.statusCode == 208)
			{
				this.alertService.alert('Error', res.error.message);
				// let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				// let cm = dialogRef.componentInstance;
				// cm.heading = this.tr.translation.error;
				// cm.message = res.error.message;
				// cm.cancelButtonText = this.tr.translation.okay;
				// cm.type = 'error';
			}
			if (res.statusCode == 404)
			{
				this.alertService.alert('Error', res.error.message);
				// let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				// let cm = dialogRef.componentInstance;
				// cm.heading = this.tr.translation.error;
				// cm.message = res.error.message;
				// cm.cancelButtonText = this.tr.translation.okay;
				// cm.type = 'error';
			}

		},
			error =>
			{
				this.alertService.alert('Error', 'Server Error');
				// let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				// let cm = dialogRef.componentInstance;
				// cm.heading = this.tr.translation.error;
				// cm.message = this.tr.translation.serverError;
				// cm.cancelButtonText = this.tr.translation.okay;
				// cm.type = 'error';
			}
		)
	}

	onValidateOTP()
	{
		let url = 'auth/validateTerminateOTP';
		let data =
		{
			"otp": this.FormA.get('otp').value
		}
		let payload = this.dec_enc.encryption(data)
		this.mainApiService.postData(url, { enc_payload: payload }).then(res =>
		{
			if (res.statusCode == 200)
			{
				this.onReason();
				return
			}
			if (res.statusCode == 208)
			{
				this.alertService.alert('Error', res.message);
				return
			}
			if (res.statusCode == 404)
			{
				this.alertService.alert('Error', res.message);
				return
			}
			if (res.error.statusCode == 412)
			{
				this.alertService.alert('Error', res.error.message);
				return
			}
			this.alertService.alert('Error', res.error.message);
		},
			error =>
			{
				this.alertService.alert('Error', 'Server Error');
			}
		)
	}

	onReason()
	{
		let url = 'terminations/create'
		let data = {
			"type": this.Form.get('type').value,
			"employee_id": this.EmployeeId,
			"last_date": this.Form.get('last_date').value,
			"employer_amount": this.TotalSettlementEmployer,
			"amount": this.TotalSettlement,
		}

		let payload = this.dec_enc.encryption(data)
		this.mainApiService.postData(url, { enc_payload: payload }).then(res =>
		{
			if (res.statusCode == 200)
			{
				let message = 'Employee Termination request has been created successfully';
				if(res.message && res.message != 'OK'){
					message = localStorage.getItem('lang') == 'ar' ? res?.message_ar :  res?.message;
				}
				this.alertService.alert('Success', message).then(res => {
					this.dialogRef.close(false);
					this.route.navigate(['/main/manage_employee']);
				});
			}
			else
			{
				if (localStorage.getItem('lang') == 'ar')
				{
					this.alertService.alert('Error', res.error.message_ar);
				}
				else
				{
					this.alertService.alert('Error', res.error.message);
				}
			}

		})
	}


	onResponce(): void
	{
		this.dialogRef.close(true);

	}
}

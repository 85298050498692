<div *ngIf="methodType == 'date' && !risk"
     style="min-width: 300px; max-width: 440px;"
     class="text-center">

    <div class="content-head clearfix">
        <!-- <div style="font-size: 25px;"
             class="title mb-4">{{heading | titlecase}}</div>
    </div> -->

        <div class="row">
            <div class="col-sm-12 text-center"
                 [innerHTML]="message"
                 style="max-height: 00px;overflow: auto;">
                <!-- {{message}} -->
            </div>



            <form [formGroup]="FormA">
                <div fxLayout="row"
                     fxLayoutAlign="space-between stretch">


                    <div fxFlex="48">
                        <mat-form-field class="example-full-width"
                                        appearance="outline">
                            <mat-label>Select Start Date</mat-label>
                            <input matInput
                                   [matDatepicker]="picker"
                                   formControlName="start">
                            <mat-datepicker-toggle matSuffix
                                                   [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div fxFlex="48">
                        <mat-form-field class="example-full-width"
                                        appearance="outline">
                            <mat-label>Select End Date</mat-label>
                            <input matInput
                                   [matDatepicker]="pickerA"
                                   formControlName="end">
                            <mat-datepicker-toggle matSuffix
                                                   [for]="pickerA"></mat-datepicker-toggle>
                            <mat-datepicker #pickerA></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </form>

            <div class="col-sm-12 text-center "
                 style="text-align:right; width: 100%;">


                <button mat-raised-button
                        color="primary"
                        style="margin-right: 5px; min-width: 100px;background: transparent linear-gradient(283deg, #040405 0%, #004390 55%, #3F638F 100%) 0% 0% no-repeat padding-box;color: #213060;border-radius: 50px;"
                        class="btn btn-clear mt-4"
                        [disabled]="isLoading"
                        type="button"
                        (click)="onCancelClick()">Cancel</button>

                <button mat-raised-button
                        color="primary"
                        style="margin-right: 5px; min-width: 100px;background: transparent linear-gradient(283deg, #040405 0%, #004390 55%, #3F638F 100%) 0% 0% no-repeat padding-box;color: white;border-radius: 50px;"
                        class="btn btn-submit btn-primary mt-4"
                        *ngIf="methodType == 'date'"
                        [disabled]="isLoading"
                        type="button"
                        (click)="senddates()">Download</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="methodType != 'delete' && !risk && methodType !='date'"
     style="min-width: 300px; max-width: 400px;"
     class="text-center">

    <div class="content-head clearfix">
        <div style="font-size: 25px;"
             class="title mb-4">{{heading | titlecase}}</div>
    </div>
    <!-- <div *ngIf="type == 'info'" class="content-head clearfix">
        <div style="font-size: 25px;"
             class="title mb-4">{{heading | titlecase}}</div>
    </div>
    <div *ngIf="type == 'success'" class="content-head clearfix">
        <div style="font-size: 25px;color: green;"
             class="title mb-4">{{heading | titlecase}}</div>
    </div> -->
    <!-- <div *ngIf="type == 'error'" class="content-head clearfix">
        <div style="font-size: 25px;color: red;"
             class="title mb-4">{{heading | titlecase}}</div>
    </div>
    <div *ngIf="type == 'ask'" class="content-head clearfix">
        <div style="font-size: 25px;color: red;"
             class="title mb-4">{{heading | titlecase}}</div>
    </div>
    <div *ngIf="type == 'ask2'" class="content-head clearfix">
        <div style="font-size: 25px;color: red;"
             class="title mb-4">{{heading | titlecase}}</div>
    </div> -->

    <div class="row">
        <div class="col-sm-12 text-center"
             [innerHTML]="message"
             style="max-height: 400px;overflow: auto;">
            <!-- {{message}} -->
        </div>
        <!--<div *ngIf="showLoading"
             style="text-align: center"
             class="col-sm-12">
            <img *ngIf="isLoading"
                 width="50"
                 height="50"
                 src="assets/images/view-loader.gif"
                 alt="">
        </div>-->
        <form [formGroup]="Form">
            <div class="mt-3 text-center ml-3"
                 style="width: 92%"
                 *ngIf="methodType == 'post'">
                <textarea class="form-control "
                          id=""
                          cols="60"
                          rows="5"
                          formControlName="additional_notes"></textarea>
            </div>

        </form>
        <div class="col-sm-12 text-center "
             style="text-align:right; width: 100%;">


            <button mat-raised-button
                    style="margin-right: 5px; min-width: 100px; color: white;border-radius: 50px;"
                    class="btn btn-cancel mt-4"
                    [disabled]="isLoading"
                    type="button"
                    (click)="onCancelClick()">{{cancelButtonText}}</button>

            <button mat-raised-button
                    style="margin-right: 5px; min-width: 100px; color: white;border-radius: 50px;"
                    class="btn btn-ok mt-4"
                    *ngIf="type == 'ask'"
                    [disabled]="isLoading"
                    type="button"
                    (click)="onSubmitClick()">Submit</button>
            <button mat-raised-button
                    style="margin-right: 5px; min-width: 100px; color: white;border-radius: 50px;"
                    class="btn btn-ok mt-4"
                    *ngIf="type == 'ask2'"
                    [disabled]="isLoading"
                    type="button"
                    (click)="onResponce()">{{submitResponceText}}</button>
        </div>
    </div>
</div>

<div *ngIf="methodType == 'delete' &&  methodType !='date' && !risk"
     style="min-width: 300px; text-align: center">

    <img src="assets/images/dowwak-portal/delete-modal.png"
         alt="">


    <div class="row">
        <div class="col-sm-12"
             style="max-height: 400px;overflow: auto;margin-bottom: 5px">
            Are you sure you want to delete?
        </div>
        <div class="col-sm-12"
             style="max-height: 400px;overflow: auto;">
            If this record is deleted, you won't be able to recover it!
        </div>
        <!--  <div *ngIf="showLoading"
             style="text-align: center"
             class="col-sm-12">
            <img *ngIf="isLoading"
                 width="50"
                 height="50"
                 src="assets/images/view-loader.gif"
                 alt="">
        </div>-->
        <div class="col-sm-12"
             style="margin-top: 30px;">

            <button mat-raised-button
                    color="primary"
                    style="margin-right: 5px; min-width: 100px;background: transparent linear-gradient(283deg, #040405 0%, #004390 55%, #3F638F 100%) 0% 0% no-repeat padding-box;color: #213060;border-radius: 50px;"
                    class="btn btn-cancel"
                    [disabled]="isLoading"
                    type="button"
                    (click)="onCancelClick()">CANCEL</button>

            <button mat-raised-button
                    color="primary"
                    style="background: #E51607"
                    class="btn btn-submit btn-primary"
                    *ngIf="type == 'ask'"
                    [disabled]="isLoading"
                    type="button"
                    (click)="onSubmitClick()">DELETE</button>
        </div>
    </div>
</div>
<div *ngIf="risk && methodType !='date'"
     style="min-width: 200px; width: 250px;text-align: center;">

     <div *ngIf="type == 'info'" class="content-head clearfix">
        <div style="font-size: 25px;"
             class="title mb-4">{{heading | titlecase}}</div>
    </div>
    <div *ngIf="type == 'success'" class="content-head clearfix">
        <div style="font-size: 25px;color: green;"
             class="title mb-4">{{heading | titlecase}}</div>
    </div>
    <div *ngIf="type == 'error'" class="content-head clearfix">
        <div style="font-size: 25px;color: red;"
             class="title mb-4">{{heading | titlecase}}</div>
    </div>
    <div *ngIf="type == 'ask'" class="content-head clearfix">
        <div style="font-size: 25px;color: red;"
             class="title mb-4">{{heading | titlecase}}</div>
    </div>
    <div *ngIf="type == 'ask2'" class="content-head clearfix">
        <div style="font-size: 25px;color: red;"
             class="title mb-4">{{heading | titlecase}}</div>
    </div>

    <div class="row text-center mx-1">
        <div class="col-sm-12"
             [innerHTML]="message"
             style="max-height: 400px;overflow: auto;">
            <!-- {{message}} -->
        </div>
        <!--<div *ngIf="showLoading"
             style="text-align: center"
             class="col-sm-12">
            <img *ngIf="isLoading"
                 width="50"
                 height="50"
                 src="assets/images/view-loader.gif"
                 alt="">
        </div>-->
        <div class="col-sm-12"
             style="text-align: center;margin-top: 20px;">

            <button mat-raised-button
                    color="primary"
                    style="margin-right: 5px; border-radius: 50px; min-width: 80px;"
                    class="btn btn-ok"
                    [disabled]="isLoading"
                    type="button"
                    (click)="onOkClick()">{{submitButtonText}}</button>

            <button mat-raised-button
                    color="primary"
                    style="margin-right: 5px; border-radius: 50px; min-width: 80px;"
                    class="btn btn-cancel"
                    [disabled]="isLoading"
                    type="button"
                    (click)="onCancelClick()">{{cancelButtonText}}</button>


        </div>
    </div>
</div>
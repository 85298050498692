import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TokenService } from 'src/app/lib/token/token.service';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit
{

    LOGIN_KEY = 'login_status';

    timer: any;
    timerRunning: boolean = true;
    maxTime: number = 60;
    timerValue: string = '1:00';

    accessToken: any;
    otp: string;

    showOtpComponent = false;
    showCredentials = true;

    otpTries = 0;

    loginData: any;

    @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
    config = {
        allowNumbersOnly: false,
        length: 6,
        isPasswordInput: true,
        disableAutoFocus: false,
        placeholder: '',
        inputStyles: {
            'width': '50px',
            'height': '50px',
            'margin-right': '8px'
        }
    };
    isEnglish: string = 'ltr';
    passwordType: string = 'password';
    logoImage: string;

    constructor(
        protected router: Router,
        protected authService: AuthService,
        protected alertService: AlertService,
        protected route: Router,
        public authorization: TokenService,
        protected translateService: TranslateService)
    {
        var lastSegment = window.location.href.split('//').pop();
        var baseUrl = lastSegment.split(".");
        console.log(baseUrl[0]);
        if (baseUrl[0] == "alpha-staging")
        {
            this.logoImage="assets/logos/alpha_logo.png"
        }
        else
        {
            this.logoImage="assets/thrift_logo.png"
        }
        this.Form = new FormGroup({
            adminUsername: new FormControl(null, [Validators.required, Validators.email]),
            adminPassword: new FormControl(null, Validators.required)
        })
        this.startCall();
        if (localStorage.getItem('lang') == 'ar')
        {
            this.isEnglish = 'rtl';
        }
        else
        {
            this.isEnglish = 'ltr';
        }
    }

    langChange(event)
    {

    }

    onOtpChange(otp)
    {
        this.otp = otp;
        if ((otp.toString().length == 6 && this.otpTries > 0))
        {
            // console.log('this.otpTries > 0')
            return;
        }
        if (otp.toString().length < 6)
        {
            this.otpTries = 0;
        }
        if (otp.toString().length == 6 && this.otpTries == 0)
        {
            let data = {
                otp: otp,
                user: this.authService.decryptionAuth(localStorage.getItem(this.LOGIN_KEY))
            }
            let otpp = this.authService.encryptionAuth(data)

            let data1 = {
                enc_password: localStorage.getItem('password_token'),
                enc_payload: otpp
            }
            this.validate(data1);
            this.otpTries++;
        }
    }

    Form: FormGroup
    checked: boolean;

    ngOnInit(): void
    {
    }

    langToggle(checked)
    {

    }

    navigateToForgot()
    {
        this.route.navigateByUrl('auth/recovery-password');
    }

    timerOTP(remaining)
    {
        let m = Math.floor(remaining / 60);
        let s = remaining % 60;

        let min = m < 10 ? '0' + m : m;
        let sec = s < 10 ? '0' + s : s;
        this.timerValue = min + ':' + sec;
        remaining -= 1;

        if (remaining >= 0)
        {
            this.timer = setTimeout(x =>
            {
                this.timerRunning = true;
                this.timerOTP(remaining);
            }, 1000);
            return
        }
        else
        {
            this.timerRunning = false;
        }
    }

    // otpTimerCheck()
    // {
    //     this.timer = setTimeout(x =>
    //     {
    //         this.maxTime -= 1;
    //         if (this.maxTime < 10)
    //         {
    //             this.timerValue = '00:0' + this.maxTime;
    //         }
    //         if (this.maxTime > 10)
    //         {
    //             this.timerValue = '00:' + this.maxTime;
    //         }
    //         if (this.maxTime > 0)
    //         {
    //             this.timerRunning = true;
    //             this.otpTimerCheck();
    //         }
    //         else
    //         {
    //             this.timerRunning = false;
    //         }
    //     }, 1000);
    // }

    login(optData?)
    {
        if (!this.checkValidations())
        {
            return;
        }
        let creds = {
            username: this.Form.get('adminUsername').value,
            password: this.Form.get('adminPassword').value,
            portalID: 'employerPortal'
        }
        let data = {
            enc_password: localStorage.getItem('password_token'),
            enc_payload: this.authService.encryptionAuth(creds)
        }
        if (!optData)
        {
            this.loginData = data;
        }
        this.authService.auth('login', this.loginData).then((res) =>
        {
            if (res.statusCode == 200)
            {
                let decrypted = this.authService.decryptionAuth(res.data);
                this.setDataAfterValidate(decrypted);
                localStorage.setItem(this.LOGIN_KEY, res.data);
                this.showOtpComponent = true;
                this.showCredentials = false;
                if (!optData)
                {
                    // this.otpTimerCheck();
                    this.timerOTP(180);
                }
            }
            else
            {
                this.alertService.alert('Error', localStorage.getItem('lang') == 'ar' ? res.message_ar : res.message, '');
            }
        })
    }

    validate(data)
    {
        this.authService.auth('validate', data).then(response =>
        {
            if (response.statusCode == 200)
            {
                localStorage.removeItem(this.LOGIN_KEY);
                let decrypted = this.authService.decryptionAuth(response.data);
                localStorage.setItem('employer_token', decrypted.access_authorization);
                this.authorization.setToken(decrypted.access_authorization);
                this.authService.token = decrypted.access_authorization;
                localStorage.setItem('employer_password_token', decrypted.password_token);
                localStorage.setItem('isLoggedIn', '1');
                this.router.navigateByUrl('terms')
            }
            else
            {
                this.alertService.alert('Error', localStorage.getItem('lang') == 'ar' ? response.message_ar : response.message, 'btn-blue');
            }
        })
    }

    setDataAfterValidate(result)
    {
        // console.log("logingresult",result)
        // localStorage.setItem('employer_password_token', result.authorization.password_token);
        // localStorage.setItem('logo_image', result.logo_image);
        localStorage.setItem('employer_name', result.name);
        localStorage.setItem('employer_id', result.id);
        // this.authorization.setToken(localStorage.getItem('employer_token'));
        localStorage.setItem('employer_email', result.email);
        localStorage.setItem('employer_iqama_id', result.iqama_id);
        localStorage.setItem('employer_iqama_expiry', result.iqama_expiry);
        localStorage.setItem('employer_iqama_image', result.iqama_image);
        localStorage.setItem('employer_role', result.role)
        localStorage.setItem('terms_status', result?.terms_status)
        localStorage.setItem('admin_term_status', result.admin_term_status);
    }

    resendOTP()
    {
        // if (!this.timerRunning)
        // {
        //     this.timerRunning = true;
        //     this.otpTimerCheck();
        // }
        // this.maxTime = 60;
        // this.login(true);
        if (!this.timerRunning)
        {
            this.timerRunning = true;
            this.timerOTP(180);
        }
        this.login(true);
    }

    startCall()
    {
        localStorage.removeItem(this.LOGIN_KEY);
        this.authService.getStart('start').then((res) =>
        {
            if (res.statusCode == 200)
            {
                localStorage.setItem('password_token', res.data.access_token);
            }
            else
            {
                // console.log('start err', res.error);
            }
        })
    }

    checkValidations()
    {
        if (this.Form.invalid)
        {
            this.Form.markAllAsTouched();
            return false;
        }
        return true;
    }

    getFieldValue(field)
    {
        if (this.Form.get(field).touched)
        {
            if (this.Form.get(field).invalid)
            {
                return true;
            }
        }
        return false;
    }

    getErrorMessage(control, fieldName, minMaxValue?)
    {
        if (this.Form.get(control).invalid)
        {
            if (this.Form.get(control).hasError('required'))
            {
                return this.translateService.instant(fieldName);
            }
            if (this.Form.get(control).hasError('email'))
            {
                return this.translateService.instant('Email_Format');
            }
        }
    }

    handlePassword()
    {
        if (this.passwordType == 'text')
        {
            this.passwordType = 'password';
        }
        else
        {
            this.passwordType = 'text';
        }
    }

    navigateToRegister()
    {
        this.route.navigateByUrl('auth/register');
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { FormBuilder } from "@angular/forms";
import EditorJS from '@editorjs/editorjs';
import Header from "@editorjs/header";
import NestedList from '@editorjs/nested-list';
import Table from "@editorjs/table";
import SimpleImage from "@editorjs/simple-image";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import Alert from "editorjs-alert";
import Raw from "@editorjs/raw";
import AlignmentTuneTool from "editorjs-text-alignment-blocktune";
import DragDrop from "editorjs-drag-drop";
import Underline from "@editorjs/underline";
import ColorPlugin from "editorjs-text-color-plugin";
import { AlertDialog } from 'src/app/lib/alert-dialog/alert.dialog';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'src/app/services/message.service';
import { MainService } from 'src/app/services/main.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { appConfig } from 'src/config';
import * as moment from 'moment';
import { AlertService } from 'src/app/services/alert.service';
import { ProfileService } from 'src/app/services/profile.service';


@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
    editor: EditorJS = null;
    data: any;
    updatedAt = null;
    lang = 'en';
    isAdminApproved: boolean = true;
    userRole: string = null;
    isEnglish:boolean = true;

    constructor(public route: Router, 
        public http: HttpClient, 
        public tr: TranslationService,
        protected dialog: MatDialog, 
        protected formbuilder: FormBuilder, 
        public dec_enc: DecEncService, 
        private mainService: MainService, 
        protected messagingService: MessageService,
        protected alertService: AlertService,
        protected profileService: ProfileService) 
        {
        // this.userRole = localStorage.getItem('employer_role');
        this.userRole = profileService.user.role;
        let admin_term_status = localStorage.getItem('admin_term_status');
        if (this.userRole == 'emp-maker' || this.userRole == 'emp-checker' || this.userRole == 'emp-finance') {
            if (admin_term_status == null || admin_term_status == '0') {
                this.isAdminApproved = false;
            }
        }
        this.getTerms()
        this.editor = new EditorJS({
            holder: 'editorjs',
            readOnly: true,
            tools: {
                header: {
                    class: Header,
                    inlineToolbar: true
                },
                paragraph: {
                    inlineToolbar: true,
                    tunes: ["alignmentTuneTool"]
                },
                image: {
                    class: SimpleImage,
                    inlineToolbar: true,
                    tunes: ["alignmentTuneTool"]
                },
                list: {
                    class: NestedList,
                    inlineToolbar: true,
                    shortcut: "CMD+SHIFT+L",
                    tunes: ["alignmentTuneTool"]
                },
                Color: {
                    class: ColorPlugin,
                    config: {
                        type: "text"
                    }
                },
                table: {
                    class: Table,
                    inlineToolbar: true,
                    shortcut: "CMD+SHIFT+T",
                    tunes: ["alignmentTuneTool"]
                },
                alert: {
                    class: Alert,
                    inlineToolbar: true,
                    shortcut: "CMD+SHIFT+A",
                    tunes: ["alignmentTuneTool"]
                },
                Marker: {
                    class: ColorPlugin,
                    config: {
                        type: "marker"
                    }
                },
                delimiter: Delimiter,
                inlineCode: InlineCode,
                // style: EditorJSStyle.StyleInlineTool,
                raw: Raw,
                underline: Underline,
                alignmentTuneTool: {
                    class: AlignmentTuneTool
                }
            },
            onReady: () => {
                // new Undo({ editor, config });
                new DragDrop(this.editor);
            },
        });

    }

    ngOnInit(): void {
        if (localStorage.getItem('lang') == 'ar')
        {
            this.isEnglish = false;
        }
        else
        {
            this.isEnglish = true;
        }
    }

    getTerms() {
        let url = 'home/terms'
        this.mainService.postData(url, {}).then(res => {
            if (res.statusCode == 200) {
                this.data = this.dec_enc.decryption(res.data);
                this.updatedAt = moment(this.data.updated_at).format(appConfig.dateTimeformatCMS);
                if(this.isEnglish){
                    this.editor.render(this.data.terms);
                }else{
                    this.editor.render(this.data.terms_ar);
                }
                return
            }
            else
            {
                if (localStorage.getItem('lang') == 'ar')
                {
                    this.alertService.alert('Error', res.error.message_ar);
                }
                else
                {
                    this.alertService.alert('Error', res.error.message);
                }
            }
          
        },
            error => {
                this.alertService.alert('Error','Server Error');
            }
        )
    }

    AcceptTerms() {
        let url = 'auth/accept_terms'
        this.mainService.postData(url, {}).then(res => {
            if (res.statusCode == 200) {
                localStorage.setItem('terms_status', '1');
                this.route.navigate(['/main'])
            }
            else
            {
                if (localStorage.getItem('lang') == 'ar')
                {
                    this.alertService.alert('Error', res.error.message_ar);
                }
                else
                {
                    this.alertService.alert('Error', res.error.message);
                }
            }
        },
            error => {
                this.alertService.alert('Error','Server Error');
            }
        )
    }

    cancelTerms() {
        this.alertService.alertAsk('Confirmation', 'You will be taken back to login screen. Are you sure you want cancel?', 'Yes', 'No', false).then(result => {
            if (result)
            {
                this.logout();
            }
        })
    }

    logout() {
        this.mainService.onLogout().then(result => {
            if (result.statusCode == 200) {
                localStorage.clear();
                localStorage.removeItem('employer_token');
                localStorage.removeItem('terms_status');
                localStorage.removeItem('name');
                localStorage.removeItem('employer_password_token');

                window.location.reload();
            }
        })

    }

    switchLang(param) {
        this.lang = param;
        if (param == 'en') {
            this.editor.render(this.data.terms);
        }
        if (param == 'ar') {
            this.editor.render(this.data.terms_ar);
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { AlertDialog } from 'src/app/lib/alert-dialog/alert.dialog';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import * as moment from 'moment';
import { ViewSubscriptionFormDialogComponent } from '../subscription-form-dialog/view-subscription-form-dialog.component';
@Component({
    selector: 'app-contribution-view',
    templateUrl: './contribution-view.component.html',
    styleUrls: ['./contribution-view.component.scss']
})
export class ContributionViewComponent implements OnInit {
    Form: FormGroup;
    setPassword: FormGroup;
    failureAlert: boolean;
    alertMsg: any = "";
    sub: Subscription;
    id: any;
    path: any;
    hed: any;
    button: any;
    ut;
    emp_id: any;
    items: any = [];
    list: any = [];
    totalFund: any = 0;
    receipet: any;
    filePath: any;
    disabled: boolean = true;
    viewStatus: any;
    listCsv: any[] = [];
    emprSum: any = 0;
    empSum: any = 0;
    cont_swift_number: any = '';
    cont_iban_number: any = '';
    cont_account_number: any = '';
    cont_beneficiary_name: any = '';
    cont_bank_name: any = '';
    isArabic = false;

    tableProps = {
        heading: '',
        hasSearch: false,
        searchLabel: 'Search',
        hasButton: false,
        headerActions: [],
        rowActions : [],
        colHeader: {
            name: 'Portfolio',
            employeeId: 'Employee_ID',
            employeeName: 'Employee_Name',
            employeeContribution: 'Employee Contribution (SAR)',
            employerContribution: 'Company Contribution (SAR)',
            amount: 'Amount (SAR)',
        },
        columnTypes: {
            status: 'errorColumn',
            employeeContribution:'number-4',
            employerContribution:'number-4',
            amount:'number-4',
        },
        filterArray: [],
    };

    colHeader = {
        name: 'Portfolio',
        employeeId: 'Employee_ID',
        employeeName: 'Employee_Name',
        employeeContribution: 'Employee Contribution (SAR)',
        employerContribution: 'Company Contribution (SAR)',
        amount: 'Amount (SAR)',
    }
    columnTypes = {
        status: 'errorColumn'
    }
    imgToShow = null;

    constructor(public tr: TranslationService, protected formbuilder: FormBuilder, protected dialog: MatDialog, protected _route: ActivatedRoute, public dec_enc: DecEncService, protected mainService: MainService) {
        this._route.params.subscribe(params => {
            //console.log("params---", params);
            this.id = params['id'];
        });

        this.isArabic = localStorage.getItem('lang') == 'ar' ? true : false;
        this.Form = this.formbuilder.group({
            id: ['', [Validators.required]],
            employer_id: ['', [Validators.required]],
            employer_name: ['', [Validators.required]],
            created_at: ['', [Validators.required]],
            status: ['', [Validators.required]],
        });
    }

    ngOnInit(): void {
        // this.getaemployee();
        this.getContribution();

    }

    getContribution() {
        let url = 'contributions/csv_details';
        let body = {
            id: this.id
        }
        let payload = this.dec_enc.encryption(body);
        this.mainService.postData(url, { enc_payload: payload }).then(res => {
            if (res.statusCode == 200) {
                let result;
                let data = res.data;
                result = this.dec_enc.decryption(data);
                // console.log('Contribuction listing = ', result);
                this.list = result?.contributions;
                this.listCsv = result?.csv[0]?.employer?.company_name;
                this.cont_bank_name = result?.csv[0]?.employer?.employers_bank_details[0]?.cont_bank_name;
                this.cont_beneficiary_name = result?.csv[0]?.employer?.employers_bank_details[0]?.cont_beneficiary_name;
                this.cont_account_number = result?.csv[0]?.employer?.employers_bank_details[0]?.cont_account_number;
                this.cont_iban_number = result?.csv[0]?.employer?.employers_bank_details[0]?.cont_iban_number;
                this.cont_swift_number = result?.csv[0]?.employer?.employers_bank_details[0]?.cont_swift_number;

                if (result.csv[0].status == 0) {
                    this.Form.get('status').setValue("Pending")
                }
                else if (result.csv[0].status == 1) {
                    this.Form.get('status').setValue("Completed")
                }
                else if (result.csv[0].status == 2) {
                    this.Form.get('status').setValue("Rejected")
                }
                else if (result.csv[0].status == 3) {
                    this.Form.get('status').setValue("Under Process")
                }
                else {
                    this.Form.get('status').setValue(result.csv[0].status);
                }
                this.Form.get('created_at').setValue(moment(result.csv[0].created_at).format('DD-MM-YYYY'));
                this.Form.get('id').setValue(result.csv[0].id);
                this.Form.get('employer_id').setValue(result.csv[0].employer_id);

                this.Form.get('employer_name').setValue(result?.csv[0]?.employer?.company_name);
                //  console.log('Contribuction listCsv = ',this.listCsv);
                for (let i = 0; i < this.list.length; i++) {

                    for (let j = 0; j < this.list[i].length; j++) {

                        //  //console.log("For loop--", this.list[i][j]);
                        this.items.push(this.list[i][j]);
                    }
                }

                this.items.forEach(element => {
                    if (!isNaN(Number(element.contribution.sum_emp_emr_cont))) {
                        var numberValue = Number(element.contribution.sum_emp_emr_cont);
                    }

                    // this.totalFund = parseInt(this.totalFund + numberValue)
                    this.totalFund = parseInt(result?.grand_sum)
                    this.empSum = parseInt(result?.empee_cont_sum)
                    this.emprSum = parseInt(result?.emprr_cont_sum)

                    if (element?.contribution?.csv_id == this.id) {
                        // let created_at = moment(element?.contribution?.created_at).format('DD-MM-YYYY');
                        // this.Form.get('id').setValue(element?.contribution?.csv_id);
                        // this.Form.get('employer_id').setValue(element?.contribution?.employer_id);
                        //  this.Form.get('employer_name').setValue(element?.employers_employee?.user?.name);
                        // this.Form.get('created_at').setValue(created_at);
                        this.viewStatus = element?.contribution?.status;
                        // if (element?.contribution?.status == 0) {
                        //     this.Form.get('status').setValue("Pending")
                        // }
                        // else if (element?.contribution?.status == 1) {
                        //     this.Form.get('status').setValue("Completed")
                        // }
                        // else if (element?.contribution?.status == 2) {
                        //     this.Form.get('status').setValue("Rejected")
                        // }
                        // else if (element?.contribution?.status == 3) {
                        //     this.Form.get('status').setValue("Under Process")
                        // }
                        // else {
                        //     this.Form.get('status').setValue(element?.contribution?.status);
                        // }

                    }
                    element['name'] = element?.fund?.name;
                    element['employeeId'] = element?.employers_employee?.employee_id;
                    element['employeeName'] = element?.employers_employee?.user?.name;
                    element['employeeContribution'] = element?.contribution?.employee_contribution_amount;
                    element['employerContribution'] = element?.contribution?.employer_contribution_amount;
                    element['amount'] = element?.contribution?.sum_emp_emr_cont;

                });
                let obj = {
                    name: 'Total Amount',
                    amount: this.totalFund,
                    employeeContribution: this.empSum,
                    employerContribution: this.emprSum
                }
                this.items.push(obj);
                this.items = [].concat(this.items);
            }

        },
            error => {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = "Submitted Successfully";
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';

            }
        );
    }

    openDialog() {
        let dialogRef = this.dialog.open(ViewSubscriptionFormDialogComponent,
            {
                // autoFocus: true,
                // maxHeight: '90vh',
                // width: '70%',
                data: this.id,
                autoFocus: false,
                width: '80%',
                maxHeight: '90%',
                panelClass: this.isArabic ? 'arabic-lang' : 'english-lang'

            });
        let cm = dialogRef.componentInstance;
        dialogRef.componentInstance.resultId = this.id;

        dialogRef.afterClosed().subscribe(result => {

        })
    }

    // getaemployee() {

    //   let enc_payload = this.dec_enc.encryption(this.employer_id);
    //   let url = 'employees';
    //   this.mainService.postData(url, { enc_payload }).then(res => {
    //     if (res.statusCode == 200) {


    //       let result;
    //       let data = res.data

    //       result = this.dec_enc.decryption(data);
    //       this.items = result.employees;
    //       //console.log('Employees listing = ', this.items);
    //     }

    //   },
    //     error => {
    //       let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
    //       let cm = dialogRef.componentInstance;
    //       cm.heading = this.tr.translation.error;
    //       cm.message = "Submitted Successfully";
    //       cm.cancelButtonText = this.tr.translation.okay;
    //       cm.type = 'error';

    //     }
    //   );
    // }

    viewSubscription()
    {

    }

    onFileChange(event: any) {

        this.filePath = event.target.files[0].name;
        //console.log("filepath---", this.filePath);

        var allowedExtensions = /(\.|\.jpg|\.jpeg|\.jfif|\.pjp|\.png|\.svg|\.webp|\.pdf)$/i;
        if (!allowedExtensions.exec(this.filePath)) {
            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
            let cm = dialogRef.componentInstance;
            cm.heading = 'Error';
            cm.message = 'Upload only Image formats.';
            cm.cancelButtonText = 'Okay';
            cm.type = 'error';
        }
        else {
            this.disabled = false;
            const file = (event.target.files[0] as File);
            //console.log("file", file);
            this.receipet = file;
        }
    }

    onBack() {
        window.history.back();
    }

    uploadReceipt() {
        let url = 'contributions/upload_receipet';
        let message = "Receipt uploaded Successfully";
        if (this.receipet != null) {
            let body = {
                csv_id: this.id
            }
            let payload = this.dec_enc.encryption(body);

            const formData = new FormData();
            formData.append('enc_payload', payload);
            formData.append('receipet', this.receipet);

            this.mainService.postData(url, formData).then(result => {
                if (result.statusCode == 200 || result.status == 200) {
                    let data = this.dec_enc.decryption(result.data);
                    //console.log("data---", data);
                    let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                    let cm = dialogRef.componentInstance;
                    cm.heading = 'success';
                    cm.message = message;
                    cm.cancelButtonText = 'Okay';
                    cm.type = 'success';
                    dialogRef.afterClosed().subscribe(result => {
                        this.onBack();
                    })
                }
                else if (result.status == 422) {
                    let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                    let cm = dialogRef.componentInstance;
                    cm.heading = 'Error';
                    cm.message = result.error.message;
                    cm.cancelButtonText = 'Okay';
                    cm.type = 'error';
                }
                else if (result.status == 412) {
                    let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                    let cm = dialogRef.componentInstance;
                    cm.heading = 'Error';
                    cm.message = result.error.message;
                    cm.cancelButtonText = 'Okay';
                    cm.type = 'error';
                }
                else if (result.status == 404) {
                    let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                    let cm = dialogRef.componentInstance;
                    cm.heading = 'Error';
                    cm.message = result.error.message;
                    cm.cancelButtonText = 'Okay';
                    cm.type = 'error';
                }
                else if (result.status == 406) {
                    let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                    let cm = dialogRef.componentInstance;
                    cm.heading = 'Error';
                    cm.message = result.error.message;
                    cm.cancelButtonText = 'Okay';
                    cm.type = 'error';
                }
                else {
                    let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                    let cm = dialogRef.componentInstance;
                    cm.heading = 'Error';
                    cm.message = result.error.message;
                    cm.cancelButtonText = 'Okay';
                    cm.type = 'error';
                }
            },
                error => {
                    let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                    let cm = dialogRef.componentInstance;
                    cm.heading = 'Error';
                    cm.message = 'Internal Server error';
                    cm.cancelButtonText = 'Okay';
                    cm.type = 'error';
                }
            );
        }
        else {
            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
            let cm = dialogRef.componentInstance;
            cm.heading = 'Missing Attachment';
            cm.message = 'Please upload the Contribution Receipt to proceed.';
            cm.cancelButtonText = 'Okay';
            cm.type = 'error';

        }





    }

    back() 
    {
        window.history.back()
    }

    getFile(event: any) 
    {
        const file = (event.target.files[0] as File);
        this.receipet = file;
        if (event.target.files && event.target.files.length > 0) {
            const file = (event.target.files[0] as File);

            const reader = new FileReader();
            reader.onload = e => this.imgToShow = reader.result;
    
            reader.readAsDataURL(file);
        }
    }
}






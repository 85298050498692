import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/timeoutWith';
import { appConfig } from '../../config';
import { TokenService } from '../lib/token/token.service';
import { Router } from '@angular/router';


@Injectable()
export class MainService {
	token: any;
	headers: HttpHeaders;
	options: any;
	public auth_key: string;
	public baseUrl: string;
	public base_url_slug: string;
    selectedApiCallAgain: Subject<any> = new Subject<any>();
	appSelectorSubscription: Subscription;
	user_app: any;
	file_url: any;
	employer_name = new BehaviorSubject(localStorage.getItem('employer_name'));
	userRole: string = '';
	constructor(private http: HttpClient, public authorization: TokenService,
		protected router: Router) {
		this.authorization.selectedToken.subscribe((state) => {
			this.token = this.authorization.getToken();
		})
		this.token = this.authorization.getToken();
		this.file_url = appConfig.file_url;
		this.baseUrl = appConfig.base_url;
		this.headers = new HttpHeaders({ 
			'Authorization': this.token,
			'portal': 'company'
		});
		this.options = { headers: this.headers, observe: 'response' };
	}

	public getList(url: string): Promise<any> {

		return this.http.get(this.baseUrl + url, this.options)
			.toPromise().then((response: any) => {
				if (response.statusCode === 401) {
					localStorage.clear();
					window.location.reload();

				}
				else {
					return response.body;
				}
			},
				(reason: any) => {
					if (reason.statusCode === 401 || reason.statusCode === 403) {
						localStorage.clear();
						window.location.reload();
						return reason;
					}
					return reason;

				}).catch(this.handleError);
	}

	public delete(url: string): Promise<any> {
		return this.http.delete(this.baseUrl + url, this.options)
			.toPromise().then((response: any) => {
				if (response.statusCode === 401) {
					localStorage.clear();
					window.location.reload();

				}
				else {
					return response.body;
				}
			},
				(reason: any) => {
					if (reason.error.statusCode === 401 || reason.error.statusCode === 403) {
						localStorage.clear();
						window.location.reload();
						return reason;
					}
					return reason;

				}).catch(this.handleError);
	}

	onLogout(): Promise<any> {
		return this.http.post(this.baseUrl + 'logout', {}, this.options)
			.toPromise().then((response: any) => {
				if (response.statusCode === 401) {
					localStorage.clear();
					window.location.reload();
				}
				else {
					return response.body;
				}
			},
				(reason: any) => {
					if (reason.error.statusCode === 401) {
						localStorage.clear();
						window.location.reload();
						return reason;
					}
					return reason;

				}).catch(this.handleError);
	}

	postData(endpoint: string, formData: any): Promise<any> {


		return this.http.post(this.baseUrl + endpoint, formData, this.options)
			.toPromise().then((response: any) => {
				if (response.statusCode === 401 || response.statusCode === 403) {
					localStorage.clear();
					window.location.reload();
				}
				else {
					return response.body;
				}
			},
				(reason: any) => {
					if (reason.error.statusCode === 401) {
						localStorage.clear();
						window.location.reload();
						return reason;
					}
                    else if (reason.status === 503)
                    {
						localStorage.setItem('maintenance_message', reason.error.message);
                        localStorage.setItem('maintenance_message_ar', reason.error.message_ar);
                        this.router.navigateByUrl('main/maintenance')
                    }
					return reason;

				}).catch(this.handleError);
	}

	readNotifications(endpoint: string, formData: any): Promise<any> {
		let baseURLforAuth = this.baseUrl.replace('employerPortal', 'v2');
		return this.http.post(baseURLforAuth + endpoint, formData, this.options)
			.toPromise().then((response: any) => {
				if (response.statusCode === 401 || response.statusCode === 403) {
					localStorage.clear();
					window.location.reload();
				}
				else {
					return response.body;
				}
			},
				(reason: any) => {
					if (reason.error.statusCode === 401) {
						localStorage.clear();
						window.location.reload();
						return reason;
					}
					return reason;

				}).catch(this.handleError);
	}

	setApiOnCall(value)
    {
        this.selectedApiCallAgain.next(value);
    }

	patch(endpoint: any, formData: any): Promise<any> {
		return this.http.patch(this.baseUrl + endpoint, formData, this.options)
			.toPromise().then((response: any) => {
				if (response.statusCode === 401) {
					localStorage.clear();
					window.location.reload();
				}
				else {
					return response.body;
				}
			},
				(reason: any) => {
					if (reason.error.statusCode === 401) {
						localStorage.clear();
						window.location.reload();
						return reason;
					}
					return reason;

				}).catch(this.handleError);
	}

	postFormData(endpoint: string, formData: any): Promise<any> {
		formData.append('user_app_id', this.user_app.user_app_id);
		return this.http.post(this.baseUrl + endpoint, formData, this.options)
			.toPromise().then((response: any) => {
				if (response.statusCode === 401) {
					localStorage.clear();
					window.location.reload();
				}
				else {
					return response.body;
				}
			},
				(reason: any) => {
					if (reason.error.statusCode === 401) {
						localStorage.clear();
						window.location.reload();
						return reason;
					}
					return reason;

				}).catch(this.handleError);
	}

	public handleError(error: any): Promise<any> {
		return error;
	}
}
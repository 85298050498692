import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
	providedIn: 'root'
}) 
export class AuthGuard implements CanActivate {
	constructor(public route: Router) {

	}
	canActivate() {
		let token = localStorage.getItem('employer_token');

		if (token == void 0) {
			return true;
		}
		else {
			this.route.navigate(['/terms']);
			return false;
		}
	}
}



<div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
  <div class="profile-card">
    <div fxLayout="row" fxLayoutAlign="end center" class="mb-20">
      <div fxFlex="100" style="text-align: end;">
        <button class="btn-disabled" (click)="onBack()" mat-raised-button>
          {{'Back' | translate}}
        </button>
      </div>
    </div>
    <ng-container *ngFor="let item of fundlist">
      <mat-card class="mb-3" (click)="onSelectFund(item.id, item.risk_rating)">
        <div class="arrow-right" *ngIf="selected_fund_id == item.id">
        </div>
        <div class="text-center mb-3" class="fund-togle">
          <h1 style="color: #00C435;" class="text-center" *ngIf="item.risk_rating=='low'">{{'low' | translate}}</h1>
          <h1 style="color: #E69A5A;" class="text-center" *ngIf="item.risk_rating=='medium'">{{'medium' | translate}}
          </h1>
          <h1 style="color: #EC4955;" class="text-center" *ngIf="item.risk_rating=='high'">{{'high' | translate}}</h1>
          <div class="portfolio mb-1">
            <!-- <p *ngIf="recomended_risk==item.risk_rating">Your recomendod risk profile is {{item.risk_rating}} risk</p> -->
            <h3 class="portfolio-name">{{'Fund_Name' | translate}}: {{item.name | translate}}</h3>
            <h3 class="portfolio-name">{{'ExpectedReturn' | translate}} :
              <!-- {{item?.expected_return_percentage ? item?.expected_return_percentage: '0'}} % -->

              <span style="font-size: 16px;">{{item?.expected_return_percentage | number:'1.2-2' | slice:0:-2}}</span>
              <span style="font-size: 13px;">{{item?.expected_return_percentage | number:'1.2-2' | slice:-2}}%</span>
              <!-- <span style="font-size: 16px;">%</span> -->
            </h3>
            <a routerLink="../../main/allocate-contribution/fund-detail/{{item.id}}">{{'Learn_more' | translate}}</a>
          </div>
        </div>
      </mat-card>
    </ng-container>
  </div>
</div>
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { tap,delay } from 'rxjs/operators';
import { ApiLoaderService } from './api-loader.service';


@Injectable({
	providedIn: 'root'
})
export class ApiLoaderInterceptorService implements HttpInterceptor {
	unloaderUrl: any;
	count: any;
	constructor(private loaderService: ApiLoaderService) {

	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let url = req.url.split('fetch');
		this.unloaderUrl = req.url;
		var lastSegment = this.unloaderUrl.split('/').pop()
		//console.log(lastSegment);
		if (lastSegment === 'unread_count' || lastSegment === 'home/unread_count' || lastSegment ===  'notifications' || lastSegment ===  'home/read_notifications' || lastSegment ===  'read_notifications')
		{
			//console.log('Notifications if', lastSegment);
		}
		else
		{
			//console.log('Notifications else', lastSegment)
			this.showLoader();
			this.count++;
		}
		const customReq = req.clone({});
		return next.handle(customReq).pipe(delay(1000), tap((event: HttpEvent<any>) =>
		{
			if (event instanceof HttpResponse)
			{
				this.hideLoader();
			}
		},
			(err: any) =>
			{
				this.count--;
				this.hideLoader();
			}));
	}
	// intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
	// 	let url = req.url.split('fetch');
	// 	// //console.log("SPLIT : ", url);

	// 	// if (url.length > 1) {
	// 	// 	let url2 = url[1].split('?');
	// 	// 	// //console.log("SPLIT 2: ", url2);
	// 	// 	if (url2[0] == '/orders') {

	// 	// 	}
	// 	// 	else {
	// 	// 		this.showLoader();
	// 	// 	}
	// 	// }
	// 	// else {
	// 		this.showLoader();
	// 	// }

	// 	return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
	// 		if (event instanceof HttpResponse) {
	// 			this.onEnd();
	// 		}
	// 	},
	// 		(err: any) => {
	// 			this.onEnd();
	// 		}));
	// }

	private onEnd(): void {
		this.hideLoader();
	}

	private showLoader(): void {
		this.loaderService.show();
	}

	private hideLoader(): void {
		this.loaderService.hide();
	}
}
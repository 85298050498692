import { OnInit, Component } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";

export class AlertButton
{
	text: string;
	class: string;
	visibility?: boolean;
}

export class AlertData
{
	heading: string = '';
	message: string = '';
	hasInput: boolean = false;
	hasArabic: boolean = false;
	type: 'ask' | 'success' | 'error' | 'info';
	positiveButton: AlertButton;
	negativeButton: AlertButton;
	neutralButton?: AlertButton;
}

export class AlertResult
{
	result: boolean;
	text?: string;
}

@Component({
	selector: 'alert-dialog',
	templateUrl: './alert.dialog.new.html'
})
export class AlertDialogNew implements OnInit 
{
	isLoading: boolean;
	hasInput: boolean = false;
	hasArabic: boolean = false;
	resultData: AlertResult = {
		result: false,
		text: null
	}
	dataToSubmit: any;
	methodName: any;
	showLoading: boolean;

	alertData: AlertData;
	isError: boolean = false;

	constructor(
		protected dialogRef: MatDialogRef<AlertDialogNew>, protected dialog: MatDialog) 
	{
		this.isLoading = false;
		this.showLoading = false;
		this.hasInput = false;

		this.alertData = {
			heading: 'Data',
			message: '',
			type: 'ask',
			hasInput: false,
			hasArabic:false,
			positiveButton: {
				text: 'No',
				class: 'Yes',
				visibility: true
			},
			negativeButton: {
				text: 'No',
				class: 'Yes',
				visibility: true
			},
			neutralButton: {
				text: 'Cancel',
				class: 'Yes',
				visibility: false
			},
		}
	}

	ngOnInit() 
	{
	}

	onNegative(): void
	{
		this.dialogRef.close(false);
	}

	onPositive(): void
	{
		if(this.alertData.hasInput == true)
		{
			this.isError = false;
			if(this.resultData.text == null)
			{
				this.isError = true;
				return;
			}
		}
		
		this.isLoading = true;

		if (this.alertData.hasInput)
		{
			this.resultData.result = true;
			this.dialogRef.close(this.resultData);
		}
		else
		{
			this.dialogRef.close(true);
		}
	}

	onNeutral(): void
	{
		if(this.alertData.hasInput == true)
		{
			this.isError = false;
			if(this.resultData.text == null)
			{
				this.isError = true;
				return;
			}
		}
		
		this.isLoading = true;

		if (this.alertData.hasInput)
		{
			this.resultData.result = false;
			this.dialogRef.close(this.resultData);
		}
		else
		{
			this.dialogRef.close(true);
		}
	}
}

<div fxLayoutAlign="space-between" class="long-card mbl-10" fxLayout="row" *ngFor="let item of list">
    <div class="p-10 w-200" *ngFor="let data of headers" [ngStyle]="data.header =='Current NAV per Unit' ? {'width': '281px'} : '' ">
        <p class="pt-10 m-0 mb-5 ellipsis">{{data.header | translate}}</p>

        <h3 *ngIf="!data.type" class="pb-10 m-0 fw-b ellipsis">{{item[data.key]}}</h3>
        <h3 matTooltip="{{item[data.key]}}" *ngIf="data.type == 'string-tooltip'" class="pb-10 m-0 fw-b ellipsis">{{item[data.key]}}</h3>
        <h3 *ngIf="data.type == 'number'" class="pb-10 m-0 fw-b ellipsis">
            <!-- {{item[data.key] | number: '1.2-2'}} -->

            <span style="font-size: 16px;">{{item[data.key] | number:'1.2-2' | slice:0:-2}}</span>
            <span style="font-size: 13px;">{{item[data.key] | number:'1.2-2' | slice:-2}}</span>
        </h3>
        <h3 *ngIf="data.type == 'number-4'" class="pb-10 m-0 fw-b ellipsis">
            <!-- {{item[data.key] | number: '1.4-4'}} -->

            <span style="font-size: 16px;">{{item[data.key] | number:'1.4-4' | slice:0:-4}}</span>
            <span style="font-size: 13px;">{{item[data.key] | number:'1.4-4' | slice:-4}}</span>
        </h3>
        <h3 *ngIf="data.type == 'number-5'" class="pb-10 m-0 fw-b ellipsis">
            <!-- {{item[data.key] | number: '1.5-5'}} -->

            <span style="font-size: 16px;">{{item[data.key] | number:'1.5-5' | slice:0:-5}}</span>
            <span style="font-size: 13px;">{{item[data.key] | number:'1.5-5' | slice:-5}}</span>
        </h3>
    </div>
</div>

import { Injectable } from '@angular/core';
// import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { appConfig } from '../../config';


import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';



@Injectable()
export class AuthService {
    headers: HttpHeaders;
    options: any;
    headersForAuth: HttpHeaders;
	optionsForAuth: any;
    public auth_key: string;
    public baseUrl: string;
    token: any;


    constructor(private http: HttpClient, private router: Router) {
        this.token = localStorage.getItem('employer_token');
        this.baseUrl = appConfig.base_url;
        this.headersForAuth = new HttpHeaders({ 
            'x-api-client': '23abc',
            'portal': 'company'
        });
		this.optionsForAuth = { headers: this.headersForAuth, observe: 'response' };
        // this.headers = new HttpHeaders({ 'Authorization': appConfig.default_auth_key });
        // this.options = { headers: this.headers, observe: 'response' };

    }
    public genrateOtp(formData: any): Promise<any> {
        return this.http.post(this.baseUrl + 'generateOTP', formData)
        .toPromise().then((response: any) => {
            let result: any = response;
            return result;
        },
            (reason: any) => {
                return reason;
            }).catch(this.handleError);
    }
   

    public login(formData: any): Promise<any> {
        return this.http.post(this.baseUrl + 'login', formData,{ headers: { portalID : "employerPortal" }})
        .toPromise().then((response: any) => {
            let result: any = response;
            return result;
        },
            (reason: any) => {
                return reason;
            }).catch(this.handleError);

    }
    public resetPassword(formData: any): Promise<any> {
        return this.http.post(this.baseUrl + 'resetPassword', formData,{ headers: { portalID : "employerPortal" }})
        .toPromise().then((response: any) => {
            let result: any = response;
            return result;
        },
            (reason: any) => {
                return reason;
            }).catch(this.handleError);

    }
    logout(): Observable<any> {
        return this.http.post(this.baseUrl + 'logout', {}, { headers: { Authorization: this.token } });
    }

    // public sendMT(formData: any): Promise<any>
    // {
    //     return this.http.post('http://18.184.164.213/up_qatar/api/v1/subscription/sendMT', formData, this.options)
    //     .toPromise().then((response: any) =>
    //     {
    //         let result: any = response.body;
    //         //console.log('Service Call', result);
    //         return result;
    //     },
    //     (reason: any) =>
    //     {
    //         return reason;
    //     }).catch(this.handleError);
    // }

    public forgotPassword(formData: any): Promise<any> {
        return this.http.post(this.baseUrl + 'send_email', formData, this.options)
            .toPromise().then((response: any) => {
                let result: any = response.body;
                return result;
            },
                (reason: any) => {
                    return reason;
                }).catch(this.handleError);
    }

    // public resetPassword( formData: any): Promise<any> {
    //     return this.http.post(this.baseUrl + 'resetPassword', formData, this.options)
    //         .toPromise().then((response: any) => {
    //             let result: any = response;
    //             return result;
    //         },
    //             (reason: any) => {
    //                 return reason;
    //             }).catch(this.handleError);
    // }

    public recoveryCode(formData: any): Promise<any> {
        return this.http.post(this.baseUrl + 'verify_code', formData, this.options)
            .toPromise().then((response: any) => {
                let result: any = response.body;
                return result;
            },
                (reason: any) => {
                    return reason;
                }).catch(this.handleError);
    }

    public getMethod(params: string): Promise<any> {
        return this.http.get(this.baseUrl + params, this.options)
            .toPromise().then((response: any) => {
                if (response.status === 401) {
                    localStorage.clear();
                    this.router.navigate(['auth/login']);
                }
                else {
                    return response.body;
                }
            },
                (reason: any) => {
                    return reason;
                }).catch(this.handleError);
    }

    public handleError(error: any): Promise<any> {
        // //console.log("handleError = " + error);
        return error;
    }

    public getStart(url: string)
    {
		let baseURLforStart = this.baseUrl.replace('employerPortal', 'v2');
		return this.http.get(baseURLforStart + url, this.optionsForAuth).toPromise().then((response: any) => {
			return this.onResponse(response, 'response');
		},
		(reason: any) => {
			return this.onResponse(reason, 'error');
		}).catch(this.handleError);
    }

	auth(url, data)
    {
		let baseURLforAuth = this.baseUrl.replace('employerPortal', 'v2');
		return this.http.post(baseURLforAuth + url, data, this.optionsForAuth).toPromise().then((response: any) => {
			return this.onResponse(response, 'response');
		},
		(reason: any) => {
			return this.onResponse(reason, 'error');
		}).catch(this.handleError);
    }

    encryptionAuth(data) {
        let key = localStorage.getItem('password_token')
        let password = key?.toString();
        let phrase = JSON.stringify(data);
        let result;
        try 
        {
            result = CryptoJS.AES.encrypt(phrase, password).toString().trim() || "{}";
        }
        catch (e) { "{}"; }
        return result;
    }

    decryptionAuth(data) {
        let key = localStorage.getItem('password_token')
        let password = key;
        let Data = data
        let result;
        try {
          result = JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(Data, password)).toString().trim() || "{}");
        }
        catch (e) { return "{}"; }
        return result;
      }

	onResponse(response: any, type: any) 
    {
        //Change the structure according to project
        if (type == 'response') 
        {
            if (response.statusCode === 403) 
            {
                localStorage.clear();
                window.location.reload();
            }
            else 
            {
                return response.body;
            }
        } 
        else 
        {
            if (response.statusCode === 401 || response.statusCode === 403) 
            {
                localStorage.clear();
                window.location.reload();
                return response.error;
            }
            else if (response.status == 503)
            {
                localStorage.setItem('maintenance_message', response.error.message);
                localStorage.setItem('maintenance_message_ar', response.error.message_ar);
                this.router.navigateByUrl('auth/maintenance');
            }
            else 
            {
                return response.error;
            }
        }
    }
}


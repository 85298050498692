import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import EditorJS from '@editorjs/editorjs';
import Header from "@editorjs/header";
import NestedList from '@editorjs/nested-list';
import Table from "@editorjs/table";
import SimpleImage from "@editorjs/simple-image";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import Alert from "editorjs-alert";
import Raw from "@editorjs/raw";
import AlignmentTuneTool from "editorjs-text-alignment-blocktune";
import DragDrop from "editorjs-drag-drop";
import Underline from "@editorjs/underline";
import ColorPlugin from "editorjs-text-color-plugin";
import edjsHTML from 'editorjs-html';

@Component({
  selector: 'app-terms-form',
  templateUrl: './terms-form.component.html',
  styleUrls: ['./terms-form.component.scss']
})
export class TermsFormComponent implements OnInit {

    editor_en: EditorJS;
    editor_ar: EditorJS;
    apiCreate = `home/terms/create`;
    apiUpdate = `home/terms/update`;
    sub: Subscription;
    type: any;
    id: any;
    force_accept = true;

    payload = {
        id: null,
        description: null,
        description_ar: null,
        force_accept: '0'
    }

    constructor(protected mainService: MainService,
        public dec_enc: DecEncService,
        protected dialog: MatDialog,
        public route: Router,
        protected _route: ActivatedRoute,
        protected alertService: AlertService)
    {

    }

    ngOnInit(): void 
    {
        this.sub = this._route.params.subscribe(params => {
            this.id = params['id'];
            
            this.type = params['type'];
        });
        if (this.id && this.type != 'add') {
            this.getDetails();
        }
        const config = {
            shortcuts: {
                undo: "CMD+Z",
                redo: "CMD+SHIFT+Z"
            }
        };
        //English
        this.editor_en = new EditorJS({
            holder: 'editor_en',
            readOnly: this.type == 'view' ? true : false,
            tools: {
                header: {
                    class: Header,
                    inlineToolbar: true
                },
                paragraph: {
                    inlineToolbar: true,
                    tunes: ["alignmentTuneTool"]
                },
                image: {
                    class: SimpleImage,
                    inlineToolbar: true,
                    tunes: ["alignmentTuneTool"]
                },
                list: {
                    class: NestedList,
                    inlineToolbar: true,
                    shortcut: "CMD+SHIFT+L",
                    tunes: ["alignmentTuneTool"]
                },
                Color: {
                    class: ColorPlugin,
                    config: {
                        type: "text"
                    }
                },
                // table: {
                //     class: Table,
                //     inlineToolbar: true,
                //     shortcut: "CMD+SHIFT+T",
                //     tunes: ["alignmentTuneTool"]
                // },
                // alert: {
                //     class: Alert,
                //     inlineToolbar: true,
                //     shortcut: "CMD+SHIFT+A",
                //     tunes: ["alignmentTuneTool"]
                // },
                Marker: {
                    class: ColorPlugin,
                    config: {
                        type: "marker"
                    }
                },
                // delimiter: Delimiter,
                inlineCode: InlineCode,
                // style: EditorJSStyle.StyleInlineTool,
                // raw: Raw,
                underline: Underline,
                alignmentTuneTool: {
                    class: AlignmentTuneTool
                }
            },
            onReady: () => {
                // new Undo({ editor, config });
                new DragDrop(this.editor_en);
            },
        });
        //Arabic
        this.editor_ar = new EditorJS({
            holder: 'editor_ar',
            readOnly: this.type == 'view' ? true : false,
            tools: {
                header: {
                    class: Header,
                    inlineToolbar: true
                },
                paragraph: {
                    inlineToolbar: true,
                    tunes: ["alignmentTuneTool"]
                },
                image: {
                    class: SimpleImage,
                    inlineToolbar: true,
                    tunes: ["alignmentTuneTool"]
                },
                list: {
                    class: NestedList,
                    inlineToolbar: true,
                    shortcut: "CMD+SHIFT+L",
                    tunes: ["alignmentTuneTool"]
                },
                Color: {
                    class: ColorPlugin,
                    config: {
                        type: "text"
                    }
                },
                // table: {
                //     class: Table,
                //     inlineToolbar: true,
                //     shortcut: "CMD+SHIFT+T",
                //     tunes: ["alignmentTuneTool"]
                // },
                // alert: {
                //     class: Alert,
                //     inlineToolbar: true,
                //     shortcut: "CMD+SHIFT+A",
                //     tunes: ["alignmentTuneTool"]
                // },
                Marker: {
                    class: ColorPlugin,
                    config: {
                        type: "marker"
                    }
                },
                // delimiter: Delimiter,
                inlineCode: InlineCode,
                // style: EditorJSStyle.StyleInlineTool,
                // raw: Raw,
                underline: Underline,
                alignmentTuneTool: {
                    class: AlignmentTuneTool
                }
            },
            onReady: () => {
                // new Undo({ editor, config });
                new DragDrop(this.editor_ar);
            },
            i18n: {
                 direction: 'rtl',
              },
        });
    }

    getDetails() 
    {
        let url = `home/terms`;
        let data = { id: this.id };
        data = this.dec_enc.encryption({ id: this.id });
        this.mainService.postData(url, { 'enc_payload': data }).then(response => {
            if (response.statusCode == 200) {
                let resp = this.dec_enc.decryption(response.data);
                this.force_accept = resp.force_accept == "0" ? true : false;
                this.payload['force_accept'] = resp.force_accept;
                this.payload['title'] = resp.title;
                this.payload['id'] = resp.id ? resp.id : this.id;
                if (resp.terms != null)
                {
                    this.editor_en.blocks.renderFromHTML(resp.terms.join(""));
                }
                if (resp.terms_ar != null)
                {
                    this.editor_ar.blocks.renderFromHTML(resp.terms_ar.join(""));
                }
                return
            }
            else
            {
                if (localStorage.getItem('lang') == 'ar')
                {
                    this.alertService.alert('Error', response.error.message_ar);
                }
                else
                {
                    this.alertService.alert('Error', response.error.message);
                }
            }
            
        })
    }

    onSave() 
    {
        this.editor_en.save()
            .then((savedData) => {
                const edjsParser = edjsHTML();
                let html = edjsParser.parse(savedData);
                if(savedData.blocks.length == 0){
                    this.alertService.alert('Error', "HR Policy 'English' text is required");
                    return;
                }
                this.payload.description = html;
                this.editor_ar.save()
                .then((savedData) => {
                    const edjsParser = edjsHTML();
                    let html = edjsParser.parse(savedData);
                    if(savedData.blocks.length == 0){
                        this.alertService.alert('Error', "HR Policy 'Arabic' text is required");
                        return;
                    }
                    this.payload.description_ar = html;
                    let url = this.type == 'update' ? this.apiUpdate : this.apiCreate;
                    let enc_payload = this.dec_enc.encryption(this.payload);
                    this.mainService.postData(url, { enc_payload }).then(response => {
                        if (response.statusCode == 200) {
                            if (this.type == 'update')
                            {
                                this.alertService.alert('Success', 'Updated successfully', 'btn-blue');
                            }
                            else
                            {
                                this.alertService.alert('Success', 'Created successfully', 'btn-blue');
                            }
                            this.route.navigateByUrl("main/hr-policy");
                            return
                        }
                        if (localStorage.getItem('lang') == 'ar')
                        {
                            this.alertService.alert('Error', response.error.message_ar);
                        }
                        else
                        {
                            this.alertService.alert('Error', response.error.message);
                        }
                    })
                }).catch((error) => {
                    this.alertService.alert('Error', error);
                    return
                });
            }).catch((error) => {
                this.alertService.alert('Error', error);
                return
            });
    }

    toggle(event)
    {
        if (event.checked)
        {
            this.payload['force_accept'] = '0';
        }
        else
        {
            this.payload['force_accept'] = '1';
        }
    }

    back()
    {
        this.route.navigateByUrl("main/hr-policy");
    }
}


<div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div fxLayoutAlign="center center">
        <div style="background-color: white; border-radius: 40px; padding: 5px 10px;">
            <div fxLayout="row" fxLayoutAlign="center center" class="lang-switch-container">
                <div (click)="onChangeList('company')" class="lang-switch"
                    [class.lang-switch-active]="companyListing == 'company'">
                    {{'Company' | translate}}
                </div>
                <div (click)="onChangeList('groups')" class="lang-switch"
                    [class.lang-switch-active]="companyListing == 'groups'">
                    {{'Groups' | translate}}
                </div>
                <div (click)="onChangeList('employees')" class="lang-switch"
                    [class.lang-switch-active]="companyListing == 'employees'">{{'Employee' |
                    translate}}</div>
            </div>
        </div>
    </div>

    <div fxLayout="column" *ngIf="!showPdf">
        <div fxFlex="100" class="info-box-container" style="background-color: white; margin-top: 20px;
            border-radius: 12px;" >
            <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="90px" style="padding:20px">
                <div fxFlex="100">
                    <h3 class="title">{{'Transaction Statement of Virtual Account' | translate}}</h3>
                    <div class="info-box">
                        <p class="info-text">{{'This statement is issued electronically and includes all the
                            transactions in
                            and out of the account.' | translate}}</p>
                    </div>
                    <div (click)="list?.length > 0 ? downloadPDF() : null" [ngClass]="{'disabled': list?.length <= 0}"
                        class="save-button">
                        {{ 'Save as PDF' | translate }}
                    </div>
                </div>
            </div>
        </div>

        <app-table *ngIf="companyListing == 'company'" class="mt-20 mb-20" fxFlex="100" [start]="start_date"
            [end]="end_date" [tableProps]="tableProps"  [dataSource]="list"
            (outDateFilters)="onDateFilters($event)" (emitFilters)="onFilters($event)">
        </app-table>

        <app-table *ngIf="companyListing == 'groups'" class="mt-20 mb-20" fxFlex="100" [start]="start_date"
            [end]="end_date" [tableProps]="groupTableProps"  [dataSource]="list"
            (outDateFilters)="onDateFilters($event)" (emitFilters)="onFilters($event)">
        </app-table>

        <app-table *ngIf="companyListing == 'employees'" class="mt-20 mb-20" fxFlex="100" [start]="start_date"
            [end]="end_date" [tableProps]="employeeTableProps"  [dataSource]="list"
            (outDateFilters)="onDateFilters($event)" (emitFilters)="onFilters($event)">
        </app-table>

        <div class="balance-container">
            <div class="balance-box">
                <h5 class="balance-label">{{'Unrealized return (SAR)' | translate}}</h5>
                <h5 class="balance-value">{{(summary?.unrealize_return ? summary?.unrealize_return : 0) |
                    number:'1.4-4'}}
                </h5>
            </div>
        </div>

        <div class="balance-container">
            <div class="balance-box">
                <h5 class="balance-label">{{'Closing balance (SAR)' | translate}}</h5>
                <h5 class="balance-value">{{ (closingBalance ? closingBalance : 0) | number:'1.4-4'}} 
                </h5>
            </div>
        </div>
    </div>
    
    <div *ngIf="showPdf" style="display: flex; justify-content: center; align-items: center;">
    <div id="contentToExport" class="main pt-1 mrl-20 mt-10" ngClass.sm="mrl-10" ngClass.xs="mrl-10"
        style="direction: ltr;">
        <table style="width:100%; max-width:700px; margin:0 auto; background-color:#fff; border-collapse:collapse;">
            <tbody style="margin: 0px 20px !important;">
                <tr *ngIf="currentLang=='en'" style="display: flex; justify-content: space-between; align-items: flex-end; border-bottom: 1px solid #79C0C2; direction:ltr;"
                    class="mrl-20">
                    <td style="padding-top:20px; text-align:left; flex: 1;" class="top-logo pr-10">
                        <img width="215" height="70" src="assets/thrift_logo.png" alt="Logo">
                    </td>
                    <td style="padding-top:20px; text-align: right; font-size:14px; color:#757575; flex: 1;">
                        <h3 style="margin: 0; font-weight: bolder; color: black;">{{companyListing === 'company' ?
                            ('Transaction Report By Company' | translate) :
                            companyListing === 'groups' ? ('Transaction Report By Groups' | translate) :
                            companyListing === 'employees' ? ('Transaction Report By Employees' | translate) : ''}}
                        </h3>
                    </td>
                </tr>
                <tr *ngIf="currentLang=='ar'" style="display: flex; justify-content: space-between; align-items: flex-end; border-bottom: 1px solid #79C0C2; direction:ltr;"
                    class="mrl-20">
                    <td style="padding-top:20px; text-align:left; flex: 1;" class="top-logo pr-10">
                        <h3 style="margin: 0; font-weight: bolder; color: black;">{{companyListing === 'company' ?
                            ('Transaction Report By Company' | translate) :
                            companyListing === 'groups' ? ('Transaction Report By Groups' | translate) :
                            companyListing === 'employees' ? ('Transaction Report By Employees' | translate) : ''}}
                        </h3>
                    </td>
                    <td style="padding-top:20px; text-align: right; font-size:14px; color:#757575; flex: 1;">                      
                        <img width="215" height="70" src="assets/thrift_logo.png" alt="Logo">
                    </td>
                </tr>
                
                <tr [style.direction]="currentLang == 'ar' ? 'rtl' : 'ltr'">
                    <td colspan="3" style="padding: 10px 20px;">
                        <div style="display: flex; justify-content: center; gap: 30px;margin:0px 15px 0px 15px">
                            <div style="flex: 1; font-size: 12px; color: #757575; text-align: left;">
                                <h5
                                    style="display: flex; justify-content: space-between;color: #000000;font-size: 12px;">
                                    <strong>{{'Report period' | translate}}</strong>
                                    <span style="direction: ltr;unicode-bidi: embed;"> {{start_date | date:
                                        'dd-MM-yyyy'}} -
                                        {{end_date | date: 'dd-MM-yyyy'}}</span>
                                </h5>

                                <h5 style="display: flex; justify-content: space-between;color: black;font-size: 12px;">
                                    <strong>{{'Benificiary name' |translate}}</strong>
                                    <div *ngIf="companyListing == 'company' || companyListing == 'groups'"><span>{{benificiaryName}}</span></div>
                                    <div *ngIf="companyListing == 'employees'"><span>{{employeeName}}</span></div>
                                </h5>
                                <div *ngIf="companyListing == 'groups'">
                                    <h5
                                        style="display: flex; justify-content: space-between;color: black;font-size: 12px;">
                                        <strong>{{'Filter Selection' |translate}}</strong>
                                        <span>{{groupName}}</span>
                                    </h5>
                                </div>
                                <div *ngIf="companyListing == 'employees'">
                                    <h5
                                        style="display: flex; justify-content: space-between;color: black;font-size: 12px;">
                                        <strong>{{'Filter Selection' |translate}}</strong>
                                        <span>{{employeeName}}</span>
                                    </h5>
                                </div>
                            </div>
                            <div style="flex: 1; font-size: 12px; color: #757575; text-align: right;">
                                <h5 style="display: flex; justify-content: space-between;color: black;font-size: 12px;">
                                    <strong>{{'Total deposits' | translate}}</strong>
                                    <span dir="ltr">SAR {{(summary?.total_debit ? summary?.total_debit : 0 )|
                                        number:'1.4-4'}}</span>
                                </h5>
                                <h5 style="display: flex; justify-content: space-between;color: black;font-size: 12px;">
                                    <strong>{{'Total withdrawls' | translate}}</strong>
                                    <span dir="ltr">SAR {{(summary?.total_credit ? summary?.total_credit : 0) |
                                        number:'1.4-4'}}</span>
                                </h5>
                                <h5
                                    style="display: flex; justify-content: space-between;color: black; font-size: 12px;">
                                    <strong>{{'Unrealized return' | translate}}</strong>
                                    <span dir="ltr">SAR {{(summary?.unrealize_return ? summary?.unrealize_return : 0) |
                                        number:'1.4-4'}}</span>
                                </h5>
                                <h5 style="display: flex; justify-content: space-between;color: black;font-size: 12px;">
                                    <strong>{{'Closing balance' | translate}}</strong>
                                    <span dir="ltr">SAR {{(closingBalance ? closingBalance : 0) |
                                         number:'1.4-4'}}</span>
                                </h5>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr [style.direction]="currentLang == 'ar' ? 'rtl' : 'ltr'">
                    <td colspan="3" style="padding: 0 20px;">
                        <table
                            style="width: 100%; border-collapse: collapse; border-bottom: 1px solid #79C0C2; margin-bottom: 20px;">
                            <thead>
                                <tr style="border-bottom: 1px solid #79C0C2;">
                                    <th style="padding: 10px; text-align: left;font-size:12px; width:125px;">{{ 'Date' | translate }}
                                    </th>
                                    <th style="padding: 10px; text-align: left;font-size:12px;  width:125px;">{{ 'Type' | translate }}
                                    </th>
                                    <th style="padding: 10px; text-align: left;font-size:12px;  width:150px;">{{ 'Description' |
                                        translate }}</th>
                                    <th style="padding: 10px; text-align: left;font-size:12px;  width:125px;">{{ 'Debit (SAR)' | translate }}
                                    </th>
                                    <th style="padding: 10px; text-align: left;font-size:12px;  width:125px;">{{ 'Credit (SAR)' | translate
                                        }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let transaction of list">
                                    <td style="padding: 10px; text-align: left;font-size:11px">{{ transaction.created_at
                                        }}</td>
                                    <td style="padding: 10px; text-align: left;font-size:11px">{{ transaction.type }}
                                    </td>
                                    <td style="padding: 10px; text-align: left;font-size:11px">{{
                                        transaction.description }}</td>
                                    <td style="padding: 10px; text-align: left;font-size:11px;direction:ltr">{{ transaction.debit |
                                        number:'1.4-4' }}</td>
                                    <td style="padding: 10px; text-align: left;font-size:11px;direction:ltr">{{ transaction.credit |
                                        number:'1.4-4'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                
            </tbody>
        </table>
    </div>
    </div>

    
</div>



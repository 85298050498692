<section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <h3 style="color: #213060; font-weight: bold;" class="mt-12">Subscription Documents</h3>
    <span class="file-bubble">{{'Files' | translate}}: {{noOfFiles}}</span>
</section>
<section class="card">
    <ng-container *ngIf="list.length > 0">
        <div *ngFor="let item of list" class="file-container mt-10" fxLayout="row" fxLayoutAlign="none center">
            <img fxFlex="10" src="assets/ic_file.svg" style="height: 23px; width: 20px;">
            <h3 fxFlex="80" style="color: #2F2F2F; margin: 0; font-size: 17px;">{{item.original_doc_name}}</h3>
            <div fxLayout="row">
                <img (click)="openDoc(item.document)" src="assets/ic_link.svg" style="height: 23px; width: 20px; margin-right: 10px; cursor: pointer;">
                <!-- <mat-icon *ngIf="data.complete != 1" (click)="removeDoc(item.id)" style="color: #3F638F; cursor: pointer;">close</mat-icon> -->
            </div>
        </div>
    </ng-container>
    <div *ngIf="list.length == 0" class="file-container" fxLayout="row" fxLayoutAlign="center center">
        <h3 style="color: #2F2F2F; margin: 0; font-size: 17px;">No document uploaded.</h3>
    </div>
</section>

<section fxLayoutAlign="end" fxLayoutGap="10px" class="mt-10">
    <!-- <button *ngIf="data.complete != 1" (click)="attach.click()" style="background-color: #3F638F; color: white; line-height: 34px; padding: 0 25px; border-radius: 10px;" mat-raised-button>{{'Upload New File' | translate}}
        <input style="display: none;" type="file" #attach (change)="getFile($event)" accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.csv">
    </button> -->
    <button (click)="closeDialog()" style="background-color: #3F638F; color: white; line-height: 34px; padding: 0 25px; border-radius: 10px;" mat-raised-button>{{'Close' | translate}}
    </button>
</section>
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';

@Component({
    selector: 'app-employers-manager-form-new',
    templateUrl: './employers-manager-form-new.component.html',
    styleUrls: ['./employers-manager-form-new.component.scss']
})
export class EmployersManagerFormNewComponent implements OnInit {

    routeType = null;
    id = null;
    emp_id = null;
    Form: FormGroup;
    role = {
        maker: false,
        checker: false,
        finance: false,
    }
    list = [];
    currentDate = new Date();
    iqama_image = null;
    imgToShow = null;
    message: string;
    @ViewChild('picker1') datePicker1: MatDatepicker<Date>;
    isArabic = false;

    constructor(protected formbuilder: FormBuilder,
        protected route: ActivatedRoute,
        protected router: Router,
        protected mainService: MainService,
        protected cryptoService: DecEncService,
        protected alertService: AlertService,
        protected translateService: TranslateService) {
        this.isArabic = localStorage.getItem('lang') == 'ar' ? true : false;
        this.initForm();
        this.emp_id = Number(localStorage.getItem('employer_id'));
        if (this.route.snapshot.paramMap.get('id') != 'new') {
            this.id = this.route.snapshot.paramMap.get('id');
        }
    }

    ngOnInit(): void {
        this.paramType();
    }

    paramType() {
        this.routeType = this.route.snapshot.paramMap.get('type');
        if (this.routeType == 'add') {

        }
        else if (this.routeType == 'view') {
            this.editViewCommon();
            this.Form.disable();
        }
        else {
            this.editViewCommon();
        }
    }

    editViewCommon() {
        this.Form.removeControl('password');
        this.Form.removeControl('confirm_password');
        this.id = this.route.snapshot.paramMap.get('id');
        this.getDetail();
    }

    initForm() {
        this.Form = this.formbuilder.group({
            id: [null],
            name: ['', [Validators.required, Validators.pattern('[a-zA-Z]+[a-zA-Z ]*')]],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required, Validators.pattern('[1-9]+[0-9]*'), Validators.minLength(9), Validators.maxLength(9)]],
            role: ['', [Validators.required]],
            address: ['', [Validators.required]],
            iqama_id: ['', [Validators.required, Validators.maxLength(10)]],
            iqama_expiry: ['', [Validators.required]],
            password: ['', [Validators.required]],
            confirm_password: ['', [Validators.required]],
            active: ['0'],
        });
    }

    getDetail() {
        this.mainService.postData('emp-managers', { employer_id: this.emp_id }).then(res => {
            if (res.statusCode == 200) {
                let data = res.data;
                let result = this.cryptoService.decryption(data);
                // console.log(result);
                this.list = result.emp_managers;
                this.list.forEach(item => {
                    if (item.id == this.id) {
                        this.Form.patchValue(item);
                        if (item.iqama_image) {
                            this.imgToShow = this.mainService.file_url + item.iqama_image;
                        }
                    }
                })
                // this.Form.patchValue(result.emp_managers[result.emp_managers.length - 1]);
                // this.iqama_image = this.mainService.file_url + result.emp_managers[result.emp_managers.length - 1].iqama_image;
                let role: string = this.Form.get('role').value;
                for (let key in this.role) {
                    if (role.includes(key)) {
                        this.setRole(role, key);
                    }
                }
            }
        })
    }

    setRole(role, key) {
        this.role.maker = false;
        this.role.checker = false;
        this.role.finance = false;
        this.role[key] = true;
        this.Form.get('role').setValue(role);
    }

    onSubmit() {
        if (!this.checkValidations()) {
            return;
        }
        let payload = this.cryptoService.encryption(this.Form.value);
        const formdata = new FormData();
        formdata.append('enc_payload', payload)
        formdata.append('iqama_image', this.iqama_image);

        let url = this.id ? 'emp-managers/update' : 'emp-managers/create';
        let type = this.id ? 'updated' : 'created';

        if (this.Form.get('role').value == 'emp-maker') {
            this.message = `EMP-Maker account has been ${type} successfully`;
        }
        else if (this.Form.get('role').value == 'emp-checker') {
            this.message = `EMP-Checker account has been ${type} successfully`;
        }
        else if (this.Form.get('role').value == 'emp-finance') {
            this.message = `EMP-Finance account has been ${type} successfully`;
        }
        else {
            this.message = `Account has been ${type} successfully.`;
        }

        if(!this.id){
            if (this.Form.get('password').value != this.Form.get('confirm_password').value) {
                this.alertService.alert('Error', 'Password and confirm password are not matched');
                return;
            }
        }

        this.create(formdata, url);
    }

    create(formdata, url) {
        this.mainService.postData(url, formdata).then(response => {
            if (response.statusCode == 200) {
                    this.alertService.alert('Success', this.message).then(res=>{
                        this.router.navigate(['main/employer-manager'])
                    });
                    return
                }
                else {
                    if (localStorage.getItem('lang') == 'ar')
                    {
                        this.alertService.alert('Error', response.error.message_ar);
                    }
                    else
                    {
                        this.alertService.alert('Error', response.error.message);
                    }
                }
            })
        }

    getFile(event: any) {
        const file = (event.target.files[0] as File);
        this.iqama_image = file;
        if (event.target.files && event.target.files.length > 0) {
            const file = (event.target.files[0] as File);
            const reader = new FileReader();
            reader.onload = e => this.imgToShow = reader.result;
            reader.readAsDataURL(file);
        }
    }

    cancel() {
        this.router.navigate(['main/employer-manager'])
    }

    openDatePicker() {
        this.datePicker1.open();
    }

    getFieldValue(field) {
        if (this.Form.get(field).touched) {
            if (this.Form.get(field).invalid) {
                return true;
            }
        }
        return false;
    }

    checkValidations() {
        if (this.Form.invalid) {
            this.Form.markAllAsTouched();
            return false;
        }
        return true;
    }

    getErrorMessage(control, fieldName) {
        if (this.Form.get(control).invalid) {
            if (this.Form.get(control).hasError('required')) {
                return this.translateService.instant(fieldName);
            }
            if (this.Form.get(control).hasError('minlength')) {
                if (control == 'phone')
                return this.translateService.instant('Phone_Min_Nine');
            }
            if (this.Form.get(control).hasError('maxlength')) {
                if (control == 'phone')
                return this.translateService.instant('Phone_Max_Nine');
                if (control == 'iqama_id')
                return this.translateService.instant('Iqama_Max_Ten');
            }
            if (this.Form.get(control).hasError('email')) {
                return this.translateService.instant('Email_Format');
            }
            if (this.Form.get(control).hasError('pattern')) {
                if (control == 'name')
                return this.translateService.instant('Manager_Name_Letter_Only');
                if (control == 'phone')
                return this.translateService.instant('Phone_Num_Only');
            }
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { DecEncService } from 'src/app/services/dec-enc';
import * as moment from 'moment';
import { AlertService } from 'src/app/services/alert.service';
import { ProfileService } from 'src/app/services/profile.service';
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    Form: FormGroup;
    setPassword: FormGroup;
    failureAlert: boolean;
    alertMsg: any = "";
    iqama_image: any;
    imgToShow = null;
    ut;
    image;
    Filepath: any;
    currentDate = new Date();

    constructor(public tr: TranslationService, 
        protected formbuilder: FormBuilder, 
        protected dialog: MatDialog, 
        protected mainService: MainService, 
        public dec_enc: DecEncService,
        protected alertService: AlertService,
        protected profileService: ProfileService) {
        this.Filepath = this.mainService.file_url;

        this.Form = this.formbuilder.group({
            name: [null, [Validators.required, Validators.pattern('[a-zA-Z]+[a-zA-Z ]*')]],
            email: [null, [Validators.required, Validators.email]],
            iqama_id: [null, [Validators.required]],
            iqama_expiry: [null, [Validators.required]],

        });
        this.setPassword = this.formbuilder.group({
            old_password: [null, [Validators.required]],
            new_password: [null, [Validators.required]],
            confirm_new_password: [null, [Validators.required]],
        })
    }

    ngOnInit(): void {
        this.getProfile();
    }

    getProfile() {
        let url = `viewProfile`;
        this.mainService.postData(url, {}).then(res => {
          if (res.statusCode == 200) {
            let result = this.dec_enc.decryption(res.data);
            // console.log("result", result);
            this.profileService.user = result.user;
            this.Form.get('name').setValue(result?.user?.name);
            this.Form.get('email').setValue(result?.user?.email);
            this.Form.get('iqama_id').setValue(result?.user?.iqama_id);
            this.Form.get('iqama_expiry').setValue(result?.user?.iqama_expiry);
            this.image = result?.user?.iqama_image;
            this.imgToShow = this.Filepath + this.image;
            return;
          }
        },
          error => {
            this.alertService.alert('Error', this.tr.translation.serverError);
          }
        )
      }

    updateProfile() {
        let expiryDate = moment(this.Form.get('iqama_expiry').value).format('YYYY-MM-DD');
        this.Form.get("iqama_expiry").setValue(expiryDate);

        let payload = this.dec_enc.encryption(this.Form.value);
        let url = "updateProfile2";
        let formData = new FormData();

        formData.append("iqama_image", this.iqama_image);
        formData.append("enc_payload", payload);
        this.mainService.postData(url, formData).then(result => {
            if (result.statusCode == 200) {
                this.getProfile();
                this.alertService.alert('Success','Profile has been updated successfully');
                localStorage.setItem('employer_name', this.Form.get('name').value);
                localStorage.setItem('employer_email', this.Form.get('email').value);
                localStorage.setItem('employer_iqama_id', this.Form.get('iqama_id').value);
                localStorage.setItem('employer_iqama_expiry', expiryDate);
                this.mainService.employer_name.next(localStorage.getItem('employer_name'));
                // this.Form.get('iqama_expiry').setValue(localStorage.getItem('employer_iqama_expiry'));
                this.Form.get('iqama_expiry').setValue(this.profileService.user.iqama_expiry);
                // return
            }
            else
            {
                if (localStorage.getItem('lang') == 'ar')
                {
                    this.alertService.alert('Error', result.error.message_ar);
                }
                else
                {
                    this.alertService.alert('Error', result.error.message);
                }
            }
        },
        error => {
            this.alertService.alert('Error', this.tr.translation.serverError);
        });
    }

    onUpdatePassword() {
        if (!this.checkValidations(this.setPassword)) {
            return;
        }
        if (this.setPassword.get('new_password').value == this.setPassword.get('confirm_new_password').value)
        {
            let enc_payload = this.dec_enc.encryption(this.setPassword.value);
            this.mainService.postData("updatePassword", { enc_payload }).then(result => {
                if (result.statusCode == 200) {
                    this.alertService.alert('Success','Password has been updated successfully');
                    this.setPassword.reset();
                    return
                }
                else
                {
                    if (localStorage.getItem('lang') == 'ar')
                    {
                        this.alertService.alert('Error', result.error.message_ar);
                    }
                    else
                    {
                        this.alertService.alert('Error', result.error.message);
                    }
                }
                
            },
                error => {
                    this.alertService.alert('Error','Server Error');
                });
        }
        else {
            this.failureAlert = true;
            this.alertService.alert('Error','Password and confirm password mismatched');
            return
        }



    }

    onFileChange(event) {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];

            this.iqama_image = file;

        }


    }
    viewImage(path) {
        if (path != null && path != '' && path != 'null') {
            window.open(this.Filepath + path, '_blank');

        }
        else {
            this.alertService.alert('Error','There is no image');
        }
    }

    getFile(event: any) 
    {
        const file = (event.target.files[0] as File);
        this.iqama_image = file;
        if (event.target.files && event.target.files.length > 0) {
            const file = (event.target.files[0] as File);

            const reader = new FileReader();
            reader.onload = e => this.imgToShow = reader.result;
    
            reader.readAsDataURL(file);
        }
    }

    getFieldValue(form, field) {
        if (form.get(field).touched) {
            if (form.get(field).invalid) {
                return true;
            }
        }
        return false;
    }

    checkValidations(form) {
        if (form.invalid) {
            form.markAllAsTouched();
            return false;
        }
        return true;
    }

    getErrorMessage(form, control, fieldName, minVal?, maxVal?) {
        if (form.get(control).invalid) {
            if (form.get(control).hasError('required')) {
                return `${fieldName} is a required field.`
            }
            if (form.get(control).hasError('minlength')) {
                return `Minimum length for ${fieldName} is ${minVal} characters.`
            }
            if (form.get(control).hasError('maxlength')) {
                return `Maximum length for ${fieldName} is ${maxVal} characters.`
            }
            if (form.get(control).hasError('email')) {
                return `${fieldName} should be in valid email format.`
            }
            if ((control == 'name' || control == 'nationality') && form.get(control).hasError('pattern')) {
                return `${fieldName} should be letters only.`
            }
            if ((control == 'phone' || control == 'iqama_id') && form.get(control).hasError('pattern')) {
                return `${fieldName} should be numbers only.`
            }
        }
    }
}

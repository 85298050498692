import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-disbursement',
  templateUrl: './disbursement.component.html',
  styleUrls: ['./disbursement.component.scss']
})
export class DisbursementComponent implements OnInit {
  list: any = [];
  id: any;
  selectedAmount: any = 0;
  remainingBalance: any = 0;
  redemptionId: any;
  withdrawal_ids: any = [];
  totalAmount: any = 0;
  pdfDownload: boolean = true;
  showPdf: boolean = false;
  btnDisabled: boolean = true;
  columnNames = {
    id: 'Employee ID',
    employee_name: 'Employee Name',
    amount: 'Withdrawal Amount',
    fee: 'Fund fees (SAR)',
    actual: 'Actual Payout',
  };
  constructor(protected mainService: MainService, protected dec_enc: DecEncService, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public dialogRef: MatDialogRef<DisbursementComponent>, protected alertService: AlertService,) {
    this.id = data.id;
  }
  tableProps = {
    heading: '',
    hasSearch: false,
    searchLabel: 'Search',
    hasButton: false,
    headerActions: [
    ],
    rowActions: [],
    colHeader: {
      checkbox: '',
      id: 'Employee ID',
      employee_name: 'Employee Name',
      amount: 'Withdrawal Amount',
      fee: 'Fund fees (SAR)',
      actual: 'Actual Payout',
    },
    columnTypes: {
      created_at: 'date',
    },
    filterArray: [],
    dateFilter: {},
  };

  pdftableProps = {
    heading: '',
    hasSearch: false,
    searchLabel: 'Search',
    hasButton: false,
    headerActions: [
    ],
    rowActions: [],
    colHeader: {
      id: 'Employee ID',
      employee_name: 'Employee Name',
      amount: 'Withdrawal Amount',
      fee: 'Fund fees (SAR)',
      actual: 'Actual Payout',
    },
    columnTypes: {
      created_at: 'date',
    },
    filterArray: [],
    dateFilter: {},
  };
  pagination = {
    page: null,
    pages: null,
    per_page: null,
    count: 0
  }

  ngOnInit(): void {
    this.getAll(1);
  }

  getAll(index) {
    let body = {
      redemption_id: this.id,
    }
    let enc_payload = this.dec_enc.encryption(body);
    let url = 'redemptions/disbursement?per_page=' + this.pagination.per_page + '&page=' + index;
    this.mainService.postData(url, { enc_payload }).then(res => {
      if (res.statusCode == 200) {
        let result = this.dec_enc.decryption(res.data);
        this.list = result.disbursement;
        this.list.forEach(item => {
          item['amount'] = item['amount'] ? parseFloat(item['amount']).toFixed(4) : '0.0000';
          item['fee'] = item['fee'] ? parseFloat(item['fee']).toFixed(4) : '0.0000';
          item['actual'] = item['actual'] ? parseFloat(item['actual']).toFixed(4) : '0.0000';
          item['dontShowCB'] = item['payoff'] == 1;
          this.totalAmount = this.list
            .filter(item => item.payoff === "0")
            .reduce((sum, item) => sum + parseFloat(item.actual), 0);
        });
        this.remainingBalance = this.totalAmount;
      }
    })
  }


  checkBox(event) {
    const id = event.rowData?.id;
    const amount = parseFloat(event.rowData?.actual);
    const itemsWithPayoffZero = this.list.filter(record => record.payoff === "0");

    if (event.checkCount > 0) {
      this.btnDisabled = false;
    } else {
      this.btnDisabled = true;
    }
    const allPayoffZeroSelected = itemsWithPayoffZero.length === event.checkCount;
    if (event.checkCount === this.list.length || event.checkCount === 0) {
      if (this.withdrawal_ids.length === this.list.length || event.checkCount === 0) {
        this.withdrawal_ids = [];
        this.redemptionId = [];
        this.selectedAmount = 0;
      } else {
        this.withdrawal_ids = this.list.map(record => record.id);
        this.redemptionId = this.list.map(record => record.id);
        this.selectedAmount = this.list.reduce((sum, record) => sum + parseFloat(record.actual), 0);
      }
    } else if (allPayoffZeroSelected) {
      this.withdrawal_ids = itemsWithPayoffZero.map(record => record.id);
      this.redemptionId = this.withdrawal_ids;
      this.selectedAmount = itemsWithPayoffZero.reduce((sum, record) => sum + parseFloat(record.actual), 0);
    } else {
      if (id != null && !isNaN(amount)) {
        const indexInWithdrawal = this.withdrawal_ids.indexOf(id);
        if (indexInWithdrawal > -1) {
          this.withdrawal_ids.splice(indexInWithdrawal, 1);
          this.selectedAmount -= amount;
        } else {
          this.withdrawal_ids.push(id);
          this.selectedAmount += amount;
        }
      }
    }
    this.remainingBalance = this.totalAmount - this.selectedAmount;
  }


  confirm() {
    let body = {
      withdrawal_ids: this.withdrawal_ids,
      redemption_id: this.id,
    }
    let enc_payload = this.dec_enc.encryption(body);
    let url = 'redemptions/payoff';
    this.mainService.postData(url, { enc_payload }).then(res => {
      if (res.statusCode == 200) {
        this.dialogRef.close();
        this.alertService.alert('Success', res.message);
        this.getAll;
      }
      else {
        this.alertService.alert('Error', res.message);
      }
    })
  }

  onCancel() {
    this.dialogRef.close();
  }

  setPage(page) {
    // this.pagination.page = page;
    // this.getall(this.pagination.page);
  }

  SetPageSize(pageSize) {
    // this.pagination.per_page = pageSize
    // this.getall(this.pagination.page);
  }

  convertToCSV(data: any[], columnNames: any, additionalColumns: any): string {
    if (data.length === 0) return '';

    // Extract headers from columnNames and include additional columns
    const headers = Object.keys(columnNames).concat(Object.keys(additionalColumns));
    const csvHeaders = headers.map(key => columnNames[key] || key).join(',');

    // Convert each row of data to CSV format
    const csvRows = [
      csvHeaders, // Header row
      ...data.map(row => {
        return headers.map(header => {
          if (header in row) {
            // Data row values
            const value = row[header] || '';
            return `"${value.toString().replace(/"/g, '""')}"`; // Escape quotes
          } else if (header === 'totalAmount') {
            // Add computed value
            return `"${additionalColumns.totalAmount}"`;
          } else if (header === 'selectedAmount') {
            return `"${additionalColumns.selectedAmount}"`;
          } else if (header === 'remainingBalance') {
            return `"${additionalColumns.remainingBalance}"`;
          } else {
            return '""'; // Empty for non-existent columns
          }
        }).join(',');
      })
    ];

    return csvRows.join('\n');
  }

  // Trigger CSV download
  downloadCSV() {
    // Define additional columns with computed values
    const additionalColumns = {
      totalAmount: this.totalAmount,
      selectedAmount: this.selectedAmount,
      remainingBalance: this.remainingBalance
    };

    // Use the columnNames and additionalColumns for the CSV generation
    const csv = this.convertToCSV(this.list, this.columnNames, additionalColumns);
    const csvFile = new Blob([csv], { type: 'text/csv' });
    const downloadLink = document.createElement('a');

    downloadLink.download = 'employees.csv'; // Filename
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();

    // Clean up
    document.body.removeChild(downloadLink);
  }


  downloadPDF() {
    this.showPdf = true;
    setTimeout(() => {
      const data = document.getElementById('contentToExport');
      if (data) {
        // Increase scale to 2 for better resolution
        html2canvas(data, { scale: 4 }).then(canvas => {
          const imgWidth = 210; // A4 width in mm
          const pageHeight = 295; // A4 height in mm
          const imgHeight = canvas.height * imgWidth / canvas.width;
          let heightLeft = imgHeight;
          let position = 0;

          const imgData = canvas.toDataURL('image/png', 1.0); // Highest quality PNG

          const pdf = new jsPDF('p', 'mm', 'a4');

          // Set PDF compression to none for best quality
          pdf.addImage(imgData, 'PNG', (pdf.internal.pageSize.getWidth() - imgWidth) / 2, position, imgWidth, imgHeight, undefined, 'FAST');

          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', (pdf.internal.pageSize.getWidth() - imgWidth) / 2, position, imgWidth, imgHeight, undefined, 'FAST');
            heightLeft -= pageHeight;
          }
          pdf.save('Disbursement-summary.pdf');
        });
      }
    }, 500);

    setTimeout(() => {
      this.showPdf = false;
    }, 500);
  }

}

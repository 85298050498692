import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { DisbursementComponent } from './disbursement/disbursement.component';
import * as moment from 'moment';

@Component({
  selector: 'app-redemption-instructions',
  templateUrl: './redemption-instructions.component.html',
  styleUrls: ['./redemption-instructions.component.scss']
})
export class RedemptionInstructionsComponent implements OnInit {
  list: any = [];
  search: any;
  body: any;
  searchTimer: any;
  totalPages: number;
  pages: any;
  perPage: number = 10;
  currentPage: any = 1;
  count: any;

  constructor(protected route: Router, public dialog: MatDialog, protected dec_enc: DecEncService, protected mainService: MainService, protected paginationService: PaginationService,) { }

  tableProps = {
    heading: 'Redemption Instructions',
    hasSearch: true,
    searchLabel: 'Search',
    hasButton: false,
    headerActions: [
    ],
    rowActions: [
      { tooltip: 'View Transaction Summary', visibility: true, opacity: true, isCustom: false, type: 'one', background: '#FFFFFF', source: 'assets/transaction-summary.svg', iconName: 'arrow_forward' },
      { tooltip: 'Instruction Confirmation', visibility: true, opacity: true, isCustom: false, type: 'two', background: '#FFFFFF', source: 'assets/instruction-confirmation.svg', iconName: 'instruction-confirmation' },
      { tooltip: 'View Disbursement Summary', visibility: true, opacity: true, isCustom: false, type: 'three', background: '#FFFFFF', source: 'assets/disbursement-summary.svg', iconName: 'disbursement-summary' },
    ],
    colHeader: {
      id: 'Request ID',
      created_at: 'Created At',
      company_amount: 'Company Amount (SAR)',
      employee_amount: 'Employee Amount (SAR)',
      statusToShow: 'Instruction Status',
      status: 'Disbursement Status',
      actions: 'Actions'
    },
    columnTypes: {
      company_amount: 'number-4',
      employee_amount: 'number-4',
      actions: 'actionsSeperate',
      created_at: 'date',
      statusToShow: 'statusToShow',
      status: 'status'
    },
    filterArray: [],
    dateFilter: {},

  };

  ngOnInit(): void {
    this.getAll(1);
    this.afterList();
  }

  pagination = {
    page: null,
    pages: null,
    per_page: null,
    count: 0
  }

  getAll(index) {
    this.body = {
      search: this.search,
    }
    let enc_payload = this.dec_enc.encryption(this.body);
    let url = '/redemptions?per_page=' + this.perPage + '&page=' + index;
    this.mainService.postData(url, { enc_payload }).then(res => {
      if (res.statusCode == 200) {
        let result = this.dec_enc.decryption(res.data);
        this.list = result.redemptions;
        this.pagination = result.pagination;
        this.afterList();
        // this.count = result.pagination.count;
        // this.currentPage = result.pagination.page;
        // this.pages = this.paginationService.setPagination(result.pagination.count, this.currentPage, this.perPage);
        // this.totalPages = this.pages.totalPages;
      }
    })
  }

  afterList() {
    this.list.forEach(item => {
      item['withdraw_amount'] = item['withdraw_amount'].toFixed(4);
      item['created_at'] = moment(item['created_at']).format('DD-MM-YYYY');
      if (item.status == 1 && item.instruction_status == 2) {
        item['statusToShow'] = 'Completed';
      }
      else if (item.status == 3) {
        item['statusToShow'] = 'Rejected by Fund Manager';
        item['opacity'] = true
        item['one'] = true;
        item['two'] = true;
        item['three'] = true;
      }
      else {
        item['statusToShow'] = 'Pending';
        item['opacity'] = true
        item['one'] = true;
        item['two'] = true;
        item['three'] = true;
      }
      if (item.disburse_status == 0 && item.status != 3) {
        item['status'] = 'Pending';
      }
      else if (item.disburse_status == 1 && item.status != 3) {
        item['status'] = 'Completed';
      }
      else if (item.status == 3) {
        item['status'] = 'Rejected by Fund Manager'
      }
    });
  }

  searchData(event) {
    this.search = event;
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.getAll(1);
    }, 800);
  }

  setPage(page) {
    this.pagination.page = page;
    this.getAll(this.pagination.page);
  }

  SetPageSize(pageSize) {
    this.pagination.per_page = pageSize
    this.getAll(this.pagination.page);
  }

  openDialog(id) {
    const dialogRef = this.dialog.open(DisbursementComponent, {
      width: '800px',
      panelClass: localStorage.getItem('lang') == 'ar' ? 'arabic-lang' : 'english-lang',
      data: { id: id },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getAll(1);
    });
  }

  actionClicked(event) {
    console.log('Event~*', event);
    if (event.element.statusToShow != 'Completed') {
      return;
    }
    if (event.action.iconName == 'arrow_forward') {
      this.route.navigate(['main/redemption-instructions/update/' + event.element.id]);
    }

    if (event.action.iconName == 'instruction-confirmation') {
      this.route.navigate(['main/redemption-instructions/view/' + event.element.id]);
    }

    if (event.action.iconName == 'disbursement-summary') {
      this.openDialog(event.element.id);
    }
  }

}


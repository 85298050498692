import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AlertDialog } from 'src/app/lib/alert-dialog/alert.dialog';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-subscription-reallocate-form-dialog',
  templateUrl: './subscription-reallocate-form-dialog.component.html',
  styleUrls: ['./subscription-reallocate-form-dialog.component.scss']
})
export class SubscriptionReallocateFormDialogComponent implements OnInit
{

  creatForm: FormGroup;
  // creatwithdrawlForm: FormGroup;
  subscriptionForm: FormGroup;
  withdrawalForm: FormGroup;
  id: any;
  csv_id: any;
  date: any;
  currencyOldvalue: any;
  navOldvalue: any;
  totalAtOldvalue: any;
  items: any = [];
  selectedId: any;
  totalFundAmount: any = 0;
  finalFund: any = {};
  fund: any[] = [];
  assets: any = [];
  status: any;
  grandTotal = 0
  lstSubscription = [];
  complete: any = '0';
  resultId: any;
  funds = [];
  subscriptionName: any;
  requestedWithdralAmount: any[] = [];
  withDawral: any;
  fromFund: any[] = [];
  toFund: any[] = [];


  constructor(public tr: TranslationService, protected formbuilder: FormBuilder,
    protected _route: ActivatedRoute, public mainService: MainService,
    public dec_enc: DecEncService, protected dialog: MatDialog)
  {

    this.creatForm = this.formbuilder.group({
      id: [null, [Validators.required]],
      // vested_amount_percent: [null, [Validators.required]],
      bank_status: [3, [Validators.required]],
      subscription: this.formbuilder.array([]),
      withdrawal: this.formbuilder.array([]),
    });
    this.date = new Date();
  }

  subscription(): FormArray
  {
    return this.creatForm.get('subscription') as FormArray;
  }
  withdrawal(): FormArray
  {
    return this.creatForm.get('withdrawal') as FormArray;
  }

  ngOnInit(): void
  {
    this.id = this.resultId;
    this.creatForm.get('id').setValue(this.id);
    // this.creatwithdrawlForm.get('id').setValue(this.id);
    this.getall();
  }

  getall(): void
  {
    let url = 'reallocations/details';
    let body = {
      id: this.id
    }
    let enc_payload = this.dec_enc.encryption(body);
    this.mainService.postData(url, { enc_payload }).then(res =>
    {
      if (res.statusCode == 200)
      {
        let result = this.dec_enc.decryption(res.data)
        this.fromFund = result?.re_allocations;
        this.toFund = result?.re_allocations;
        //console.log("FromFund", this.fromFund);
        //console.log("toFund", this.toFund);
        this.lstSubscription = result?.re_allocations;
        this.fromFund.forEach(element =>
        {
          this.complete=element.status;
          element?.from_fund?.assets.forEach(asset =>
          {
            //console.log("from_fund loop asset", asset)
            asset?.reallocation_withdrawals?.forEach(ele =>
            {
              //console.log("from_fund loop", ele)
              // ele.no_of_units = ele.amount_sar / ele.nav_per_unit;
              // asset['totalAmount'] = asset['totalAmount'] * 1 + ele.amount_sar * 1;
              asset['currency_price'] = ele.currency_price;
              asset['nav_per_unit'] = ele.nav_per_unit;
              asset['amount_sar'] = ele.amount_sar;
              asset['no_of_units'] = ele.no_of_units;
              asset['fund_id'] = ele.fund_id;
              asset['asset_id'] = ele.asset_id;
              asset['asset_name'] = asset.asset_name;
            });

            this.grandTotal = this.grandTotal * 1 + Number(asset.amount_sar);
          });
        });
        this.toFund.forEach(element =>
        {
          element?.fund?.assets.forEach(asset =>
          {
            
            asset?.reallocation_subscriptions?.forEach(ele =>
            {
              //console.log("fund loop", ele)
              // ele.no_of_units = ele.amount_sar / ele.nav_per_unit;
              // element['totalAmount'] = element['totalAmount'] * 1 + ele.amount_sar * 1;
              asset['currency_price'] = ele.currency_price;
              asset['nav_per_unit'] = ele.nav_per_unit;
              asset['amount_sar'] = ele.amount_sar;
              asset['no_of_units'] = ele.no_of_units;
              asset['fund_id'] = ele.fund_id;
              asset['asset_id'] = ele.asset_id;
              asset['asset_name'] = asset.asset_name;
            });

            this.grandTotal = this.grandTotal * 1 + Number(asset.amount_sar);
          });
        });
          // console.log("toFund", this.toFund);
      }
    })
  }

  updateAsset(asset)
  {
    if (!!asset.available_units && !!asset.nav_per_unit && asset.available_units != 0 && asset.nav_per_unit != 0) 
    {
      asset.amount_sar = asset.available_units * asset.nav_per_unit;
    }
    this.UpdateFund();
  }
  updateAsset1(asset)
  {
    if (!!asset.amount_sar && !!asset.nav_per_unit && asset.amount_sar != 0 && asset.nav_per_unit != 0 ) 
    {
      asset.no_of_units = asset.amount_sar / asset.nav_per_unit;
    }
    this.UpdateFund();
  }

  UpdateFund()
  {
    this.subscription().clear();
    this.withdrawal().clear();
    this.grandTotal = 0;
    this.updatevalues();
    //console.log("validateAssets for subscription", this.creatForm.get('subscription').value);
    //console.log("validateAssets for withdrawal", this.creatForm.get('withdrawal').value);
  }
  updatevalues()
  {
    this.lstSubscription.forEach(element =>
    {
      element['totalAmount'] = 0
      //console.log("element onUpdateFund ", element)
      element?.from_fund?.assets.forEach(asset =>
      {
        if (!!asset.amount_sar && !!asset.nav_per_unit && !!asset.available_units && !isNaN(asset.available_units) && asset.available_units != Infinity) 
        {
          asset.amount_sar = asset.available_units * asset.nav_per_unit;
          element['totalAmount'] = element['totalAmount'] * 1 + asset.amount_sar * 1;
          let fdForm = this.formbuilder.group({
            fund_id: new FormControl(asset?.funds_assets?.fund_id),
            asset_id: new FormControl(asset.id),
            asset_name: new FormControl(asset.asset_name),
            currency: new FormControl(asset.currency),
            // currency_price: new FormControl(asset.currency_price),
            nav_per_unit: new FormControl(asset.nav_per_unit),
            amount_sar: new FormControl(asset.amount_sar),
            no_of_units: new FormControl(asset.available_units),
          });
          // this.grandTotal = this.grandTotal * 1 + Number(asset.amount_sar);
          this.withdrawal().push(fdForm);
        }
      });

    });
    this.lstSubscription.forEach(element =>
    {
      element['totalAmount'] = 0
      // //console.log("element onUpdateFund ", element)
      element?.fund?.assets.forEach(asset =>
      {
        if (!!asset.amount_sar && !!asset.nav_per_unit && !!asset.no_of_units && !isNaN(asset.no_of_units) && asset.no_of_units != Infinity) 
        {
          asset.no_of_units = asset.amount_sar / asset.nav_per_unit;
          element['totalAmount'] = element['totalAmount'] * 1 + asset.amount_sar * 1;
          let fdForm = this.formbuilder.group({
            fund_id: new FormControl(asset?.funds_assets?.fund_id),
            asset_id: new FormControl(asset.id),
            asset_name: new FormControl(asset.asset_name),
            currency: new FormControl(asset.currency),
            // currency_price: new FormControl(asset.currency_price),
            nav_per_unit: new FormControl(asset.nav_per_unit),
            amount_sar: new FormControl(asset.amount_sar),
            no_of_units: new FormControl(asset.no_of_units),
          });
          this.grandTotal = this.grandTotal * 1 + Number(asset.amount_sar);
          this.subscription().push(fdForm);
        }
      });
    });
  }

  validateFundContribution()
  {
    let isValidated = true;
    this.lstSubscription.forEach(element =>
    {
      let fn = this.funds.filter(item => item.fund_id == element.id);
      if (!!fn[0])
      {
        if (+fn[0].amount !== +element['totalAmount'])
        {
          //console.log("validateFundContribution", fn[0], element['totalAmount']);
          //console.log("validateAssets check fund_id", fn[0].amount);
          isValidated = false;
        }
      }
    })
    return isValidated;
  }

  onUpdateClick()
  {
    let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
    let cm = dialogRef.componentInstance;
    cm.heading = 'Alert';
    cm.message = "Are you sure! you want to upload?";
    cm.submitResponceText = 'Yes';
    cm.cancelButtonText = 'No';
    cm.type = 'ask2';
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result == true)
      {
        this.onUpdate();
      }
    })
  }

  onUpdate()
  {
    // this.UpdateFund();
    // let isValidated = this.validateFundContribution();
    // if(!isValidated) {
    //   let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
    //   let cm = dialogRef.componentInstance;
    //   cm.heading = 'Error';
    //   cm.message = "Sum of Total Amount must be equal to Total Withdrawal Amount";
    //   cm.cancelButtonText = 'Okay';
    //   cm.type = 'error';
    //   return
    // }
    //console.log("this.creatForm.value", this.creatForm.value)
    // //console.log("this.creatwithdrawlForm.value", this.creatwithdrawlForm.value)
    let enc_payload = this.dec_enc.encryption(this.creatForm.value);
    let url = "reallocations/update";
    this.mainService.postData(url, { enc_payload }).then(res =>
    {
      if (res.statusCode == 200)
      {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Success';
        cm.message = "Updated successfully";
        cm.cancelButtonText = 'Okay';
        cm.type = 'success';
        dialogRef.afterClosed().subscribe(result =>
        {
          this.dialog.closeAll();

        })
      }
      else if (res.statusCode == 422)
      {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = res.error.message;
        cm.cancelButtonText = 'Okay';
        cm.type = 'error';
      }
      else if (res.error.statusCode == 412)
      {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = res.error.message;
        cm.cancelButtonText = 'Okay';
        cm.type = 'error';
      }
      else if (res.statusCode == 404)
      {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = res.error.message;
        cm.cancelButtonText = 'Okay';
        cm.type = 'error';
      }
      else if (res.statusCode == 406)
      {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = res.error.message;
        cm.cancelButtonText = 'Okay';
        cm.type = 'error';
      }
      else
      {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = res.error.message;
        cm.cancelButtonText = 'Okay';
        cm.type = 'error';
      }
    },
      error =>
      {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = 'Internal Server error';
        cm.cancelButtonText = 'Okay';
        cm.type = 'error';
      });
  }

  dialogClose()
  {
    this.dialog.closeAll();
  }
}

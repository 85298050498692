<div>
    <h2 class="mid-head mb-35">{{'Invite Employees' | translate}}</h2>

    <div fxLayout="row" fxLayoutAlign="space-around center">
        <div fxFlex="45" (click)="selectOption('invite')" class="small-Btn"
            [ngClass]="{ 'selectedBox': selectedOption == 'invite' }">{{'Invite' | translate}}
        </div>
        <div fxFlex="45" (click)="selectOption('copy')" class="small-Btn"
            [ngClass]="{ 'selectedBox': selectedOption == 'copy' }">{{'Copy Link' | translate}}
        </div>
    </div>

    <h5 *ngIf="selectedOption == 'invite'" class="mt-40 label">{{'Enter email address of employees' | translate}}</h5>
    <h5 *ngIf="selectedOption == 'copy'" class="mt-20 label">{{'Please share this URL with employees' | translate}}</h5>

    <ng-container *ngIf="selectedOption == 'invite'">
        <div fxLayout="row wrap">
            <mat-form-field fxFlex="100" fxFlex.xs="100" style="width: 50%;" class="input-field"
                appearance="outline">
                <mat-label>{{'Email' | translate}}</mat-label>
                <input placeholder="name1@yourcompany.sa, name2@yourcompany.sa" [(ngModel)]="email"
                    (keyup)="checkMail($event)" autocomplete="off" type="text" matInput>
            </mat-form-field>
        </div>
        <div class="chip-list mt-20">
            <div style="display: flex;flex-wrap: wrap; gap: 5px;">
                <div *ngFor="let item of emailList">
                    <span class="chip">
                        {{ item }}
                        <mat-icon class="cp" (click)="remove(item)">cancel</mat-icon>
                    </span>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="selectedOption == 'copy'">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="94">
                <div class="copyLink">
                    <div style="flex: 90;padding: 10px; overflow-x: auto; white-space: nowrap;">{{link}}</div>
                    <div style="border-left: 2px solid;padding: 10px;">
                        <img (click)="onRefresh()" src="assets/images/repeat.png" alt="">
                    </div>
                </div>
            </div>
            <div fxFlex="5">
                <img (click)="onCopy(link)" src="assets/images/copy.png">
            </div>
        </div>
    </ng-container>

    <div class="mt-20" fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="70">
            <div class="btn-group">
                <button (click)="onSkip()" *ngIf="selectedOption == 'invite'" 
                    style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
                    mat-raised-button>{{'Cancel' | translate}}
                </button>
                <button [disabled]="emailList.length == 0" *ngIf="selectedOption == 'invite'" (click)="onInvite()" class="btn-submit" mat-raised-button>{{'Invite Employees' | translate}}</button>
                <button *ngIf="selectedOption == 'copy'" (click)="onSkip()" class="btn-submit" mat-raised-button>{{'Ok' | translate}}</button>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { TranslateService } from '@ngx-translate/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { DatePipe } from '@angular/common';
import {enPdfNote} from 'src/assets/pdf-notes/pdf-note-en';
import { arPdfNote } from 'src/assets/pdf-notes/pdf-note-ar';

@Component({
  selector: 'app-transaction-report',
  templateUrl: './transaction-report.component.html',
  styleUrls: ['./transaction-report.component.scss']
})
export class TransactionReportComponent implements OnInit {
  companyListing = 'company';
  list: any = [];
  summary: any = [];
  employeesList: any;
  sumOfDebit: any = 0.0000;
  sumoFCredit: any = 0.0000;
  groupId: any = '0';
  portfolioId: any;
  groupName: any = 'All';
  groupList: any = [];
  portfolioList: any = [];
  start_date: any;
  end_date: any;
  search: any;
  body: any;
  searchTimer: any;
  benificiaryName: any;
  employeeName: any;
  employeeId: any;
  showPdf: boolean = false;
  currentLang = 'en';
  closingBalance : any;
  reportType: any = 'all';
  selectedReportType = 'All'
  transactions: any[] = [];
  tableProps = {
    heading: '',
    hasSearch: false,
    searchLabel: 'Search',
    hasButton: false,
    report: false,
    isEmptyRow: false,
    headerActions: [],
    filterArray: [ 
      {
        label: 'Type',
        type: 'filter_list',
        key: 'report_type',
        visibility: true,
        selected: '',
        options: [
          {
            value: '',
            label: 'All',
            key: 'report_type',
          },
          {
            value: 'contribution',
            label: 'Contribution',
            key: 'report_type',
          },
          {
            value: 'withdrawal',
            label: 'Withdrawal',
            key: 'report_type',
          },
          {
            value: 'reallocation',
            label: 'Reallocation',
            key: 'report_type',
          },
        ],
      },
    ],
    rowActions: [],
    colHeader: {
      id: 'Transaction ID',
      created_at: 'Date',
      type: 'Type',
      description: 'Description',
      debit: 'Debit (SAR)',
      credit: 'Credit (SAR)',
      remainig: 'Running Balance (SAR)',
    },
    columnTypes: {
      created_at: 'date',
      debit: 'number-4',
      credit: 'number-4',
      balance: 'number-4',
      remainig: 'number-4'
    },
    dateFilter: {
      hasDatePicker: true,
      removeCross: true,
      type: "dateRange",
      startLabel: "Start_Date",
      endLabel: "End_Date",
    },
  };

  groupTableProps = {
    heading: '',
    hasSearch: false,
    searchLabel: 'Search',
    report: false,
    isEmptyRow: false,
    hasButton: false,
    headerActions: [],
    filterArray: [
      {
        label: 'Groups',
        type: 'filter_list',
        key: 'report_of',
        visibility: true,
        selected: '0',
        options: [
          {
            value: '0',
            label: 'All',
            key: 'report_of',
          },
        ],
      },
      {
        label: 'Portfolios',
        type: 'filter_list',
        key: 'report_of',
        visibility: true,
        selected: '',
        options: [
          {
            value: '',
            label: 'All',
            key: 'report_of',
          },
        ],
      },
      {
        label: 'Type',
        type: 'filter_list',
        key: 'report_type',
        visibility: true,
        selected: '',
        options: [
          {
            value: '',
            label: 'All',
            key: 'report_type',
          },
          {
            value: 'contribution',
            label: 'Contribution',
            key: 'report_type',
          },
          {
            value: 'withdrawal',
            label: 'Withdrawal',
            key: 'report_type',
          },
          {
            value: 'reallocation',
            label: 'Reallocation',
            key: 'report_type',
          },
        ],
      },
    ],
    rowActions: [],
    colHeader: {
      id: 'Transaction ID',
      created_at: 'Date',
      type: 'Type',
      description: 'Description',
      debit: 'Debit (SAR)',
      credit: 'Credit (SAR)',
      remainig: 'Running Balance (SAR)',
    },
    columnTypes: {
      created_at: 'date',
      debit: 'number-4',
      credit: 'number-4',
      balance: 'number-4',
      remainig: 'number-4'
    },
    dateFilter: {
      hasDatePicker: true,
      removeCross: true,
      type: "dateRange",
      startLabel: "Start_Date",
      endLabel: "End_Date",
    }
  };

  employeeTableProps = {
    heading: '',
    hasSearch: false,
    searchLabel: 'Search',
    report: false,
    isEmptyRow: false,
    hasButton: false,
    headerActions: [],
    filterArray: [
      {
        label: 'Employees',
        type: 'filter_list',
        key: 'report_of',
        visibility: true,
        selected: '',
        options: [],
      },
      {
        label: 'Type',
        type: 'filter_list',
        key: 'report_type',
        visibility: true,
        selected: '',
        options: [
          {
            value: '',
            label: 'All',
            key: 'report_type',
          },
          {
            value: 'contribution',
            label: 'Contribution',
            key: 'report_type',
          },
          {
            value: 'withdrawal',
            label: 'Withdrawal',
            key: 'report_type',
          },
          {
            value: 'reallocation',
            label: 'Reallocation',
            key: 'report_type',
          },
        ],
      },
    ],
    rowActions: [],
    colHeader: {
      id: 'Transaction ID',
      created_at: 'Date',
      type: 'Type',
      description: 'Description',
      debit: 'Debit (SAR)',
      credit: 'Credit (SAR)',
      remainig: 'Running Balance (SAR)',
    },
    columnTypes: {
      created_at: 'date',
      debit: 'number-4',
      credit: 'number-4',
      balance: 'number-4',
      remainig: 'number-4'
    },
    dateFilter: {
      hasDatePicker: true,
      removeCross: true,
      type: "dateRange",
      startLabel: "Start_Date",
      endLabel: "End_Date",
    }
  };

  constructor(protected dec_enc: DecEncService, protected mainService: MainService, private datePipe: DatePipe, private translate: TranslateService) {
    this.currentLang = localStorage.getItem('lang');
    //this.employerName = localStorage.getItem('employer_name');
  }

  ngOnInit(): void {
    const today = new Date();
    this.start_date = this.datePipe.transform(today, 'yyyy-MM-dd');
    this.end_date = this.datePipe.transform(today, 'yyyy-MM-dd');
    this.getAll(1);
    this.getEmployees();
    this.getGroups();
    this.getPortfolio();
    this.getBenificiaryName();
  }

  capitalizeFirstLetter(word: string): string {
    if (!word) return word;
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  getAll(index: any) {
    this.body = {
      search: this.search,
      start_date: this.start_date,
      end_date: this.end_date,
      report_type: this.reportType,
      //...(this.companyListing == 'company' ? {report_type: this.reportType} : {}),
      ...(this.companyListing == 'groups' ? { group_id: this.groupId, portfolio_id: this.portfolioId,} : {}),
      ...(this.companyListing == 'employees' ? { employee_id: this.employeeId } : {})
    };
    let enc_payload = this.dec_enc.encryption(this.body);
    let url;
    if (this.companyListing == 'company') {
      url = 'reports/company-transaction';
    }
    else if (this.companyListing == 'groups') {
      url = 'reports/group-transaction';
    }
    else if (this.companyListing == 'employees') {
      url = 'reports/employee-transaction';
    }
    this.mainService.postData(url, { enc_payload }).then(res => {
      if (res.statusCode === 200) {
        let result = this.dec_enc.decryption(res.data);
        console.log('result~',result);
        
        this.summary = result.summary;
        this.list = result.data;
        this.list.forEach(element => {
          element['created_at'] = this.datePipe.transform(element['created_at'], 'dd-MM-yyyy');
          element['type'] = this.capitalizeFirstLetter(element['type']);
        });
        this.list.reverse();
        if (this.list.length > 0) {
          let total = {
            id: this.translate.instant('Total changes to account:'),
            debit: this.summary.total_debit,
            credit:this.summary.total_credit,
            report: true,
          }         
          console.log('total', total);
          
          this.closingBalance = this.summary.closing_balance + this.summary.unrealize_return;
          this.list.push(this.createEmptyRow());
          this.list.push(total);
        }
      }
    });
  }

  createEmptyRow() {
    return {
      id: '',
      debit: '',
      credit: '',
      isEmptyRow: true, // You can use this field to identify and style the empty row differently
    }
  }

  getGroups() {
    let payload = {}
    let enc_payload = this.dec_enc.encryption(payload);
    let url = 'funds_groups?per_page=100';
    this.mainService.postData(url, { enc_payload }).then(res => {
      if (res.statusCode === 200) {
        let result = this.dec_enc.decryption(res.data);        
        this.groupList = result.funds_groups;
        const initialOption = this.groupTableProps.filterArray[0].options[0];
        const dynamicOptions = this.groupTableProps.filterArray[0].options = result.funds_groups.map(item => ({
          value: item.id,
          label: item.name,
          key: 'report_of'
        }));
        this.groupTableProps.filterArray[0].options = [initialOption, ...dynamicOptions];
      }
    });
  }

  getPortfolio() {
    let payload = {
      funds_group_id: this.groupId,
      availability: "not-assigned"
    }
    let enc_payload = this.dec_enc.encryption(payload);
    let url = 'funds_groups/funds';
    this.mainService.postData(url, { enc_payload }).then(res => {
      if (res.statusCode === 200) {
        let result = this.dec_enc.decryption(res.data);
        this.portfolioList = result.funds;
        const initialOption = this.groupTableProps.filterArray[1].options[0];
        const dynamicOptions = this.groupTableProps.filterArray[1].options = result.funds.map(item => ({
          value: item.id,
          label: item.name,
          key: 'report_of'
        }));
        this.groupTableProps.filterArray[1].options = [initialOption, ...dynamicOptions];
      }
    });
  }

  getEmployees() {
    let payload = {}
    let enc_payload = this.dec_enc.encryption(payload);
    let url = 'employees';
    this.mainService.postData(url, { enc_payload }).then(res => {
      if (res.statusCode === 200) {
        let result = this.dec_enc.decryption(res.data);
        console.log('result~', result);
        this.employeesList = result.employees;
        this.employeeTableProps.filterArray[0].options = this.employeesList.map(item => ({
          value: item.id,
          label: item.name,
          key: 'report_of'
        }));
      }
    });
  }

  onChangeList(item) {
    this.companyListing = item;
    this.list = [];
    this.summary = [];
    this.groupId = '0';
    this.portfolioId = '';
    this.employeeId = '';
    this.reportType = 'all'
    this.groupTableProps.filterArray[0].selected = '0';
    this.groupTableProps.filterArray[1].selected = '';
    this.employeeTableProps.filterArray[0].selected = '';
    const today = new Date();
    this.start_date = this.datePipe.transform(today, 'yyyy-MM-dd');
    this.end_date = this.datePipe.transform(today, 'yyyy-MM-dd');
    if (this.companyListing != "employees"){
      this.getAll(1);
    }

  }
  getBenificiaryName(){
    let url = 'home/manage-organization';
    this.mainService.postData(url, {}).then(res => {
      if (res.statusCode === 200) {
        let result = this.dec_enc.decryption(res.data);
        console.log('result~', result);
        this.benificiaryName = result.employer.company_name;
        console.log('benificiaryName~', this.benificiaryName );
      }
    });
  }

  searchData(event) {
    this.search = event;
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.getAll(1);
    }, 800);
  }

  onFilters(event) {
    this.list = [];
    this.summary = [];
    console.log('event~',event)
    event.options.forEach(element => {
      if (element.value == event.selected) {
        if(this.companyListing == 'company'){
            this.reportType = element.value;
            this.getAll(1);
        }
        // if(event.label == 'Type'){
        //   this.reportType = element.value;
        //   this.getAll(1);
        // }
        if (this.companyListing == 'groups') {
          if(event.label == 'Groups'){
            this.groupId = element.value;
            this.groupName = element.label;
            this.portfolioId = '';
            this.groupTableProps.filterArray[1].selected = '';
            this.getPortfolio();
            this.getAll(1);
          }
          else if(event.label == 'Type'){
            this.reportType = element.value;
            this.getAll(1);
          }
          else{
            this.portfolioId = element.value;
            this.getAll(1);
          }
        }
        if (this.companyListing == 'employees') {
          if(event.label == 'Employees'){
            this.employeeId = element.value;
            this.employeeName = element.label;
            this.getAll(1);
          }
          else{
            this.reportType = element.value;
            this.getAll(1);
          }
        }
      }
    });
  }

  onDateFilters(event) {
    this.list = [];
    this.summary = [];
    if (event.type == "dateRange") {
      this.start_date = event['start'];
      this.end_date = event['end'];
      if (this.companyListing === 'employees') {
        if (this.employeeId !== '') {
            this.getAll(1);
        } 
      } else if (this.companyListing === 'company' || this.companyListing === 'groups') {
          this.getAll(1);
      }
      
    }
  }

  downloadPDF() {
    this.showPdf = true;

    setTimeout(() => {
        const data = document.getElementById('contentToExport');
        //const pdfNoteElement = document.getElementById('pdfNote');
        const pdfNoteImage = enPdfNote;
        const pdfNoteImageAr = arPdfNote;

        if (data) {
            html2canvas(data, { scale: 3 }).then(canvas => {
                const imgWidth = 210; // A4 width in mm
                const pageHeight = 295; // A4 height in mm
                const imgHeight = canvas.height * imgWidth / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;

                const imgData = canvas.toDataURL('image/png', 1.0);
                const pdf = new jsPDF('p', 'mm', 'a4');

                // Add the main image content to the PDF
                pdf.addImage(imgData, 'PNG', (pdf.internal.pageSize.getWidth() - imgWidth) / 2, position, imgWidth, imgHeight, undefined, 'FAST');

                heightLeft -= pageHeight;

                // Handle multiple pages
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', (pdf.internal.pageSize.getWidth() - imgWidth) / 2, position, imgWidth, imgHeight, undefined, 'FAST');
                    heightLeft -= pageHeight;
                }

                let remainingHeight = pageHeight - (position + imgHeight);
                if (remainingHeight < 50) {
                    pdf.addPage();
                    remainingHeight = pageHeight; // Reset remaining height on the new page
                }

                const screenshotWidth = 195; // Adjust width as necessary
                const screenshotHeight = 30; // Adjust height as necessary, or calculate based on aspect ratio

                // Append the PDF_Note image as base64
                if (this.currentLang == 'ar'){
                  pdf.addImage(pdfNoteImageAr, 'PNG', 5, 250, screenshotWidth, screenshotHeight);
                }else{
                  pdf.addImage(pdfNoteImage, 'PNG', 5, 250, screenshotWidth, screenshotHeight);
                }
                
                pdf.setTextColor(100, 100, 100);
                pdf.setFontSize(10);
                pdf.text("Thriftplan.com.sa/Reports", 5, 291);

                // Save the PDF based on the listing type
                this.companyListing == "company" ? pdf.save('Transaction Statement by Company.pdf') :
                this.companyListing == "groups" ? pdf.save('Transaction Statement by Groups.pdf'):
                pdf.save('Transaction Statement by Employees.pdf') ;
            });
        }
    }, 500);

    setTimeout(() => {
        this.showPdf = false;
    }, 500);
  }

  //  downloadPDF() {
  //   this.showPdf = true;
  //   setTimeout(() => {
  //     const data = document.getElementById('contentToExport');
  //     if (data) {
  //       // Increase scale to 2 for better resolution
  //       html2canvas(data, { scale: 3 }).then(canvas => {
  //         const imgWidth = 210; // A4 width in mm
  //         const pageHeight = 295; // A4 height in mm
  //         const imgHeight = canvas.height * imgWidth / canvas.width;
  //         let heightLeft = imgHeight;
  //         let position = 0;

  //         const imgData = canvas.toDataURL('image/png', 1.0); // Highest quality PNG

  //         const pdf = new jsPDF('p', 'mm', 'a4');

  //         // Set PDF compression to none for best quality
  //         pdf.addImage(imgData, 'PNG', (pdf.internal.pageSize.getWidth() - imgWidth) / 2, position, imgWidth, imgHeight, undefined, 'FAST');

  //         heightLeft -= pageHeight;

  //         while (heightLeft >= 0) {
  //           position = heightLeft - imgHeight;
  //           pdf.addPage();
  //           pdf.addImage(imgData, 'PNG', (pdf.internal.pageSize.getWidth() - imgWidth) / 2, position, imgWidth, imgHeight, undefined, 'FAST');
  //           heightLeft -= pageHeight;
  //         }
  //         this.companyListing == "company" ? pdf.save('Transaction Statement by Company.pdf') :
  //          this.companyListing == "groups" ? pdf.save('Transaction Statement by Groups.pdf'):
  //          pdf.save('Transaction Statement by Employees.pdf') ;
  //       });
  //     }
  //   }, 500);

  //   setTimeout(() => {
  //     this.showPdf = false;
  //   }, 500);
  // }

}



<div class="mt-20">
  <mat-card>
    <div *ngIf="pagination" fxLayout="row" fxLayoutAlign="space-between">
      <div class="pg-text">{{'Total_Count' | translate}} {{getPaginationText() | translate}}</div>
      <div fxLayout="row" style="align-items: center;">
        <mat-form-field style="width: 100px;">
          <mat-select [(value)]="selectedPageSize" (selectionChange)="onPageSizeChange($event.value)">
            <mat-option *ngFor="let pageSize of pageSizeOptions" [value]="pageSize">{{ pageSize }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-icon class="pg-arrow" [ngStyle]="{'transform': isArabic ? 'rotate(180deg)' : '' }"
          (click)="$event.target.classList.contains('disabled') || setPagination(pagination.page-1)"
          [ngClass]="{disabled:pagination.page == 1}">arrow_back</mat-icon>
        <!-- <div *ngIf="pagination.page > 3" style="border-radius: 50%; text-align: center; padding: 3px 9px;">...</div> -->

        <div class="pg-circle" (click)="setPagination(pagination.page-2)" *ngIf="pagination.page > 2">{{pagination.page
          - 2}}</div>
        <div class="pg-circle" (click)="setPagination(pagination.page-1)" *ngIf="pagination.page > 1">{{pagination.page
          - 1}}</div>

        <div class="pg-circle pg-circle-selected">{{pagination.page ? pagination.page : '0' | translate}}</div>
        <div class="pg-circle" (click)="setPagination(pagination.page+1)" *ngIf="pagination.page < pagination.pages">
          {{pagination.page + 1}}</div>
        <div class="pg-circle" (click)="setPagination(pagination.page+2)" *ngIf="pagination.page+1 < pagination.pages">
          {{pagination.page + 2}}</div>

        <!-- <div *ngIf="pagination.page + 2 < pagination.pages"style="width: 20px; border-radius: 50%; text-align: center; padding: 3px 9px;">...</div> -->
        <mat-icon class="pg-arrow" [ngStyle]="{'transform': isArabic ? 'rotate(180deg)' : '' }"
          style="transform: rotate(180deg)"
          (click)="$event.target.classList.contains('disabled') || setPagination(pagination.page+1)"
          [ngClass]="{disabled:pagination.page >= pagination.pages}">arrow_forward</mat-icon>
      </div>
    </div>
  </mat-card>
</div>
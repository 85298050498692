<div fxLayoutAlign="space-between" style="margin-inline: 20px;">
    <h3 class="form-heading heading-margin">{{'Reallocate_Portfolio' | translate}}</h3>
    <button *ngIf="status != 0" (click)="openDialog()" style="background-color: #213060; color: white; line-height: 34px; padding: 0 25px; border-radius: 10px;" mat-raised-button>{{'View_Subscription_Form' | translate}}</button>
</div>

<form [formGroup]="Form">
    <div fxLayout="row wrap" fxLayoutGap="10px" class="form-container">
        <mat-form-field fxFlex="49" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
            appearance="outline">
            <mat-label>{{'Reallocation_id' | translate}}</mat-label>
            <input readonly autocomplete="off" type="text" matInput formControlName="id">
        </mat-form-field>
        <mat-form-field fxFlex="49" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
            appearance="outline">
            <mat-label>{{'Company_Name' | translate}}</mat-label>
            <input readonly autocomplete="off" type="text" matInput formControlName="company_name">
        </mat-form-field>
        <mat-form-field fxFlex="49" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
            appearance="outline">
            <mat-label>{{'From_Portfolio' | translate}}</mat-label>
            <input readonly autocomplete="off" type="text" matInput formControlName="from_fund">
        </mat-form-field>
        <mat-form-field fxFlex="49" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
            appearance="outline">
            <mat-label>{{'To_Portfolio' | translate}}</mat-label>
            <input readonly autocomplete="off" type="text" matInput formControlName="to_fund">
        </mat-form-field>
        <mat-form-field fxFlex="49" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
            appearance="outline">
            <mat-label>{{'Created_At' | translate}}</mat-label>
            <input readonly autocomplete="off" type="text" matInput formControlName="createdAt">
        </mat-form-field>
        <mat-form-field fxFlex="49" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
            appearance="outline">
            <mat-label>{{'Status' | translate}}</mat-label>
            <input readonly autocomplete="off" type="text" matInput formControlName="status">
        </mat-form-field>
    </div>
</form>

<div style="margin-inline: 20px;">
    <button (click)="back()" style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 25px; border-radius: 10px;" mat-raised-button>{{'Back' | translate}}</button>
</div>
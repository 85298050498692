import { Component, Inject, OnInit } from '@angular/core';
import { AlertDialog } from '../alert-dialog/alert.dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { MainService } from 'src/app/services/main.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { appConfig } from 'src/config';

@Component({
    selector: 'app-invite-employee-dialog',
    templateUrl: './invite-employee-dialog.component.html',
    styleUrls: ['./invite-employee-dialog.component.scss']
})
export class InviteEmployeeDialogComponent implements OnInit
{

    selectedOption = 'invite';
    email = '';
    emailList = [];
    isArabic = false;

    constructor(protected dialogRef: MatDialogRef<AlertDialog>,
       protected alertService: AlertService, 
       @Inject(MAT_DIALOG_DATA) public link: any,
        protected apiService: MainService,
        protected dec_enc: DecEncService)
    {
        console.log('link', this.link)
        this.link = `${appConfig.invite_link}?code=${this.link}`;
        this.isArabic = localStorage.getItem('lang') == 'ar' ? true : false;
    }

    ngOnInit(): void
    {
    }

    selectOption(option)
    {
        this.selectedOption = option;
    }

    checkMail(e: any)
    {
        if (e.key == ',') {
            let arr = this.email.split(',').filter(x => x != '');
            this.emailList = arr;
        }
    }

    remove(item)
    {
        this.emailList.splice(this.emailList.indexOf(item), 1);
    }

    onSkip()
    {
        let data = {
            type: "skip",
            data: null
        }
        this.dialogRef.close(data);
    }

    onRefresh()
    {
        this.getProfileForHash();
    }

    onCopy(link)
    {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = link;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        this.alertService.toastMessage('Copied.');
    }

    onInvite(){
        let body = {
            emails: this.emailList
        }
        let enc_payload = this.dec_enc.encryption(body);
        this.apiService.postData('employees/send-invitation', {enc_payload}).then(response => {
            if (response.statusCode == 200) {
              let message = localStorage.getItem('lang') == 'ar' ? response.message_ar : response.message;
                this.alertService.alert('Invites sent!', 'Invitation emails with onboarding instructions have been sent successfully to employees.').then(res => {
                  let data = {
                    type: "invite",
                    data: this.emailList
                }
                console.log(this.emailList)
                this.dialogRef.close(data)
                });
                return;
            }
            let message = localStorage.getItem('lang') == 'ar' ? response.error.message_ar : response.error.message;
            this.alertService.alert('Error', message);
        })
    }

    getProfileForHash()
    {
        let url = `viewProfile`;
        this.apiService.postData(url, {}).then(res => {
            if (res.statusCode == 200) {
                let result = this.dec_enc.decryption(res.data);
                this.link = `${appConfig.invite_link}?code=${result.user.hash_link}`;
                return;
            }
            else {

            }
        },)
    }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { ProfileService } from 'src/app/services/profile.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  summaryForm: FormGroup;
  summary: any = [];
  instructionSummary: any = [];
  funds: any = [];
  withdrawal_ids: any = [];
  currentDate = new Date();
  currentLang = 'en';
  ipAddress: any;
  iBnNo: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SummaryComponent>,
    private fb: FormBuilder,
    public tr: TranslationService,
    public route: Router,
    public dec_enc: DecEncService,
    protected mainService: MainService,
    public paginationService: PaginationService,
    public dialog: MatDialog,
    protected alertService: AlertService,
    private http: HttpClient,
    private profileService: ProfileService) {
    this.instructionSummary = data.summaryData;
    this.summary = this.instructionSummary.summary;
    this.funds = this.instructionSummary.portfolios;
    this.withdrawal_ids = data.withdrawal_ids;
    this.currentLang = localStorage.getItem('Lang');
  }

  ngOnInit(): void {
    this.setIpAddress();
    this.getBankDetail();
    this.initializeForm();
  }

  initializeForm() {
    this.summaryForm = this.fb.group({
      termsAccepted: [false],
      date: [this.currentDate],
      ip_address: [this.ipAddress],
      user_name: [this.profileService.user.name],
    });
  }

  setIpAddress() {
    this.http.get<{ ip: any }>('http://api.ipify.org/?format=json')
      .subscribe(
        (result) => {
          this.ipAddress = result.ip;
          this.initializeForm();
        },
        (error) => {
          console.error('Failed to fetch IP address', error);
        }
      );
  }

  onCancel() {
    this.dialogRef.close();
  }

  print(): void {
    const divId = 'printable-div';
    const divContents = document.getElementById(divId)?.innerHTML;
    const printWindow = window.open('', '', 'height=600,width=800');
    if (printWindow) {
      printWindow.document.write('<html><head><title>Print</title>');
      printWindow.document.write('<style>body{font-family: Arial, sans-serif;} table{width:100%; border-collapse:collapse;} td{padding:8px;}</style>');
      printWindow.document.write('</head><body >');
      printWindow.document.write(divContents || '');
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
    }
  }

  get isSubmitButtonDisabled() {
    return !this.summaryForm.get('termsAccepted')?.value;
  }

  confirm() {
    let data = {
      withdrawal_ids: this.withdrawal_ids
    }
    let enc_payload = this.dec_enc.encryption(data);
    this.mainService.postData('redemptions/confirm', { enc_payload }).then(res => {
      if (res.statusCode == 200) {
        this.dialogRef.close();
        if (localStorage.getItem('lang') == 'ar') {
          this.alertService.alert('Success', res.message_ar);
        }
        else {
          this.alertService.alert('Success', res.message);
        }
        this.route.navigate(['main/redemption-instructions'])
      }
      else {
        if (localStorage.getItem('lang') == 'ar') {
          this.alertService.alert('Error', res.message_ar);
        }
        else {
          this.alertService.alert('Error', res.message);
        }
      }
    },
      error => {
        this.alertService.alert(this.tr.translation.error, this.tr.translation.serverError, '');
      }
    )
  }

  getBankDetail() {
    let url = 'home/manage-organization';
    let employer_id = localStorage.getItem('employer_id');
    let enc_payload = this.dec_enc.encryption({ employer_id });

    this.mainService.postData(url, { enc_payload }).then(result => {
      if (result.statusCode === 200) {

        let data = this.dec_enc.decryption(result.data);
        if (data.bank_details != null) {
          this.iBnNo = data.bank_details.iban_number;
        }
        return;
      }
    });
  }

}

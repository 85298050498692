<div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div fxLayoutAlign="end">
        <button (click)="onPrint()"
            style="background-color: #213060; color: white; line-height: 34px; padding: 0 25px; border-radius: 10px; outline: none;"
            mat-raised-button>{{'Save as PDF' | translate}}
        </button>
   </div>

    <mat-card class="mt-4 mb-4">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="mx-3" style="position: relative;z-index: 9999;">
            <div fxFlex="60">
                <h2 style="color: #3F638F;">{{'Employee Detail' | translate}}</h2>
            </div>
            <div *ngIf="list?.employeeDetails?.termination_status == '0'" fxFlex="38" style="text-align: end;">
                <button (click)="getTerminationCheck()" style=" height:40px; font-size: 14px; font-weight: 700; padding: 2px 16px; 
                  border-radius: 10px; background-color: red; transform: scale(0.9);
                  color: white; justify-content: center;" mat-raised-button>
                    {{'Terminate_Employee' | translate}}
                </button>
            </div>
        </div>
        <table class="table table-hover table-borderless mt-3" style="position: relative;z-index: 9999;">
            <tbody>
                <tr>
                    <td>
                        <p>{{'Name' | translate}}</p>
                        <h4 style="text-transform: capitalize;">{{dashboardData?.employee?.name ? dashboardData?.employee?.name : 'N/A'}}</h4>
                    </td>
                    <td>
                        <p>{{'Email' | translate}}</p>
                        <h4 style="text-transform: capitalize;">{{dashboardData?.employee?.email ? dashboardData?.employee?.email : 'N/A'}}</h4>
                    </td>
                    <td>
                        <p>{{'Phone_Number' | translate}}</p>
                        <h4 style="text-transform: capitalize;">{{ dashboardData?.employee?.phone ? dashboardData?.employee?.phone : 'N/A'}}</h4>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{{'Designation' | translate}}</p>
                        <h4 style="text-transform: capitalize;">
                            {{dashboardData?.employee?.employees_identification?.position ? dashboardData?.employee?.employees_identification?.position :'N/A'}}</h4>
                    </td>
                    <td>
                        <p>{{ 'Employee Joining Date' | translate}}</p>
                        <h4>{{dashboardData?.employee?.employees_identification?.employee_since ? dashboardData?.employee?.employees_identification?.employee_since :'N/A'}}</h4>
                    </td>

                    <td>
                        <p>{{'Employee Group' | translate}}</p>
                        <h4 style="text-transform: capitalize;">{{ dashboardData?.employee?.employees_identification?.employee_group ? dashboardData?.employee?.employees_identification?.employee_group : 'N/A'}}</h4>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="bg-card-bottom"></div>
    </mat-card>

    <mat-card class="mt-4 mb-4">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="mx-3" style="position: relative;z-index: 9999;">
            <div fxFlex="60">
                <h2 style="color: #3F638F;">{{'Settlement_Summary' | translate}}</h2>
                <h5 style="color: #3F638F;">{{'Based_on_Current_Nav_Prices' | translate}}</h5>
            </div>
        </div>


        <table class="table table-hover table-borderless mt-3" style="position: relative;z-index: 9999;">
            <tbody>
                <tr>
                    <td>
                        <p>{{'Total Settlement Amount (SAR)' | translate}}</p>
                        <h2 style="text-transform: capitalize;">
                            <!-- {{tr.numberFormatter(employeeaggregate?.total_settelment_Amount ? employeeaggregate?.total_settelment_Amount: '0.0000' )}} -->
                            <!-- {{dashboardData?.termination?.total_settlement_amount ? (dashboardData?.termination?.total_settlement_amount | number: '1.4-4') : '0.0000'}} -->

                            <span class="number">{{ dashboardData?.termination?.total_settlement_amount | number:'1.4-4' | slice:0:-4 }}</span> 
                            <span style="font-size: 14px;">{{ dashboardData?.termination?.total_settlement_amount | number:'1.4-4' | slice:-4 }}</span>
                        </h2>
                    </td>
                    <td>
                        <p>{{'Total Employee Settlement (SAR)' | translate}}</p>
                        <h2 style="text-transform: capitalize;">
                            <!-- {{tr.numberFormatter(employeeaggregate?.settelmentAmount ? employeeaggregate?.settelmentAmount:'0.00' )}} -->
                            <!-- {{ dashboardData?.termination?.total_employee_settlement ? (dashboardData?.termination?.total_employee_settlement | number : '1.4-4') : '0.0000' }} -->

                            <span class="number">{{ dashboardData?.termination?.total_employee_settlement | number:'1.4-4' | slice:0:-4 }}</span> 
                            <span style="font-size: 14px;">{{ dashboardData?.termination?.total_employee_settlement | number:'1.4-4' | slice:-4 }}</span>
                        </h2>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{{'Company Settlement (SAR)' | translate}}</p>
                        <h2 style="text-transform: capitalize;">
                            <!-- {{tr.numberFormatter(investedEmployer)}} -->
                            <!-- {{ dashboardData?.termination?.company_settlement ? (dashboardData?.termination?.company_settlement | number : '1.4-4') : '0.00' }} -->

                            <span class="number">{{ dashboardData?.termination?.company_settlement | number:'1.4-4' | slice:0:-4 }}</span> 
                            <span style="font-size: 14px;">{{ dashboardData?.termination?.company_settlement | number:'1.4-4' | slice:-4 }}</span>
                        </h2>
                    </td>
                    <td>
                        <p>{{'Employee Settlement (SAR)' | translate}}</p>
                        <h2 style="text-transform: capitalize;">
                            <!-- {{tr.numberFormatter(investedEmployee)}} -->
                            <!-- {{ dashboardData?.termination?.employee_settlement ? (dashboardData?.termination?.employee_settlement | number : '1.4-4') : '0.0000' }} -->

                            <span class="number">{{ dashboardData?.termination?.employee_settlement | number:'1.4-4' | slice:0:-4 }}</span> 
                            <span style="font-size: 14px;">{{ dashboardData?.termination?.employee_settlement | number:'1.4-4' | slice:-4 }}</span>
                        </h2>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="bg-card-bottom"></div>
    </mat-card>

    <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="mt-4">
        <h2 fxFlex="100" style="color: #3F638F;text-align: start;">{{'Portfolio' | translate}}</h2>
        <P fxFlex="100" style="text-align: start;">{{'Term_Emp_Portfolio_Text' | translate}}</P>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="mt-4">
        <div fxFlex="48">
            <h2 style="color: #3F638F;">{{tr.translation.Employer_Count}}</h2>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxFlex="25">
                    <h2 style="color: #3F638F;">{{'riskprofile' | translate}} : </h2>
                </div>
                <div fxFlex class="box">
                    <h2 *ngIf="dashboardData?.company_fund?.risk_type=='low'" style="background-color: #00C435;color: white;border-radius: 10px;
                                text-align: center;
                                width: 100%;
                                height: 100%;">{{tr.translation.low}}</h2>
                    <h2 *ngIf="dashboardData?.company_fund?.risk_type=='medium'" style="background-color: #E69A5A;color: white;border-radius: 10px;
                                 text-align: center;
                                width: 100%;
                                height: 100%;">{{tr.translation.medium}}</h2>
                    <h2 *ngIf="dashboardData?.company_fund?.risk_type=='high'" style="background-color: #EC4955; text-align: center;color: white;border-radius: 10px;
                                width: 100%;
                                height: 100%;">{{tr.translation.high}}</h2>
                    <h2 *ngIf="!dashboardData?.company_fund?.risk_type" style="background-color: #55cfeb; text-align: center;color: white;border-radius: 10px;
                        width: 100%;
                        height: 100%;">N/A</h2>
                </div>
            </div>
        </div>
        <div fxFlex="48">
            <h2 style="color: #3F638F;">{{tr.translation.Employee_Count}}</h2>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxFlex="25">
                    <h2 style="color: #3F638F;">{{'riskprofile' | translate}} : </h2>
                </div>
                <div fxFlex class="box">
                    <h2 *ngIf="dashboardData?.employee_fund?.risk_type=='low'" style="background-color: #00C435;color: white;border-radius: 10px;
                                text-align: center;
                                width: 100%;
                                height: 100%;">{{tr.translation.low}}</h2>
                    <h2 *ngIf="dashboardData?.employee_fund?.risk_type=='medium'" style="background-color: #E69A5A;color: white;border-radius: 10px;
                                 text-align: center;
                                width: 100%;
                                height: 100%;">{{tr.translation.medium}}</h2>
                    <h2 *ngIf="dashboardData?.employee_fund?.risk_type=='high'" style="background-color: #EC4955; text-align: center;color: white;border-radius: 10px;
                                width: 100%;
                                height: 100%;">{{tr.translation.high}}</h2>
                    <h2 *ngIf="!dashboardData?.employee_fund?.risk_type" style="background-color: #55cfeb; text-align: center;color: white;border-radius: 10px;
                        width: 100%;
                        height: 100%;">N/A</h2>
                </div>
            </div>
        </div>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="mt-4">
        <mat-card fxFlex="49" class="chart-a">
            <div class="bg-card-top"></div>
            <h2 style="color: #3F638F" class="ml-3 mx-3">{{'Employer Portfolio Breakdown' | translate}}</h2>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="mt-1">

                <div fxFlex="100">
                    <div id="pieChart" style="display: block;"></div>
                </div>
                <div fxFlex="100">
                    <mat-card style="margin-left:20px ;margin-right:20px">
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-1">
                            <div fxFlex="5">
                                <div style=" width: 20px;
                                height: 20px;
                                border: 1px solid #004390;
                                border-radius: 50px;
                                box-sizing: border-box;
                               background-color:#004390;margin-top: -10px;
                                margin-left: 10px;"></div>
                            </div>
                            <div fxFlex="23">
                                <p>{{'Employer_Contribution' | translate}}</p>
                            </div>
                            <div fxFlex="5">
                                <div style=" width: 20px;
                                height: 20px;
                                border: 1px solid #687784;
                                border-radius: 50px;
                                box-sizing: border-box;
                                margin-top: -10px;
                               background-color:#687784"></div>
                            </div>
                            <div fxFlex="23">
                                <p>{{'Company Invested' | translate}}</p>
                            </div>
                            <div fxFlex="5">
                                <div style=" width: 20px;
                                height: 20px;
                                border: 1px solid #E80F1F;
                                border-radius: 50px;
                                box-sizing: border-box;
                                margin-top: -10px;
                               background-color:#E80F1F"></div>
                            </div>
                            <div fxFlex="23">
                                <p>{{'Profit_Loss' | translate}}</p>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div fxFlex="100" style="margin-bottom:10px">
                    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                        <app-card fxFlex="49" fxFlex.sm="49" fxFlex.xs="99" img="assets/images/person-arrows.png"
                            topText="{{'Employer_Contribution' | translate}}" middleTextAlphabet="SAR " [middleTextNumberFour]="dashboardData?.summary?.company_contribution"
                            bottomText="" [error]="false" [bg]="'#eeeeee'">
                        </app-card>
                        <app-card fxFlex="49" fxFlex.sm="49" fxFlex.xs="99" img="assets/images/person-arrows.png"
                            topText="{{'Vested Amount' | translate}}" middleTextAlphabet="SAR " [middleTextNumberFour]="dashboardData?.vest_breakdown?.vested"
                            bottomText="" [error]="false" [bg]="'#eeeeee'">
                        </app-card>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                        <app-card fxFlex="49" fxFlex.sm="49" fxFlex.xs="99" img="assets/images/person-arrows.png"
                            topText="{{'Company Invested' | translate}}"
                            middleTextAlphabet="SAR " [middleTextNumberFour]="dashboardData?.company_fund?.invested_amount"
                            bottomText="" [error]="false" [bg]="'#eeeeee'">
                        </app-card>
                        <app-card fxFlex="49" fxFlex.sm="49" fxFlex.xs="99" img="assets/images/person-arrows.png"
                            topText="{{'Profit' | translate}}"
                            middleTextAlphabet="SAR " [middleTextNumberFour]="dashboardData?.company_fund?.profit"
                            bottomText="" [error]="false" [bg]="'#eeeeee'">
                        </app-card>
                    </div>
                </div>
            </div>

        </mat-card>

        <mat-card fxFlex="49" class="chart-a">
            <div class="bg-card-top"></div>
            <h2 style="color: #3F638F" class="ml-3 mx-3"> {{'Employee Portfolio Breakdown' | translate}}</h2>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="mt-1">
                <div fxFlex="100">
                    <div id="pieChartB" style="display: block;"></div>
                </div>
                <div fxFlex="100">
                    <mat-card style="margin-left:20px ;margin-right:20px">
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-1">
                            <div fxFlex="5">
                                <div style=" width: 20px;
                                height: 20px;
                                border: 1px solid #004390;
                                border-radius: 50px;
                                box-sizing: border-box;
                               background-color:#004390;margin-top: -10px;
                                margin-left: 10px;"></div>
                            </div>
                            <div fxFlex="23">
                                <p>{{'Employee_Contribution' | translate}}</p>
                            </div>
                            <div fxFlex="5">
                                <div style=" width: 20px;
                                height: 20px;
                                border: 1px solid #687784;
                                border-radius: 50px;
                                box-sizing: border-box;
                                margin-top: -10px;
                               background-color:#687784"></div>
                            </div>
                            <div fxFlex="23">
                                <p>{{'Employee Invested' | translate}}</p>
                            </div>
                            <div fxFlex="5">
                                <div style=" width: 20px;
                                height: 20px;
                                border: 1px solid #E80F1F;
                                border-radius: 50px;
                                box-sizing: border-box;
                                margin-top: -10px;
                               background-color:#E80F1F"></div>
                            </div>
                            <div fxFlex="23">
                                <p>{{'Profit_Loss' | translate}}</p>
                            </div>
                        </div>

                    </mat-card>

                </div>
                <div fxFlex="100" style="margin-bottom:10px">

                    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                        <app-card fxFlex="49" fxFlex.sm="49" fxFlex.xs="99" img="assets/images/person-arrows.png"
                            topText="{{'Employee_Contribution' | translate}}"
                            middleTextAlphabet="SAR " [middleTextNumberFour]="dashboardData?.summary?.employee_contribution"
                            bottomText="" [error]="false" [bg]="'#eeeeee'">
                        </app-card>
                        <app-card fxFlex="49" fxFlex.sm="49" fxFlex.xs="99" img="assets/images/person-arrows.png"
                            topText="{{'Withdrawal_Amount' | translate}}"
                            middleTextAlphabet="SAR " [middleTextNumberFour]="dashboardData?.employee_fund?.withdrawn"
                            bottomText="" [error]="false" [bg]="'#eeeeee'">
                        </app-card>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                        <app-card fxFlex="49" fxFlex.sm="49" fxFlex.xs="99" img="assets/images/person-arrows.png"
                            topText="{{'Employee Invested' | translate}}"
                            middleTextAlphabet="SAR " [middleTextNumberFour]="dashboardData?.employee_fund?.invested_amount"
                            bottomText="" [error]="false" [bg]="'#eeeeee'">
                        </app-card>
                        <app-card fxFlex="49" fxFlex.sm="49" fxFlex.xs="99" img="assets/images/person-arrows.png"
                            topText="{{'Profit' | translate}}"
                            middleTextAlphabet="SAR " [middleTextNumberFour]="dashboardData?.employee_fund?.profit"
                            bottomText="" [error]="false" [bg]="'#eeeeee'">
                        </app-card>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>

    <!-- Table -->
    <div class="mt-20" *ngIf="dashboardData">
        <app-table [tableProps]="tablePropsEmployer" [dataSource]="dashboardData?.company_assets"></app-table>
    </div>

    <!-- Table -->

    <!-- Table -->
    <div class="mt-20" *ngIf="dashboardData">
        <app-table class="mt-20" [tableProps]="tablePropsEmployee" [dataSource]="dashboardData?.employee_assets"></app-table>
    </div>
    <!-- Table -->
</div>


<!-- Print Section -->
<div style="display: none;">
    <div id="print">
  
        <!-- Top Heading ThriftPlan -->
        <section fxLayout="row" fxLayoutAlign="center">
            <h4>ThriftPlan</h4>
        </section>
        
        <!-- Employee Details -->
        <div fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;z-index: 9999;">
            <div fxFlex="60">
                <h2 style="font-weight: bold;">{{'Termination Summary' | translate}}</h2>
            </div>
        </div>
  
        <!-- Employee Details -->
        <div fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;z-index: 9999;">
            <div fxFlex="60">
                <h3>{{'Employee Detail' | translate}}</h3>
            </div>
        </div>
        <table style="width: 100%; border: 1px solid #5a5959; border-collapse: collapse; text-align: left;">
            <tr>
                <th style="border: 1px solid #5a5959;">{{'Name' | translate}}</th>
                <th style="border: 1px solid #5a5959;">{{'Email' | translate}}</th>
                <th style="border: 1px solid #5a5959;">{{'Phone_Number' | translate}}</th>
            </tr>
            <tr>
                <td style="border: 1px solid #5a5959;">{{dashboardData?.employee?.name ? dashboardData?.employee?.name : 'N/A'}}</td>
                <td style="border: 1px solid #5a5959;">{{dashboardData?.employee?.email ? dashboardData?.employee?.email : 'N/A'}}</td>
                <td style="border: 1px solid #5a5959;">{{ dashboardData?.employee?.phone ? dashboardData?.employee?.phone : 'N/A'}}</td>
            </tr>
            <tr>
                <th style="border: 1px solid #5a5959;">{{'Designation' | translate}}</th>
                <th style="border: 1px solid #5a5959;">{{'Employee Joining Date' | translate}}</th>
                <th style="border: 1px solid #5a5959;">{{'Employee Group' | translate}}</th>
            </tr>
            <tr>
                <td style="border: 1px solid #5a5959;">{{dashboardData?.employee?.employees_identification?.position ? dashboardData?.employee?.employees_identification?.position :'N/A'}}</td>
                <td style="border: 1px solid #5a5959;">{{dashboardData?.employee?.employees_identification?.employee_since ? dashboardData?.employee?.employees_identification?.employee_since :'N/A'}}</td>
                <td style="border: 1px solid #5a5959;">{{ dashboardData?.employee?.employees_identification?.employee_group ? dashboardData?.employee?.employees_identification?.employee_group : 'N/A'}}</td>
            </tr>
        </table>
  
        <!-- Settlement Summary -->
        <div fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;z-index: 9999;">
            <div fxFlex="60">
                <h3 style="margin-bottom: 0;">{{'Settlement_Summary' | translate}}</h3>
                <h5 style="margin-top: 0;">{{'Based_on_Current_Nav_Prices' | translate}}</h5>
            </div>
        </div>
        <table style="width: 100%; border: 1px solid #5a5959; border-collapse: collapse; text-align: left;">
            <tr>
                <th style="border: 1px solid #5a5959;">{{'Total_Settlement_Amount' | translate}}</th>
                <th style="border: 1px solid #5a5959;">{{'Total Employee Settlement' | translate}}</th>
            </tr>
            <tr>
              <td style="border: 1px solid #5a5959;">
                  <!-- {{dashboardData?.termination?.total_settlement_amount ? (dashboardData?.termination?.total_settlement_amount | number: '1.4-4') : '0.0000'}} -->
  
                  <span style="font-size: 16px;">{{dashboardData?.termination?.total_settlement_amount | number:'1.4-4' | slice:0:-4}}</span>
                  <span style="font-size: 12px;">{{dashboardData?.termination?.total_settlement_amount | number:'1.4-4' | slice:-4}}</span>
              </td>
                <td style="border: 1px solid #5a5959;">
                  <!-- {{ dashboardData?.termination?.total_employee_settlement ? (dashboardData?.termination?.total_employee_settlement | number : '1.4-4') : '0.0000' }} -->
  
                  <span style="font-size: 16px;">{{dashboardData?.termination?.total_employee_settlement | number:'1.4-4' | slice:0:-4}}</span>
                  <span style="font-size: 12px;">{{dashboardData?.termination?.total_employee_settlement | number:'1.4-4' | slice:-4}}</span>
              </td>
            </tr>
            <tr>
                <th style="border: 1px solid #5a5959;">{{'Company Settlement' | translate}}</th>
                <th style="border: 1px solid #5a5959;">{{'Employee Settlement' | translate}}</th>
            </tr>
            <tr>
                <td style="border: 1px solid #5a5959;">
                  <!-- {{ dashboardData?.termination?.company_settlement ? (dashboardData?.termination?.company_settlement | number : '1.4-4') : '0.00' }} -->
  
                  <span style="font-size: 16px;">{{dashboardData?.termination?.company_settlement | number:'1.4-4' | slice:0:-4}}</span>
                  <span style="font-size: 12px;">{{dashboardData?.termination?.company_settlement | number:'1.4-4' | slice:-4}}</span>
              </td>
                <td style="border: 1px solid #5a5959;">
                  <!-- {{ dashboardData?.termination?.employee_settlement ? (dashboardData?.termination?.employee_settlement | number : '1.4-4') : '0.0000' }} -->
  
                  <span style="font-size: 16px;">{{dashboardData?.termination?.employee_settlement | number:'1.4-4' | slice:0:-4}}</span>
                  <span style="font-size: 12px;">{{dashboardData?.termination?.employee_settlement | number:'1.4-4' | slice:-4}}</span>
              </td>
            </tr>
        </table>
  
        <!-- Company Portfolio Breakdown -->
        <div fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;z-index: 9999;">
            <div fxFlex="60">
                <h3 style="margin-bottom: 10px;">{{'Employer Portfolio Breakdown' | translate}}</h3>
            </div>
        </div>
        <table style="width: 100%; border: 1px solid #5a5959; border-collapse: collapse; text-align: left;">
            <tr>
                <th style="border: 1px solid #5a5959;">{{'Employer_Contribution' | translate}}</th>
                <th style="border: 1px solid #5a5959;">{{'Vested Amount' | translate}}</th>
            </tr>
            <tr>
              <td style="border: 1px solid #5a5959;">
                  <!-- {{dashboardData?.summary?.company_contribution ? (dashboardData?.summary?.company_contribution | number: '1.4-4') : '0.0000'}} -->
  
                  <span style="font-size: 16px;">{{dashboardData?.summary?.company_contribution | number:'1.4-4' | slice:0:-4}}</span>
                  <span style="font-size: 12px;">{{dashboardData?.summary?.company_contribution | number:'1.4-4' | slice:-4}}</span>
              </td>
                <td style="border: 1px solid #5a5959;">
                  <!-- {{ dashboardData?.vest_breakdown?.vested ? (dashboardData?.vest_breakdown?.vested | number : '1.4-4') : '0.0000' }} -->
  
                  <span style="font-size: 16px;">{{dashboardData?.vest_breakdown?.vested | number:'1.4-4' | slice:0:-4}}</span>
                  <span style="font-size: 12px;">{{dashboardData?.vest_breakdown?.vested | number:'1.4-4' | slice:-4}}</span>
              </td>
            </tr>
            <tr>
                <th style="border: 1px solid #5a5959;">{{'Company Invested' | translate}}</th>
                <th style="border: 1px solid #5a5959;">{{'Profit' | translate}}</th>
            </tr>
            <tr>
                <td style="border: 1px solid #5a5959;">
                  <!-- {{ dashboardData?.company_fund?.invested_amount ? (dashboardData?.company_fund?.invested_amount | number : '1.4-4') : '0.00' }} -->
  
                  <span style="font-size: 16px;">{{dashboardData?.company_fund?.invested_amount | number:'1.4-4' | slice:0:-4}}</span>
                  <span style="font-size: 12px;">{{dashboardData?.company_fund?.invested_amount | number:'1.4-4' | slice:-4}}</span>
              </td>
                <td style="border: 1px solid #5a5959;">
                  <!-- {{dashboardData?.company_fund?.profit ? (dashboardData?.company_fund?.profit | number: '1.4-4') : '0.0000'}} -->
  
                  <span style="font-size: 16px;">{{dashboardData?.company_fund?.profit | number:'1.4-4' | slice:0:-4}}</span>
                  <span style="font-size: 12px;">{{dashboardData?.company_fund?.profit | number:'1.4-4' | slice:-4}}</span>
              </td>
            </tr>
        </table>
  
        <!-- Employee Portfolio Breakdown -->
        <div fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;z-index: 9999;">
            <div fxFlex="60">
                <h3 style="margin-bottom: 10px;">{{'Employee Portfolio Breakdown' | translate}}</h3>
            </div>
        </div>
        <table style="width: 100%; border: 1px solid #5a5959; border-collapse: collapse; text-align: left;">
            <tr>
                <th style="border: 1px solid #5a5959;">{{'Employee_Contribution' | translate}}</th>
                <th style="border: 1px solid #5a5959;">{{'Withdrawal_Amount' | translate}}</th>
            </tr>
            <tr>
                <td style="border: 1px solid #5a5959;">
                  <!-- {{dashboardData?.summary?.employee_contribution ? (dashboardData?.summary?.employee_contribution | number: '1.4-4') : '0.0000'}} -->
                  
                  <span style="font-size: 16px;">{{dashboardData?.summary?.employee_contribution | number:'1.4-4' | slice:0:-4}}</span>
                  <span style="font-size: 12px;">{{dashboardData?.summary?.employee_contribution | number:'1.4-4' | slice:-4}}</span>
              </td>
                <td style="border: 1px solid #5a5959;">
                  <!-- {{dashboardData?.employee_fund?.withdrawn ? (dashboardData?.employee_fund?.withdrawn | number: '1.4-4') : '0.0000'}} -->
  
                  <span style="font-size: 16px;">{{dashboardData?.employee_fund?.withdrawn | number:'1.4-4' | slice:0:-4}}</span>
                  <span style="font-size: 12px;">{{dashboardData?.employee_fund?.withdrawn | number:'1.4-4' | slice:-4}}</span>
              </td>
            </tr>
            <tr>
                <th style="border: 1px solid #5a5959;">{{'Employee Invested' | translate}}</th>
                <th style="border: 1px solid #5a5959;">{{'Profit' | translate}}</th>
            </tr>
            <tr>
                <td style="border: 1px solid #5a5959;">
                  <!-- {{ dashboardData?.employee_fund?.invested_amount ? (dashboardData?.employee_fund?.invested_amount | number : '1.4-4') : '0.0000' }} -->
  
                  <span style="font-size: 16px;">{{dashboardData?.employee_fund?.invested_amount | number:'1.4-4' | slice:0:-4}}</span>
                  <span style="font-size: 12px;">{{dashboardData?.employee_fund?.invested_amount | number:'1.4-4' | slice:-4}}</span>
              </td>
                <td style="border: 1px solid #5a5959;">
                  <!-- {{dashboardData?.employee_fund?.profit ? (dashboardData?.employee_fund?.profit | number: '1.4-4') : '0.0000' }} -->
  
                  <span style="font-size: 16px;">{{dashboardData?.employee_fund?.profit | number:'1.4-4' | slice:0:-4}}</span>
                  <span style="font-size: 12px;">{{dashboardData?.employee_fund?.profit | number:'1.4-4' | slice:-4}}</span>
              </td>
            </tr>
        </table>
  
        <!-- Company Portfolio -->
        <div>
            <h3 style="margin-bottom: 10px;">{{'Company Portfolio' | translate}}</h3>
        </div>
        <table style="width: 100%; border: 1px solid #5a5959; border-collapse: collapse; text-align: left;">
            <tr>
                <th *ngFor="let col of headersPrintCompanyPortfolio" style="border: 1px solid #5a5959;">{{col.colHeader | translate}}</th>
            </tr>
            <tr *ngFor="let row of EmployerAssetslist; let i = index">
              <ng-container *ngFor="let col of headersPrintCompanyPortfolio; let p = index">
                  <td *ngIf="col.colType == ''" style="border: 1px solid #5a5959;">{{row[col.colKey]}}</td>
                  <td *ngIf="col.colType == 'number'" style="border: 1px solid #5a5959;">
                      <!-- {{row[col.colKey]}} -->
  
                      <span style="font-size: 16px;">{{row[col.colKey] | number:'1.2-2' | slice:0:-2}}</span>
                      <span style="font-size: 12px;">{{row[col.colKey] | number:'1.2-2' | slice:-2}}</span>
                  </td>
                  <td *ngIf="col.colType == 'number-4'" style="border: 1px solid #5a5959;">
                      <!-- {{row[col.colKey]}} -->
  
                      <span style="font-size: 16px;">{{row[col.colKey] | number:'1.4-4' | slice:0:-4}}</span>
                      <span style="font-size: 12px;">{{row[col.colKey] | number:'1.4-4' | slice:-4}}</span>
                  </td>
                  <td *ngIf="col.colType == 'number-5'" style="border: 1px solid #5a5959;">
                      <!-- {{row[col.colKey]}} -->
  
                      <span style="font-size: 16px;">{{row[col.colKey] | number:'1.5-5' | slice:0:-5}}</span>
                      <span style="font-size: 12px;">{{row[col.colKey] | number:'1.5-5' | slice:-5}}</span>
                  </td>
              </ng-container>
                
            </tr>
        </table>
  
        <!-- Employee Portfolio -->
        <div>
            <h3 style="margin-bottom: 10px;">{{'Employee Portfolio' | translate}}</h3>
        </div>
        <table style="width: 100%; border: 1px solid #5a5959; border-collapse: collapse; text-align: left;">
            <tr>
                <th *ngFor="let col of headersPrintEmployeePortfolio" style="border: 1px solid #5a5959;">{{col.colHeader | translate}}</th>
            </tr>
            <tr *ngFor="let row of EmployeeAssetslist; let i = index">
              <ng-container *ngFor="let col of headersPrintEmployeePortfolio; let p = index">
                  <td *ngIf="col.colType == ''" style="border: 1px solid #5a5959;">{{row[col.colKey]}}</td>
                  <td *ngIf="col.colType == 'number'" style="border: 1px solid #5a5959;">
                      <!-- {{row[col.colKey]}} -->
  
                      <span style="font-size: 16px;">{{row[col.colKey] | number:'1.2-2' | slice:0:-2}}</span>
                      <span style="font-size: 12px;">{{row[col.colKey] | number:'1.2-2' | slice:-2}}</span>
                  </td>
                  <td *ngIf="col.colType == 'number-4'" style="border: 1px solid #5a5959;">
                      <!-- {{row[col.colKey]}} -->
  
                      <span style="font-size: 16px;">{{row[col.colKey] | number:'1.4-4' | slice:0:-4}}</span>
                      <span style="font-size: 12px;">{{row[col.colKey] | number:'1.4-4' | slice:-4}}</span>
                  </td>
                  <td *ngIf="col.colType == 'number-5'" style="border: 1px solid #5a5959;">
                      <!-- {{row[col.colKey]}} -->
  
                      <span style="font-size: 16px;">{{row[col.colKey] | number:'1.5-5' | slice:0:-5}}</span>
                      <span style="font-size: 12px;">{{row[col.colKey] | number:'1.5-5' | slice:-5}}</span>
                  </td>
              </ng-container>
                
            </tr>
        </table>
    </div>
  </div>
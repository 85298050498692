import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AlertDialog } from 'src/app/lib/alert-dialog/alert.dialog';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
    selector: 'app-manage-investment-funds',
    templateUrl: './manage-investment-funds.component.html',
    styleUrls: ['./manage-investment-funds.component.scss']
})
export class ManageInvestmentFundsComponent implements OnInit {
    list: any = [];
    totalPages: number;
    pages: any;
    perPage: number = 10;
    index: any = 1;
    currentPage: any = 1;
    count: any;
    items: any;
    searchTimer: any;
    search: any = "";
    employer_id: any;
    role: any;
    showCreateBtn: boolean = true;
    showEditIcon: boolean = true;
    showViewIcon: boolean = true;
    statusIcon: boolean = true;

    tableProps = {
        heading: 'Company Groups',
        hasSearch: true,
        searchLabel: 'Search',
        hasButton: true,
        headerActions: [
            {
                label: 'Create_New_Group',
                route: 'investment-funds/employee-group/add',
                type: 'link',
                visibility: true,
                isCustom: false,
                icon: 'add',
            },
        ],
        rowActions: [
            { label: 'Edit', visibility: true, background: '#4318FF0D', source: 'assets/new-icons/edit.svg', iconName: 'arrow_forward' }
        ],
        colHeader: {
            id: 'Group_ID',
            name: 'Group_Name',
            group_employees_count: 'No_of_Employees',
            group_funds_count: 'No_of_Funds',
            actions: 'Actions'
        },
        columnTypes: {
            actions: 'actionsSeperate',
        },
        filterArray: []
    };

    pagination = {
        page: 1,
        pages: null,
        per_page: 10,
        count: 0
    }
    constructor(public tr: TranslationService,
        public route: Router,
        protected mainService: MainService,
        public dec_enc: DecEncService,
        protected paginationService: PaginationService,
        public dialog: MatDialog,
        protected alertService: AlertService,
        protected profileService: ProfileService) {
        // this.role = localStorage.getItem('employer_role');
        this.role = profileService.user.role;
        if (this.role == 'employer' || this.role == 'emp-maker') {
            this.showCreateBtn = true;
            this.showEditIcon = true;
            this.statusIcon = true;
        }
        else {
            this.showCreateBtn = false;
            this.showEditIcon = false;
            this.statusIcon = true;
        }
    }

    ngOnInit(): void {
        this.getall(1);
    }


    creatInvestmentFund() {
        this.route.navigate(['main/investment-funds/employee-group/add'])
    }

    editInvestmentFund(id) {
        this.route.navigate(['main/investment-funds/employee-group/update/' + id])
    }

    InvestmentFund(id) {
        this.route.navigate(['main/investment-funds/fund-group/' + id])
    }

    setPage(page) {
        this.pagination.page = page;
        this.getall(this.pagination.page);
    }

    SetPageSize(pageSize) {
        this.perPage = pageSize
        this.getall(this.pagination.page);
    }

    getall(index: any) {
        let enc_payload = this.dec_enc.encryption({ search: this.search })
        let url = 'funds_groups?per_page=' + this.perPage + '&page=' + index;
        this.mainService.postData(url, { enc_payload }).then(res => {
            if (res.statusCode == 200) {
                let result = this.dec_enc.decryption(res.data);
                this.pagination = result.pagination;
                this.list = result.funds_groups;
                this.afterList();
            }

        },
            error => {
                this.alertService.alert('Error', 'Server Error');
            }
        )
    }

    afterList() {
        this.list.forEach(item => {

            if (this.showEditIcon) {
                item['Edit'] = true;
            }
            item['created_at'] = moment(item['created_at']).format('DD-MM-YYYY');
            item['name'] = item.name ? item.name : 'N/A';
            item['fromPortfolio'] = item.from_fund?.name ? item.from_fund?.name : 'N/A';
            item['toPortfolio'] = item.fund?.name ? item.fund?.name : 'N/A';

            if (item.status == 1 && item.bank_status == 1) {
                item['statusToShow'] = 'Accepted';
            }
        });
        // console.log('AfterList', this.list);
    }

    onSearch() {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.getall(1);

        }, 800);
    }

    searchData(event) {
        this.search = event;
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.getall(1);
        }, 800);
    }

    actionClicked(event) {
        if (event.action.label == 'Edit') {
            this.editInvestmentFund(event.element.id);
        }
    }
}

import { OnInit, Component } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { FormGroup, Validators, FormBuilder, FormControl, FormArray } from "@angular/forms";
import { MainService } from "../../services/main.service";
import { DecEncService } from "src/app/services/dec-enc";
import { Router } from "@angular/router";
import { TranslationService } from "../translation/translation.service";

@Component({
	selector: 'alert-dialog',
	templateUrl: './alert.dialog.html'
})
export class AlertDialog implements OnInit
{
	isLoading: boolean;
	heading: string = '';
	message: string = '';
	type: 'ask' | 'success' | 'error' | 'info' | 'ask2';
	cancelButtonText: string;
	submitButtonText: string;
	submitResponceText: string;
	dataToSubmit: any;
	methodName: any;
	methodId: any;
	methodType: any;
	status: any;
	showLoading: boolean;
	Form: FormGroup;
	fund_id: any;
	fund: any = true;
	risk: boolean = false;
	contribution: any = false;
	FormA: FormGroup;

	constructor(protected mainApiService:
		MainService, public enc_dec: DecEncService,
		protected dialogRef: MatDialogRef<AlertDialog>,
		protected dialog: MatDialog, public fb: FormBuilder, private dec_enc: DecEncService, public route: Router
		, public tr: TranslationService)
	{
		this.isLoading = false;
		this.showLoading = false;
		this.Form = this.fb.group({

			additional_notes: [''],
			is_under_review: ['0']

		})
		this.FormA = this.fb.group({

			start: [''],
			end: ['']

		})
	}

	ngOnInit()
	{
	}

	onCancelClick(): void
	{
		this.dialogRef.close(false);
		if (this.contribution == true)
		{
			this.route.navigate(['main/allocate-contribution'])
			this.contribution = false
		}

	}
	onOkClick()
	{
		let url = 'funds/allocate'
		this.dialogRef.close(false);
		let enc_payload = this.dec_enc.encryption({ fund_id: this.fund_id })
		this.mainApiService.postData(url, { enc_payload }).then(res =>
		{
			if (res.statusCode == 200)
			{

				let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				let cm = dialogRef.componentInstance;
				cm.heading = 'Success';
				cm.message = "Portfolio has been allocated successfully";
				cm.cancelButtonText = this.tr.translation.okay;
				cm.type = 'success';
				this.fund = false;
			}
			if (res.statusCode == 208)
			{

				let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				let cm = dialogRef.componentInstance;
				cm.heading = this.tr.translation.error;
				cm.message = "You have already select a fund";
				cm.cancelButtonText = this.tr.translation.okay;
				cm.type = 'error';
			}
			if (res.statusCode == 404)
			{

				let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				let cm = dialogRef.componentInstance;
				cm.heading = this.tr.translation.error;
				cm.message = this.tr.translation.serverError;
				cm.cancelButtonText = this.tr.translation.okay;
				cm.type = 'error';
			}

		},
			error =>
			{
				let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				let cm = dialogRef.componentInstance;
				cm.heading = this.tr.translation.error;
				cm.message = this.tr.translation.serverError;
				cm.cancelButtonText = this.tr.translation.okay;
				cm.type = 'error';
			}
		)
	}

	onSubmitClick(): void
	{
		// this.dialogRef.close(true);
		this.isLoading = true;

		if (this.methodType == 'get')
		{
			this.mainApiService.getList(this.methodName)
				.then(result =>
				{
					if (result.status === 200)
					{
						// this.gerOrdersList(this.currentPage);
						this.dialogRef.close(true);
						this.isLoading = false;
					}
					else
					{
						this.isLoading = false;
						let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
						let cm = dialogRef.componentInstance;
						cm.heading = this.tr.translation.error;
						cm.message = this.tr.translation.serverError;
						cm.cancelButtonText = this.tr.translation.okay;
						cm.type = 'error';
					}
				});
		}
		else if (this.methodType == 'delete')
		{

			this.mainApiService.delete(this.methodName)
				.then(result =>
				{
					if (result.status === 200)
					{
						// this.gerOrdersList(this.currentPage);
						this.dialogRef.close(true);
						this.isLoading = false;
					}
					else
					{
						this.isLoading = false;
						let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
						let cm = dialogRef.componentInstance;
						cm.heading = this.tr.translation.error;
						cm.message = this.tr.translation.serverError;
						cm.cancelButtonText = this.tr.translation.okay;
						cm.type = 'error';
					}
				});
		}
		else if (this.methodType == 'ask')
		{
			this.dialogRef.close(true);
		}
		else if (this.methodType == 'post')
		{
			this.Form.addControl('id', new FormControl(this.methodId))
			this.Form.addControl('status', new FormControl(this.status))
			let payload = this.enc_dec.encryption(this.Form.value);
			let formData = new FormData();
			formData.append("enc_payload", payload);

			this.mainApiService.postData(this.methodName, formData)
				.then(result =>
				{
					if (result.statusCode === 200)
					{
						// this.gerOrdersList(this.currentPage);
						this.dialogRef.close(true);
						this.isLoading = false;
					}
					else
					{
						this.isLoading = false;
						let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
						let cm = dialogRef.componentInstance;
						cm.heading = this.tr.translation.error;
						cm.message = result?.error?.message;
						cm.cancelButtonText = this.tr.translation.okay;
						cm.type = 'error';
					}
				});
		}

		else
		{
			this.mainApiService.postData(this.methodName, this.dataToSubmit)
				.then(result =>
				{
					if ((result.status === 200 || result.status === 201) && result.data)
					{
						// this.gerOrdersList(this.currentPage);
						this.dialogRef.close(true);
						this.isLoading = false;
					}
					else
					{
						this.isLoading = false;
						let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
						let cm = dialogRef.componentInstance;
						cm.heading = this.tr.translation.error;
						cm.message = this.tr.translation.serverError;
						cm.cancelButtonText = this.tr.translation.okay;
						cm.type = 'error';
					}
				});
		}
	}
	onResponce(): void
	{
		this.dialogRef.close(true);
	}
	senddates()
	{
		this.dialogRef.close(this.FormA.value);
		this.isLoading = false;
	}
}

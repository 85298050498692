import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { ChangeDetectorRef } from '@angular/core';
import { SummaryComponent } from '../summary/summary.component';

@Component({
  selector: 'app-withdrawal-process',
  templateUrl: './withdrawal-process.component.html',
  styleUrls: ['./withdrawal-process.component.scss']
})
export class WithdrawalProcessComponent implements OnInit {
  Form: FormGroup;
  list: any = [];
  withdrawal_ids: any = [];
  processData: any = [];
  reject: any;
  summaryData: any = [];
  dialogType: any;
  employeeTotal = 0;
  companyTotal = 0;
  rejectReasons = [
    { reason: 'Canceled by Admin' },
    { reason: 'User not eligible' },
    { reason: 'Amount exceeds limit of withdrawal' },
    { reason: 'Transaction limit exceeded' },
    { reason: 'Request passed processing deadline' }
  ];

  constructor(public tr: TranslationService,
    public route: Router,
    public dec_enc: DecEncService,
    protected mainService: MainService,
    public paginationService: PaginationService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<WithdrawalProcessComponent>,
    protected alertService: AlertService,
    protected formbuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dialogType = data?.dialogType;
    if (this.dialogType == "process") {
      this.withdrawal_ids = data.withdrawal_ids;
      this.processData = data.processData;

      this.processData.forEach(item => {
        if (item.is_company === "0") {
          this.employeeTotal += parseFloat(item.amount);
        } else {
          this.companyTotal += parseFloat(item.amount);
        }
      });
      let employeeTotal = this.employeeTotal.toFixed(4);
      let companyTotal = this.companyTotal.toFixed(4);
      this.Form = this.formbuilder.group({
        company_withdraw: [companyTotal],
        employees_withdraw: [employeeTotal]
      })
      this.list = this.processData;
      this.Form.patchValue(this.processData);
    }
    if (this.dialogType == "reject") {
      this.withdrawal_ids = data.withdrawal_ids;
    }
  }

  tableProps = {
    heading: '',
    hasSearch: false,
    searchLabel: '',
    hasButton: false,
    hasButtonsMenu: false,
    headerActions: [],
    rowActions: [],
    colHeader: {
      employee_id: 'Employee_ID',
      name: 'Employee_Name',
      companyOne: 'Company Amount (SAR)',
      companyZero: 'Employee Amount (SAR)',
      type: 'Type',
    },
    columnTypes: {
      companyOne: 'number-4',
      companyZero: 'number-4',
      status: 'errorColumn',
      created_at: 'date',
    },
    filterArray: [],
  };

  ngOnInit(): void { }

  openDialog(): void {
    const dialogRef = this.dialog.open(WithdrawalProcessComponent, {
      width: '700px',
      panelClass: localStorage.getItem('lang') == 'ar' ? 'arabic-lang' : 'english-lang',
      data: { /* pass any data if needed */ },
      disableClose: true,
    });
  }

  openSummaryDialog(): void {
    const dialogRef = this.dialog.open(SummaryComponent, {
      width: '900px',
      panelClass: localStorage.getItem('lang') == 'ar' ? 'arabic-lang' : 'english-lang',
      data: { summaryData: this.summaryData, withdrawal_ids: this.withdrawal_ids },
      disableClose: true,
    });
  }

  process() {
    let data = {
      withdrawal_ids: this.withdrawal_ids
    }
    let enc_payload = this.dec_enc.encryption(data);
    this.mainService.postData('redemptions/process', { enc_payload }).then(res => {
      if (res.statusCode == 200) {
        this.summaryData = this.dec_enc.decryption(res.data);
        this.dialogRef.close();
        this.openSummaryDialog();
      }
      else {
        if (localStorage.getItem('lang') == 'ar') {
          this.alertService.alert('Error', res.error.message_ar);
        }
        else {
          this.alertService.alert('Error', res.error.message);
        }
      }
    },
      error => {
        this.alertService.alert(this.tr.translation.error, this.tr.translation.serverError, '');
      }
    )
  }

  onCancel() {
    this.dialogRef.close();
  }

  rejectReason() {
    let data = {
      withdrawal_ids: this.withdrawal_ids,
      reason: this.reject
    }
    let enc_payload = this.dec_enc.encryption(data);
    this.mainService.postData('redemptions/reject', { enc_payload }).then(res => {
      if (res.statusCode == 200) {
        this.list = this.dec_enc.decryption(res.data);
        this.dialogRef.close();
        if (localStorage.getItem('lang') == 'ar') {
          this.alertService.alert('Success', res.error.message_ar);
        }
        else {
          this.alertService.alert('Success', res.error.message);
        }
      }
      else {
        if (localStorage.getItem('lang') == 'ar') {
          this.alertService.alert('Error', res.error.message_ar);
        }
        else {
          this.alertService.alert('Error', res.error.message);
        }
      }
    },
      error => {
        this.alertService.alert(this.tr.translation.error, this.tr.translation.serverError, '');
      }
    )
  }
}

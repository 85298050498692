<main class="mrl-20 mt-30 mb-20">

    <section fxLayout="row" fxLayoutAlign="center">
        <h2 class="heading fs-24">{{'Contribution Summary' | translate}}</h2>
    </section>

    <section fxLayout="row wrap">
        <div fxFlex="49.5" class="mr-10 pb-10" [class.mr-10]="!isArabic" [class.ml-10]="isArabic">
            <form [formGroup]="Form">
                <div fxLayout="row wrap" class="form-container">
                    <mat-form-field fxFlex="100" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Date' | translate}}</mat-label>
                        <input matInput type="text" formControlName="dated" readonly>
                        <mat-icon matSuffix>today</mat-icon>
                    </mat-form-field>

                    <mat-form-field fxFlex="100" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Authorizer' | translate}}</mat-label>
                        <input autocomplete="off" type="text" matInput formControlName="ip" readonly>
                    </mat-form-field>

                    <mat-form-field fxFlex="100" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Username' | translate}}</mat-label>
                        <input autocomplete="off" type="text" matInput formControlName="userName" readonly>
                    </mat-form-field>

                    <mat-form-field *ngIf="urlType == 'new'" fxFlex="100" fxFlex.xs="100" style="width: 100%;"
                        class="input-field mt-10 date-picker-custom" fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Contribution Period' | translate}}</mat-label>
                        <input (click)="openDatePicker()" autocomplete="off" type="text" matInput
                            formControlName="contributionPeriod" readonly [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker panelClass="example-month-picker" startView="multi-year"
                            (monthSelected)="chosenMonthHandler($event)" #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field *ngIf="urlType != 'new'" fxFlex="100" fxFlex.xs="100" style="width: 100%;"
                        class="input-field mt-10 date-picker-custom" fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Contribution Period' | translate}}</mat-label>
                        <input autocomplete="off" type="text" matInput formControlName="contributionPeriod" readonly>
                    </mat-form-field>
                    <div *ngIf="Form.get('contributionPeriod').invalid && Form.get('contributionPeriod').touched">
                        <p style="color: red; font-size: 12; margin-left: 10px;">Please select contribution period.</p>
                    </div>
                </div>
            </form>
        </div>

        <div fxFlex="49.5" fxLayout="row wrap" fxLayoutAlign="space-between" style="padding-top: 13px;">
            <app-card [ngStyle]="isArabicLang() ? {'text-align': 'right'} : {}"
                fxFlex="49" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="99" img="assets/images/person-arrows.png"
                topText="{{'Employer_Contribution' | translate}}" middleTextAlphabet="SAR "
                [middleTextNumberFour]="summary.company">
            </app-card>
            <app-card [ngStyle]="isArabicLang() ? {'text-align': 'right'} : {}"
                fxFlex="49" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="99" img="assets/images/person-arrows.png"
                topText="{{'Employee_Contribution' | translate}}" middleTextAlphabet="SAR "
                [middleTextNumberFour]="summary.employees">
            </app-card>
            <app-card [ngStyle]="isArabicLang() ? {'text-align': 'right'} : {}"
                fxFlex="49" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="99" img="assets/images/person-arrows.png"
                topText="{{'Required Balance' | translate}}" middleTextAlphabet="SAR "
                [middleTextNumberFour]="summary.total">
            </app-card>
            <app-card [ngStyle]="isArabicLang() ? {'text-align': 'right'} : {}"
                fxFlex="49" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="99" topText="{{'Reminder' | translate}}"
                bottomText="{{'Please make sure the required balance is available in your virtual IBAN' | translate}}">
            </app-card>
        </div>
    </section>

    <section class="summary-section mt-20">
        <h2 [class.ta-r]="isArabic" class="heading">{{'Investment Summary' | translate}}</h2>
        <ng-container *ngIf="list.length > 0">
            <app-list-card [ngStyle]="isArabicLang() ? {'text-align': 'right'} : {}"
                [headers]="headersSummary" [list]="list"></app-list-card>
            <p [class.ta-r]="isArabic" class="note-text">{{'Please note that these numbers of units' | translate}}</p>
        </ng-container>
    </section>

    <ng-container *ngIf="urlType == 'new'">
        <section [class.ta-r]="isArabic" class="mt-20">
            <mat-checkbox [(ngModel)]="disableButton" class="example-margin">
                <h3 class="checkbox-text">{{'I have read and accepted the subscription terms of the fund managers above'
                    | translate}}</h3>
            </mat-checkbox>
        </section>
        <section class="mt-20" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="20px">
            <!-- <button (click)="cancel()" mat-raised-button class="btn-canc">{{'Cancel' | translate}}</button> -->
            <button (click)="cancel()"
                style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
                mat-raised-button>{{'Cancel' | translate}}</button>
            <button (click)="submitContributions()" mat-raised-button class="btn-submit"
                [disabled]="!disableButton">{{'Submit' | translate}}</button>
        </section>
    </ng-container>

</main>
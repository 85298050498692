<div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
  <h3 style="font-weight: bold; margin: 0px; color: #213060;">{{'Update_Profile' | translate}}</h3>

  <form [formGroup]="Form">
    <div fxLayout="row wrap" fxLayoutGap="10px" class="form-container">
      <mat-form-field fxFlex="48" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
        appearance="outline">
        <mat-label>{{'Name' | translate}}</mat-label>
        <input readonly autocomplete="off" type="text" matInput formControlName="name">
      </mat-form-field>

      <mat-form-field fxFlex="48" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
        appearance="outline">
        <mat-label> {{'Email' | translate}}</mat-label>
        <input readonly autocomplete="off" type="text" matInput formControlName="email">
      </mat-form-field>

      <mat-form-field fxFlex="48" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
        appearance="outline">
        <mat-label>{{'National_Iqama_ID' | translate}}</mat-label>
        <input readonly autocomplete="off" type="text" matInput formControlName="iqama_id">
      </mat-form-field>

      <mat-form-field fxFlex="48" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
        appearance="outline">
        <mat-label>{{'Iqama_Expiry_Date' | translate}}</mat-label>
        <!-- <input readonly autocomplete="off" type="text" matInput formControlName="iqama_expiry"> -->

        <input (click)="picker2.open()" matInput type="text" formControlName="iqama_expiry" [matDatepicker]="picker2"
          [min]="currentDate" readonly />
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
        <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'iqama_expiry')" #tooltip="matTooltip"
          [matTooltip]="getErrorMessage(Form, 'iqama_expiry', 'National ID/Iqama Expiry')"
          [matTooltipDisabled]="!getFieldValue(Form, 'iqama_expiry')" matTooltipPosition="above" matPrefix>info
        </mat-icon>
      </mat-form-field>

      <mat-card class="card mt-10" fxFlex="48" fxFlex.sm="93.5" fxFlex.xs="97" fxLayout="row" fxLayout.xs="column"
        style="height: 162px; border-radius: 20px;" ngStyle.xs="height:280px;">
        <input style="display: none;" type="file" #attach (change)="getFile($event)" accept=".png, .jpg, .jpeg">
        <button mat-button class="upload-button" (click)="attach.click()">
          <ng-container *ngIf="!imgToShow">
            <mat-icon>upload</mat-icon>
            <h2>Upload Files</h2>
            <h5>{{'File_Types' | translate}}</h5>
          </ng-container>
          <ng-container *ngIf="imgToShow">
            <img style="width: 100%; height: 100%;" [src]="imgToShow">
          </ng-container>
        </button>
        <span class="button-text" fxFlexOffset="6" ngStyle.xs="margin-left:22px;">
          <h2 style="color: #213060; font-weight: bold;">{{'National_Iqama_Scanned' | translate}}</h2>
        </span>
      </mat-card>
      <div fxFlex="97" fxLayoutAlign="end center">
        <button (click)="updateProfile()" style="background-color: #213060; color: white;
            line-height: 34px; padding: 0 25px; border-radius: 10px; outline: none; margin-top: 20px;"
          mat-raised-button>{{'Update Profile' | translate}}</button>
      </div>
    </div>
  </form>

  <h3 style="font-weight: bold; margin: 40px 0 0 0; color: #213060;">{{'Update_Password' | translate}}</h3>
  <form [formGroup]="setPassword">
    <div class="mt-10" fxLayout="row wrap" fxLayoutAlign="space-between start">
      <div fxLayout="row wrap" fxLayoutAlign="space-between" fxFlex="97">
        <mat-form-field fxFlex="32" fxFlex.xs="100" fxFlex.sm="100" class="input-field mt-20" fxFlexAlign="center"
          appearance="outline">
          <mat-label>{{'EnterCurrentPassword' | translate}}</mat-label>
          <input autocomplete="off" type="password" matInput formControlName="old_password">
          <mat-icon *ngIf="getFieldValue(setPassword, 'old_password')" #tooltip="matTooltip"
            [matTooltip]="(getErrorMessage(setPassword,'old_password', 'Old Password'))| translate"
            [matTooltipDisabled]="!getFieldValue(setPassword,'old_password')" matTooltipPosition="above"
            style="color: #213060;" matSuffix>info
          </mat-icon>
        </mat-form-field>

        <mat-form-field fxFlex="32" fxFlex.xs="100" fxFlex.sm="100" class="input-field mt-20" fxFlexAlign="center"
          appearance="outline">
          <mat-label>{{'EnterNewPassword' | translate}}</mat-label>
          <input autocomplete="off" type="password" matInput formControlName="new_password">
          <mat-icon *ngIf="getFieldValue(setPassword,'new_password')" #tooltip="matTooltip"
            [matTooltip]="(getErrorMessage(setPassword,'new_password', 'New Password'))| translate"
            [matTooltipDisabled]="!getFieldValue(setPassword,'new_password')" matTooltipPosition="above"
            style="color: #213060;" matSuffix>info
          </mat-icon>
        </mat-form-field>

        <mat-form-field fxFlex="32" fxFlex.xs="100" fxFlex.sm="100" class="input-field mt-20" fxFlexAlign="center"
          appearance="outline">
          <mat-label>{{'Confirm_New_Password' | translate}}</mat-label>
          <input autocomplete="off" type="password" matInput formControlName="confirm_new_password">
          <mat-icon *ngIf="getFieldValue(setPassword,'confirm_new_password')" #tooltip="matTooltip"
            [matTooltip]="(getErrorMessage(setPassword,'confirm_new_password', 'Confirm Password'))| translate"
            [matTooltipDisabled]="!getFieldValue(setPassword,'confirm_new_password')" matTooltipPosition="above"
            style="color: #213060;" matSuffix>info
          </mat-icon>
        </mat-form-field>

      </div>

      <div fxFlex="97" fxLayoutAlign="end center">
        <button (click)="onUpdatePassword()" style="background-color: #213060; color: white;
        line-height: 34px; padding: 0 25px; border-radius: 10px; outline: none; margin-top: 20px;"
          mat-raised-button>{{'Update_Password' | translate}}</button>
      </div>


    </div>
  </form>
</div>

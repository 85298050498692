<div class="main pt-1 mrl-20 mt-30" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div fxLayout="row wrap" fxLayoutAlign="space-between">

        <app-card fxFlex="32" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99" imgCustom="assets/new-icons/total-balance.png"
            topText="{{'Total Portfolio Balance (SAR)' | translate}}" [middleTextNumberFour]="dashboardData?.summary?.total_portfolio_balance" bottomText=""
            [error]="false">
        </app-card>

        <app-card fxFlex="32" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99" 
            [imgCustom]="riskTypeString == 'HIGH' ? 'assets/new-icons/high-risk.png' : riskTypeString == 'MEDIUM' ? 'assets/new-icons/medium-risk.png' : 'assets/new-icons/low-risk.png'"
            topText="{{'Employer_Risk_Type' | translate}}" middleText="{{riskTypeString ? riskTypeString : 'N/A'}}" bottomText="" [error]="false">
        </app-card>

        <app-card fxFlex="32" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99" imgCustom="assets/new-icons/company-cotribution.png"
            topText="{{'Company Contribution (SAR)' | translate}}" [middleTextNumberFour]="dashboardData?.summary?.company_contribution" bottomText=""
            [error]="false">
        </app-card>

        <app-card fxFlex="32" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99" imgCustom="assets/new-icons/employee-cotribution.png"
            topText="{{'Employee Contribution (SAR)' | translate}}" [middleTextNumberFour]="dashboardData?.summary?.employee_contribution"
            bottomText="" [error]="false">
        </app-card>

        <app-card fxFlex="32" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99" imgCustom="assets/new-icons/TotalProfit.png"
            topText="{{'Total Profit/Loss (SAR)' | translate}}" [middleTextNumberFour]="dashboardData?.summary?.profit" bottomText="" [error]="false">
        </app-card>

        <!-- <app-card fxFlex="32" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99" img="assets/images/person-arrows.png" topText="{{'Total_Units' | translate}}"
            middleText="{{newlist?.total_available_units ? (newlist?.total_available_units | number: '1.4-4') : '0.0000'}}" bottomText="" [error]="false">
        </app-card> -->

        <app-card fxFlex="32" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99" imgCustom="assets/new-icons/total-withdrawals-amount.png"
            topText="{{'Total Withdrawal Amount (SAR)' | translate}}" [middleTextNumberFour]="dashboardData?.summary?.total_withdrawal"
            bottomText="" [error]="false">
        </app-card>
        <div fxFlex="24.1" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99"></div>
        <div fxFlex="24.1" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99"></div>
        <div fxFlex="24.1" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99"></div>
    </div>
</div>

<div fxLayout="column" fxLayoutGap="14" class="mrl-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20" fxLayoutAlign="space-between">

        <mat-card class="mt-20" fxFlex="49" fxFlex.sm="99" fxFlex.xs="99">
            <div class="bg-card-top"></div>
            <div fxLayout="row" style="position: relative;z-index: 9999">
                <h3>{{'Portfolio_Breakdown' | translate}}</h3>
            </div>
            <mat-card style="box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);border-radius: 10px;position: absolute;
                        top:26%;left:8%; width: 24%; padding: 10px;" ngStyle.xs="top:20%">
                <h4 style="color:#213060;">{{'SAR' | translate}}</h4>
                <h2 style="font-weight: 700;font-size: 20px;color:#213060;margin-bottom: 0px;">
                    <!-- {{ dashboardData?.portfolio_breakdown?.total_portfolio_balance ? (dashboardData?.portfolio_breakdown?.total_portfolio_balance | number : '1.4-4') : '0.00' }} -->

                    <span class="number">{{ dashboardData?.portfolio_breakdown?.total_portfolio_balance | number:'1.4-4' | slice:0:-4 }}</span> 
                    <span style="font-size: 14px;">{{ dashboardData?.portfolio_breakdown?.total_portfolio_balance | number:'1.4-4' | slice:-4 }}</span>
                </h2>
                <p style="font-weight: 500;font-size: 10px;color: #A3AED0;">{{'Total Portfolio Balance' | translate}}</p>
            </mat-card>
            <div style="margin-left: 34%;height: 24vh; position: relative;z-index: 9999;" ngStyle.sm="margin-left: 40%;height: 13vh;"
                ngStyle.xs="margin-left: 44%;height: 13vh;">
                <canvas baseChart height="100" [chartType]="'pie'" [datasets]="portfolioPieChartData"
                    [labels]="portfolioPieChartLabels" [legend]="pieChartLegend">
                </canvas>
            </div>
            <mat-card fxLayoutAlign="space-between" fxLayout="row" fxLayoutAlign.sm="space-around center"
                [fxLayoutGap]='"24"'
                [style]="'padding: 10px 26px; height: 80px;border-radius: 15px;margin-top:4%; box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);'">
                <span>
                    <p style="font-size: 10px;color: #A3AED0; margin-bottom: 0px;">{{'Employee Contribution (SAR)' |
                        translate}}
                    </p>
                    <h2 style="font-weight: 700;font-size: 18px;">
                        <!-- {{ dashboardData?.portfolio_breakdown?.employee_contribution ? (dashboardData?.portfolio_breakdown?.employee_contribution | number : '1.4-4') : '0.00' }} -->

                        <span class="number">{{ dashboardData?.portfolio_breakdown?.employee_contribution | number:'1.4-4' | slice:0:-4 }}</span> 
                        <span style="font-size: 14px;">{{ dashboardData?.portfolio_breakdown?.employee_contribution | number:'1.4-4' | slice:-4 }}</span>
                    </h2>
                </span>
                <mat-divider vertical style="height:40px;" fxFlexAlign="center"></mat-divider>
                <span>
                    <p style="font-size: 10px;color: #A3AED0;margin-bottom: 0px;">{{'Company Contribution (SAR)' |
                        translate}}
                    </p>
                    <h2 style="font-weight: 700;font-size: 18px;">
                        <!-- {{ dashboardData?.portfolio_breakdown?.company_contribution ? (dashboardData?.portfolio_breakdown?.company_contribution | number : '1.4-4') : '0.00' }} -->

                        <span class="number">{{ dashboardData?.portfolio_breakdown?.company_contribution | number:'1.4-4' | slice:0:-4 }}</span> 
                        <span style="font-size: 14px;">{{ dashboardData?.portfolio_breakdown?.company_contribution | number:'1.4-4' | slice:-4 }}</span>
                    </h2>
                </span>
                <mat-divider vertical style="height:40px;" fxFlexAlign="center"></mat-divider>
                <span>
                    <p style="font-size: 10px;color: #A3AED0;margin-bottom: 0px;">{{'Profit (SAR)' | translate}}</p>
                    <h2 style="font-weight: 700;font-size: 18px;">
                        <!-- {{ dashboardData?.portfolio_breakdown?.profit ? (dashboardData?.portfolio_breakdown?.profit | number : '1.4-4') : '0.00' }} -->

                        <span class="number">{{ dashboardData?.portfolio_breakdown?.profit | number:'1.4-4' | slice:0:-4 }}</span> 
                        <span style="font-size: 14px;">{{ dashboardData?.portfolio_breakdown?.profit | number:'1.4-4' | slice:-4 }}</span>
                    </h2>
                </span>
            </mat-card>
        </mat-card>

        <mat-card class="mt-20" fxFlex="49" fxFlex.sm="99" fxFlex.xs="99">
            <div class="bg-card-top"></div>
            <div fxLayout="row" style="position: relative;z-index: 9999;">
                <h3>{{'Vesting Breakdown' | translate}}</h3>
            </div>
            <mat-card style="box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);border-radius: 10px;position: absolute;
                        top:26%;left:8%; width: 24%; padding: 10px;" ngStyle.xs="top:20%">
                <h4 style="color:#213060;">{{'SAR' | translate}}</h4>
                <h2 style="font-weight: 700;font-size: 20px;color:#213060;margin-bottom: 0px;">
                    <!-- {{ dashboardData?.vest_breakdown?.total_portfolio_balance ? (dashboardData?.vest_breakdown?.total_portfolio_balance | number : '1.4-4') : '0.00' }} -->

                    <span class="number">{{ dashboardData?.vest_breakdown?.total_portfolio_balance | number:'1.4-4' | slice:0:-4 }}</span> 
                    <span style="font-size: 14px;">{{ dashboardData?.vest_breakdown?.total_portfolio_balance | number:'1.4-4' | slice:-4 }}</span>
                </h2>
                <p style="font-weight: 500;font-size: 10px;color: #A3AED0;">{{'Total Amount' | translate}}</p>
            </mat-card>
            <div style="margin-left: 34%;height: 24vh; position: relative;z-index: 9999;" ngStyle.sm="margin-left: 40%;height: 13vh;"
                ngStyle.xs="margin-left: 44%;height: 13vh;">
                <canvas baseChart height="100" [chartType]="'pie'" [datasets]="vestingPieChartData"
                    [labels]="vestingPieChartLabels" [legend]="pieChartLegend">
                </canvas>
            </div>
            <mat-card fxLayoutAlign="space-around" fxLayout="row" fxLayoutAlign.sm="space-around center"
                [fxLayoutGap]='"24"'
                [style]="'padding: 10px 26px; height: 80px;border-radius: 15px;margin-top:4%; box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);'">
                <span>
                    <p style="font-size: 10px;color: #A3AED0; margin-bottom: 0px;">{{'Vested (SAR)' | translate}}
                    </p>
                    <h2 style="font-weight: 700;font-size: 18px;">
                        <!-- {{ dashboardData?.vest_breakdown?.vested ? (dashboardData?.vest_breakdown?.vested | number : '1.4-4') : '0.00' }} -->

                        <span class="number">{{ dashboardData?.vest_breakdown?.vested | number:'1.4-4' | slice:0:-4 }}</span> 
                        <span style="font-size: 14px;">{{ dashboardData?.vest_breakdown?.vested | number:'1.4-4' | slice:-4 }}</span>
                    </h2>
                </span>
                <mat-divider vertical style="height:40px;" fxFlexAlign="center"></mat-divider>
                <span>
                    <p style="font-size: 10px;color: #A3AED0;margin-bottom: 0px;">{{'Unvested (SAR)' | translate}}
                    </p>
                    <h2 style="font-weight: 700;font-size: 18px;">
                        <!-- {{ dashboardData?.vest_breakdown?.unvested ? (dashboardData?.vest_breakdown?.unvested | number : '1.4-4') : '0.00' }} -->

                        <span class="number">{{ dashboardData?.vest_breakdown?.unvested | number:'1.4-4' | slice:0:-4 }}</span> 
                        <span style="font-size: 14px;">{{ dashboardData?.vest_breakdown?.unvested | number:'1.4-4' | slice:-4 }}</span>
                    </h2>
                </span>
            </mat-card>
        </mat-card>
    </div>
</div>

<div fxLayout="column" fxLayoutGap="14" class="mrl-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20" fxLayoutAlign="space-between">
        <mat-card fxLayout="column" fxLayoutAlign="space-between" class="mt-20" fxFlex="49" fxFlex.sm="99" fxFlex.xs="99">
            <div class="bg-card-top"></div>
            <div fxLayout="row" fxLayoutAlign="space-between" style="position: relative;z-index: 9999;">
                <h3>{{'Contribution_Breakdown_By_Risk' | translate}}</h3>
                <button mat-icon-button [matMenuTriggerFor]="barMenu"
                    style="margin-right:6%;color: #A3AED0; outline: none;">
                    {{'Yearly' | translate}}
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu style="display: none;" #barMenu="matMenu">
                    <ng-container *ngFor="let item of sortData">
                        <button (click)="sort(item.key)" style="outline: none;" mat-menu-item>
                            <span>{{item.value}}</span>
                        </button>
                    </ng-container>
                </mat-menu>
            </div>
            <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                [legend]="barChartLegend" [chartType]="'bar'" style="position: relative;z-index: 9999;">
            </canvas>
            <div class="bg-card-bottom"></div>
        </mat-card>

        <mat-card class="mt-20" fxFlex="49" fxFlex.sm="99" fxFlex.xs="99">
            <div class="bg-card-top"></div>
            <div fxLayout="row">
                <h3>{{'Contribution_Breakdown_By_Risk' | translate}}</h3>
            </div>
            <mat-card  style="box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);border-radius: 10px;position: absolute;
                        top:26%;left:8%; width: 24%; padding: 10px; z-index: 9999;" ngStyle.xs="top:20%">
                <h4 style="color:#213060;">{{'SAR' | translate}}</h4>
                <h2 style="font-weight: 700;font-size: 20px;color:#213060;margin-bottom: 0px;">
                    <!-- {{ dashboardData?.summary?.total_contribution ? (dashboardData?.summary?.total_contribution | number : '1.4-4') : '0.00' }} -->

                    <span class="number">{{ dashboardData?.summary?.total_contribution | number:'1.4-4' | slice:0:-4 }}</span> 
                    <span style="font-size: 14px;">{{ dashboardData?.summary?.total_contribution | number:'1.4-4' | slice:-4 }}</span>
                </h2>
                <p style="font-weight: 500;font-size: 10px;color: #A3AED0;">{{'Total_Contribution_Amount' | translate}}
                </p>
            </mat-card>

            <div style="margin-left: 34%;height: 24vh; position: relative;z-index: 9999;" ngStyle.sm="margin-left: 40%;height: 13vh;"
                ngStyle.xs="margin-left: 44%;height: 13vh;">
                <canvas baseChart height="100" [chartType]="'pie'" [datasets]="contributionPieChartData"
                    [labels]="contributionPieChartLabels" [legend]="pieChartLegend">
                </canvas>
            </div>
            <mat-card fxLayoutAlign="space-between" fxLayout="row" fxLayoutAlign.sm="space-around center"
                [fxLayoutGap]='"24"'
                [style]="'padding: 10px 26px; height: 80px;border-radius: 15px;margin-top:4%; box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12); position: relative;z-index: 9999;'">
                <span>
                    <p style="font-size: 10px;color: #A3AED0; margin-bottom: 0px;">{{'High_Risk' | translate}}
                    </p>
                    <h2 style="font-weight: 700;font-size: 18px;">
                        <!-- {{riskValues.high | number: '1.4-4'}} -->

                        <span class="number">{{ riskValues.high | number:'1.4-4' | slice:0:-4 }}</span> 
                        <span style="font-size: 14px;">{{ riskValues.high | number:'1.4-4' | slice:-4 }}</span>
                    </h2>
                </span>
                <mat-divider vertical style="height:40px;" fxFlexAlign="center"></mat-divider>
                <span>
                    <p style="font-size: 10px;color: #A3AED0;margin-bottom: 0px;">{{'Medium_Risk' | translate}}
                    </p>
                    <h2 style="font-weight: 700;font-size: 18px;">
                        <!-- {{riskValues.medium | number: '1.4-4'}} -->

                        <span class="number">{{ riskValues.medium | number:'1.4-4' | slice:0:-4 }}</span> 
                        <span style="font-size: 14px;">{{ riskValues.medium | number:'1.4-4' | slice:-4 }}</span>
                    </h2>
                </span>
                <mat-divider vertical style="height:40px;" fxFlexAlign="center"></mat-divider>
                <span>
                    <p style="font-size: 10px;color: #A3AED0;margin-bottom: 0px;">{{'Low_Risk' | translate}}</p>
                    <h2 style="font-weight: 700;font-size: 18px;">
                        <!-- {{riskValues.low | number: '1.4-4'}} -->

                        <span class="number">{{ riskValues.low | number:'1.4-4' | slice:0:-4 }}</span> 
                        <span style="font-size: 14px;">{{ riskValues.low | number:'1.4-4' | slice:-4 }}</span>
                    </h2>
                </span>
            </mat-card>
        </mat-card>
    </div>
</div>

<div fxLayout="column" fxLayoutGap="14" class="mrl-20 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20" fxLayoutAlign="space-between">
        <mat-card fxLayout="column" fxLayoutAlign="space-between" class="mt-20" fxFlex="99" fxFlex.sm="99" fxFlex.xs="99">
            <div class="bg-card-top"></div>
            <div fxLayout="row" fxLayoutAlign="space-between" style="position: relative;z-index: 9999;">
                <h3>{{'Fund Portfolio Details' | translate}}</h3>
            </div>
            <div  *ngIf="dashboardData">
                <app-table
                  
                  [dataSource]="dashboardData?.portfolio_assets"
                  [tableProps]="companyTableProps"
                >
                </app-table>
            </div>
            <div class="bg-card-bottom"></div>
        </mat-card>
    </div>
</div>

<div fxLayout="column" fxLayoutGap="14" class="mrl-20 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20" fxLayoutAlign="space-between">
        <mat-card class="mt-20" fxFlex="100" fxFlex.sm="99" fxFlex.xs="99">
            <div class="bg-card-top"></div>
            <div fxLayout="row" fxLayoutAlign="space-between" style="position: relative;z-index: 9999;">
                <h3>{{'Contributions' | translate}}</h3>
                <button mat-icon-button [matMenuTriggerFor]="lineMenu"
                    style="margin-right:6%;color: #A3AED0; outline: none;">
                    {{year | translate}}
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu style="display: none;" #lineMenu="matMenu">
                    <ng-container *ngFor="let item of sortData">
                        <button (click)="sortB(item.key, item.value)" style="outline: none;" mat-menu-item>
                            <span>{{item.value | translate}}</span>
                        </button>
                    </ng-container>
                </mat-menu>
            </div>
            <canvas style="position: relative;z-index: 10000;" baseChart [chartType]="'line'" [datasets]="lineChartData" [labels]="lineChartLabels"
                [options]="lineChartOptions" [legend]="lineChartLegend" [colors]="lineChartColors" height="100">
            </canvas>
            <div class="bg-card-bottom"></div>
        </mat-card>
    </div>
</div>
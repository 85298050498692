import { OnInit, Component, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { TranslationService } from "./translation.service";
import {english, arabic} from "../../../translation";
// import { Subscription } from "rxjs";

@Component({
	selector: 'app-translation',
	templateUrl: './translation.html',
})
export class TranslationComponent implements OnInit, OnDestroy
{
	@Output() onAppSelect:  EventEmitter<any> = new EventEmitter;
	engArr: any = false;
	keyValue: any;
	UrbanpointAdmin: any;
	// loaderSubscription: Subscription;

	constructor(protected translationService: TranslationService)
	{
		// localStorage.setItem('isEnglish','1');
		// document.body.classList.add('english');
		// document.body.classList.remove('arabic');
		// document.body.setAttribute("dir", "ltr");
		// this.translationService.setApp(english)

		this.keyValue = [
			{key: '1', title: 'English'},
			{key: '0', title: 'Arabic'}
		];


		if(localStorage.getItem('isEnglish') == '1'){
			this.translationService.setApp(english);
			document.body.classList.add('english');
			document.body.classList.remove('arabic');
			this.engArr= false;

		}
		else if(localStorage.getItem('isEnglish') == '0'){
			this.translationService.setApp(arabic);
			document.body.classList.add('arabic');
			document.body.classList.remove('english');
			this.engArr= true;
		}
		else{
			this.translationService.setApp(english);
			document.body.classList.add('english');
			document.body.classList.remove('arabic');
			this.engArr= false;
		}
	}
	toggle(e)
	{
		if(e.checked==false)
		{
			localStorage.setItem('isEnglish','1');
			document.body.classList.add('english');
			document.body.classList.remove('arabic');
			document.body.setAttribute("dir", "ltr");
			this.translationService.setApp(english);
		}
		else{
			localStorage.setItem('isEnglish','0');
			document.body.classList.add('arabic');
			document.body.classList.remove('english');
			document.body.setAttribute("dir", "rtl");
			this.translationService.setApp(arabic);
		}
		
	}

	ngOnInit() 
	{
		// this.UserApps = JSON.parse(localStorage.getItem('UserApps'));
	}

	// onChange(): void
	// {
		
	// 	if(this.engArr == '1'){

	// 		localStorage.setItem('isEnglish','1');
	// 		document.body.classList.add('english');
	// 		document.body.classList.remove('arabic');
	// 		document.body.setAttribute("dir", "ltr");
	// 		this.translationService.setApp(english);

	// 	}
	// 	else{
	// 		localStorage.setItem('isEnglish','0');
	// 		document.body.classList.add('arabic');
	// 		document.body.classList.remove('english');
	// 		document.body.setAttribute("dir", "rtl");
	// 		this.translationService.setApp(arabic);
	// 	}

	// }

	ngOnDestroy(): void 
	{

	}
}

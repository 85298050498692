<h3 class="form-heading">{{'Manage_Organization' | translate}}</h3>

<mat-card
    style="border-radius: 15px; background: #FFF; margin-inline: 20px; box-shadow: none; display: flex; margin-bottom: 10px;">
    <div class="mr-10">
        <img *ngIf="!logo_image" src="assets/ellipse-4.svg" style="width: 130px; border-radius: 50%;">
        <img *ngIf="logo_image" style="width: 130px; border-radius: 50%; height: 130px;" [src]="logo_image">
        <!-- <h3 style="text-align: center; margin: 10px 0 0 0; color: #213060;">Change Logo</h3> -->
    </div>
    <div style="width: 100%; display: flex; align-items: center;">
        <div style="display: flex; flex-wrap: wrap; flex: 1; gap: 10px;">
            <mat-form-field style="flex: 49%;" class="input-field" fxFlexAlign="center" appearance="outline">
                <mat-label>{{'Company Name' | translate}}</mat-label>
                <input readonly autocomplete="off" type="text" matInput [formControl]="companyName">
            </mat-form-field>
            <mat-form-field style="flex: 49%;" class="input-field" fxFlexAlign="center" appearance="outline">
                <mat-label>{{'Industry' | translate}}</mat-label>
                <input readonly autocomplete="off" type="text" matInput [formControl]="industry">
            </mat-form-field>
            <mat-form-field style="flex: 49%;" class="input-field" fxFlexAlign="center" appearance="outline">
                <mat-label>{{'CR License' | translate}}</mat-label>
                <input readonly autocomplete="off" type="text" matInput [formControl]="cr_license">
            </mat-form-field>
            <mat-form-field style="flex: 49%;" class="input-field" fxFlexAlign="center" appearance="outline">
                <mat-label>{{'CR License Expiry' | translate}}</mat-label>
                <input readonly autocomplete="off" type="text" matInput [formControl]="cr_license_expiry">
            </mat-form-field>
        </div>
    </div>
</mat-card>

<section style="display: flex; margin-inline: 20px; margin-block: 10px; gap: 5px; justify-content: flex-end;">
    <div class="stepper-navigator" [class.selected-stepper]="currentIndexOfStepper == 0"
        (click)="stepperNavigator('0', 'companyIdentification')">
        {{'Company Identification' | translate}}
    </div>
    <div [class.selected-stepper]="currentIndexOfStepper == 1" class="stepper-navigator"
        (click)="stepperNavigator('1', 'adminDetails')">
        {{'Admin Details' | translate}}
    </div>
    <div [class.selected-stepper]="currentIndexOfStepper == 2" class="stepper-navigator"
        (click)="stepperNavigator('2', 'commercialDetails')">
        {{'Commercial Registration Details' | translate}}
    </div>
    <div [class.selected-stepper]="currentIndexOfStepper == 3" class="stepper-navigator"
        (click)="stepperNavigator('3', 'companyDetails')">
        {{'Company Representative Details' | translate}}
    </div>
    <div [class.selected-stepper]="currentIndexOfStepper == 4" class="stepper-navigator"
        (click)="stepperNavigator('4', 'fundManagerDetails')">
        {{'Fund Manager Details' | translate}}
    </div>
    <div [class.selected-stepper]="currentIndexOfStepper == 5" class="stepper-navigator"
        (click)="stepperNavigator('5', 'billing')">
        {{'Subscription & Billing' | translate}}
    </div>
    <div [class.selected-stepper]="currentIndexOfStepper == 6" class="stepper-navigator"
        (click)="stepperNavigator('6', 'documentList')">
        {{'Document Check List' | translate}}
    </div>
</section>

<mat-horizontal-stepper #stepper (selectionChange)="onStepChange($event)" [selectedIndex]="currentIndexOfStepper">
    <mat-step [completed]="false" [stepControl]="empIdentificationForm">
        <mat-card
            style="border-radius: 15px; background: #FFF; margin-inline: 20px; box-shadow: none; display: flex; margin-bottom: 10px;">
            <form [formGroup]="empIdentificationForm">
                <div fxLayout="row wrap" fxLayoutGap="10px" class="form-container">
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Company_ID' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="company_id">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Company_Name' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="company_name">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Company_Name_Arabic' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="company_name_arabic">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Preferred_Language' | translate}}</mat-label>
                        <mat-select formControlName="preferred_language">
                            <mat-option disabled value="arabic" selected>Arabic</mat-option>
                            <mat-option disabled value="english">English</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'No_of_Employees' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="no_of_employees">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Industry_Classification' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput
                            formControlName="industry_classification">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Type_of_Activity' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="type_of_activity">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Country_of_Activity' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="country_of_activity">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Other_Country_of_Activities' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput
                            formControlName="other_country_of_activities">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Name_of_Other_Countries' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput
                            formControlName="name_of_other_countries">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Portfolio_Currency' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="portfolio_currency">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Is_This_Listed_Company' | translate}}</mat-label>
                        <mat-select formControlName="is_this_listed_company">
                            <mat-option disabled value="0" selected>Yes</mat-option>
                            <mat-option disabled value="1">No</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="start" appearance="outline">
                        <mat-label>{{'Address' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="address">
                    </mat-form-field>
                    <div fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center">
                        <mat-card class="card mt-10" fxFlex="100" fxLayout="row" fxLayout.xs="column"
                            style="height: 162px; border-radius: 20px;" ngStyle.xs="height:280px;">
                            <input style="display: none;" type="file" #attack accept=".png, .jpg, .jpeg">
                            <button mat-button class="upload-button">
                                <ng-container *ngIf="image">
                                    <img style="width: 100%; height: 100%;" [src]="logo_image">
                                </ng-container>
                            </button>
                            <span class="button-text" fxFlexOffset="6" ngStyle.xs="margin-left:22px;">
                                <h2 style="color: #213060; font-weight: bold;">{{'Company_Logo' | translate}}</h2>
                            </span>
                        </mat-card>
                    </div>
                </div>
                <div class="mt-20 mb-20" fxLayoutAlign="end center" fxLayoutGap="10px">
                    <button (click)="onCancel()"
                        style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
                        mat-raised-button>{{'Cancel' | translate}}</button>
                    <button *ngIf="currentIndexOfStepper < 5" (click)="onNext()" class="btn-submit"
                        mat-raised-button>{{'Next' | translate}}</button>
                </div>
            </form>
        </mat-card>
    </mat-step>

    <mat-step [completed]="false" [stepControl]="adminForm">
        <mat-card
            style="border-radius: 15px; background: #FFF; margin-inline: 20px; box-shadow: none; display: flex; margin-bottom: 10px;">
            <form [formGroup]="adminForm">
                <ng-template matStepLabel>{{'Admin Details' | translate}}</ng-template>
                <div fxLayout="row wrap" fxLayoutGap="10px" class="form-container">
                    <div fxFlex="48">
                        <mat-form-field style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                            appearance="outline">
                            <mat-label>{{'Admin_Name' | translate}}</mat-label>
                            <input readonly autocomplete="off" type="text" matInput formControlName="name">
                        </mat-form-field>
                        <mat-form-field style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                            appearance="outline">
                            <mat-label>{{'Email' | translate}}</mat-label>
                            <input readonly autocomplete="off" type="text" matInput formControlName="email">
                        </mat-form-field>
                        <mat-form-field style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                            appearance="outline">
                            <mat-label>{{'Phone_Number' | translate}}</mat-label>
                            <input readonly autocomplete="off" type="text" matInput formControlName="phone">
                        </mat-form-field>
                    </div>
                    <div fxFlex="48">
                        <mat-form-field style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                            appearance="outline">
                            <mat-label>{{'National_Iqama_ID' | translate}}</mat-label>
                            <input readonly autocomplete="off" type="text" matInput formControlName="iqama_id">
                        </mat-form-field>
                        <mat-form-field style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                            appearance="outline">
                            <mat-label>{{'Iqama_ID_National_ID_Expiry_Date' | translate}}</mat-label>
                            <input readonly autocomplete="off" type="text" matInput formControlName="iqama_expiry">
                        </mat-form-field>
                    </div>
                    <mat-card class="card mt-10" fxFlex="48" fxFlex.sm="93.5" fxFlex.xs="97" fxLayout="row"
                        fxLayout.xs="column" style="height: 162px; border-radius: 20px;" ngStyle.xs="height:280px;">
                        <input style="display: none;" type="file" #attack accept=".png, .jpg, .jpeg">
                        <button mat-button class="upload-button">
                            <ng-container *ngIf="!image">
                                <mat-icon>upload</mat-icon>
                                <h2>National ID/Iqama</h2>
                                <h5>{{'File_Types' | translate}}</h5>
                            </ng-container>
                            <ng-container *ngIf="image">
                                <img style="width: 100%; height: 100%;" [src]="image">
                            </ng-container>
                        </button>
                        <span class="button-text" fxFlexOffset="6" ngStyle.xs="margin-left:22px;">
                            <h2 style="color: #213060; font-weight: bold;">{{'National_Iqama_Scanned' | translate}}</h2>
                        </span>
                    </mat-card>
                    <!-- <div fxLayout="row wrap" fxFlex="48" fxFlex.xs="100">
      
      
                  </div> -->
                </div>
                <div class="mt-20 mb-20" fxLayoutAlign="end center" fxLayoutGap="10px">
                    <button (click)="onCancel()"
                        style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
                        mat-raised-button>{{'Cancel' | translate}}</button>
                    <button *ngIf="currentIndexOfStepper < 5" (click)="onNext()" class="btn-submit"
                        mat-raised-button>{{'Next' | translate}}</button>
                </div>
            </form>
        </mat-card>
    </mat-step>

    <mat-step [completed]="false" [stepControl]="comercialRegistrationForm">
        <mat-card
            style="border-radius: 15px; background: #FFF; margin-inline: 20px; box-shadow: none; display: flex; margin-bottom: 10px;">
            <form [formGroup]="comercialRegistrationForm">
                <ng-template matStepLabel>{{'Commercial Registration Details' | translate}}</ng-template>
                <div fxLayout="row wrap" fxLayoutGap="10px" class="form-container">
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'CR_No' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="cr_no">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'CR_Expiry_Date' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="cr_expiry_date">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'CR_Issue_date' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="cr_issue_date">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'CR_Date_of_incorporation' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput
                            formControlName="cr_date_of_incorporation">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'CR_Country_of_first_registration' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput
                            formControlName="cr_country_of_first_registration">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'CR_Issue_place' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="cr_issue_place">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'CR_Taxation_Identification_number' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput
                            formControlName="cr_taxation_identification_number">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'CR_Global_Intermediary_Identification_Number' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput
                            formControlName="cr_global_intermediary_identification_number">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'CR_unified_number' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="cr_unified_no">
                    </mat-form-field>
                </div>
                <div class="mt-20 mb-20" fxLayoutAlign="end center" fxLayoutGap="10px">
                    <button (click)="onCancel()"
                        style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
                        mat-raised-button>{{'Cancel' | translate}}</button>
                    <button *ngIf="currentIndexOfStepper < 5" (click)="onNext()" class="btn-submit"
                        mat-raised-button>{{'Next' | translate}}</button>
                </div>
            </form>
        </mat-card>
    </mat-step>

    <mat-step [completed]="false" [stepControl]="empRepresentativeDetailForm">
        <mat-card
            style="border-radius: 15px; background: #FFF; margin-inline: 20px; box-shadow: none; display: flex; margin-bottom: 10px;">
            <form [formGroup]="empRepresentativeDetailForm">
                <ng-template matStepLabel>{{'Company Representative Details' | translate}}</ng-template>
                <div fxLayout="row wrap" fxLayoutGap="10px" class="form-container">
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Contact_First_name' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="first_name">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Contact_Middle_name' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="middle_name">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Contact_Last_name' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="last_name">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Contact_Position_in_company' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="position_in_company">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Contact_number' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="contact_phone">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Contact_email' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="contact_email">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Contact_Preferred_mode_of_communication' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput
                            formControlName="preferred_mode_of_communication">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Investment_Bank_Authorization' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput
                            formControlName="investment_bank_authorization">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Back_up_Contact_Name' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="backup_contact_name">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Back_up_Person_Position' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput
                            formControlName="backup_contact_position">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Back_up_Person_Phone' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="backup_contact_phone">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Back_up_Person_Email' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="backup_contact_email">
                    </mat-form-field>
                </div>
                <div class="mt-20 mb-20" fxLayoutAlign="end center" fxLayoutGap="10px">
                    <button (click)="onCancel()"
                        style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
                        mat-raised-button>{{'Cancel' | translate}}</button>
                    <button *ngIf="currentIndexOfStepper < 5" (click)="onNext()" class="btn-submit"
                        mat-raised-button>{{'Next' | translate}}</button>
                </div>
            </form>
        </mat-card>
    </mat-step>

    <mat-step [completed]="false" [stepControl]="bankDetailForm">
        <mat-card
            style="border-radius: 15px; background: #FFF; margin-inline: 20px; box-shadow: none; display: flex; margin-bottom: 10px;">
            <form [formGroup]="bankDetailForm">
                <ng-template matStepLabel>{{'Banks_Details' | translate}}</ng-template>
                <div fxLayout="row wrap" fxLayoutGap="10px" class="form-container">
                    <h3 fxFlex="100"
                        style="font-weight: bold; margin: 20px 0 0 40px; color: #213060;text-align: start;">
                        {{'Employee_Banks_Details' | translate}}
                    </h3>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Beneficiary_name' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="beneficiary_name">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Account_number' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="account_number">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'IBAN_number' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="iban_number">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'SWIFT Number' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="swift_number">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Name_of_bank' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="bank_name">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Address_of_bank' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="bank_address">
                    </mat-form-field>
                    <h3 fxFlex="100"
                        style="font-weight: bold; padding-top: 20px !important; margin-bottom: 20px; color: #213060;text-align: start;">
                        {{'Contribution_Banks_Details' | translate}}</h3>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Beneficiary_name' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="cont_beneficiary_name">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Account_number' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="cont_account_number">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'IBAN_number' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="cont_iban_number">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'SWIFT Number' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="cont_swift_number">
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Name_of_bank' | translate}}</mat-label>
                        <input readonly autocomplete="off" type="text" matInput formControlName="cont_bank_name">
                    </mat-form-field>
                </div>
                <div class="mt-20 mb-20" fxLayoutAlign="end center" fxLayoutGap="10px">
                    <button (click)="onCancel()"
                        style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
                        mat-raised-button>{{'Cancel' | translate}}</button>
                    <button *ngIf="currentIndexOfStepper < 5" (click)="onNext()" class="btn-submit"
                        mat-raised-button>{{'Next' | translate}}</button>
                </div>
            </form>
        </mat-card>
    </mat-step>
    <mat-step [completed]="false" [stepControl]="billingForm">
        <mat-card
            style="border-radius: 15px; background: #FFF; margin-inline: 20px; box-shadow: none; margin-bottom: 10px;">
            <h3 style="color: #213060; font-weight: bold;">{{'Plan Details' | translate}}</h3>
            <div style="display: flex;">
                <div style="flex: 1;">
                    <div style="display: flex; gap: 10px; margin-bottom: 5px;">
                        <h4 style="color: #213060; font-weight: bold; margin: 0;">{{'Payment plan' | translate}}</h4>
                        <img [matTooltip]="tooltipOne" src="assets/icon-question-mark-circle.svg">
                    </div>
                    <p style="margin-bottom: 5px; color: #A3AED0;">SAR {{package.frequency_rate}} {{'per employee/month'
                        | translate}}</p>
                    <p style="color: #A3AED0;">{{'Flexible Plan' | translate}}</p>
                </div>
                <div style="flex: 1;">
                    <div style="display: flex; gap: 10px; margin-bottom: 5px;">
                        <h4 style="color: #213060; font-weight: bold; margin: 0;">{{'Licenses' | translate}}</h4>
                        <img [matTooltip]="tooltipTwo" src="assets/icon-question-mark-circle.svg">
                    </div>
                    <p style="margin-bottom: 5px; color: #A3AED0;">{{package.number_of_employees}} {{'active employees'
                        | translate}}</p>
                </div>
                <div style="flex: 1;">
                    <div style="display: flex; gap: 10px; margin-bottom: 5px;">
                        <h4 style="color: #213060; font-weight: bold; margin: 0;">{{'Estimated monthly bill' |
                            translate}}</h4>
                        <img [matTooltip]="tooltipThree" src="assets/icon-question-mark-circle.svg">
                    </div>
                    <p style="margin-bottom: 5px; color: #A3AED0;">SAR 750.00</p>
                </div>
                <div style="flex: 1;">
                    <div style="display: flex; gap: 10px; margin-bottom: 5px;">
                        <h4 style="color: #213060; font-weight: bold; margin: 0;">{{'Payment method' | translate}}</h4>
                        <img [matTooltip]="tooltipFour" src="assets/icon-question-mark-circle.svg">
                    </div>
                    <p style="margin-bottom: 5px; color: #A3AED0;">{{'No payment method selected' | translate}}</p>
                    <div
                        style="display: flex; gap: 5px; background-color: #EBEFF0; border-radius: 20px; align-items: center; width: fit-content; padding: 3px 10px;">
                        <mat-icon style="color: #B6C6D7;">link</mat-icon>
                        <p style="color: #79C0C2; margin: 0;">{{'Link Card' | translate}}</p>
                    </div>
                </div>
            </div>
        </mat-card>
        <h3 style="color: #213060; margin-inline: 20px; font-weight: bold;">{{'Past Invoices' | translate}}</h3>
        <mat-card
            style="display: flex; border-radius: 15px; background: #FFF; margin-inline: 20px; box-shadow: none; margin-bottom: 10px;">
            <h4 style="color: #213060; font-weight: bold; flex: 1; margin: 0;">{{'Month' | translate}}</h4>
            <h4 style="color: #213060; font-weight: bold; flex: 2; margin: 0;">{{'Description' | translate}}</h4>
            <h4 style="color: #213060; font-weight: bold; flex: 1; margin: 0;">{{'Amount (SAR)' | translate}}</h4>
            <h4 style="color: #213060; font-weight: bold; flex: 1; margin: 0;">{{'Status' | translate}}</h4>
            <h4 style="color: #213060; font-weight: bold; flex: 1; margin: 0;">{{'Actions' | translate}}</h4>
        </mat-card>
        <mat-card *ngFor="let item of invoices"
            style="display: flex; border-radius: 15px; background: #FFF; margin-inline: 20px; box-shadow: none; margin-bottom: 10px;">
            <h4 style="color: #213060; font-weight: bold; flex: 1; margin: 0;">{{item.date ? item.date : 'N/A'}}</h4>
            <h4 style="color: #213060; font-weight: bold; flex: 2; margin: 0;">{{item.description ? item.description :
                'N/A'}}</h4>
            <h4 style="color: #213060; font-weight: bold; flex: 1; margin: 0;">{{item.charge_amount ? item.charge_amount
                : 0}}</h4>
            <h4 [style.color]="item.status == 'Pending' ? '#EE9250' : item.status == 'Failed' ? '#FF1500' : '#79C0C2'"
                style="color: #EE9250; font-weight: bold; flex: 1; margin: 0;">{{item.status}}</h4>
            <h4 style="color: #213060; font-weight: bold; flex: 1; margin: 0; display: flex; gap: 10px;">
                <button (click)="getInvoice(item.id)"
                    style="color: #213060; background-color: #EBEFF0; border-radius: 9px !important; box-shadow: none;"
                    class="btn-submit" mat-raised-button>{{'Invoice' | translate}}</button>
                <!-- <button *ngIf="item.status != 'Paid'" style="color: #FFFFFF; background-color: #79C0C2; border-radius: 9px !important; box-shadow: none;" class="btn-submit" mat-raised-button>{{'Pay Now' | translate}}</button> -->
            </h4>
        </mat-card>
        <div style="margin-inline: 20px; margin-bottom: 10px;">
            <tp-pagination *ngIf="invoicePagination" class="mt-20" (setPage)="setPagination($event)"
                (pageSize)="SetPageSize($event)" [pagination]="invoicePagination">
            </tp-pagination>
        </div>
    </mat-step>

    <mat-step [completed]="false" [stepControl]="bankDetailForm">
        <mat-card
            style="border-radius: 15px; background: #FFF; margin-inline: 20px; box-shadow: none; display: flex; margin-bottom: 10px;">
            <form [formGroup]="bankDetailForm">
                <ng-template matStepLabel>{{'Document_Check_List' | translate}}</ng-template>
                <h4 style="font-weight: 600; color: #213060;">{{'Document_Check_List' | translate}}</h4>
                <div fxLayout="row wrap" fxLayoutAlign="space-between start">
                    <div fxFlex="70" class="mt-3">
                        <h4>{{'NDA_Agreement' | translate}}</h4>
                    </div>
                    <div fxFlex="26" class="mt-3">
                        <button class="btn" id="btn" (click)="openImage(images[0].nda_agreement)">
                            {{images[0]?.nda_agreement?images[0].nda_agreement:'Not Available'}}
                        </button>
                    </div>
                    <div fxFlex="70" class="mt-3">
                        <h4>{{'Registration_form' | translate}}</h4>
                    </div>
                    <div fxFlex="26" class="mt-3">
                        <button class="btn" id="btn" (click)="openImage(images[0].registration_form)">
                            {{images[0]?.registration_form?images[0].registration_form:'Not Available'}}
                        </button>
                    </div>
                    <div fxFlex="70" class="mt-3">
                        <h4>{{'Copy_CR' | translate}}</h4>
                    </div>
                    <div fxFlex="26" class="mt-3">
                        <button class="btn" id="btn" (click)="openImage(images[0].copy_cr)">
                            {{images[0]?.copy_cr?images[0].copy_cr:'Not Available'}}
                        </button>
                    </div>
                    <div fxFlex="70" class="mt-3">
                        <h4>{{'Trade_Licence' | translate}}</h4>
                    </div>
                    <div fxFlex="26" class="mt-3">
                        <button class="btn" id="btn" (click)="openImage(images[0].trade_licence)">
                            {{images[0]?.trade_licence?images[0].trade_licence:'Not Available'}}
                        </button>
                    </div>
                    <div fxFlex="70" class="mt-3">
                        <h4>{{'Objective_Form' | translate}}</h4>
                    </div>
                    <div fxFlex="26" class="mt-3">
                        <button class="btn" id="btn" (click)="openImage(images[0].objective_form)">
                            {{images[0]?.objective_form?images[0].objective_form:'Not Available'}}
                        </button>
                    </div>
                    <div fxFlex="70" class="mt-3">
                        <h4>{{'Authority_Matrix_for_Portal_Access' | translate}}</h4>
                    </div>
                    <div fxFlex="26" class="mt-3">
                        <button class="btn" id="btn" (click)="openImage(images[0].authority_max_for_portal_access)">
                            {{images[0]?.authority_max_for_portal_access?images[0].authority_max_for_portal_access:'Not
                            Available'}}
                        </button>
                    </div>
                    <div fxFlex="70" class="mt-3">
                        <h4>{{'Terms_Conditions' | translate}}</h4>
                    </div>
                    <div fxFlex="26" class="mt-3">
                        <button class="btn" id="btn" (click)="openImage(images[0].terms_and_condition)">
                            {{images[0]?.terms_and_condition?images[0].terms_and_condition:'Not Available'}}
                        </button>
                    </div>

                    <div fxFlex="70" class="mt-3">
                        <h4>{{'Documentation_checklist' | translate}}</h4>
                    </div>
                    <div fxFlex="26" class="mt-3">
                        <button class="btn" id="btn" (click)="openImage(images[0].documentation_checklist)">
                            {{images[0]?.documentation_checklist?images[0].documentation_checklist:'Not Available'}}
                        </button>
                    </div>

                </div>
                <div class="mt-20 mb-20" fxLayoutAlign="end center" fxLayoutGap="10px">
                    <button (click)="onCancel()"
                        style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
                        mat-raised-button>{{'Cancel' | translate}}</button>
                    <button *ngIf="currentIndexOfStepper < 5" (click)="onNext()" class="btn-submit"
                        mat-raised-button>{{'Next' | translate}}</button>
                </div>
            </form>
        </mat-card>
    </mat-step>

</mat-horizontal-stepper>






<!-- to be added later -->
<!-- <div *ngIf="selected=='6'" class="form-2">
    <form>
        <h4 style="font-weight: 600;">{{tr.translation.Document_Check_List}}</h4>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start">

            <div fxFlex="70" class="mt-3">
                <h4>{{tr.translation.NDA_Agreement}}</h4>
            </div>
            <div fxFlex="26" class="mt-3">

                <button class="btn" id="btn" (click)="openImage(images[0].nda_agreement)">
                    {{images[0]?.nda_agreement?images[0].nda_agreement:'N/A'}}
                </button>
            </div>

            
            <div fxFlex="70" class="mt-3">
                <h4>{{tr.translation.Registration_form}}</h4>
            </div>
            <div fxFlex="26" class="mt-3">

                <button class="btn" id="btn" (click)="openImage(images[0].registration_form)">
                    {{images[0]?.registration_form?images[0].registration_form:'N/A'}}
                </button>
            </div>

            

            <div fxFlex="70" class="mt-3">
                <h4>{{tr.translation.Copy_CR}}</h4>
            </div>
            <div fxFlex="26" class="mt-3">

                <button class="btn" id="btn" (click)="openImage(images[0].copy_cr)">
                    {{images[0]?.copy_cr?images[0].copy_cr:'N/A'}}
                </button>
            </div>

            
            <div fxFlex="70" class="mt-3">
                <h4>{{tr.translation.Trade_Licence}}</h4>
            </div>
            <div fxFlex="26" class="mt-3">

                <button class="btn" id="btn" (click)="openImage(images[0].trade_licence)">
                    {{images[0]?.trade_licence?images[0].trade_licence:'N/A'}}
                </button>
            </div>

            
            <div fxFlex="70" class="mt-3">
                <h4>{{tr.translation.Objective_Form}}</h4>
            </div>
            <div fxFlex="26" class="mt-3">

                <button class="btn" id="btn" (click)="openImage(images[0].objective_form)">
                    {{images[0]?.objective_form?images[0].objective_form:'N/A'}}
                </button>
            </div>

            
            <div fxFlex="70" class="mt-3">
                <h4>{{tr.translation.Authority_Matrix_for_Portal_Access}}</h4>
            </div>
            <div fxFlex="26" class="mt-3">

                <button class="btn" id="btn" (click)="openImage(images[0].authority_max_for_portal_access)">
                    {{images[0]?.authority_max_for_portal_access?images[0].authority_max_for_portal_access:'N/A'}}
                </button>
            </div>

            
            <div fxFlex="70" class="mt-3">
                <h4>{{tr.translation.Terms_Conditions}}</h4>
            </div>
            <div fxFlex="26" class="mt-3">

                <button class="btn" id="btn" (click)="openImage(images[0].terms_and_condition)">
                    {{images[0]?.terms_and_condition?images[0].terms_and_condition:'N/A'}}
                </button>
            </div>

            
            <div fxFlex="70" class="mt-3">
                <h4>{{tr.translation.Documentation_checklist}}</h4>
            </div>
            <div fxFlex="26" class="mt-3">

                <button class="btn" id="btn" (click)="openImage(images[0].documentation_checklist)">
                    {{images[0]?.documentation_checklist?images[0].documentation_checklist:'N/A'}}
                </button>
            </div>
        </div>
    </form>
</div> -->


<div style="display: none;">
    <div id="print">
        <main style="margin-inline: 20px; font-family: raleway !important;">
            <section class="top" style="display: flex; justify-content: space-between;">
                <div style="display: flex; gap: 10px;">
                    <h1 style="margin: 0; font-size: 40px; color: #213060; font-weight: bold;">tp</h1>
                    <h2 style="margin: 0; font-size: 40px; color: #213060;">thrift<span
                            style="color: #999999;">plan</span></h2>
                </div>
                <h3 style="margin: 0; display: flex; align-items: end;">Invoice</h3>
            </section>
            <hr>
            <section style="display: flex; justify-content: space-between;">
                <h4 style="margin: 0;">Thriftplan</h4>
                <h4 style="margin: 0;">Invoice # {{invoice?.id ? invoice?.id : 'N/A'}}</h4>
            </section>
            <section class="address">
                <p style="margin: 0;">Office 3, 3998</p>
                <p style="margin: 0;">Anas bin Malik</p>
                <p style="margin: 0;">Riyadh Ar Riyad 13326</p>
                <p style="margin: 0;">Saudi Arabia</p>
                <p style="margin: 0;">VAT: 311085302200003</p>
            </section>

            <section class="bill" style="margin-top: 30px;">
                <p style="margin: 0;">Bill To</p>
                <p style="margin: 0;">{{invoice?.employers_subscriptions[0]?.employer.name ?
                    invoice?.employers_subscriptions[0]?.employer?.name : 'N/A' }}</p>
                <p style="margin: 0;">VAT: 311085302200003</p>
            </section>

            <section class="invoice-date" style="display: flex;">
                <div style="flex: 2;"></div>
                <div style="flex: 1; display: flex; justify-content: space-between;">
                    <div>
                        <p style="margin: 0;">Invoice Date:</p>
                        <p style="margin: 0;">Payment Terms:</p>
                        <p style="margin: 0;">Due Date:</p>
                    </div>
                    <div>
                        <p style="margin: 0;">{{invoice.created_at ? invoice.created_at : 'N/A'}}</p>
                        <p style="margin: 0;">Due On Receipt</p>
                        <p style="margin: 0;">{{invoice?.employers_subscriptions[0]?.next_invoice_date ?
                            invoice?.employers_subscriptions[0]?.next_invoice_date : 'N/A'}}</p>
                    </div>
                </div>
            </section>

            <section class="table" style="margin-top: 20px;">
                <div style="display: flex;">
                    <p style="margin: 0; flex: 1; font-weight: bold;">#</p>
                    <p style="margin: 0; flex: 2; font-weight: bold;">Subscription Plan</p>
                    <p style="margin: 0; flex: 1; font-weight: bold;">Qty</p>
                    <p style="margin: 0; flex: 1; font-weight: bold;">Interval</p>
                    <p style="margin: 0; flex: 1; font-weight: bold;">Price (SAR)</p>
                    <p style="margin: 0; flex: 1; font-weight: bold;">Amount (SAR)</p>
                    <p style="margin: 0; flex: 1; font-weight: bold;">Discount (SAR)</p>
                    <p style="margin: 0; flex: 1; font-weight: bold;">Total</p>
                </div>
                <hr>
                <div style="display: flex;">
                    <p style="margin: 0; flex: 1;">{{invoice?.employers_subscriptions[0]?.id ?
                        invoice?.employers_subscriptions[0]?.id : 'N/A'}}</p>
                    <p style="margin: 0; flex: 2;">{{invoice?.employers_subscriptions[0]?.package?.package_name ?
                        invoice?.employers_subscriptions[0]?.package?.package_name : 'N/A'}}</p>
                    <p style="margin: 0; flex: 1;">{{invoice?.number_of_employees ? invoice?.number_of_employees : '0'}}
                    </p>
                    <p style="margin: 0; flex: 1;">{{invoice?.number_of_days ? invoice?.number_of_days : 0}}</p>
                    <p style="margin: 0; flex: 1;">{{invoice?.rate_per_day ? (invoice?.rate_per_day | number:'1.4-4') :
                        '0.0000'}}</p>
                    <p style="margin: 0; flex: 1;">{{invoice?.invoice_amount ? (invoice?.invoice_amount |
                        number:'1.4-4') : '0.0000'}}</p>
                    <p style="margin: 0; flex: 1;">{{invoice?.discount_amount ? (invoice?.discount_amount |
                        number:'1.4-4') : '0.0000'}}</p>
                    <p style="margin: 0; flex: 1;">{{invoice?.total_amount ? (invoice?.total_amount | number:'1.4-4') :
                        '0.0000'}}</p>
                </div>
                <hr style="margin-top: 60px;">
            </section>

            <section class="invoice-date" style="display: flex;">
                <div style="flex: 2;"></div>
                <div style="flex: 1; display: flex; justify-content: space-between;">
                    <div>
                        <p style="margin: 0; font-weight: bold;">Subtotal in SAR</p>
                        <p style="margin: 0; font-weight: bold;">VAT ({{invoice?.vat_percentage ?
                            invoice?.vat_percentage : 0}}%)</p>
                        <p style="margin: 0; font-weight: bold;">Total in SAR </p>
                    </div>
                    <div>
                        <p style="margin: 0; font-weight: bold;">SAR {{invoice?.total_amount ? (invoice?.total_amount |
                            number:'1.4-4') : '0.0000'}}</p>
                        <p style="margin: 0; font-weight: bold;">SAR {{invoice?.vat_amount ? (invoice?.vat_amount |
                            number:'1.4-4') : '0.0000'}}</p>
                        <p style="margin: 0; font-weight: bold;">SAR {{invoice?.charge_amount ? (invoice?.charge_amount
                            | number:'1.4-4') : '0.0000'}}</p>
                    </div>
                </div>
            </section>

            <section class="terms">
                <h3>Terms & Conditions</h3>
                <p>Payment for this invoice is due within 5 days of receipt. Please note that we cannot process
                    onboarding until payment is received. Thank you for your prompt attention to this matter.</p>
            </section>
        </main>
    </div>
</div>


<!-- <div style="display: none;">
    <div id="print">
        <main style="margin-inline: 20px; font-family: raleway !important;">
            <section class="top" style="display: flex; justify-content: space-between;">
                <div style="display: flex; gap: 10px;">
                    <h1 style="margin: 0; font-size: 40px; color: #213060;">tp</h1>
                    <h2 style="margin: 0; font-size: 40px; color: #213060;">thrift<span style="color: #999999;">plan</span></h2>
                </div>
                <h3 style="margin: 0; display: flex; align-items: end;">Invoice</h3>
            </section>
            <hr>
            <section style="display: flex; justify-content: space-between;">
                <h4 style="margin: 0;">Thriftplan</h4>
                <h4 style="margin: 0;"># INV-{{invoice?.id ? invoice?.id : 'N/A'}}</h4>
            </section>
            <section class="address">
                <p style="margin: 0;">Office 3, 3998</p>
                <p style="margin: 0;">Anas bin Malik</p>
                <p style="margin: 0;">Riyadh Ar Riyad 13326</p>
                <p style="margin: 0;">Saudi Arabia</p>
                <p style="margin: 0;">VAT: 311085302200003</p>
            </section>
        
            <section class="bill" style="margin-top: 30px;">
                <p style="margin: 0;">Bill To</p>
                <p style="margin: 0;">{{invoice?.employers_subscriptions[0]?.employer.name ? invoice?.employers_subscriptions[0]?.employer?.name : 'N/A' }}</p>
                <p style="margin: 0;">VAT:</p>
            </section>
        
            <section class="invoice-date" style="display: flex;">
                <div style="flex: 2;"></div>
                <div style="flex: 1; display: flex; justify-content: space-between;">
                    <div>
                        <p style="margin: 0;">Invoice Date:</p>
                        <p style="margin: 0;">Payment Terms:</p>
                        <p style="margin: 0;">Due Date:</p>
                    </div>
                    <div>
                        <p style="margin: 0;">{{invoice.created_at ? invoice.created_at : 'N/A'}}</p>
                        <p style="margin: 0;">Due On Receipt</p>
                        <p style="margin: 0;">{{invoice?.employers_subscriptions[0]?.next_invoice_date ? invoice?.employers_subscriptions[0]?.next_invoice_date : 'N/A'}}</p>
                    </div>
                </div>
            </section>
        
            <section class="table" style="margin-top: 20px;">
                <div style="display: flex;">
                    <p style="margin: 0; flex: 1; font-weight: bold;">#</p>
                    <p style="margin: 0; flex: 3; font-weight: bold;">Subscription Plan</p>
                    <p style="margin: 0; flex: 1; font-weight: bold;">Qty</p>
                    <p style="margin: 0; flex: 1; font-weight: bold;">Interval</p>
                    <p style="margin: 0; flex: 1; font-weight: bold;">Price</p>
                    <p style="margin: 0; flex: 1; font-weight: bold;">Amount</p>
                    <p style="margin: 0; flex: 1; font-weight: bold;">VAT</p>
                    <p style="margin: 0; flex: 1; font-weight: bold;">Total</p>
                </div>
                <hr>
                <div style="display: flex;">
                    <p style="margin: 0; flex: 1;">{{invoice?.employers_subscriptions[0]?.id ? invoice?.employers_subscriptions[0]?.id : 'N/A'}}</p>
                    <p style="margin: 0; flex: 3;">End of Service Benefits</p>
                    <p style="margin: 0; flex: 1;">14</p>
                    <p style="margin: 0; flex: 1;">15</p>
                    <p style="margin: 0; flex: 1;">4.05</p>
                    <p style="margin: 0; flex: 1;">850.50</p>
                    <p style="margin: 0; flex: 1;">{{invoice?.vat_percentage ? invoice?.vat_percentage : 'N/A'}}</p>
                    <p style="margin: 0; flex: 1;">{{invoice?.charge_amount ? invoice?.charge_amount : 'N/A'}}</p>
                </div>
                <hr style="margin-top: 60px;">
            </section>
        
            <section class="invoice-date" style="display: flex;">
                <div style="flex: 2;"></div>
                <div style="flex: 1; display: flex; justify-content: space-between;">
                    <div>
                        <p style="margin: 0; font-weight: bold;">Subtotal in SAR</p>
                        <p style="margin: 0; font-weight: bold;">VAT (15%)</p>
                        <p style="margin: 0; font-weight: bold;">Total in SAR </p>
                    </div>
                    <div>
                        <p style="margin: 0; font-weight: bold;">SAR {{invoice?.total_amount ? invoice?.total_amount : 'N/A'}}</p>
                        <p style="margin: 0; font-weight: bold;">SAR {{invoice?.vat_amount ? invoice?.vat_amount : 'N/A'}}</p>
                        <p style="margin: 0; font-weight: bold;">SAR {{invoice?.charge_amount ? invoice?.charge_amount : 'N/A'}}</p>
                    </div>
                </div>
            </section>
        
            <section class="terms">
                <h3>Terms & Conditions</h3>
                <p>Payment for this invoice is due within 5 days of receipt. Please note that we cannot process onboarding until payment is received. Thank you for your prompt attention to this matter.</p>
            </section>
        
            <section class="bank-details">
                <h3>Bank Details</h3>
                <div style="display: flex;">
                    <div style="flex: 2;">
                        <p style="margin: 0;">Beneficiary Name:</p>
                        <p style="margin: 0;">Bank Name:</p>
                        <p style="margin: 0;">Account Number:</p>
                        <p style="margin: 0;">IBAN:</p>
                        <p style="margin: 0;">Address of Bank:</p>
                    </div>
                    <div style="flex: 2;">
                        <p style="margin: 0;">Name</p>
                        <p style="margin: 0;">Riyad Bank</p>
                        <p style="margin: 0;">24700-004-4302856542739</p>
                        <p style="margin: 0;">SA2470000443028565427394</p>
                        <p style="margin: 0;">Riyadh</p>
                    </div>
                    <div style="flex: 3;"></div>
                </div>
            </section>
        </main>
    </div>
</div> -->
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs-compat';

@Component({
  selector: 'app-translation-new',
  templateUrl: './translation-new.component.html',
  styleUrls: ['./translation-new.component.scss']
})
export class TranslationNewComponent implements OnInit {

    @Output() onAppSelect:  EventEmitter<any> = new EventEmitter;
    @Output() isEnglish:  EventEmitter<any> = new EventEmitter;
	engArr: any = false;
	keyValue: any;
	UrbanpointAdmin: any;
	isChecked: boolean = false;
    subscription: Subscription;
    isLoggedIn: boolean = false;
    
    constructor(public translate: TranslateService,
		protected router: Router) 
    {
		translate.addLangs(['en', 'ar']);
		if (localStorage.getItem('lang') == 'ar')
		{
			this.isEnglish.emit(false);
			document.body.classList.add('arabic');
			document.body.classList.remove('english');
			document.body.setAttribute("dir", "rtl");

			this.isChecked = true;
			this.translate.use('ar');
		}
		else
		{
			this.isEnglish.emit(true);
			document.body.classList.add('english');
			document.body.classList.remove('arabic');
			document.body.setAttribute("dir", "ltr");
			
			this.isChecked = false;
			this.translate.use('en');
		}
		this.checkRouteForPosition();
		// console.log('Translation Service');
    }

    ngOnInit(): void 
    {

    }

    toggle(e)
	{
		if(e.checked)
		{
			this.isEnglish.emit(false);
			localStorage.setItem('lang', 'ar');
			this.translate.use('ar');
			document.body.classList.add('arabic');
			document.body.classList.remove('english');
			document.body.setAttribute("dir", "rtl");
		}
		else
		{
			this.isEnglish.emit(true);
			localStorage.setItem('lang', 'en');
			this.translate.use('en');
			document.body.classList.add('english');
			document.body.classList.remove('arabic');
			document.body.setAttribute("dir", "ltr");
		}
        window.location.reload();
	}

	checkRouteForPosition()
	{
		this.subscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) 
			{
                if(event.url.includes('main'))
                {
                    this.isLoggedIn = true;
                }
            }
        });
	}

	ngOnDestroy()
    {
        this.subscription.unsubscribe();
    }
}

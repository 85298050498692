<div class="main pt-1 mrl-20 mt-30" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div fxLayout="row wrap" fxLayoutAlign="space-between">
        <app-card fxFlex="32" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99"
            [middleTextNumberFour]="vestingCards?.current_year" imgCustom="assets/new-icons/total-employees.png"
            topText="{{'Current Year Vesting' | translate}}" bottomText="" [error]="false">
        </app-card>
        <app-card fxFlex="32" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99"
            [middleTextNumberFour]="vestingCards?.unvested" imgCustom="assets/new-icons/active-employees.png"
            topText="{{'Unvested balance' | translate}}" bottomText="" [error]="false">
        </app-card>
        <app-card fxFlex="32" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99" [middleTextNumberFour]="vestingCards?.due"
            imgCustom="assets/new-icons/quarter-icon.png" topText="{{'Vesting due this quarter' | translate}}"
            bottomText="" [error]="false">
        </app-card>
    </div>
    <div class="mt-20">
        <app-table fxFlex="100" [tableProps]="tablePropsEmp" (searchData)="searchData($event)" [dataSource]="list"
            [pagination]="pagination" (setPage)="setPage($event)" (actionClicked)="actionClicked($event)"
            (pageSize)="SetPageSize($event)" (outPutHeaders)="headerButton($event)">
        </app-table>
    </div>
</div>
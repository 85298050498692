import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TermsGuard implements CanActivate {
  constructor(public route: Router) {

  }
  canActivate() {
    let terms_status = localStorage.getItem('terms_status');
    let token = localStorage.getItem('employer_token');
    if ((terms_status == '0' || terms_status == void 0) && token) {
      return true;
    }
    else {
      if (token == void 0) 
      {
        this.route.navigate(['/auth']);
      }
      else
      {
        this.route.navigate(['/main']);
      }
      return false;
    }
  }
}


import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit {
  list: any = [];
  listData: any = [];
  start_date: any;
  end_date: any;
  currentDate = new Date();
  searchTimer: any;
  count = 0;
  search: any = "";
  body: any;
  tablePropsEmp = {
    heading: 'Archive',
    hasSearch: true,
    searchLabel: 'Search',
    hasButton: true,
    headerActions: [
    ],
    rowActions: [],
    colHeader: {
      employee_id: 'ID',
      employee_name: 'Name',
      group_name: 'Company Group',
      author: 'Author',
      vested_amount: 'Vested Amount',
      vested_balance: 'Unvested Amount',
      created_at: 'Created At',
    },
    columnTypes: {
      vested_amount: 'number-4',
      vested_balance: 'number-4',
      created_at: 'date',
      statusToShow: 'errorColumn',
    },
    filterArray: [],
    dateFilter: {
      hasDatePicker: true,
      type: "dateRange",
      startLabel: "Start_Date",
      endLabel: "End_Date",
    }
  };

  pagination = {
    page: 1,
    pages: null,
    per_page: 10,
    count: 0
  }

  constructor(protected route: Router, protected dec_enc: DecEncService, protected mainService: MainService, protected paginationService: PaginationService, public datePipe: DatePipe) { }

  ngOnInit(): void {
    this.getAll(1);
    this.afterList()
  }

  getAll(index) {
    this.body = {
      start_date: this.start_date,
      end_date: this.end_date,
    }
    let enc_payload = this.dec_enc.encryption(this.body);
    let url = 'vesting/archives';
    this.mainService.postData(url, { enc_payload }).then(res => {
      if (res.statusCode == 200) {
        let result = this.dec_enc.decryption(res.data);
        this.listData = result.archives;
        this.listData.forEach(element => {
          element['created_at'] = this.datePipe.transform(element['created_at'], 'dd-MM-yyyy');
        });
        this.updatePageData();
        this.afterList();
      }
    })
  }

  updatePageData() {
    const start = (this.pagination.page - 1) * this.pagination.per_page;
    const end = start + this.pagination.per_page;
    this.list = this.listData.slice(start, end);
    if (this.search !== "" && this.search !== null) {
      let hasMatch = false;
      this.list.forEach(item => {
        if (item.employee_id == this.search) {
          this.pagination.page = 1;
          this.pagination.per_page = 10;
          this.list = [];
          this.list.push(item);
          this.listData = this.list;
          hasMatch = true;
        }
      });
      if (!hasMatch) {
        this.listData = this.list;
        this.list = [];
      }
    } else {
      this.list = this.listData.slice(start, end);
    }
    this.count = this.listData.length;
    this.pagination.count = this.count;
    this.pagination.pages = Math.ceil(this.pagination.count / this.pagination.per_page);
  }

  setPage(page) {
    this.pagination.page = page;
    this.getAll(this.pagination.page);
  }

  SetPageSize(pageSize) {
    this.pagination.per_page = pageSize
    this.getAll(this.pagination.page);
  }

  searchData(event) {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.onSearch(event);
    }, 800);
  }

  onSearch(event) {
    this.search = event;
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.getAll(1);

    }, 800);
  }

  actionClicked(event) { }

  headerButton(event) { }

  afterList() { }

  onDateFilters(event) {
    if (event.type == "dateRange") {
      this.start_date = event['start'];
      this.end_date = event['end'];
      this.getAll(1);
    }
  }
}
<!-- <button class="btn-submit" mat-raised-button (click)="print()">
  <img src="assets/printer.svg" alt=""> {{'Print' | translate}}
</button> -->

<button (click)="downloadPDF()"
                style="background-color: #213060; color: white; border-radius: 10px; outline: none; height: 35px;"
                mat-raised-button>{{'Save as PDF' | translate}}</button>

<!--multiple tables-->
<!-- <div id="printable-div" style="margin:0; padding:0;display: none;">
  <table style="width:100%; max-width:700px; margin:0 auto; background-color:#fff; border-collapse:collapse;">
      <tbody style="margin: 0px 20px !important;">
          <tr style="display: flex; justify-content: space-between; align-items: flex-end; border-bottom: 1px solid #79C0C2;"
              class="mrl-20">
              <td style="padding-top:20px; text-align:left; flex: 1;" class="top-logo pr-10">
                  <img width="215" height="70" src="assets/thrift_logo.png" alt="Logo">
              </td>
              <td style="padding-top:20px; text-align: right; font-size:14px; color:#757575; flex: 1;">
                  <h2 style="margin: 0; font-weight: bolder; color: black;">{{'Portfolio Holding Report' | translate}}
                  </h2>
              </td>
          </tr>
          <tr>
              <td colspan="3" style="padding: 10px 20px;">
                  <div style="display: flex; justify-content: center; gap: 30px;">
                      <div style="flex: 1; font-size: 14px; color: #757575; text-align: left;">
                          <p style="display: flex; justify-content: space-between;color: #000000;">
                            <strong>{{'Report as of' | translate}}</strong>
                            <span>2 Jul 2023</span>
                          </p>
                          <p style="display: flex; justify-content: space-between;color: black;">
                            <strong>{{'Holding type' | translate}}</strong>
                            <span>{{'Portfolio-wise' |translate}} </span>
                          </p>
                          <p style="display: flex; justify-content: space-between;color: black;">
                            <strong>{{'IBAN' | translate}}</strong>
                            <span>SA21550000012210000</span>
                          </p>
                      </div>
                      <div style="flex: 1; font-size: 14px; color: #757575; text-align: right;">
                          <p style="display: flex; justify-content: space-between;color: black;">
                              <strong>{{'Currency' |translate}}</strong>
                              <span>SAR</span>
                          </p>
                          <p style="display: flex; justify-content: space-between;color: black;">
                            <strong>{{'Filter selection' | translate}}</strong>
                            <span>All portfolios</span>
                          </p>
                      </div>
                  </div>
              </td>
          </tr>

          
          <tr *ngFor="let fund of funds">
              <td colspan="3" style="padding: 0 20px;">
                  <p style="font-weight: bolder; border-bottom: 1px solid black; margin-bottom: 5px;"><strong>{{ fund.name }}</strong>
                  </p>
                  <table
                      style="width: 100%; border-collapse: collapse; border-bottom: 1px solid #79C0C2; margin-bottom: 20px;">
                      <thead>
                          <tr style="border-bottom: 1px solid #79C0C2;">
                              <th style="padding: 10px; text-align: left;">{{ 'Fund' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Invested Amount' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Purchased Units' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Withdrawn Units' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Withdrawn Amount' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Units on Hand' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Current Value' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Absolute Return' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Return (%)' | translate }}</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let record of fund.records">
                              <td style="padding: 10px; text-align: left;">{{ record.fund }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.invested_amount }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.purchased_units }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.withdrawn_units }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.withdrawn_amount }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.units_on_hand }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.current_value }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.absolute_return }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.return }}</td>
                          </tr>
                      </tbody>
                  </table>
                  <div style="text-align: right; padding: 10px; margin-top: 5px;">
                      <span style="color: black; font-weight: bolder; margin-right: 10px;">{{'Current Asset Value'| translate}}</span>
                      <span>  {{ fund.current_asset_value }}</span>
                  </div>
                  <div style="text-align: right; padding: 10px; margin-top: 5px;">
                    <span style="color: black; font-weight: bolder; margin-right: 10px;">{{'Total return'| translate}}</span>
                    <span>  {{ fund.total_return }}</span>
                </div>
              </td>
          </tr>

          <tr class="mrl-20">
              <td colspan="3" style="padding: 0px 20px;">
                  <p style="color: black; font-size: 13px;">{{'PDF_Note' | translate}}
                  </p>
              </td>
          </tr> 

          <tr class="mrl-20">
              <td colspan="3" style="padding: 0px 20px;">
                  <p style="color: black;"><strong>Thriftplan.com.sa/Reports</strong></p>
              </td>
          </tr>
      </tbody>
  </table>
</div> -->

<!-- Single Table -->

<!-- <div id="printable-div" style="margin:0; padding:0;display: none;">
  <table style="width:100%; max-width:700px; margin:0 auto; background-color:#fff; border-collapse:collapse;">
      <tbody style="margin: 0px 20px !important;">
          <tr style="display: flex; justify-content: space-between; align-items: flex-end; border-bottom: 1px solid #79C0C2;"
              class="mrl-20">
              <td style="padding-top:20px; text-align:left; flex: 1;" class="top-logo pr-10">
                  <img width="215" height="70" src="assets/thrift_logo.png" alt="Logo">
              </td>
              <td style="padding-top:20px; text-align: right; font-size:14px; color:#757575; flex: 1;">
                  <h2 style="margin: 0; font-weight: bolder; color: black;">{{'Portfolio Holding Report' | translate}}
                  </h2>
              </td>
          </tr>
          <tr>
              <td colspan="3" style="padding: 10px 20px;">
                  <div style="display: flex; justify-content: center; gap: 30px;">
                      <div style="flex: 1; font-size: 14px; color: #757575; text-align: left;">
                          <p style="display: flex; justify-content: space-between;color: #000000;">
                            <strong>{{'Report as of' | translate}}</strong>
                            <span>2 Jul 2023</span>
                          </p>
                         
                          <p style="display: flex; justify-content: space-between;color: black;">
                            <strong>{{'Currency' |translate}}</strong>
                            <span>SAR</span>
                        </p>
                          <p style="display: flex; justify-content: space-between;color: black;">
                            <strong>{{'IBAN' | translate}}</strong>
                            <span>SA21550000012210000</span>
                          </p>
                      </div>
                      <div style="flex: 1; font-size: 14px; color: #757575; text-align: right;">
                        <p style="display: flex; justify-content: space-between;color: black;">
                          <strong>{{'Holding type' | translate}}</strong>
                          <span>{{'Portfolio-wise' |translate}} </span>
                        </p>
                          <p style="display: flex; justify-content: space-between;color: black;">
                            <strong>{{'Filter selection' | translate}}</strong>
                            <span>All portfolios</span>
                          </p>
                      </div>
                  </div>
              </td>
          </tr>

          
          <tr>
              <td colspan="3" style="padding: 0 20px;">
                  <table
                      style="width: 100%; border-collapse: collapse; border-bottom: 1px solid #79C0C2; margin-bottom: 20px;">
                      <thead>
                          <tr style="border-bottom: 1px solid #79C0C2;">
                              <th style="padding: 10px; text-align: left;">{{ 'Fund' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Invested Amount' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Purchased Units' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Withdrawn Units' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Withdrawn Amount' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Units on Hand' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Current Value' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Absolute Return' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Return (%)' | translate }}</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let record of this.funds[0].records">
                              <td style="padding: 10px; text-align: left;">{{ record.fund }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.invested_amount }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.purchased_units }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.withdrawn_units }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.withdrawn_amount }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.units_on_hand }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.current_value }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.absolute_return }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.return }}</td>
                          </tr>
                      </tbody>
                  </table>
                  <div style="text-align: right; padding: 10px; margin-top: 5px;">
                      <span style="color: black; font-weight: bolder; margin-right: 10px;">{{'Current Asset Value'| translate}}</span>
                      <span>  {{ this.funds[0].current_asset_value }}</span>
                  </div>
                  <div style="text-align: right; padding: 10px; margin-top: 5px;">
                    <span style="color: black; font-weight: bolder; margin-right: 10px;">{{'Total return'| translate}}</span>
                    <span>  {{ this.funds[0].total_return }}</span>
                </div>
              </td>
          </tr>

          <tr class="mrl-20">
              <td colspan="3" style="padding: 0px 20px;">
                  <p style="color: black; font-size: 13px;">{{'PDF_Note' | translate}}
                  </p>
              </td>
          </tr> 

          <tr class="mrl-20">
              <td colspan="3" style="padding: 0px 20px;">
                  <p style="color: black;"><strong>Thriftplan.com.sa/Reports</strong></p>
              </td>
          </tr>
      </tbody>
  </table>
</div>
 -->

<!-- Transaction-Report -->
 <!-- <div id="printable-div" style="margin:0; padding:0;display: none;"> -->
    <div *ngIf="showPdf" id="contentToExport" class="main pt-1 mrl-20 mt-10" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
  <table style="width:100%; max-width:700px; margin:0 auto; background-color:#fff; border-collapse:collapse;">
      <tbody style="margin: 0px 20px !important;">
          <tr style="display: flex; justify-content: space-between; align-items: flex-end; border-bottom: 1px solid #79C0C2;"
              class="mrl-20">
              <td style="padding-top:20px; text-align:left; flex: 1;" class="top-logo pr-10">
                  <img width="215" height="70" src="assets/thrift_logo.png" alt="Logo">
              </td>
              <td style="padding-top:20px; text-align: right; font-size:14px; color:#757575; flex: 1;">
                  <h2 style="margin: 0; font-weight: bolder; color: black;">{{'Transaction Report' | translate}}
                  </h2>
              </td>
          </tr>
          <tr>
              <td colspan="3" style="padding: 10px 20px;">
                  <div style="display: flex; justify-content: center; gap: 30px;">
                      <div style="flex: 1; font-size: 14px; color: #757575; text-align: left;">
                          <p style="display: flex; justify-content: space-between;color: #000000;">
                            <strong>{{'Report period' | translate}}</strong>
                            <span>2 Jul 2023 - 2 jul 2024</span>
                          </p>
                         
                          <p style="display: flex; justify-content: space-between;color: black;">
                            <strong>{{'Benificiary name' |translate}}</strong>
                            <span>Reem Ikram Hamza</span>
                        </p>
                          
                      </div>
                      <div style="flex: 1; font-size: 14px; color: #757575; text-align: right;">
                        <p style="display: flex; justify-content: space-between;color: black;">
                          <strong>{{'Total deposits' | translate}}</strong>
                          <span>SAR 15,000.00</span>
                        </p>
                          <p style="display: flex; justify-content: space-between;color: black;">
                            <strong>{{'Total withdrawls' | translate}}</strong>
                            <span>SAR 1251.90</span>
                          </p>
                          <p style="display: flex; justify-content: space-between;color: black;">
                            <strong>{{'Unutitlized returns' | translate}}</strong>
                            <span>SAR 1013.33</span>
                          </p>
                          <p style="display: flex; justify-content: space-between;color: black;">
                            <strong>{{'Closing balance' | translate}}</strong>
                            <span>SAR 14,761.43</span>
                          </p>
                      </div>
                  </div>
              </td>
          </tr>

          
          <tr>
              <td colspan="3" style="padding: 0 20px;">
                  <table
                      style="width: 100%; border-collapse: collapse; border-bottom: 1px solid #79C0C2; margin-bottom: 20px;">
                      <thead>
                          <tr style="border-bottom: 1px solid #79C0C2;">
                              <th style="padding: 10px; text-align: left;">{{ 'Date' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Type' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Description' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Debit' | translate }}</th>
                              <th style="padding: 10px; text-align: left;">{{ 'Credit' | translate }}</th>
                              
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let record of this.transactions">
                              <td style="padding: 10px; text-align: left;">{{ record.date }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.type }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.description }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.credit }}</td>
                              <td style="padding: 10px; text-align: left;">{{ record.debit }}</td>
                              
                          </tr>
                      </tbody>
                  </table>
              </td>
          </tr>

          <tr class="mrl-20">
              <td colspan="3" style="padding: 0px 20px;">
                  <p style="color: black; font-size: 13px;">{{'PDF_Note' | translate}}
                  </p>
              </td>
          </tr> 

          <tr class="mrl-20">
              <td colspan="3" style="padding: 0px 20px;">
                  <p style="color: black;"><strong>Thriftplan.com.sa/Reports</strong></p>
              </td>
          </tr>
      </tbody>
  </table>
</div>
import { Direction } from '@angular/cdk/bidi';
import { Component, OnInit } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs-compat';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { MessageService } from 'src/app/services/message.service';
import { ProfileService } from 'src/app/services/profile.service';
import { appConfig } from 'src/config';

@Component({
    selector: 'app-main-app',
    templateUrl: './main-app.component.html',
    styleUrls: ['./main-app.component.scss']
})

export class MainAppComponent implements OnInit {
    employer_name: any;
    menus: any[];
    Count: any = 0;
    afterResponceCount: any = 0;
    notificationsCount: any;
    NotificationType: any;
    NotificationNavigation: any;
    manageOrganization: boolean = true;
    isExpanded = false;
    direction: Direction = 'ltr'
    isArabic = false;
    mode: any = 'side';
    activeMenuItem: any = null;
    activeSubmenuItem: any = null;
    openSidenav: boolean = false;
    mediaConfig: Subscription;
    routerSubscription: Subscription;
    employerName: string = '';
    topBarRouteNames = {
        'home': 'Dashboard',
        'employer-manager': 'Manage_Admins',
        'manage_employee': 'Employees',
        'terminate-detail': 'Terminate_Employee',
        'investment-funds': 'Company Groups',
        'manage-withdrawls': 'Manage_Withdrawal',
        'manage-contribution': 'Manage_Contributions',
        'hr-policy': 'HR Policy',
        'maintenance': 'Maintenance',
        'hr-policy/audit': 'HR Policy - Audit',
        'reallocate-funds': 'reallocate_funds',
        'notification': 'Announcements',
        'tp-logs': 'Logs',
        'activity-notifications': 'Activity_Notification',
        'allocate-contribution': 'Allocate_Contributions',
        'profile': 'My_Profile',
        'manage_orgnazation': 'Manage_Organization',
        'manage_portfolio': 'Manage_Portfolio',
        'terminate_employee': 'Terminate_Employee',
        'reports': 'Reports',
        'vesting': 'Vesting',
        'archive': 'Archive',
        'redemption-instructions': 'Redemption Instructions',
        'transaction-report': 'Transaction Report',
        'asset-holding-report': 'Portfolio Holding Report'
    }
    routeName = '';
    subRouteName = null;
    logoImage: string = null;

    constructor(public tr: TranslationService,
        protected mainservice: MainService,
        public route: Router,
        protected messageservice: MessageService,
        public dec_enc: DecEncService,
        private media: MediaObserver,
        public prService: ProfileService,
        protected activatedRoute: ActivatedRoute) {

        if (prService.user.logo_image) {
            this.logoImage = `${appConfig.file_url}${this.prService.user.logo_image}`;
        }

        this.manageOrganization = this.prService.user.role == 'employer' ? true : false;

        this.mediaConfig = this.media.asObservable().subscribe((change: MediaChange[]) => {
            this.openSidenav = !(change[0].mqAlias == 'xs');
            if (change[0].mqAlias == 'xs') {
                this.isExpanded = true;
            } else {
                this.isExpanded = false;
            }
        });

        if (localStorage.getItem('lang') == 'ar') {
            this.direction = 'rtl';
            this.isArabic = true;
        }
        else {
            this.direction = 'ltr';
            this.isArabic = false;
        }

        this.routerSubscription = route.events.subscribe(
            (event) => {
                if (event instanceof NavigationEnd) {
                    for (let key in this.topBarRouteNames) {
                        if (event.urlAfterRedirects.includes(key)) {
                            if (key == "terminate_employee") {
                                this.subRouteName = 'Manage Employees';
                            }
                            else if (key == "manage_portfolio") {
                                this.subRouteName = 'Manage Employees';
                            }
                            else {
                                this.subRouteName = null
                            }
                            this.routeName = this.topBarRouteNames[key];
                        }
                    }
                }
            });
    }

    toggle(mode?: any) {
        if (this.mode == 'side') {
            this.mode = 'over'
        } else {
            this.mode = 'side'
        }
    }

    ngOnInit(): void {
        document.addEventListener("visibilitychange", () => {
            this.notificationCount();
        });

        // this.messageservice.receiveMessage();
        this.notificationCount();
        this.mainservice.employer_name.subscribe(
            (data) => {
                if (data != null) {
                    this.employerName = data;
                    const fullName = data.split(' ');
                    if (fullName.length == 1) {
                        let name = fullName[0].charAt(0);
                        this.employer_name = name.toUpperCase();
                    }
                    else {
                        let name = fullName[0].charAt(0) + fullName[fullName.length - 1].charAt(0);
                        this.employer_name = name.toUpperCase();
                    }
                }

            }
        );

        this.tr.selectedApp.subscribe((state) => {
            this.tr.translation = state;
            this.menusInit();
        })

        this.menusInit();

        this.messageservice.receiveMessage()
        this.messageservice.pushMsgRefresh.subscribe(
            (data) => {
                this.NotificationType = data.data.type;
                let body = {
                    title: data.data.title,
                    message: data.data.body,
                    id: data.data.id
                }
                this.notificationCount();
            }
        );
        this.mainservice.selectedApiCallAgain.subscribe((state) => {
            this.notificationCount()
        })
        this.tr.selectedApiCallAgain.subscribe((state) => {
            this.notificationCount()
        })
    }

    menusInit() {
        //do not change the sequence 
        this.menus = [
            { routerLink: '/main/home', label: 'Dashboard', visibility: true, icon: 'home' },
            {
                label: 'Manage Employees', visibility: true, icon: 'local_library', submenuVisible: false, submenus: [
                    { routerLink: '/main/manage_employee', label: 'Employees', visibility: true, icon: 'local_library', },
                    { routerLink: '/main/investment-funds', label: 'Company Groups', visibility: true, icon: 'person' },
                    { routerLink: '/main/vesting', label: 'Vesting', visibility: true, icon: 'person' },
                ]
            },
            {
                label: 'Employees Request', visibility: true, icon: 'question_answer', submenuVisible: false, submenus: [
                    { routerLink: '/main/manage-withdrawls', label: 'Withdrawal', visibility: this.prService.user.role == "employer" || this.prService.user.role == "emp-finance" ? true : false, icon: 'account_balance' }, //emp-maker, emp-chaker 
                    { routerLink: '/main/reallocate-funds', label: 'Reallocation', visibility: true, icon: 'local_library' },
                    { routerLink: '/main/terminate-detail', label: "Terminate_Request", visibility: this.prService.user.role == "emp-finance" ? false : true, icon: 'apartment' },
                ]
            },
            {
                label: 'Instructions', visibility: true, icon: 'assignment', submenuVisible: false, submenus: [
                    { routerLink: '/main/manage-contribution', label: 'Contribution', visibility: true, icon: 'account_box' },
                    { routerLink: '/main/redemption-instructions', label: 'Redemption Instructions', visibility: true, icon: '' },
                    //{ routerLink: '/main/manage-withdrawls', label: 'Withdrawals', visibility: this.prService.user.role == "employer" || this.prService.user.role == "emp-finance" ? true : false, icon: 'account_balance' }, //emp-maker, emp-chaker 
                    { routerLink: '/main/reallocate-funds', label: 'Reallocations', visibility: true, icon: 'local_library' },
                ]
            },
            //{ routerLink: '/main/terminate-detail', label: "Terminate_Request", visibility: this.prService.user.role == "emp-finance" ? false : true, icon: 'apartment' }, //restrict emp-finance
            { routerLink: '/main/hr-policy', label: 'HR Policy', visibility: true, icon: 'assignment' },
            // { routerLink: '/main/reports', label: 'Reports', visibility: true, icon: 'list_alt' },
            {
                label: 'Settings', visibility: true, icon: 'settings', submenuVisible: false, submenus: [
                    { routerLink: '/main/employer-manager', label: 'Admins', visibility: true, icon: 'person' },
                    { routerLink: '/main/notification', label: 'Announcements', visibility: true, icon: 'local_post_office' },
                    { routerLink: '/main/tp-logs', label: 'Logs', visibility: this.prService.user.role == "employer" || this.prService.user.role == "emp-maker" ? true : false, icon: 'streetview' },//emp-finance, emp-chacker
                    { routerLink: '/main/activity-notifications', label: 'Activity_Notifications', visibility: this.prService.user.role == "employer" ? true : false, icon: 'notifications' }, //emp-finance, emp-chacker, emp-maker
                    { routerLink: '/main/allocate-contribution', label: 'Allocate_Contribution', visibility: true, icon: 'assignment_ind' },
                ]
            },
            {
                label: 'Reports', visibility: true, icon: 'list_alt', submenuVisible: false, submenus: [
                    { routerLink: '/main/transaction-report', label: 'Transaction Statement', visibility: true, icon: 'account_box' },
                    { routerLink: '/main/asset-holding-report', label: 'Portfolio Holding Report', visibility: true, icon: 'local_library' },
                ]
            },
        ];
    }

    toggleSubmenu(item: any, event: Event): void {
        event.stopPropagation();
        this.menus.forEach((menuItem: any) => {
            if (menuItem !== item && menuItem.submenuVisible) {
                menuItem.submenuVisible = false;
            }
        });
        item.submenuVisible = !item.submenuVisible;
        this.activeMenuItem = item.submenuVisible ? item : null;
    }

    setActiveSubmenu(submenu: any, event: Event): void {
        event.stopPropagation();
        this.activeSubmenuItem = submenu;
        this.activeMenuItem = null;
    }

    setActiveMenuItem(item: any): void {
        this.closeAllSubmenus();
        this.activeMenuItem = item;
        this.activeSubmenuItem = null;
    }

    closeAllSubmenus(): void {
        this.menus.forEach((menuItem: any) => {
            menuItem.submenuVisible = false;
        });
    }

    profile() {
        this.route.navigate(['main/profile'])
    }

    organizationProfile() {
        this.route.navigate(['main/manage_orgnazation'])
    }

    logout() {
        this.mainservice.onLogout().then(result => {
            if (result.statusCode == 200) {
                localStorage.clear();
                window.location.reload();
            }
        })
    }

    notificationCount(): void {
        this.mainservice.postData('home/unread_count', {}).then(response => {
            if (response.statusCode == 200) {
                let result = this.dec_enc.decryption(response.data);
                this.afterResponceCount = result.notification_count + result.activity_count;
                this.notificationsCount = this.afterResponceCount;
                if (this.notificationsCount > 99) {
                    this.notificationsCount = '99+';
                }
                this.NotificationNavigation = result.navigation;
                if (this.Count != this.afterResponceCount) {
                    if (this.afterResponceCount > this.Count) {
                        try {
                            let audio = new Audio();
                            audio.src = "./assets/pendingAudio.mp3";
                            audio.load();
                            audio.muted = false;
                            audio.play();
                            audio.loop = false;
                        }
                        catch (e) {
                        }
                    }
                    this.Count = result.count;
                }
            }

        })
    }

    countValue() {
        this.route.navigateByUrl("main/activity-notifications");
        // if (this.NotificationNavigation == "activity") {
        //     this.route.navigateByUrl("main/activity-notifications");
        // }
        // else {
        //     this.route.navigateByUrl("main/notification");
        // }
    }

    ngOnDestroy(): void {
        // this.tr.selectedApp.unsubscribe();
        this.mediaConfig.unsubscribe();
        this.routerSubscription.unsubscribe();
    }
}


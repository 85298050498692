import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'allocate-contribution-fund',
  templateUrl: './allocate-contribution.component.html',
  styleUrls: ['./allocate-contribution.component.scss']
})
export class AllocateContributionComponent implements OnInit {
  risk: any = "Low"
  list: any = [];
  totalPages: number;
  pages: any;
  perPage: number = 10;
  index: any = 1;
  currentPage: any = 1;
  count: any;
  items: any;
  fundlist: any;
  fund_allocated: any;
  allocate: any = true;
  selected_fund_id = -1;
  role: any;

  constructor(public tr: TranslationService,
    protected dialog: MatDialog, 
    protected mainService: MainService, 
    public dec_enc: DecEncService, 
    protected paginationService: PaginationService,
    protected alertService: AlertService,
    protected profileService: ProfileService) {
      this.role = profileService.user.role;
  }

  ngOnInit(): void {
    this.onCheckAllocatedFund();
    this.getAllavailableFunds();
  }

  riskProfile(val) {
    this.risk = val;
  }

  getAllavailableFunds() {
    let url = 'funds'
    this.mainService.postData(url, {}).then(res => {
      if (res.statusCode == 200) {
        let result = this.dec_enc.decryption(res.data);
        this.fundlist = result.funds;
      }
    },
      error => {
        this.alertService.alert(this.tr.translation.error, this.tr.translation.serverError);
      }
    )
  }
  
  // removing re_alloacte api because there's no such api on the backend according to farhan

  // onSelectFund(id, risk) {
  //   this.mainService.postData('funds/allocated', {}).then(res => {
  //     if (res.statusCode == 200) {
  //       let result = this.dec_enc.decryption(res.data);
  //       this.fund_allocated = result.employer_fund;
  //       if (this.fund_allocated == null) {
  //         this.alertService.alertAsk("Confirmation", "Do you want to select this fund?", "Yes", "No", false).then(result => {
  //           if (result)
  //           {
  //             let enc_payload = this.dec_enc.encryption({ fund_id: id });
  //             this.mainService.postData('funds/re_allocate', { enc_payload }).then(res =>
  //               {
  //                 if (res.statusCode == 200)
  //                 {
  //                   this.alertService.alert(this.tr.translation.success, "Portfolio has been allocated successfully");
  //                 }
  //                 if (res.statusCode == 208)
  //                 {
  //                   this.alertService.alert(this.tr.translation.error, "You have already select a fund");
  //                 }
  //                 if (res.statusCode == 404)
  //                 {
  //                   this.alertService.alert(this.tr.translation.error, this.tr.translation.serverError);
  //                 }
  //               },
  //                 error =>
  //                 {
  //                   this.alertService.alert(this.tr.translation.error, this.tr.translation.serverError);
  //                 }
  //               )
  //           }
  //         })
  //       }
  //       else {
  //         this.alertService.alert(this.tr.translation.error, this.tr.translation.riskProfile);
  //       }
  //     }
  //   },
  //     error => {
  //       this.alertService.alert(this.tr.translation.error, this.tr.translation.serverError);
  //     }
  //   )
  // }

  onSelectFund(id, risk) {
    if (this.role == 'employer' || this.role == 'emp-maker')
    {
      this.alertService.alertAsk('Confirmation', 'Do you want to select this fund?', 'Yes', 'No', false).then(result => {
        if (result)
        {
          let enc_payload = this.dec_enc.encryption({ fund_id: id });
          this.mainService.postData('funds/allocate', { enc_payload }).then(res => {
            if (res.statusCode == 200)
            {
              this.onCheckAllocatedFund();
              this.alertService.alert(this.tr.translation.success, "Portfolio has been allocated successfully");
            }
            if (res.statusCode == 208)
            {
              this.alertService.alert(this.tr.translation.error, "You have already select a fund");
            }
            if (res.statusCode == 404)
            {
              if (localStorage.getItem('lang') == 'ar')
              {
                  this.alertService.alert('Error', res.error.message_ar);
              }
              else
              {
                  this.alertService.alert('Error', res.error.message);
              }
            }
          },
            error => {
              this.alertService.alert(this.tr.translation.error, this.tr.translation.serverError);
            }
          )
        }
      }) 
    }
    else
    {
      this.alertService.alert(this.tr.translation.error, "You are not allowed to change the portfolio request");
    }
  
  }

  onCheckAllocatedFund() {
    let url = 'funds/allocated'
    this.mainService.postData(url, {}).then(res => {
      if (res.statusCode == 200) {
        let result = this.dec_enc.decryption(res.data);
        console.log('funds/allocated', result);
        this.fund_allocated = result?.employer_fund;
        this.selected_fund_id = result?.employer_fund?.fund_id;
        if (this.fund_allocated == null) {
          this.allocate = true
        }
        else {
          this.allocate = false
        }
      }
    },
      error => {
        this.alertService.alert(this.tr.translation.error, this.tr.translation.serverError);
      }
    )
  }

  onBack() {
    window.history.back();
  }
}

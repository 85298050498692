<div [ngStyle.xs]="{'width': '100%'}" style="width: 400px;">
  <div style="font-size: 24px;text-align: center;color: #111e6c;
    padding-block: 20px; font-weight: bold;">{{alertData.heading | translate}}</div>
  <div *ngIf="hasArabic" [innerHTML]="alertData.message"
    style="font-size: 13px;color: #666666;text-align: center;padding: 0 30px;">
  </div>
  <div *ngIf="!hasArabic" style="font-size: 13px;color: #666666;text-align: center;margin-top: 20px;padding: 0 30px;">
    {{alertData.message | translate}}
  </div>
  <!-- <div *ngIf="showLoading" style="text-align: center">
       <img *ngIf="isLoading"width="50"height="50"src="assets/images/view-loader.gif">
    </div> -->
  <div *ngIf="alertData.hasInput == true" fxLayout="row wrap" fxLayoutAlign="space-evenly start" fxLayoutGap="20px"
    style="text-align: right;margin-top: 20px;">

    <textarea *ngIf="alertData.hasInput == true" [disabled]="isLoading" style="height: 100px; width: 400px;font-size: 12px;
                   background-color: #F7F8F9; border-radius: 10px; padding: 5px; resize: none;"
      [(ngModel)]="resultData.text" [ngStyle]="{'border-color': isError ? 'red' : 'transparent' }">
                   </textarea>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="space-evenly start" fxLayoutGap="20px"
    style="text-align: right; padding-block: 20px;">
    <div fxLayoutGap="20px">
      <button [ngClass]="alertData.negativeButton.class"
      style="background-color: #E9EAEF; color: #3F638F; border-radius: 15px;" mat-button [disabled]="isLoading"
      type="button" (click)="onNegative()">{{alertData.negativeButton.text | translate}}</button>
      <button *ngIf="alertData.neutralButton.visibility" [ngClass]="alertData?.neutralButton?.class"
        style="background-color: #E9EAEF; color: #3F638F; border-radius: 15px;" mat-button [disabled]="isLoading"
        type="button" (click)="onNeutral()">{{alertData?.neutralButton?.text | translate}}</button>
      <button [ngClass]="alertData.positiveButton.class"
        style="background-color: #213060; color: white; border-radius: 15px;" mat-button *ngIf="alertData.type == 'ask'"
        [disabled]="isLoading" type="button"
        (click)="onPositive()">{{alertData.positiveButton.text | translate}}</button>
    </div>
  </div>
</div>

<div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
  <div class="mt-5 card-main">
    <mat-card class="mt-4 mb-1">
      <div class="bg-card-top"></div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="mx-3" style="position: relative;z-index: 9999;">
        <div fxFlex="60">
          <h2 style="color: #3F638F;">{{'Employee Detail' | translate}}</h2>
        </div>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="end center" style="position: relative;z-index: 9999;">
        <div fxFlex="24" fxFlex.sm="49" fxFlex.xs="99" class="mt-10 mb-20">
          <p>{{tr.translation.ID}}</p>
          <h3>{{dashboardData?.employee?.id ? dashboardData?.employee.id : 'N/A'}}</h3>
        </div>
        <div fxFlex="24" fxFlex.sm="49" fxFlex.xs="99" class="mt-10 mb-20">
          <p>{{tr.translation.name}}</p>
          <h3 style="text-transform: capitalize;">{{dashboardData?.employee?.name ? dashboardData?.employee.name : 'N/A'}}</h3>
        </div>
        <div fxFlex="24" fxFlex.sm="49" fxFlex.xs="99" class="mt-10 mb-20">
          <p>{{tr.translation.Phone}}</p>
          <h3>{{dashboardData?.employee?.phone ? dashboardData?.employee?.phone : 'N/A'}}</h3>
        </div>
        <div fxFlex="24" fxFlex.sm="49" fxFlex.xs="99" class="mt-10 mb-20">
          <p>{{tr.translation.Email}}</p>
          <h3>{{dashboardData?.employee?.email ? dashboardData?.employee?.email : 'N/A'}}</h3>
        </div>
      </div>
      <div class="bg-card-bottom"></div>
    </mat-card>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="space-between">
    <app-card fxFlex="24" fxFlex.sm="49" fxFlex.xs="99" imgCustom="assets/new-icons/total-balance.png"
      topText="{{'Total Portfolio (SAR)' | translate}}" [middleTextNumberFour]="dashboardData?.summary?.total_portfolio_balance" [error]="false">
    </app-card>
    <app-card fxFlex="24" fxFlex.sm="49" fxFlex.xs="99" imgCustom="assets/new-icons/total-units.png"
      topText="{{'Total Contributions (SAR)' | translate}}" [middleTextNumberFour]="dashboardData?.summary?.total_contribution" [error]="false">
    </app-card>
    <app-card fxFlex="24" fxFlex.sm="49" fxFlex.xs="99" imgCustom="assets/new-icons/employee-cotribution.png"
      topText="{{'Employee Contribution (SAR)' | translate}}" [middleTextNumberFour]="dashboardData?.summary?.employee_contribution" [error]="false">
    </app-card>
    <app-card fxFlex="24" fxFlex.sm="49" fxFlex.xs="99" imgCustom="assets/new-icons/company-cotribution.png"
      topText="{{'Company Contribution (SAR)' | translate}}" [middleTextNumberFour]="dashboardData?.summary?.company_contribution" [error]="false">
    </app-card>
    <app-card fxFlex="24" fxFlex.sm="49" fxFlex.xs="99" imgCustom="assets/new-icons/total-withdrawals-amount.png"
      topText="{{'Available for Withdrawal (SAR)' | translate }}" [middleTextNumberFour]="dashboardData?.employee_fund?.available" [error]="false">
    </app-card>
    <app-card fxFlex="24" fxFlex.sm="49" fxFlex.xs="99" imgCustom="assets/new-icons/total-withdrawals-amount.png"
      topText="{{'Total Withdrawal Amount (SAR)' | translate}}" [middleTextNumberFour]="dashboardData?.summary?.total_withdrawal" [error]="false">
    </app-card>
    <app-card fxFlex="24" fxFlex.sm="49" fxFlex.xs="99" imgCustom="assets/new-icons/TotalProfit.png"
      topText="{{'Total Profit/Loss (SAR)' | translate}}" [middleTextNumberFour]="dashboardData?.summary?.profit" [error]="false">
    </app-card>
    <div fxFlex="24" fxFlex.sm="49" fxFlex.xs="99"></div>

  </div>
  <div fxLayout="row wrap" fxLayoutAlign="space-between center">

    <!-- pie chart -->
    <mat-card class="mt-20" fxFlex="49" fxFlex.sm="99" fxFlex.xs="99" style="padding: 12px 24px;">
      <div class="bg-card-top"></div>
      <ng-container style="position: relative;z-index: 9999;">
        <div fxLayout="row">
          <h3>{{'Portfolio_Breakdown' | translate}}</h3>
        </div>
        <mat-card style="box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);border-radius: 10px;position: absolute;
                      top:26%;left:8%; width: 24%; padding: 10px;" ngStyle.xs="top:20%">
          <h4 style="color:#213060;">SAR</h4>
          <h2 style="font-weight: 700;font-size: 20px;color:#213060;margin-bottom: 0px;">
            <!-- {{ dashboardData?.portfolio_breakdown?.total_portfolio_balance ? (dashboardData?.portfolio_breakdown?.total_portfolio_balance | number : '1.4-4') : '0.0000' }} -->

            <span class="number">{{ dashboardData?.portfolio_breakdown?.total_portfolio_balance | number:'1.4-4' | slice:0:-4 }}</span> 
            <span style="font-size: 14px;">{{ dashboardData?.portfolio_breakdown?.total_portfolio_balance | number:'1.4-4' | slice:-4 }}</span>
          </h2>
          <p style="font-weight: 500;font-size: 10px;color: #A3AED0;">{{'Total_Portfolio' | translate}}</p>
        </mat-card>
        <div style="margin-left: 34%;height: 24vh; position: relative;z-index: 9999;" ngStyle.sm="margin-left: 40%;height: 13vh;"
          ngStyle.xs="margin-left: 44%;height: 13vh;">
          <canvas baseChart height="100" [chartType]="'pie'" [datasets]="portfolioPieChartData"
            [labels]="portfolioPieChartLabels" [legend]="pieChartLegend">
          </canvas>
        </div>
        <mat-card fxLayoutAlign="space-between" fxLayout="row" fxLayoutAlign.sm="space-around center"
          [fxLayoutGap]='"24"'
          [style]="'padding: 10px 26px; height: 80px;border-radius: 15px;margin-top:4%; box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);'">
          <span>
            <p style="font-size: 10px;color: #A3AED0; margin-bottom: 0px;">{{'Employee Contribution (SAR)' |
                        translate}}
            </p>
            <!-- <h2 style="font-weight: 700;font-size: 18px;">{{ dashboardData?.portfolio_breakdown?.employee_contribution ? (dashboardData?.portfolio_breakdown?.employee_contribution | number : '1.4-4') : '0.0000' }}</h2> -->

            <h2 style="font-weight: 700;font-size: 18px;">
              <span class="number">{{ dashboardData?.portfolio_breakdown?.employee_contribution | number:'1.4-4' | slice:0:-4 }}</span> 
              <span style="font-size: 14px;">{{ dashboardData?.portfolio_breakdown?.employee_contribution | number:'1.4-4' | slice:-4 }}</span>
            </h2>
          </span>
          <mat-divider vertical style="height:40px;" fxFlexAlign="center"></mat-divider>
          <span>
            <p style="font-size: 10px;color: #A3AED0;margin-bottom: 0px;">{{'Company Contribution (SAR)' |
                        translate}}
            </p>
            <!-- <h2 style="font-weight: 700;font-size: 18px;">{{ dashboardData?.portfolio_breakdown?.company_contribution ? (dashboardData?.portfolio_breakdown?.company_contribution | number : '1.4-4') : '0.0000' }}</h2> -->

            <h2 style="font-weight: 700;font-size: 18px;">
              <span class="number">{{ dashboardData?.portfolio_breakdown?.company_contribution | number:'1.4-4' | slice:0:-4 }}</span> 
              <span style="font-size: 14px;">{{ dashboardData?.portfolio_breakdown?.company_contribution | number:'1.4-4' | slice:-4 }}</span>
            </h2>
          </span>
          <mat-divider vertical style="height:40px;" fxFlexAlign="center"></mat-divider>
          <span>
            <p style="font-size: 10px;color: #A3AED0;margin-bottom: 0px;">{{'Profit (SAR)' | translate}}</p>
            <!-- <h2 style="font-weight: 700;font-size: 18px;">{{ dashboardData?.portfolio_breakdown?.profit ? (dashboardData?.portfolio_breakdown?.profit | number : '1.4-4') : '0.0000' }}</h2> -->

            <h2 style="font-weight: 700;font-size: 18px;">
              <span class="number">{{ dashboardData?.portfolio_breakdown?.profit | number:'1.4-4' | slice:0:-4 }}</span> 
              <span style="font-size: 14px;">{{ dashboardData?.portfolio_breakdown?.profit | number:'1.4-4' | slice:-4 }}</span>
            </h2>
          </span>
        </mat-card>
      </ng-container>
    </mat-card>
    <!-- pie chart end -->

    <!-- pie chart -->
    <mat-card class="mt-20" fxFlex="49" fxFlex.sm="99" fxFlex.xs="99" style="padding: 12px 24px;">
      <div class="bg-card-top"></div>
      <ng-container style="position: relative;z-index: 9999;">
        <div fxLayout="row">
          <h3>{{'Vesting Breakdown' | translate}}</h3>
        </div>
        <mat-card style="box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);border-radius: 10px;position: absolute;
                      top:26%;left:8%; width: 24%; padding: 10px;" ngStyle.xs="top:20%">
          <h4 style="color:#213060;">SAR</h4>
          <!-- <h2 style="font-weight: 700;font-size: 20px;color:#213060;margin-bottom: 0px;">{{ dashboardData?.vest_breakdown?.total_portfolio_balance ? (dashboardData?.vest_breakdown?.total_portfolio_balance | number : '1.4-4') : '0.0000' }}</h2> -->

          <h2 style="font-weight: 700;font-size: 20px;color:#213060;margin-bottom: 0px;">
            <span class="number">{{ dashboardData?.vest_breakdown?.total_portfolio_balance | number:'1.4-4' | slice:0:-4 }}</span> 
            <span style="font-size: 14px;">{{ dashboardData?.vest_breakdown?.total_portfolio_balance | number:'1.4-4' | slice:-4 }}</span>
          </h2>
          <p style="font-weight: 500;font-size: 10px;color: #A3AED0;">{{'Total_Portfolio' | translate}}</p>
        </mat-card>
        <div style="margin-left: 34%;height: 24vh; position: relative;z-index: 9999;" ngStyle.sm="margin-left: 40%;height: 13vh;"
          ngStyle.xs="margin-left: 44%;height: 13vh;">
          <canvas baseChart height="100" [chartType]="'pie'" [datasets]="vestingPieChartData"
            [labels]="vestingPieChartLabels" [legend]="pieChartLegend">
          </canvas>
        </div>
        <mat-card fxLayoutAlign="space-around" fxLayout="row" fxLayoutAlign.sm="space-around center"
          [fxLayoutGap]='"24"'
          [style]="'padding: 12px 24px; height: 80px;border-radius: 15px;margin-top:4%; box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);'">
          <span>
            <p style="font-size: 10px;color: #A3AED0; margin-bottom: 0px;">{{'Vested (SAR)' | translate}}
            </p>
            <!-- <h2 style="font-weight: 700;font-size: 18px;">{{ dashboardData?.vest_breakdown?.vested ? (dashboardData?.vest_breakdown?.vested | number : '1.4-4') : '0.0000' }}</h2> -->

            <h2 style="font-weight: 700;font-size: 18px;">
              <span class="number">{{ dashboardData?.vest_breakdown?.vested | number:'1.4-4' | slice:0:-4 }}</span> 
              <span style="font-size: 14px;">{{ dashboardData?.vest_breakdown?.vested | number:'1.4-4' | slice:-4 }}</span>
            </h2>
          </span>
          <mat-divider vertical style="height:40px;" fxFlexAlign="center"></mat-divider>
          <span>
            <p style="font-size: 10px;color: #A3AED0;margin-bottom: 0px;">{{'Unvested (SAR)' | translate}}
            </p>
            <!-- <h2 style="font-weight: 700;font-size: 18px;">{{ dashboardData?.vest_breakdown?.unvested ? (dashboardData?.vest_breakdown?.unvested | number : '1.4-4') : '0.0000' }}</h2> -->

            <h2 style="font-weight: 700;font-size: 18px;">
              <span class="number">{{ dashboardData?.vest_breakdown?.unvested | number:'1.4-4' | slice:0:-4 }}</span> 
              <span style="font-size: 14px;">{{ dashboardData?.vest_breakdown?.unvested | number:'1.4-4' | slice:-4 }}</span>
            </h2>
          </span>
        </mat-card>
      </ng-container>
    </mat-card>
    <!-- pie chart end -->

  </div>
  <div class="card-main mt-20">
    <mat-card class="mt-4 mb-4">
      <div class="bg-card-top"></div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="mx-3">
        <div fxFlex="60">
          <h2 style="color: #3F638F;">{{tr.translation.Employee_Contribution_Detail}}</h2>
        </div>
      </div>

      <table class="table table-hover table-borderless mt-3" style="position: relative;z-index: 9999;">
        <tbody>
          <tr>

            <td>
              <p>{{tr.translation.Fund_Name}}</p>
              <h3 style="text-transform: capitalize;">{{dashboardData?.employee_fund ? dashboardData?.employee_fund.portfolio :
                                'N/A'}}</h3>
            </td>
            <td>
              <p>{{'Risk_Type' | translate}}</p>
              <h3 *ngIf="dashboardData?.employee_fund?.risk_type=='low'" style="color: #00C435;">
                {{tr.translation.low}}</h3>
              <h3 *ngIf="dashboardData?.employee_fund?.risk_type=='medium'" style="color: #E69A5A;">
                {{tr.translation.medium}}</h3>
              <h3 *ngIf="dashboardData?.employee_fund?.risk_type=='high'" style="color: #EC4955;">
                {{tr.translation.high}}</h3>
              <h3 *ngIf="!dashboardData?.employee_fund?.risk_type">N/A</h3>
            </td>
            <td>
              <p>{{tr.translation.Investment_Bank}}</p>
              <h3>{{dashboardData?.employee_fund ? dashboardData?.employee_fund?.bank_name : 'N/A'}}</h3>
            </td>
            <td>
              <p>{{'Invested Amount (SAR)' | translate}}</p>
              <h3>
                <!-- {{ dashboardData?.employee_fund?.invested_amount ? (dashboardData?.employee_fund?.invested_amount | number : '1.4-4') : '0.0000' }} -->

                <span class="number">{{ dashboardData?.employee_fund?.invested_amount | number:'1.4-4' | slice:0:-4 }}</span> 
                <span style="font-size: 14px;">{{ dashboardData?.employee_fund?.invested_amount | number:'1.4-4' | slice:-4 }}</span>
              </h3>

              
            </td>
            <td>
              <p>{{'Total Profit/Loss (SAR)' | translate}}</p>
              <h3 *ngIf="dashboardData?.employee_fund?.profit > 0" style="color: #00C435;"> 
                <!-- {{dashboardData?.employee_fund?.profit ? (dashboardData?.employee_fund?.profit | number: '1.4-4') : '0.0000' }} -->

                <span class="number">{{ dashboardData?.employee_fund?.profit | number:'1.4-4' | slice:0:-4 }}</span> 
                <span style="font-size: 14px;">{{ dashboardData?.employee_fund?.profit | number:'1.4-4' | slice:-4 }}</span>
              </h3>
              <h3 *ngIf="dashboardData?.employee_fund?.profit <= 0" style="color: red;">
                <!-- {{dashboardData?.employee_fund?.profit ? (dashboardData?.employee_fund?.profit | number: '1.4-4') : '0.0000' }} -->
              
                <span class="number">{{ dashboardData?.employee_fund?.profit | number:'1.4-4' | slice:0:-4 }}</span> 
                <span style="font-size: 14px;">{{ dashboardData?.employee_fund?.profit | number:'1.4-4' | slice:-4 }}</span>
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p>{{'Employee_Unit' | translate}}</p>
              <h3>
                <!-- {{ dashboardData?.employee_fund?.available_units ? (dashboardData?.employee_fund?.available_units | number : '1.5-5') : '0.00000' }} -->
              
                <span class="number">{{ dashboardData?.employee_fund?.available_units | number:'1.4-4' | slice:0:-4 }}</span> 
                <span style="font-size: 14px;">{{ dashboardData?.employee_fund?.available_units | number:'1.4-4' | slice:-4 }}</span>
              </h3>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="bg-card-bottom"></div>
    </mat-card>

    <mat-card class="mt-4 mb-4">
      <div class="bg-card-top"></div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="mx-3" style="position: relative;z-index: 9999;">
        <div fxFlex="60">
          <h2 style="color: #3F638F;">{{tr.translation.Employer_Contribution_Detail}}</h2>
        </div>
      </div>


      <table class="table table-hover table-borderless mt-3" style="position: relative;z-index: 9999;">
        <tbody>
          <tr>

            <td>
              <p>{{tr.translation.Fund_Name}}</p>
              <h3 style="text-transform: capitalize;">{{dashboardData?.company_fund ? dashboardData?.company_fund?.portfolio
                                : 'N/A'}}</h3>
            </td>
            <td>
              <p>{{tr.translation.Risk_Type}}</p>
              <h3 *ngIf="dashboardData?.company_fund?.risk_type=='low'" style="color: #00C435;">
                {{tr.translation.low}}</h3>
              <h3 *ngIf="dashboardData?.company_fund?.risk_type=='medium'" style="color: #E69A5A;">
                {{tr.translation.medium}}</h3>
              <h3 *ngIf="dashboardData?.company_fund?.risk_type=='high'" style="color: #EC4955;">
                {{tr.translation.high}}</h3>
              <h3 *ngIf="!dashboardData?.company_fund?.risk_type">N/A</h3>
            </td>
            <td>
              <p>{{tr.translation.Investment_Bank}}</p>
              <h3>{{dashboardData?.company_fund ? dashboardData?.company_fund?.bank_name : 'N/A'}}</h3>
            </td>
            <td>
              <p>{{'Invested Amount (SAR)' | translate}}</p>
              <h3>
                <!-- {{ dashboardData?.company_fund?.invested_amount ? (dashboardData?.company_fund?.invested_amount | number : '1.4-4') : '0.00' }} -->

                <span class="number">{{ dashboardData?.company_fund?.invested_amount | number:'1.4-4' | slice:0:-4 }}</span> 
                <span style="font-size: 14px;">{{ dashboardData?.company_fund?.invested_amount | number:'1.4-4' | slice:-4 }}</span>
              </h3>
            </td>
            <td>
              <p>{{'Total Profit/Loss (SAR)' | translate}}</p>
              <h3 *ngIf="dashboardData?.company_fund?.profit  > 0" style="color: #00C435;">
                <!-- {{dashboardData?.company_fund?.profit ? (dashboardData?.company_fund?.profit | number: '1.4-4') : '0.0000'}} -->

                <span class="number">{{ dashboardData?.company_fund?.profit | number:'1.4-4' | slice:0:-4 }}</span> 
                <span style="font-size: 14px;">{{ dashboardData?.company_fund?.profit | number:'1.4-4' | slice:-4 }}</span>
              </h3>
              <h3 *ngIf="dashboardData?.company_fund?.profit  <= 0" style="color: red;">
                <!-- {{dashboardData?.company_fund?.profit ? (dashboardData?.company_fund?.profit | number: '1.4-4') : '0.0000'}} -->

                <span class="number">{{ dashboardData?.company_fund?.profit | number:'1.4-4' | slice:0:-4 }}</span> 
                <span style="font-size: 14px;">{{ dashboardData?.company_fund?.profit | number:'1.4-4' | slice:-4 }}</span>
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p>{{'Company_Unit' | translate}}</p>
              <h3>
                <!-- {{ dashboardData?.company_fund?.available_units ? (dashboardData?.company_fund?.available_units | number : '1.5-5') : '0.00000' }} -->

                <span class="number">{{ dashboardData?.company_fund?.available_units | number:'1.5-5' | slice:0:-5 }}</span> 
                <span style="font-size: 14px;">{{ dashboardData?.company_fund?.available_units | number:'1.5-5' | slice:-5 }}</span>
              </h3>
              
            </td>
            <td>
              <p>{{tr.translation.vestd}}</p>
              <h3>
                <!-- {{ dashboardData?.company_fund?.vested ? (dashboardData?.company_fund?.vested | number : '1.4-4') : '0.0000' }} -->

                <span class="number">{{ dashboardData?.company_fund?.vested | number:'1.4-4' | slice:0:-4 }}</span> 
                <span style="font-size: 14px;">{{ dashboardData?.company_fund?.vested | number:'1.4-4' | slice:-4 }}</span>
              </h3>
            </td>
            <td>
              <p>{{tr.translation.unves}}</p>
              <h3>
                <!-- {{ dashboardData?.company_fund?.unvested ? (dashboardData?.company_fund?.unvested | number : '1.4-4') : '0.0000' }} -->

                <span class="number">{{ dashboardData?.company_fund?.unvested | number:'1.4-4' | slice:0:-4 }}</span> 
                <span style="font-size: 14px;">{{ dashboardData?.company_fund?.unvested | number:'1.4-4' | slice:-4 }}</span>
              </h3>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="bg-card-bottom"></div>
    </mat-card>


<div
fxLayout="column"
fxLayoutGap="14"
class="mrl-20"
ngClass.sm="mrl-10"
ngClass.xs="mrl-10"
>
<div
  fxLayout="row wrap"
  fxLayout.sm="column"
  fxLayout.xs="column"
  fxLayoutGap="20"
  fxLayoutAlign="space-between"
>
  <mat-card
    class="mt-20"
    fxFlex="49"
    fxFlex.sm="98"
    fxFlex.xs="94"
    fxFlexOffset.xs="3"
    ngStyle.sm="margin-bottom:20px;"
    ngStyle.xs="margin-bottom:14px;"
  >
    <div class="bg-card-top"></div>
    <div fxLayout="row">
      <h3>{{'Employee Portfolio Detail' | translate}}</h3>
    </div>
    <div *ngIf="dashboardData">
      <app-table
        
        [dataSource]="dashboardData?.employee_assets"
        [tableProps]="employeeTableProps"
      >
      </app-table>
    </div>
    <div class="bg-card-bottom"></div>
  </mat-card>

  <mat-card
    class="mt-20"
    fxFlex="49"
    fxFlex.sm="98"
    fxFlex.xs="94"
    fxFlexOffset.xs="3"
    ngStyle.sm="margin-bottom:20px;"
    ngStyle.xs="margin-bottom:14px;"
  >
    <div class="bg-card-top"></div>
    <div fxLayout="row">
      <h3>{{'Company Portfolio Detail' | translate}}</h3>
    </div>
    <div  *ngIf="dashboardData">
      <app-table
        
        [dataSource]="dashboardData?.company_assets"
        [tableProps]="companyTableProps"
      >
      </app-table>
  </div>
    <div class="bg-card-bottom"></div>
  </mat-card>
</div>
</div>

    <mat-card class="mt-4 mb-4">
      <div class="bg-card-top"></div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="mx-3">
        <div fxFlex="60">
          <h2 style="color: #3F638F;">{{tr.translation.Transfer_to_Vested_Account}}</h2>
        </div>
      </div>
      <div class="mx-3" style="position: relative;z-index: 9999;">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20">
          <div class="mt-10" fxFlex="19" fxFlex.sm="99" fxFlex.xs="99">
            <p>{{'Total_vested_Amount' | translate}}</p>
            <h3 style="margin-top: -10px;">
              <!-- {{dashboardData?.company_fund?.vested_amount_percent ? (dashboardData?.company_fund?.vested_amount_percent | number: '1.2-2') : '0.00'}} -->

              <span class="number">{{ dashboardData?.company_fund?.vested_amount_percent | number:'1.2-2' | slice:0:-2 }}</span> 
              <span style="font-size: 14px;">{{ dashboardData?.company_fund?.vested_amount_percent | number:'1.2-2' | slice:-2 }}</span>
            </h3>
          </div>
          <div fxFlex="2"></div>
          <div class="mt-10" fxFlex="19" fxFlex.sm="99" fxFlex.xs="99">
            <p>{{'Total_Unvested_Amount' | translate}}</p>
            <h3 style="margin-top: -10px;">
              <!-- {{dashboardData?.company_fund?.unvested_amount_percent ? (dashboardData?.company_fund?.unvested_amount_percent | number: '1.2-2') : '0.00'}} -->

              <span class="number">{{ dashboardData?.company_fund?.unvested_amount_percent | number:'1.2-2' | slice:0:-2 }}</span> 
              <span style="font-size: 14px;">{{ dashboardData?.company_fund?.unvested_amount_percent | number:'1.2-2' | slice:-2 }}</span>
            </h3>
          </div>
        </div>
        <form [formGroup]="Form" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20">
          <mat-form-field fxFlex="19" fxFlex.sm="99" fxFlex.xs="99" class="input-field mt-20" appearance="outline">
            <mat-label>{{'Enter_Amount' | translate}} (0 - 100 %)</mat-label>
            <input class="input-field" matInput type="text" mask="999" formControlName="vested_amount_percent" />
          </mat-form-field>
          <div fxFlex="2"></div>
          <button *ngIf="!hideButton" fxFlex="22" fxFlex.sm="99" fxFlex.xs="99" (click)="onSubmit()" class="btn-submit mt-20"
            mat-raised-button>
            {{'Transfer_to_Vested_Account' | translate}}
          </button>
        </form>
      </div>
      <div class="bg-card-bottom"></div>
    </mat-card>
    <div>
      <button (click)="back()" style="margin-bottom: 20px; background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
        mat-raised-button>{{'Back' | translate}}</button>
    </div>
  </div>

</div>

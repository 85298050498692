import { Component, OnInit } from '@angular/core';
import { AlertDialog } from '../alert-dialog/alert.dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-on-board-complete-dialog',
  templateUrl: './on-board-complete-dialog.component.html',
  styleUrls: ['./on-board-complete-dialog.component.scss']
})
export class OnBoardCompleteDialogComponent implements OnInit {

  constructor(protected dialogRef: MatDialogRef<AlertDialog>, protected alertService: AlertService)
  { }

  ngOnInit(): void
  {
  }

  onGetStarted() {
    let data = {
      type: "complete",
      data: null
    }
    this.dialogRef.close(data);
  }
}

<div class="main" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">

    <div fxLayout="column" fxFlex="59">
        <img src="assets/back-img.png" alt="Back Ground Image" class="img" ngClass.sm="img-sm" ngClass.xs="img-xs">
        <span class="back-img" ngClass.sm="back-img-sm" ngClass.xs="back-img-xs" fxLayoutAlign="center">
            <span fxLayout="column" fxFlexOffset="8" fxFlexOffset.sm="0" fxLayoutGap="50">
                <h1>{{'Welcome to the future of employee benefits' | translate}}</h1>
            </span>
        </span>
    </div>

    <div class="login-form" ngClass.sm="login-form-sm" ngClass.xs="login-form-xs" fxLayout="column"
        fxLayoutAlign="center center" style="background: #F7F8F9;">

        <img [src]="logoImage" [alt]="logoImage == 'assets/logos/alpha_logo.png' ? 'Alpha Capital Logo' : 'Thriftplan Logo'" width="200px" fxFlexOffset="-8" fxFlexOffset.sm="0" fxFlexOffset.xs="0">
        <h1 fxFlexOffset="3" style="font-weight: 600; font-size: 25px; margin-bottom: 0;">{{'Login as Employer' | translate}}</h1>
        <h6 style="margin-top: 5px;" *ngIf="showOtpComponent">{{'Please enter your 6 digit code here to login' | translate}}</h6>
        <h6 style="margin-top: 5px;" *ngIf="!showOtpComponent">{{'Enter your details to get started' | translate}}</h6>

        <form fxFlexOffset="-2" ngClass.xs="form-xs" ngClass.sm="form-sm" *ngIf="showCredentials" [formGroup]="Form" fxLayout="column">

            <mat-form-field fxFlexOffset="8" style="width: 100%;" class="input-field" fxFlexAlign="center" appearance="outline">
                <mat-label>{{'Username' | translate}}</mat-label>
                <input style="font-weight: bold;" autocomplete="off" type="text" matInput formControlName="adminUsername">
                <mat-icon *ngIf="getFieldValue('adminUsername')"
                    #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('adminUsername', 'Username_Required')"
                    [matTooltipDisabled]="!getFieldValue('adminUsername')"
                    matTooltipPosition="above"
                    style="color: #213060;" matSuffix>info
                </mat-icon>
            </mat-form-field>

            <mat-form-field style="margin-block: 10px; width: 100%;" class="input-field" fxFlexAlign="center" appearance="outline">
                <mat-label>{{'Password' | translate}}</mat-label>
                <input style="font-weight: bold;" autocomplete="off" [type]="passwordType" matInput formControlName="adminPassword">
                <mat-icon matTooltip="{{'Show Password' | translate}}" *ngIf="Form.get('adminPassword').value && passwordType == 'password'" style="cursor: pointer;" (click)="handlePassword()" matSuffix>visibility</mat-icon>
                <mat-icon matTooltip="{{'Hide Password' | translate}}" *ngIf="Form.get('adminPassword').value && passwordType == 'text'" style="cursor: pointer;" (click)="handlePassword()" matSuffix>visibility_off</mat-icon>
                <mat-icon *ngIf="getFieldValue('adminPassword')"
                    #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('adminPassword', 'Password_Required')"
                    [matTooltipDisabled]="!getFieldValue('adminPassword')"
                    matTooltipPosition="above"
                    style="color: #213060;" matSuffix>info
                </mat-icon>
            </mat-form-field>

            <div style="display: flex; flex-direction: column-reverse;">
                <div fxFlexOffset="5" style="width: 100%;" fxLayout="row" fxLayoutAlign="end">
                    <button style="width: 120px; font-weight: bold;" (click)="login()" class="btnLogin"
                        mat-raised-button>{{'Login' | translate}}<mat-icon>{{isEnglish == 'ltr' ? 'arrow_forward' : 'arrow_back'}}</mat-icon>
                    </button>
                </div>
                <div style="width: 100%;" fxFlexAlign="space-between"
                    fxLayoutAlign="start baseline">
                    <!-- <mat-checkbox style="margin-left: 10px;">Remember me</mat-checkbox> -->
                    <p (click)="navigateToForgot()" mat-button
                        style="padding: 0; border: none; outline: none; color: #213060; font-size: 11px; font-weight: bold; margin-right: 10px; cursor: pointer;">
                        {{'ForgotPassword' | translate}}
                    </p>
                </div>
            </div>

            <div style="display: flex; flex-direction: column-reverse;">
              <div style="width: 100%;" fxFlexAlign="space-between"
                  fxLayoutAlign="end baseline">
                  <!-- <mat-checkbox style="margin-left: 10px;">Remember me</mat-checkbox> -->
                  <p (click)="navigateToRegister()" mat-button
                      style="padding: 0; border: none; outline: none; color: #213060; font-size: 11px; font-weight: 500; margin:20px 10px 0 0; cursor: pointer;">
                      {{'Do not have an account?' | translate}} <b style="font-weight: 700;">{{'Register now!' | translate}}</b>
                  </p>
              </div>
          </div>

        </form>
        <ng-container *ngIf="showOtpComponent">
            <ng-otp-input #ngOtpInput dir="ltr"
                (onInputChange)="onOtpChange($event)"
                [config]="config">
            </ng-otp-input>
            <div style="margin-top: 10px;">{{timerValue}}</div>
            <button (click)="resendOTP()" mat-button
                style="padding: 0; border: none; outline: none;">
                {{'ResendOTP' | translate}}
            </button>
        </ng-container>
        <div class="footer" ngClass.sm="footer-sm" ngClass.xs="footer-xs" fxLayoutAlign="space-between baseline">
            <p>© {{'2023 Thriftplan. All Rights Reserved' | translate}}</p>
        </div>
    </div>
</div>

<main style="display: flex; flex-direction: column; align-items: center; text-align: center;">
    <div style="display: flex; justify-content: flex-end; width: 100%;">
        <mat-icon (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
    </div>
    <h1 style="color: #213060; font-weight: bold; margin: 0 0 10px;">{{'Congratulations!' | translate}}</h1>
    <h3 style="color: #213060; font-weight: bold;">{{'You are qualified for a welcome gift!' | translate}}</h3>
    <p style="color: #707EAE; font-size: 13px;">{{'We appreciate your trust in us, and we look forward to a long and fruitful partnership with you.' | translate}}</p>
    <p style="font-weight: 700; color: #707EAE;">{{'To help you get started, we would like to present you with free credit on our onboarding packages, after onboarding your first employees!' | translate}}</p>
    <div style="display: flex;  gap: 5px; height: 42px;" dir="ltr">
        <div style="display: flex; flex-direction: column; justify-content: flex-end; height: 100% !important;">
            <h1 style="margin-bottom: 0; color: #7AB4A6;">SAR</h1>
        </div>
        <h1 style="margin-bottom: 0; color: #7AB4A6; font-size: 50px; font-weight: bold;">2,000</h1>
    </div>
    <div>
        <h1 style="color: #213060; font-weight: 700; font-size: 42px; margin-top: 10px;">{{'CREDITS' | translate}}</h1>
    </div>
    <button (click)="onInvite()" class="btn-submit" style="margin-bottom: 16px;" mat-raised-button>{{'Invite Employees' | translate}}</button>
    <p style="margin-top: 20px; margin-bottom: -10px; color: #9E9E9E; font-size: 12px;">{{'*You must claim this offer from 04/09/2023 and use before 1/10/2023.' | translate}}</p>
</main>
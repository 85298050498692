<!-- <div class="card-set px-3">
  <div fxLayout="row"
       fxLayoutAlign="space-between stretch">
    <div fxFlex="50">
      <h2>View Subscription Form</h2>
    </div>
  </div>

  <ng-container *ngFor="let item of fromFund; let index = i">
    <div>
      <h2 style="color: #000;">From {{item?.from_fund?.name}}</h2>
    </div>

    <mat-card class="my-3"
              *ngFor="let lis of item?.from_fund?.assets; let odd=odd; let even=even "
              [ngClass]="{ odd: odd, even: even}">

      <div fxLayout="row wrap"
           fxLayoutAlign="space-between center">
        <div fxFlex="8">
          <mat-label>Fund ID</mat-label>
          <p>{{lis.id}}</p>
        </div>
        <div fxFlex="14">
          <mat-label>Fund Name</mat-label>
          <p>{{lis.asset_name}}</p>
        </div>
        <div fxFlex="10">
          <mat-label>Valuation Date</mat-label>
          <p>{{lis.valuation_date}}</p>
        </div>
        <div fxFlex="8">
          <mat-label>Currency</mat-label>
          <p style="text-transform:uppercase;">{{lis.currency}}</p>
        </div>
        <div fxFlex="10">
          <mat-label>Available Units</mat-label>
          <p>{{tr.numberFormatter5(lis.available_units)}}</p>
        </div>
        <mat-form-field fxFlex="14">
          <mat-label>NAV Per Unit (SAR)</mat-label>
          <input matInput
                 type="text"
                 (focusout)="updateAsset(lis)"
                 [(ngModel)]="lis.nav_per_unit"
                 mask="separator.2" />
        </mat-form-field>
        <div fxFlex="16">
          <mat-label>Total Amount (SAR)</mat-label>
          <p>{{lis.amount_sar | number:'1.2-2'}}</p>
        </div>
      </div>
    </mat-card>
    <div *ngIf="item?.from_fund?.assets.length == 0"
         class="text-center">
      <h3>Assets Not Found</h3>
    </div>
  </ng-container>

  <ng-container *ngFor="let item of toFund; let index = i">
    <div>
      <h2 style="color: #000;">To {{item?.fund?.name}}</h2>
    </div>

    <mat-card class="my-3"
              *ngFor="let lis of item?.fund?.assets; let odd=odd; let even=even "
              [ngClass]="{ odd: odd, even: even}">

      <div fxLayout="row wrap"
           fxLayoutAlign="space-between center">
        <div fxFlex="8">
          <mat-label>Fund ID</mat-label>
          <p>{{lis.id}}</p>
        </div>
        <div fxFlex="14">
          <mat-label>Fund Name</mat-label>
          <p>{{lis.asset_name}}</p>
        </div>
        <div fxFlex="10">
          <mat-label>Valuation Date</mat-label>
          <p>{{lis.valuation_date}}</p>
        </div>
        <div fxFlex="8">
          <mat-label>Currency</mat-label>
          <p style="text-transform:uppercase;">{{lis.currency}}</p>
        </div>
        <mat-form-field fxFlex="10">
          <mat-label>NAV Per Unit (SAR)</mat-label>
          <input matInput
                 type="text"
                 (focusout)="updateAsset1(lis)"
                 [(ngModel)]="lis.nav_per_unit"
                 mask="separator.2" />
        </mat-form-field>
        <mat-form-field fxFlex="14">
          <mat-label style="width: 100%;">Total Amount (SAR)</mat-label>
          <input matInput
                 type="text"
                 (focusout)="updateAsset1(lis)"
                 [(ngModel)]="lis.amount_sar"
                 mask="separator.2" />
        </mat-form-field>
        <div fxFlex="16">
          <mat-label>Total No. Units</mat-label>
          <p>{{lis.no_of_units | number:'1.5-5'}}</p>
        </div>
      </div>


    </mat-card>

    <div *ngIf="item?.fund?.assets.length == 0"
         class="text-center">
      <h3>Assets Not Found</h3>
    </div>
  </ng-container>
  <button id="button"
          class="btn mr-2"
          (click)="dialogClose()">Cancel</button>
</div> -->



<h2 style="color: #213060; font-weight: bold;">{{'View_Subscription_Form' | translate}}</h2>
<ng-container *ngFor="let item of fromFund; let index = i">
    <div>
      <h2 style="color: #213060; font-weight: bold;">From {{item?.from_fund?.name}}</h2>
    </div>

    <ng-container *ngFor="let lis of item?.from_fund?.assets; let odd=odd; let even=even">
        <div fxLayout="row" fxLayoutGap="10px">
            <p style="font-weight: bold;" fxFlex="13">{{'Fund_ID' | translate}}</p>
            <p style="font-weight: bold;" fxFlex="13">{{'Fund_Name' | translate}}</p>
            <p style="font-weight: bold;" fxFlex="13">{{'Valuation_Date' | translate}}</p>
            <p style="font-weight: bold;" fxFlex="13">{{'Currency' | translate}}</p>
            <p style="font-weight: bold;" fxFlex="13">{{'Total_number_of_units' | translate}}</p>
            <p style="font-weight: bold;" fxFlex="13">{{'NAV_Per_Unit' | translate}}</p>
            <p style="font-weight: bold;" fxFlex="13">{{'total_amount' | translate}}</p>
        </div>

        <mat-divider></mat-divider>

        <div fxLayoutGap="10px">
            <mat-form-field fxFlex="13" appearance="legacy">
                <input readonly matInput type="text" autocomplete="off" [(ngModel)]="lis.id">
            </mat-form-field>
            <mat-form-field fxFlex="13" appearance="legacy">
                <input readonly matInput type="text" autocomplete="off" [(ngModel)]="lis.asset_name">
            </mat-form-field>
            <mat-form-field fxFlex="13" appearance="legacy">
                <input readonly matInput type="text" autocomplete="off" [(ngModel)]="lis.valuation_date">
            </mat-form-field>
            <mat-form-field fxFlex="13" appearance="legacy">
                <input readonly matInput type="text" autocomplete="off" [(ngModel)]="lis.currency">
            </mat-form-field>
            <mat-form-field fxFlex="13" appearance="legacy">
                <input readonly matInput type="text" autocomplete="off" [(ngModel)]="lis.available_units">
            </mat-form-field>
            <mat-form-field fxFlex="13" appearance="legacy">
                <input readonly matInput type="text" autocomplete="off" [(ngModel)]="lis.nav_per_unit">
            </mat-form-field>
            <mat-form-field fxFlex="13" appearance="legacy">
                <input readonly matInput type="text" autocomplete="off" [(ngModel)]="lis.amount_sar">
            </mat-form-field>
        </div>
    </ng-container>
    <div *ngIf="item?.from_fund?.assets.length == 0" class="text-center">
      <h3 style="color: #213060; font-weight: bold;">Assets Not Found</h3>
    </div>
</ng-container>

<ng-container *ngFor="let item of toFund; let index = i">
    <div style="margin-top: 20px;">
      <h2 style="color: #000;">To {{item?.fund?.name}}</h2>
    </div>

    <ng-container *ngFor="let lis of item?.fund?.assets; let odd=odd; let even=even">
        <div style="margin-top: 10px;" fxLayout="row" fxLayoutGap="10px">
            <p style="font-weight: bold;" fxFlex="13">{{'Fund_ID' | translate}}</p>
            <p style="font-weight: bold;" fxFlex="13">{{'Fund_Name' | translate}}</p>
            <p style="font-weight: bold;" fxFlex="13">{{'Valuation_Date' | translate}}</p>
            <p style="font-weight: bold;" fxFlex="13">{{'Currency' | translate}}</p>
            <p style="font-weight: bold;" fxFlex="13">{{'NAV_Per_Unit' | translate}}</p>
            <p style="font-weight: bold;" fxFlex="13">{{'total_amount' | translate}}</p>
            <p style="font-weight: bold;" fxFlex="13">{{'Total_number_of_units' | translate}}</p>
        </div>

        <mat-divider></mat-divider>

        <div fxLayoutGap="10px">
            <mat-form-field fxFlex="13" appearance="legacy">
                <input readonly matInput type="text" autocomplete="off" [(ngModel)]="lis.id">
            </mat-form-field>
            <mat-form-field fxFlex="13" appearance="legacy">
                <input readonly matInput type="text" autocomplete="off" [(ngModel)]="lis.asset_name">
            </mat-form-field>
            <mat-form-field fxFlex="13" appearance="legacy">
                <input readonly matInput type="text" autocomplete="off" [(ngModel)]="lis.valuation_date">
            </mat-form-field>
            <mat-form-field fxFlex="13" appearance="legacy">
                <input readonly matInput type="text" autocomplete="off" [(ngModel)]="lis.currency">
            </mat-form-field>
            <mat-form-field fxFlex="13" appearance="legacy">
                <input readonly matInput type="text" autocomplete="off" [(ngModel)]="lis.nav_per_unit">
            </mat-form-field>
            <mat-form-field fxFlex="13" appearance="legacy">
                <input readonly matInput type="text" autocomplete="off" [(ngModel)]="lis.amount_sar">
            </mat-form-field>
            <mat-form-field fxFlex="13" appearance="legacy">
                <input readonly matInput type="text" autocomplete="off" [(ngModel)]="lis.no_of_units">
            </mat-form-field>
        </div>
    </ng-container>
    <div *ngIf="item?.fund?.assets.length == 0" class="text-center">
        <h3 style="color: #213060; font-weight: bold;">Assets Not Found</h3>
      </div>
</ng-container>

<div fxLayoutAlign="end center" style="font-size: 12px; margin-top: 10px;">
    <button (click)="dialogClose()" style="background-color: #E9EAEF; color: #213060; line-height: 28px; padding: 0 25px; outline: none;" mat-raised-button>Cancel</button>
</div>
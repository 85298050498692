<div class="main pt-1 mb-30" ngClass.sm="mrl-10" ngClass.xs="mrl-10"
    style="max-height: 100vh; overflow-y: auto; padding: 10px;">
    <div style="display: flex; justify-content: space-between; align-items: center; align-content: center;">
        <h4 style="text-align: center; font-size: 20px; font-weight: bold; color: #213060; flex: 1;">
            {{'Instruction Summary' | translate}}
        </h4>
        <button class="btn-submit" mat-raised-button (click)="print()">
            <img src="assets/printer.svg" alt=""> {{'Print' | translate}}
        </button>
    </div>

    <form [formGroup]="summaryForm" fxLayout="column" fxLayoutAlign="center start"
        style="width: 100%; margin-top: 30px;">
        <div fxLayout="row" fxLayoutAlign="center start" style="width: 100%;">
            <div fxFlex="50" fxLayout="column" fxLayoutAlign="start start">
                <mat-form-field fxFlex="23.3" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                    fxFlexAlign="center" appearance="outline">
                    <mat-label>{{'Date' | translate}}</mat-label>
                    <input matInput type="text" formControlName="date" [matDatepicker]="picker1" [min]="currentDate" />
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <mat-form-field fxFlex="100" style="width: 100%;" class="input-field mt-10" appearance="outline">
                    <mat-label>{{'IP Address' | translate}}</mat-label>
                    <input autocomplete="off" readonly type="text" matInput formControlName="ip_address">
                </mat-form-field>
                <mat-form-field fxFlex="100" style="width: 100%;" class="input-field mt-10" appearance="outline">
                    <mat-label>{{'User Name' | translate}}</mat-label>
                    <input autocomplete="off" readonly type="text" matInput formControlName="user_name">
                </mat-form-field>
            </div>

            <div fxFlex="50" fxLayout="column" fxLayoutAlign="start start"
                style="padding-left: 10px; padding-right: 10px;">
                <app-card style="border: 1px solid #EEEEEE; border-radius: 10px; width: 100%;" fxFlex="100"
                    imgCustom="assets/new-icons/total-balance.png"
                    topNumberText="{{'Total amount requested for redemption' | translate}}"
                    [middleNumberValue]="summary.total_withdraw" bottomText="" [error]="false">
                </app-card>
                <div fxLayout="row" fxLayoutAlign="start start" style="display: flex; gap: 10px; margin-top: 10px;">
                    <app-card style="border: 1px solid #EEEEEE; border-radius: 10px; flex: 1;"
                        imgCustom="assets/new-icons/total-balance.png" topNumberText="{{'Employee Wallet' | translate}}"
                        [middleNumberValue]="summary.employees_withdraw" bottomText="" [error]="false">
                    </app-card>
                    <app-card style="border: 1px solid #EEEEEE; border-radius: 10px; flex: 1;"
                        imgCustom="assets/new-icons/total-balance.png" topNumberText="{{'Company Wallet' | translate}}"
                        [middleNumberValue]="summary.company_withdraw" bottomText="" [error]="false">
                    </app-card>
                </div>
            </div>
        </div>

        <div fxFlex="100" fxLayout="column" fxLayoutAlign="center start" style="width: 100%; margin-top: 15px;">
            <mat-accordion style="width: 100%;">
                <mat-expansion-panel *ngFor="let fund of funds" class="custom-expansion-panel mb-20 pt-1 pb-1"
                    style="width: 100%;">
                    <mat-expansion-panel-header class="mrl-10 mt-1"
                        style="display: flex; justify-content: space-between; align-items: center;">
                        <mat-panel-title style="color: #213060; font-weight: bolder;">
                            {{ fund.name }}
                        </mat-panel-title>
                        <div class="rounded-border-div">
                            <p style="font-size: 12px; text-align: center; margin: 0; color: #213060;">
                                {{'Total Redemption Amount' | translate}}:
                                <span style="font-weight: bold; color: #213060; font-size: 13;">
                                    SAR {{ fund.total | number:'1.4-4' }}
                                </span>
                            </p>
                        </div>
                    </mat-expansion-panel-header>
                    <div class="mb-20" fxLayout="row wrap" fxFlex="100" style="margin-top: -20px;">
                        <mat-card *ngFor="let asset of fund.assets" class="mt-3" style="flex: 1 1 100%; margin: 5px;">
                            <div fxLayout="column">
                                <div style="margin-bottom: -8px;" fxLayout="row wrap" class="justify-content-between"
                                    fxFlex="100">
                                    <div style="flex: 1; text-align: left;">{{ 'Fund Name' | translate }}</div>
                                    <div style="flex: 1; text-align: left;">{{ 'Units' | translate }}</div>
                                    <div style="flex: 1; text-align: left;">{{ 'Est. Amount (SAR)' | translate }}</div>
                                    <div style="flex: 1; text-align: left;">{{ 'Current Nav Per Unit' | translate }}
                                    </div>
                                </div>
                                <div style="margin-bottom: -8px;" fxLayout="row wrap" class="justify-content-between"
                                    fxFlex="100">
                                    <div
                                        style="flex: 1; text-align: left; font-size: 15px; font-weight: 600; color:#213060;">
                                        {{ asset.asset_name }}
                                    </div>
                                    <div
                                        style="flex: 1; text-align: left; font-size: 15px; font-weight: 600; color:#213060;">
                                        {{ asset.withdraw_units }}
                                    </div>
                                    <div
                                        style="flex: 1; text-align: left; font-size: 15px; font-weight: 600; color:#213060;">
                                        {{ asset.withdraw_amount }}
                                    </div>
                                    <div
                                        style="flex: 1; text-align: left; font-size: 15px; font-weight: 600; color:#213060;">
                                        {{ asset.nav_per_unit }}
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div fxFlex="100" fxLayout="column" fxLayoutAlign="center start" style="width: 100%; margin-top: 10px;">
            <p style="font-size: 13px; color: #7081B6;">
                {{'Please note' | translate}}
            </p>
        </div>

        <div fxFlex="100" fxLayout="column" fxLayoutAlign="center start" style="width: 100%; margin-top: 10px;">
            <div style="display: flex; align-items: center;">
                <mat-checkbox formControlName="termsAccepted" style="margin: 0;" color="primary"></mat-checkbox>
                <p style="font-weight: 500; color: #213060; margin-left: 8px; margin-bottom: 0;">
                    {{'I have read and accept the' | translate}}
                    <span style="font-weight: 600; color: #213060;">{{'Terms & Conditions' |translate}}.</span>
                </p>
            </div>
        </div>
    </form>

    <div class="mt-20 mb-20" fxLayoutAlign="center center" fxLayoutGap="10px">
        <button (click)="onCancel()"
            style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
            mat-raised-button>{{'Cancel' | translate}}</button>
        <button class="btn-submit" [disabled]="isSubmitButtonDisabled" (click)="confirm()" mat-raised-button>{{'Confirm'
            | translate}}</button>
    </div>
</div>

<!-- Print  -->
<div id="printable-div" style="margin:0; padding:0; display: none;">
    <table style="width:100%; max-width:700px; margin:0 auto; background-color:#fff; border-collapse:collapse;">
        <tbody style="margin: 0px 20px !important;">
            <tr style="display: flex; justify-content: space-between; align-items: flex-end; border-bottom: 1px solid #79C0C2;"
                class="mrl-20">
                <td style="padding-top:20px; text-align:left; flex: 1;" class="top-logo pr-10">
                    <img width="215" height="70" src="assets/thrift_logo.png" alt="Logo">
                </td>
                <td style="padding-top:20px; text-align: right; font-size:14px; color:#757575; flex: 1;">
                    <h2 style="margin: 0; font-weight: bolder; color: black;">{{'Instruction Summary' | translate}}</h2>
                </td>
            </tr>
            <tr>
                <td colspan="3" style="padding: 10px 20px;">
                    <div style="display: flex; justify-content: center; align-items: center;">
                        <div style="flex: 1; font-size: 14px; color: #757575; text-align: left;">
                            <p style="color: black;"><strong>{{'Requested' | translate}}</strong>
                                {{instructionSummary?.redemption?.created_at}}</p>
                            <p style="color: black;"><strong>{{'Request type' | translate}}</strong> Redemption</p>
                        </div>
                        <div style="flex: 1; font-size: 14px; color: #757575; text-align: left;">
                            <p style="color: black;"><strong>{{'Processed' |
                                    translate}}</strong>{{instructionSummary?.redemption?.updated_at}}</p>
                            <div *ngIf="currentLang == 'ar'">
                                <p style="color: black;direction: rtl;">
                                    <strong>{{ 'Virtual IBAN' | translate }}: </strong>
                                    <span style="unicode-bidi: embed;">{{iBnNo}}</span>
                                </p>
                            </div>
                            <div *ngIf="currentLang != 'ar'">
                                <p style="color: black;">
                                    <strong>{{ 'Virtual IBAN' | translate }}: </strong>
                                    <span style="unicode-bidi: embed;">{{iBnNo}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>

            <!-- Loop through assets for each fund -->
            <tr *ngFor="let fund of funds">
                <td colspan="3" style="padding: 0 20px;">
                    <p style="font-weight: bolder; border-bottom: 1px solid black; margin-bottom: 5px;">{{ fund.name }}
                    </p>
                    <table
                        style="width: 100%; border-collapse: collapse; border-bottom: 1px solid #79C0C2; margin-bottom: 20px;">
                        <thead>
                            <tr style="border-bottom: 1px solid #79C0C2;">
                                <th style="padding: 10px; text-align: left;">{{ 'Fund Name' | translate }}</th>
                                <th style="padding: 10px; text-align: left;">{{ 'Units' | translate }}</th>
                                <th style="padding: 10px; text-align: left;">{{ 'Est. Amount (SAR)' | translate }}</th>
                                <th style="padding: 10px; text-align: left;">{{ 'Current Nav Per Unit' | translate }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let asset of fund.assets">
                                <td style="padding: 10px; text-align: left;">{{ asset.asset_name }}</td>
                                <td style="padding: 10px; text-align: left;">{{ asset.withdraw_units }}</td>
                                <td style="padding: 10px; text-align: left;">{{ asset.withdraw_amount }}</td>
                                <td style="padding: 10px; text-align: left;">{{ asset.nav_per_unit }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="text-align: right; padding: 10px; margin-top: 10px;">
                        <span style="color: black; font-weight: bolder; margin-right: 10px;">{{'Total Redemption Amount
                            (SAR)' | translate}}</span>
                        {{ fund.total | number:'1.4-4' }}
                    </div>
                </td>
            </tr>

            <tr class="mrl-20">
                <td colspan="3" style="padding: 0px 20px;">
                    <p style="color: black; font-size: 13px;">{{'PDF_Note' | translate}}</p>
                </td>
            </tr>

            <tr class="mrl-20">
                <td colspan="3" style="padding: 0px 20px;">
                    <p style="color: black;"><strong>Thriftplan.com.sa/Reports</strong></p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
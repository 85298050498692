<div class="main pt-1" ngClass.sm="mrl-10" ngClass.xs="mrl-10" style="max-height: 100vh; overflow-y: auto;"
    *ngIf="dialogType == 'process'">
    <h4 style="text-align: center; font-size: 20px; font-weight: bold; color: #213060;">{{'Process Withdrawal Request' | translate}}</h4>

    <form [formGroup]="Form" fxLayout="column" fxLayoutAlign="start stretch">
        <div style="display: flex; justify-content: space-between;">
            <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                appearance="outline">
                <mat-label>{{'Company Amount' | translate}}</mat-label>
                <input autocomplete="off" type="text" matInput readonly formControlName="company_withdraw">
            </mat-form-field>
            <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                appearance="outline">
                <mat-label>{{'Employee Amount' | translate}}</mat-label>
                <input autocomplete="off" readonly type="text" matInput formControlName="employees_withdraw">
            </mat-form-field>
        </div>
        <div>
            <app-table fxFlex="100" [tableProps]="tableProps" [dataSource]="list">
            </app-table>
        </div>
    </form>

    <div class="mt-20 mb-20" fxLayoutAlign="center center" fxLayoutGap="10px">
        <button (click)="onCancel()"
            style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
            mat-raised-button>{{'Cancel' | translate}}</button>
        <button class="btn-submit" (click)="process()" mat-raised-button>{{'Submit' | translate}}</button>
    </div>
</div>

<div class="main pt-1" ngClass.sm="mrl-10" ngClass.xs="mrl-10" *ngIf="dialogType == 'reject'">
    <h4 style="text-align: center; font-size: 20px; font-weight: bold; color: #213060;">{{'Process Withdrawal Request' | translate}}</h4>
    <form fxLayout="column" fxLayoutAlign="start stretch">
        <mat-form-field class="input-field" fxFlex="100" fxFlex.sm="46" fxFlex.xs="100" appearance="outline">
            <mat-label>{{ 'Vesting_Type' | translate }}</mat-label>
            <mat-select [(ngModel)]="reject" name="rejectType">
                <mat-option *ngFor="let rejectReason of rejectReasons" [value]="rejectReason.reason">
                    {{ rejectReason.reason | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>

    <div class="mt-20 mb-20" fxLayoutAlign="center center" fxLayoutGap="10px">
        <button (click)="onCancel()"
            style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
            mat-raised-button>{{'Cancel' | translate}}</button>
        <button class="btn-submit" (click)="rejectReason()" mat-raised-button>{{'Submit' | translate}}</button>
    </div>
</div>
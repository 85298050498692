<main style="display: flex; flex-direction: column; align-items: center; text-align: center;">
    <div style="display: flex; justify-content: flex-end; width: 100%;">
        <mat-icon (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
    </div>
    <h1 style="color: #213060; font-weight: bold; margin: 0 0 10px;">{{'Welcome to your workplace savings plan!' | translate}}</h1>
    <p style="color: #707EAE; font-size: 13px;">{{'You have successfully claimed your voucher to help make this Saving plan YOUR Saving plan.' | translate}}</p>
    <div style="display: flex;  gap: 5px; height: 42px;" dir="ltr">
        <div style="display: flex; flex-direction: column; justify-content: flex-end; height: 100% !important;">
            <h1 style="margin-bottom: 0; color: #7AB4A6;">SAR</h1>
        </div>
        <h1 style="margin-bottom: 0; color: #7AB4A6; font-size: 50px; font-weight: bold;">2,000</h1>
    </div>
    <div>
        <h1 style="color: #213060; font-weight: 700; font-size: 42px; margin-top: 10px;">{{'CREDITS' | translate}}</h1>
    </div>
    <p style="color: #707EAE; font-weight: bold; font-size: 13px;">{{'Reach out to our expert to help customize your plan and set you up on the road to improved employee retention!' | translate}}</p>
    <button (click)="onContact()" class="btn-submit" style="margin-bottom: 16px;" mat-raised-button>{{'Contact Us' | translate}}</button>
    <p style="margin-top: 20px; margin-bottom: -10px; color: #9E9E9E; font-size: 12px;">{{'*Valid until 01/10/2023 only.' | translate}}</p>
</main>
import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { english, arabic } from "../../../translation";

@Injectable()
export class TranslationService
{
  selectedApp: Subject<any> = new Subject<any>();
  selectedApiCallAgain: Subject<any> = new Subject<any>();
  translation: any;

  constructor(protected decimalPipe: DecimalPipe) 
  {
    // this.isLoadingEvent.next(false);
    this.translation = null;
    if (localStorage.getItem('lang') == 'en')
    {
      this.setApp(english);
      document.body.classList.add('english');
      document.body.classList.remove('arabic');
      document.body.setAttribute("dir", "ltr");
    }
    else if (localStorage.getItem('lang') == 'ar')
    {
      this.setApp(arabic);
      document.body.classList.add('arabic');
      document.body.classList.remove('english');
      document.body.setAttribute("dir", "rtl");
    }
    else
    {
      this.setApp(english);
      document.body.classList.add('english');
      document.body.classList.remove('arabic');
      document.body.setAttribute("dir", "ltr");
    }
  }

  setApiOnCall(value)
  {
    this.selectedApiCallAgain.next(value);
  }
  setApp(val)
  {
    this.translation = val;
    this.selectedApp.next(val);
  }

  getApp()
  {
    if (this.translation != null)
    {
      return this.translation;
    }
  }

  numberFormatter(val)
  {
    if (val  != 'NaN')
    {
      return this.decimalPipe.transform(val, '1.4-4');
    }
    else
    {
      return '0.0000';
    }
  }

  numberFormatter2(val)
  {
    if (val  != 'NaN')
    {
      return this.decimalPipe.transform(val, '1.2-2');
    }
    else
    {
      return '0.00';
    }
  }
  
  numberFormatter5(val)
  {
    if (val  != 'NaN')
    {
      return this.decimalPipe.transform(val, '1.5-5');
    }
    else
    {
      return '0.00000';
    }
  }
}

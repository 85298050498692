<div class="main" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
    <div fxLayout="column" fxFlex="59" ngClass.sm="d-none" ngClass.xs="d-none">
        <img src="assets/back-img.png" alt="Back Ground Image" class="img" ngClass.sm="img-sm" ngClass.xs="img-xs">
        <span class="back-img" ngClass.sm="back-img-sm" ngClass.xs="back-img-xs" fxLayoutAlign="center">
            <span fxLayout="column" fxFlexOffset="8" fxFlexOffset.sm="0" fxLayoutGap="50">
                <h1>{{'Welcome to the future of employee benefits' | translate}}</h1>
            </span>
        </span>
    </div>

    <div fxFlex="41" class="login-form" ngClass.sm="login-form-sm" ngClass.xs="login-form-xs" fxLayout="column"
        fxLayoutAlign="end center">

        <div fxLayout="column" fxFlex="96">
            <div class="center" ngClass.xs="margin-top-15">
                <h1>{{'Register your account' | translate}}</h1>
                <h6>{{'Start rewarding your employees in only 5 minutes.' | translate}}</h6>
            </div>

            <mat-horizontal-stepper class="stepperStyle" #stepper (selectionChange)="onStepChange($event)"
                [selectedIndex]="currentIndexOfStepper" [linear]="true">
                <!-- step one -->
                <mat-step [completed]="false" [stepControl]="firstForm">
                    <h3 class="mid-head">{{'Company Information' | translate}}</h3>
                    <form [formGroup]="firstForm">
                        <div fxLayout="row wrap">
                            <mat-form-field fxFlex="100" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                                fxFlexAlign="center" appearance="outline">
                                <mat-label>{{'Company_Cr_Number' | translate}}</mat-label>
                                <input autocomplete="off" type="text" matInput formControlName="cr_no">
                                <mat-icon class="mat-icon-error" *ngIf="getFieldValue(firstForm, 'cr_no')"
                                    #tooltip="matTooltip"
                                    [matTooltip]="getErrorMessage(firstForm, 'cr_no', 'CR No is required.')"
                                    [matTooltipDisabled]="!getFieldValue(firstForm, 'cr_no')" matTooltipPosition="above"
                                    matSuffix>info
                                </mat-icon>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row wrap">
                            <mat-form-field fxFlex="100" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                                fxFlexAlign="center" appearance="outline">
                                <mat-label>{{'Company Name' | translate}}</mat-label>
                                <input autocomplete="off" type="text" matInput formControlName="company_name">
                                <mat-icon class="mat-icon-error" *ngIf="getFieldValue(firstForm, 'company_name')"
                                    #tooltip="matTooltip"
                                    [matTooltip]="getErrorMessage(firstForm, 'company_name', 'Company Name is required.')"
                                    [matTooltipDisabled]="!getFieldValue(firstForm, 'company_name')"
                                    matTooltipPosition="above" matSuffix>info
                                </mat-icon>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row wrap">
                            <mat-form-field fxFlex="100" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                                fxFlexAlign="center" appearance="outline">
                                <mat-label>{{'Company Website' | translate}}</mat-label>
                                <input autocomplete="off" placeholder="companyname.com" type="text" matInput
                                    formControlName="company_website">
                                <mat-icon class="mat-icon-error" *ngIf="getFieldValue(firstForm, 'company_website')"
                                    #tooltip="matTooltip"
                                    [matTooltip]="getErrorMessage(firstForm, 'company_website', 'Company Website is required.')"
                                    [matTooltipDisabled]="!getFieldValue(firstForm, 'company_website')"
                                    matTooltipPosition="above" matSuffix>info
                                </mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="mt-10" fxLayout="row wrap" style="justify-content: center; align-items:center;">
                            <div class="grey-text" fxFlex="40" fxFlex.xs="100" fxLayoutAlign="start center">
                                {{'No_of_Employees' | translate}}
                            </div>
                            <div fxLayout="row wrap" fxFlex="60" fxFlex.xs="100" fxLayoutAlign="start center">
                                <mat-form-field class="input-field dropdown" appearance="outline">
                                    <mat-select formControlName="no_of_employees">
                                        <mat-option style="direction: ltr; text-align: left;" value="10">10+</mat-option>
                                        <mat-option style="direction: ltr; text-align: left;" value="50">11-50</mat-option>
                                        <mat-option style="direction: ltr; text-align: left;" value="100">51-100</mat-option>
                                        <mat-option style="direction: ltr; text-align: left;" value="1000">101-1000</mat-option>
                                        <mat-option style="direction: ltr; text-align: left;" value="1200">1000+</mat-option>
                                    </mat-select>
                                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue(firstForm, 'no_of_employees')"
                                        #tooltip="matTooltip"
                                        [matTooltip]="getErrorMessage(firstForm, 'no_of_employees', 'No. of Employees is required.')"
                                        [matTooltipDisabled]="!getFieldValue(firstForm, 'no_of_employees')"
                                        matTooltipPosition="above" matSuffix>info
                                    </mat-icon>
                                </mat-form-field>
                            </div>
                        </div>

                        <div style="margin-top: 10px;">
                            <re-captcha (resolved)="resolved($event)" [siteKey]="siteKey">
                            </re-captcha>
                        </div>

                        <div class="mt-20 mb-20" fxLayoutAlign="space-between center">
                            <button (click)="onBack()"
                                style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
                                mat-raised-button>{{'Back' | translate}}</button>
                            <button *ngIf="currentIndexOfStepper < 5" (click)="onNext()" class="btn-submit"
                                mat-raised-button>{{'Next' | translate}}</button>
                        </div>
                    </form>
                </mat-step>
                <!-- step one -->

                <!-- step two -->
                <mat-step [completed]="false" [stepControl]="secondForm">

                    <h3 class="mid-head">{{'Who will be your main user?' | translate}}</h3>
                    <form [formGroup]="secondForm">
                        <div fxLayout="row wrap">
                            <mat-form-field fxFlex="100" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                                fxFlexAlign="center" appearance="outline">
                                <mat-label>{{'Company Email Address' | translate}}</mat-label>
                                <input autocomplete="off" type="email" matInput formControlName="company_email">
                                <mat-icon class="mat-icon-error" *ngIf="getFieldValue(secondForm, 'company_email')"
                                    #tooltip="matTooltip"
                                    [matTooltip]="getErrorMessage(secondForm, 'company_email', 'Company Email Address is required.')"
                                    [matTooltipDisabled]="!getFieldValue(secondForm, 'company_email')"
                                    matTooltipPosition="above" matSuffix>info
                                </mat-icon>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row wrap">
                            <mat-form-field fxFlex="100" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                                fxFlexAlign="center" appearance="outline">
                                <mat-label>{{'Mobile Number' | translate}}</mat-label>
                                <input autocomplete="off" type="text" matInput formControlName="company_phone">
                                <span style="margin-right: 2px;" matPrefix>+966</span>
                                <mat-icon class="mat-icon-error" *ngIf="getFieldValue(secondForm, 'company_phone')"
                                    #tooltip="matTooltip"
                                    [matTooltip]="getErrorMessage(secondForm, 'company_phone', 'Mobile Number is required.')"
                                    [matTooltipDisabled]="!getFieldValue(secondForm, 'company_phone')"
                                    matTooltipPosition="above" matSuffix>info
                                </mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="mt-10" fxLayout="row wrap">
                            <div fxFlex="100" fxFlex.xs="100" fxLayoutAlign="start center">
                                <mat-form-field fxFlex="100" fxFlex.xs="100" style="width: 100%;" class="input-field"
                                    fxFlexAlign="center" appearance="outline">
                                    <mat-label>{{'ID number' | translate}}</mat-label>
                                    <input autocomplete="off" placeholder="{{'ID number' | translate}}" type="text"
                                        matInput formControlName="company_id">
                                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue(secondForm, 'company_id')"
                                        #tooltip="matTooltip"
                                        [matTooltip]="getErrorMessage(secondForm, 'company_id', 'ID number is required.')"
                                        [matTooltipDisabled]="!getFieldValue(secondForm, 'company_id')"
                                        matTooltipPosition="above" matSuffix>info
                                    </mat-icon>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="mt-20 mb-20" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                            <button (click)="onBack()"
                                style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
                                mat-raised-button>{{'Back' | translate}}</button>
                            <button *ngIf="currentIndexOfStepper < 5" (click)="onNext()" class="btn-submit"
                                mat-raised-button>{{'Next' | translate}}</button>
                        </div>
                    </form>
                </mat-step>
                <!-- step two -->

                <!-- step three -->
                <mat-step [completed]="false" [stepControl]="thirdForm">
                    <h3 class="mid-head">{{'How do you want to reward your employees?' | translate}}</h3>
                    <form [formGroup]="thirdForm">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between start">
                            <div (click)="selectOption('gradually')" class="box-button center"
                                [ngClass]="{ 'selectedBox': selectedOption == 'gradually' }" fxFlex="30">
                                <div>
                                    <img width="30"
                                        [src]="'assets/images/' + (selectedOption == 'gradually' ? 'bar-up.svg' : 'bar-up-grey.svg')"
                                        alt="">
                                </div>
                                <div class="mt-10 fs-11">{{'Gradually' | translate}}</div>
                            </div>
                            <div (click)="selectOption('fixed')" class="box-button center"
                                [ngClass]="{ 'selectedBox': selectedOption == 'fixed' }" fxFlex="30">
                                <div>
                                    <img width="30"
                                        [src]="'assets/images/' + (selectedOption == 'fixed' ? 'fixed.svg' : 'fixed-grey.svg')"
                                        alt="">
                                </div>
                                <div class="mt-10 fs-11">{{'Fixed' | translate}}</div>
                            </div>
                            <div (click)="selectOption('noReward')" class="box-button center"
                                [ngClass]="{ 'selectedBox': selectedOption == 'noReward' }" fxFlex="30">
                                <div>
                                    <img width="30"
                                        [src]="'assets/images/' + (selectedOption == 'noReward' ? 'no-reward.svg' : 'no-reward-grey.svg')"
                                        alt="">
                                </div>
                                <div class="mt-10 fs-11">{{'No Reward' | translate}}</div>
                            </div>
                        </div>

                        <h4 *ngIf="selectedOption == 'gradually'" class="mid-head mt-20">{{'How fast do you want to
                            reach 100% rewards?' | translate}}</h4>
                        <h4 *ngIf="selectedOption == 'fixed'" class="mid-head mt-20">{{"How much of the employee's
                            contribution are you ready to match?" | translate}}</h4>

                        <div *ngIf="selectedOption == 'gradually'" fxLayout="row" fxLayoutAlign="center center">
                            <div class="inline" fxFlex="53" fxFlex.xs="80">
                                <div (click)="thirdForm.controls['employee_reward_year'].setValue(1)" class="small-Btn"
                                    [ngClass]="{ 'selectedBox': thirdForm.controls['employee_reward_year'].value == 1 }">
                                    {{'1 year' | translate}}
                                </div>
                                <div (click)="thirdForm.controls['employee_reward_year'].setValue(3)" class="small-Btn"
                                    [ngClass]="{ 'selectedBox': thirdForm.controls['employee_reward_year'].value == 3 }">
                                    {{'3 year' | translate}}
                                </div>
                                <div (click)="thirdForm.controls['employee_reward_year'].setValue(5)" class="small-Btn"
                                    [ngClass]="{ 'selectedBox': thirdForm.controls['employee_reward_year'].value == 5 }">
                                    {{'5 year' | translate}}
                                </div>
                            </div>
                        </div>

                        <div *ngIf="selectedOption == 'fixed'" fxLayout="row" fxLayoutAlign="start center"
                            fxLayoutGap="10px">
                            <div class="center" fxFlex="30" style="color:#213060;">
                                {{'Matching level (%)' | translate}}
                            </div>
                            <div fxFlex="35">
                                <mat-form-field style="width: 50%;" class="input-field" appearance="outline">
                                    <input autocomplete="off" type="number" min="0" matInput
                                        formControlName="employee_reward_level">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="mt-20 mb-20" fxLayoutAlign="space-between center">
                            <button (click)="onBack()"
                                style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
                                mat-raised-button>{{'Back' | translate}}</button>
                            <button *ngIf="currentIndexOfStepper < 5" (click)="onNext()" class="btn-submit"
                                mat-raised-button>{{'Next' | translate}}</button>
                        </div>

                    </form>
                </mat-step>
                <!-- step three -->

                <!-- step four -->
                <mat-step [completed]="false" [stepControl]="fourthForm">
                    <!-- <ng-template matStepLabel>{{'Admin_Details' | translate}}</ng-template> -->

                    <h2 class="mid-head" [innerHTML]="'Start your 14-days FREE trial today' | translate"></h2>
                    <form [formGroup]="fourthForm">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between start">
                            <div (click)="selectPlan('monthly')" class="box-button center"
                                [ngClass]="{ 'selectedBox': plan == 'monthly' }" fxFlex="48">
                                <div style="display: flex; justify-content: center; gap: 5px;" [class.reverse]="isArabic">
                                    <span class="f-23">{{'SAR 40' | translate}}</span>
                                    <span> {{'(+VAT)' | translate}}</span>
                                </div>
                                <div style="margin-top: 3px;">
                                    <span>{{'Monthly' | translate}}</span> <br>
                                    <span class="f-10">{{'per user' | translate}}</span>
                                </div>
                            </div>
                            <div style="position: relative;" (click)="selectPlan('annually')" class="box-button center"
                                [ngClass]="{ 'selectedBox': plan == 'annually' }" fxFlex="48">
                                <div [ngClass]="{ 'green': plan == 'monthly' }" style="display: flex; justify-content: center; gap: 5px;" [class.reverse]="isArabic">
                                    <span class="f-23"> {{'SAR 420' | translate}}</span>
                                    <span> {{'(+VAT)' | translate}}</span>
                                </div>
                                <div style="margin-top: 3px;">
                                    <span>{{'Annually' | translate}}</span> <br>
                                    <span class="f-10"> {{'per user' | translate}} </span>
                                </div>
                                <div class="tag">{{'Save 12.5%' | translate}}</div>
                            </div>
                        </div>

                        <div class="mt-20 trial-info center" [innerHTML]="'Please select your Subscription preference; you will not be billed until the free trial ends.' | translate"></div>

                        <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="start center">
                            <div fxFlex="10">
                                <mat-checkbox (change)="fourthForm.get('accept_terms').setValue($event.checked ? 1 : 0)"
                                    formControlName="accept_terms">
                                </mat-checkbox>
                            </div>
                            <div class="grey-text" fxFlex="90">
                                {{'I have thoroughly read and consent to the Terms and Conditions of this service.' | translate}}
                            </div>
                        </div>

                        <div class="mt-10" fxLayout="row wrap" fxLayoutAlign="start center">
                            <div fxFlex="10">
                                <mat-checkbox
                                    (change)="fourthForm.get('accept_privacy_policy').setValue($event.checked ? 1 : 0)"
                                    formControlName="accept_privacy_policy">
                                </mat-checkbox>
                            </div>
                            <div class="grey-text" fxFlex="90" [innerHTML]="'I give my consent to the Privacy Policy and I agree to the collection and use of my personal data.' | translate"></div>
                        </div>

                        <div class="mt-10" fxLayout="row wrap" fxLayoutAlign="start center">
                            <div fxFlex="10">
                                <mat-checkbox
                                    (change)="fourthForm.get('accept_promotions').setValue($event.checked ? 1 : 0)"
                                    formControlName="accept_promotions">
                                </mat-checkbox>
                            </div>
                            <div class="grey-text" fxFlex="90">
                                {{'I would like to receive future communications, updates, and promotional information relevant to my account.' | translate}}
                            </div>
                        </div>

                        <div class="mt-20 mb-20" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                            <button (click)="onBack()"
                                style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
                                mat-raised-button>{{'Back' | translate}}</button>
                            <button
                                [disabled]="!(fourthForm.get('accept_privacy_policy').value == 1 && fourthForm.get('accept_terms').value == 1)"
                                *ngIf="currentIndexOfStepper < 5" (click)="onNext()" class="btn-submit"
                                mat-raised-button>{{'Complete Registration' | translate}}</button>
                        </div>
                    </form>
                </mat-step>
                <!-- step four -->
            </mat-horizontal-stepper>
        </div>

        <div class="footer" ngClass.sm="footer-sm" ngClass.xs="footer-xs" fxLayoutAlign="space-between baseline">
            <p>© {{'2023 Thriftplan. All Rights Reserved' | translate}}</p>
        </div>
    </div>
</div>
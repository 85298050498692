import { Component, OnInit } from '@angular/core';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {enPdfNote} from 'src/assets/pdf-notes/pdf-note-en';
import { arPdfNote } from 'src/assets/pdf-notes/pdf-note-ar';

@Component({
  selector: 'app-asset-holding-report',
  templateUrl: './asset-holding-report.component.html',
  styleUrls: ['./asset-holding-report.component.scss']
})
export class AssetHoldingReportComponent implements OnInit {
  companyListing = 'portfolios';
  list: any = [];
  summary: any;
  iBnNo: any;
  portfolios: any = [];
  portfolioList: any = [];
  fundlist: any = [];
  start_date: any;
  end_date: any;
  today: any;
  totalInvestedAmount: any = 0.0000;
  totalPurchasedUnits: any = 0.0000;
  totalWithdrawnUnits: any = 0.0000;
  totalUnitsOnHand: any = 0.0000;
  totalCurrentValue: any = 0.0000;
  totalAbsoluteReturn: any = 0.0000;
  totalReturn: any = 0.0000;
  search: any;
  body: any;
  selectedPortfolioId = '';
  selectedPortfolioLabel = 'All';
  selectedfundLabel: any;
  fundId: any = '0';
  searchTimer: any;
  currentLang = 'en';
  showPdf: boolean = false;
  funds: any[] = [];
  portfolioTableProps = {
    heading: '',
    hasSearch: false,
    searchLabel: 'Search',
    hasButton: false,
    report: true,
    isEmptyRow: false,
    headerActions: [],
    filterArray: [
      {
        label: 'Portfolio',
        type: 'filter_list',
        key: 'report_of',
        visibility: true,
        selected: '',
        options: [
          {
            value: '',
            label: 'All',
            key: 'report_of',
          },
        ],
      },
    ],
    rowActions: [],
    colHeader: {
      fund_name: 'Fund',
      invested_amount: 'Contribution Amount (SAR)',
      purchased_units: 'Purchased Units',
      withdrawn_units: 'Withdrawn Units',
      units_in_hand: 'Units on Hand',
      current_value: 'Current Value (SAR)',
      absolute_return: 'Absolute Return (SAR)',
      return_percentage: 'Return (%)',
    },
    columnTypes: {
      date: 'date',
      invested_amount: 'number-4',
      purchased_units: 'number-4',
      units_in_hand: 'number-4',
      current_value: 'number-4',
      absolute_return: 'number-4',
      portfolio: 'number-4',
      withdrawn_units: 'number-4',
    },
    dateFilter: {
      hasDatePicker: true,
      removeCross: true,
      type: "date",
      startLabel: "Till Date",
    }
  };
  fundsTableProps = {
    heading: '',
    hasSearch: false,
    searchLabel: 'Search',
    hasButton: false,
    headerActions: [],
    filterArray: [
      {
        label: 'Funds',
        type: 'filter_list',
        key: 'report_of',
        visibility: true,
        selected: '',
        options: [
          {
            value: '',
            label: '',
            key: 'report_of',
          },
        ],
      },
    ],
    rowActions: [],
    colHeader: {
      fund_name: 'Fund',
      invested_amount: 'Contribution Amount (SAR)',
      purchased_units: 'Purchased Units',
      withdrawn_units: 'Withdrawn Units',
      units_in_hand: 'Units on Hand',
      current_value: 'Current Value (SAR)',
      absolute_return: 'Absolute Return (SAR)',
      return_percentage: 'Return (%)',
      //report: true
    },
    columnTypes: {
      date: 'date',
      invested_amount: 'number-4',
      purchased_units: 'number-4',
      units_in_hand: 'number-4',
      current_value: 'number-4',
      absolute_return: 'number-4',
      portfolio: 'number-4',
      withdrawn_units: 'number-4',
    },
    dateFilter: {
      hasDatePicker: true,
      removeCross: true,
      type: "date",
      startLabel: "Till Date",
    }
  };
  constructor(protected dec_enc: DecEncService, protected mainService: MainService, private datePipe: DatePipe, private translate: TranslateService) { 
    this.currentLang = localStorage.getItem('lang')
  }

  ngOnInit(): void {
    this.today = new Date();
    this.start_date = this.datePipe.transform(this.today, 'yyyy-MM-dd');
    this.end_date = this.datePipe.transform(this.today, 'yyyy-MM-dd');
    this.getAll();
    this.getPortfolio();
    this.getFunds();
    this.getBankDetail();
  }

  getAll() {
    this.resetValues();
    this.body = {
      start_date: this.start_date,
      ...(this.companyListing == 'portfolios' ? { portfolio_id: this.selectedPortfolioId } : {}),
      ...(this.companyListing == 'funds' ? { fund_id: this.fundId } : {})

    };
    let enc_payload = this.dec_enc.encryption(this.body);
    let url;
    if (this.companyListing == 'portfolios') {
      url = 'reports/portfolio-holding';
    }
    else if (this.companyListing == 'funds') {
      url = 'reports/fund-holding';
    }
    this.mainService.postData(url, { enc_payload }).then(res => {
      if (res.statusCode === 200) {
        let result = this.dec_enc.decryption(res.data);
        this.summary = result.summary;
        if (this.companyListing == 'portfolios') {
          this.list = result.data;
          this.list.forEach(element => {
            this.totalInvestedAmount += Number(element['invested_amount']);
            this.totalPurchasedUnits += Number(element['purchased_units']);
            this.totalWithdrawnUnits += Number(element['withdrawn_units']);
            this.totalUnitsOnHand += element['units_in_hand'];
            this.totalCurrentValue += element['current_value'];
            this.totalAbsoluteReturn += element['absolute_return'];
            this.totalReturn += element['return_percentage'];
            element.return_percentage = parseFloat(element.return_percentage).toFixed(4) + '%';
          });
          if (this.list.length > 0) {
            let total = {
              fund_name: this.translate.instant('Total:'),
              invested_amount: this.totalInvestedAmount.toFixed(4),
              purchased_units: this.totalPurchasedUnits.toFixed(4),
              withdrawn_units: this.totalWithdrawnUnits.toFixed(4),
              units_in_hand: this.totalUnitsOnHand,
              current_value: this.totalCurrentValue,
              absolute_return: this.totalAbsoluteReturn,
              return_percentage: parseFloat((this.totalAbsoluteReturn / this.totalInvestedAmount).toFixed(4)) + ' %',
              report: true,
            }
            this.list.push(this.createEmptyRow());
            this.list.push(total)
          }
        }
        if (this.companyListing == 'funds') {
          this.list = result.data;
          if (this.list.length > 0) {
            result.data.forEach(element => {
              element.portfolios.forEach(item => {
                item['fund_name'] = item.portfolio_name;
                this.totalInvestedAmount += Number(item['invested_amount']);
                this.totalPurchasedUnits += Number(item['purchased_units']);
                this.totalWithdrawnUnits += Number(item['withdrawn_units']);
                this.totalUnitsOnHand += item['units_in_hand'];
                this.totalCurrentValue += item['current_value'];
                this.totalReturn += item['return_percentage'];
                this.totalAbsoluteReturn += item['absolute_return'];
                item.return_percentage = parseFloat(item.return_percentage).toFixed(4) + '%';
                this.list.push(item);
              });
            });
            let total = {
              fund_name: result?.data[0]?.fund_name,
              invested_amount: this.totalInvestedAmount,
              purchased_units: this.totalPurchasedUnits,
              withdrawn_units: this.totalWithdrawnUnits,
              units_in_hand: this.totalUnitsOnHand,
              current_value: this.totalCurrentValue,
              absolute_return: this.totalAbsoluteReturn,
              return_percentage: parseFloat(this.totalReturn).toFixed(4) + '%',
              report: true,
            }
            this.list.shift();
            this.list.unshift(total);
          }
        }
      }
    });
  }

  createEmptyRow() {
    return {
      fund_name: '',
      invested_amount: '',
      purchased_units: '',
      withdrawn_units: '',
      units_in_hand: '',
      current_value: '',
      absolute_return: '',
      return_percentage: '',
      isEmptyRow: true, // You can use this field to identify and style the empty row differently
    };
  }

  getPortfolio() {
    let payload = {
      availability: "not-assigned"
    }
    let enc_payload = this.dec_enc.encryption(payload);
    let url = 'funds_groups/funds';
    this.mainService.postData(url, { enc_payload }).then(res => {
      if (res.statusCode === 200) {
        let result = this.dec_enc.decryption(res.data);
        this.portfolioList = result.funds;
        const initialOption = this.portfolioTableProps.filterArray[0].options[0];
        const dynamicOptions = this.portfolioTableProps.filterArray[0].options = result.funds.map(item => ({
          value: item.id,
          label: item.name,
          key: 'report_of'
        }));
        this.portfolioTableProps.filterArray[0].options = [initialOption, ...dynamicOptions];
      }
    });
  }

  getBankDetail() {
    let url = 'home/manage-organization';
    let employer_id = localStorage.getItem('employer_id');
    let enc_payload = this.dec_enc.encryption({ employer_id });

    this.mainService.postData(url, { enc_payload }).then(result => {
      if (result.statusCode === 200) {

        let data = this.dec_enc.decryption(result.data);
        if (data.bank_details != null) {
          this.iBnNo = data.bank_details.iban_number;
        }
        return;
      }
    });
  }

  onChangeList(item) {
    this.companyListing = item;
    this.list = [];
    this.selectedPortfolioId = '';
    this.fundId = '0'
    this.summary = [];
    this.fundsTableProps.filterArray[0].selected = '';
    this.portfolioTableProps.filterArray[0].selected = '';
    this.start_date = this.datePipe.transform(this.today, 'yyyy-MM-dd');
    this.getAll();
  }

  searchData(event) {
    this.search = event;
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.getAll();
    }, 800);
  }

  onFilters(event) {
    event.options.forEach(element => {
      if (element.value == event.selected) {
        if (this.companyListing == 'portfolios') {
          this.selectedPortfolioId = element.value;
          this.selectedPortfolioLabel = element.label;
          this.getAll();
        }
        else {
          this.fundId = element.value
          this.selectedfundLabel = element.label;
          this.getAll();
        }
      }
    });
  }

  getFunds() {
    let url = 'funds'
    this.mainService.postData(url, {}).then(res => {
      if (res.statusCode == 200) {
        let result = this.dec_enc.decryption(res.data);
        result.funds.forEach(item => {
          item.assets.forEach(element => {
            if (!this.fundlist.some(fund => fund.id === element.id)) {
              this.fundlist.push(element);
            }
          });
        })
        this.fundsTableProps.filterArray[0].options = this.fundlist.map(item => ({
          value: item.id,
          label: item.asset_name,
          key: 'report_of'
        }));
      }
    })
  }

  onDateFilters(event) {
    if (event.type == "date") {
      this.start_date = event['date'];
      this.getAll();
    }
  }

  resetValues() {
    this.totalInvestedAmount = 0;
    this.totalPurchasedUnits = 0;
    this.totalWithdrawnUnits = 0;
    this.totalUnitsOnHand = 0;
    this.totalCurrentValue = 0;
    this.totalAbsoluteReturn = 0;
    this.totalReturn = 0;
  }
   
  downloadPDF() {
    this.showPdf = true;

    setTimeout(() => {
        const data = document.getElementById('contentToExport');
        //const pdfNoteElement = document.getElementById('pdfNote');
        const pdfNoteImage = enPdfNote;
        const pdfNoteImageAr = arPdfNote;

        if (data) {
            html2canvas(data, { scale: 3 }).then(canvas => {
                const imgWidth = 210; // A4 width in mm
                const pageHeight = 295; // A4 height in mm
                const imgHeight = canvas.height * imgWidth / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;

                const imgData = canvas.toDataURL('image/png', 1.0);
                const pdf = new jsPDF('p', 'mm', 'a4');

                // Add the main image content to the PDF
                pdf.addImage(imgData, 'PNG', (pdf.internal.pageSize.getWidth() - imgWidth) / 2, position, imgWidth, imgHeight, undefined, 'FAST');

                heightLeft -= pageHeight;

                // Handle multiple pages
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', (pdf.internal.pageSize.getWidth() - imgWidth) / 2, position, imgWidth, imgHeight, undefined, 'FAST');
                    heightLeft -= pageHeight;
                }

                let remainingHeight = pageHeight - (position + imgHeight);
                if (remainingHeight < 45) {
                    pdf.addPage();
                    remainingHeight = pageHeight; // Reset remaining height on the new page
                }

                const screenshotWidth = 195; // Adjust width as necessary
                const screenshotHeight = 30; // Adjust height as necessary, or calculate based on aspect ratio

                // Append the PDF_Note image as base64
                if (this.currentLang == 'ar'){
                  pdf.addImage(pdfNoteImageAr, 'PNG', 5, 250, screenshotWidth, screenshotHeight);
                }else{
                  pdf.addImage(pdfNoteImage, 'PNG', 5, 250, screenshotWidth, screenshotHeight);
                }
                
                pdf.setTextColor(100, 100, 100);
                pdf.setFontSize(10);
                pdf.text("Thriftplan.com.sa/Reports", 5, 291);

                // Save the PDF based on the listing type
                this.companyListing === 'portfolios'
                    ? pdf.save('Portfolio Holding Report by Portfolios.pdf')
                    : pdf.save('Portfolio Holding Report by Funds.pdf');
            });
        }
    }, 500);

    setTimeout(() => {
        this.showPdf = false;
    }, 500);
 } 

 // downloadPDF() {
  //   this.showPdf = true;
  //   setTimeout(() => {
  //     const data = document.getElementById('contentToExport');
  //     if (data) {
  //       // Increase scale to 2 for better resolution
  //       html2canvas(data, { scale: 3 }).then(canvas => {
  //         const imgWidth = 210; // A4 width in mm
  //         const pageHeight = 295; // A4 height in mm
  //         const imgHeight = canvas.height * imgWidth / canvas.width;
  //         let heightLeft = imgHeight;
  //         let position = 0;

  //         const imgData = canvas.toDataURL('image/png', 1.0); // Highest quality PNG
  //         const pdf = new jsPDF('p', 'mm', 'a4');
  //         pdf.addImage(imgData, 'PNG', (pdf.internal.pageSize.getWidth() - imgWidth) / 2, position, imgWidth, imgHeight, undefined, 'FAST');

  //         heightLeft -= pageHeight;

  //         while (heightLeft >= 0) {
  //           position = heightLeft - imgHeight;
  //           pdf.addPage();
  //           pdf.addImage(imgData, 'PNG', (pdf.internal.pageSize.getWidth() - imgWidth) / 2, position, imgWidth, imgHeight, undefined, 'FAST');
  //           heightLeft -= pageHeight;
  //         }

  //         this.companyListing == "portfolios"
  //           ? pdf.save('Asset Holding Report by Portfolios.pdf')
  //           : pdf.save('Asset Holding Report by Funds.pdf');
  //       });
  //     }
  //   }, 500);

  //   setTimeout(() => {
  //     this.showPdf = false;
  //   }, 500);
  // }
  
}


<div *ngIf="!showPdf" class="main pt-1 mrl-20 mt-10" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <mat-card style="margin: 20px 20px; padding: 16px; box-shadow: none; border: none;">
        <div class="mb-10 mt-3" style="display: flex; justify-content: space-between; align-content: center;">
            <h3 style="font-weight: bold;">
                {{'Transaction summary' | translate}}
            </h3>
            <button (click)="downloadPDF()"
                style="background-color: #213060; color: white; border-radius: 10px; outline: none; height: 35px;"
                mat-raised-button>{{'Save as PDF' | translate}}</button>
        </div>

        <form [formGroup]="Form">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <div fxFlex="48" fxLayout="column" fxLayoutGap="5px" class="form-container">
                    <mat-form-field fxFlex="23.3" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Date' | translate}}</mat-label>
                        <input matInput type="text" formControlName="created_at" [matDatepicker]="picker1" readonly />
                        <mat-datepicker-toggle matSuffix [for]="picker1" disabled="true"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field fxFlex="100" fxFlex.xs="100" class="input-field" fxFlexAlign="center"
                        appearance="outline" style="width: 100%;">
                        <mat-label>{{'Initiated by' | translate}}</mat-label>
                        <input autocomplete="off" type="text" matInput formControlName="initiated_by" readonly>
                    </mat-form-field>
                    <mat-form-field fxFlex="100" fxFlex.xs="100" class="input-field" fxFlexAlign="center"
                        appearance="outline" style="width: 100%;">
                        <mat-label>{{'Number of withdrawal request processed' | translate}}</mat-label>
                        <input autocomplete="off" type="text" matInput formControlName="withdraw_processed" readonly>
                    </mat-form-field>
                </div>

                <div fxFlex="48" class="col-md-6 ml-3">
                    <div class="summary-info">
                        <div class="row align-items-center justify-content-between mb-3">
                            <span class="col-auto d-flex justify-content-end" style="color:#aaa">{{'Total Withdrawal' |
                                translate}}</span>
                            <span class="col-auto" style="font-weight: bold;">{{totalWithdraw |
                                number:'1.4-4'}}</span>
                        </div>
                        <div class="row mb-3"
                            style="background-color: #aaa; border: 1px; height: 1px; border: 0.5px 0px 0px 0px;"></div>
                        <div class="row align-items-center justify-content-between mb-3">
                            <span class="col-auto d-flex justify-content-end" style="color:#aaa">{{'Amount requested for
                                withdrawal' | translate}}</span>
                            <span class="col-auto" style="font-weight: bold;">{{summary?.withdraw_amount |
                                number:'1.4-4'}}</span>
                        </div>
                        <div class="row align-items-center justify-content-between">
                            <span class="col-auto d-flex justify-content-end" style="color:#aaa">{{'Transaction fees' |
                                translate}}</span>
                            <span class="col-auto" style="font-weight: bold;">{{sumOfFees | number:'1.4-4'}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </mat-card>

    <div class="main pt-1 mrl-20 mt-30" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
        <app-table fxFlex="100" [tableProps]="tableProps" (searchData)="searchData($event)" [dataSource]="list"
            (setPage)="setPage($event)" (pageSize)="SetPageSize($event)">
        </app-table>
    </div>
    <div class="mt-2 mb-20 mrl-20" fxLayoutAlign="end end">
        <button (click)="onBack()"
            style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
            mat-raised-button>{{'Back' | translate}}</button>
    </div>
</div>


<!-- Print Section  -->
<div *ngIf="showPdf" id="contentToExport" class="main pt-1 mrl-20 mt-10" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <mat-card style="margin: 20px 20px; padding: 16px; box-shadow: none; border: none;">
        <div class="mb-10 mt-3" style="display: flex; justify-content: space-between; align-content: center;">
            <h2 style="font-weight: bold;">
                {{'Transaction summary' | translate}} </h2>
        </div>

        <form [formGroup]="Form">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <div fxFlex="48" fxLayout="column" fxLayoutGap="5px" class="form-container">
                    <div fxFlex="23.3" fxFlex.xs="100" class="field-container mt-10">
                        <h3 style="font-weight: bolder; margin-bottom: 0px;">{{'Date' | translate}}</h3>
                        <h5>{{summary.created_at}}</h5>
                    </div>

                    <div fxFlex="100" fxFlex.xs="100" class="field-container">
                        <h3 style="font-weight: bolder; margin-bottom: 0px;">{{'Initiated by' | translate}}</h3>
                        <p>{{summary.initiated_by}}</p>
                    </div>

                    <div fxFlex="100" fxFlex.xs="100" class="field-container">
                        <h3 style="font-weight: bolder; margin-bottom: 0px; margin-top: 0px;">{{'Number of withdrawal
                            request processed'
                            | translate}}</h3>
                        <p>{{this.list.length}}</p>
                    </div>
                </div>

                <div fxFlex="48" class="col-md-6 ml-3">
                    <div class="summary-info">
                        <div class="row align-items-center justify-content-between mb-3">
                            <span class="col-auto d-flex justify-content-end" style="color:#aaa">{{'Total Withdrawal' |
                                translate}}</span>
                            <span class="col-auto" style="font-weight: bold;">{{totalWithdraw |
                                number:'1.4-4'}}</span>
                        </div>
                        <div class="row mb-3"
                            style="background-color: #aaa; border: 1px; height: 1px; border: 0.5px 0px 0px 0px;"></div>
                        <div class="row align-items-center justify-content-between mb-3">
                            <span class="col-auto d-flex justify-content-end" style="color:#aaa">{{'Amount requested for
                                withdrawal' | translate}}</span>
                            <span class="col-auto" style="font-weight: bold;">{{summary?.withdraw_amount |
                                number:'1.4-4'}}</span>
                        </div>
                        <div class="row align-items-center justify-content-between">
                            <span class="col-auto d-flex justify-content-end" style="color:#aaa">{{'Transaction fees' |
                                translate}}</span>
                            <span class="col-auto" style="font-weight: bold;">{{sumOfFees | number:'1.4-4'}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        
        <h2 style="margin-top: 20px; font-weight: bold; ">{{'Transaction history' | translate}} </h2>
        
        <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
        
            <thead>
                <tr style="border-bottom: 1px solid;">
                    <th style="padding: 7px; text-align: left;"><h3 style="font-weight:bolder">{{ 'Employee ID' | translate }}</h3></th>
                    <th style="padding: 7px; text-align: left;"><h3 style="font-weight:bolder">{{ 'Requested amount (SAR)' | translate }}</h3></th>
                    <th style="padding: 7px; text-align: left;"><h3 style="font-weight:bolder">{{ 'Transaction fees (SAR)' | translate }}</h3></th>
                    <th style="padding: 7px; text-align: left;"><h3 style="font-weight:bolder">{{ 'withdrawn amount (SAR)' | translate }}</h3></th>
                    <th style="padding: 7px; text-align: left;"><h3 style="font-weight:bolder">{{ 'Status' | translate }}</h3></th>                  
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let record of list">
                    <td style="padding: 10px; text-align: left;">{{ record.employee_id }}</td>
                    <td style="padding: 10px; text-align: left;">{{ record. amount}}</td>
                    <td style="padding: 10px; text-align: left;">{{ record.fee }}</td>
                    <td style="padding: 10px; text-align: left;">{{ record.actual }}</td>
                    <td style="padding: 10px; text-align: left;">{{ record.status }}</td>
                </tr>
            </tbody>
        </table>
    </mat-card>

    
</div>
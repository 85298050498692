import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { ProfileService } from 'src/app/services/profile.service';
import { appConfig } from 'src/config';

@Component({
    selector: 'app-contribution-summary',
    templateUrl: './contribution-summary.component.html',
    styleUrls: ['./contribution-summary.component.scss']
})
export class ContributionSummaryComponent implements OnInit {
    Form: FormGroup;

    headersSummary = [
        { header: 'Fund Name', key: 'name', type: 'string-tooltip' },
        { header: 'Amount (%)', key: 'percentage' },
        { header: 'Amount (SAR)', key: 'amount', type: 'number-4' },
        { header: 'Current NAV per Unit', key: 'per_unit', type: 'number-5' },
        { header: 'Est Units', key: 'estimated', type: 'number-5' }
    ]
    list = []
    summary = {
        company: 0,
        employees: 0,
        total: 0
    };
    urlType: string = '';
    disableButton: boolean = false;
    isArabic: boolean = false;
    @ViewChild('picker') picker: MatDatepicker<Date>;

    constructor(protected formbuilder: FormBuilder,
        protected mainService: MainService,
        protected cryptoService: DecEncService,
        protected alertService: AlertService,
        public route: Router,
        public router: ActivatedRoute,
        protected profileService: ProfileService) {
        this.initForm();
        this.urlType = this.router.snapshot.paramMap.get('id');
    }

    ngOnInit(): void {
        this.getDetail();
        this.isArabic = localStorage.getItem('lang') == 'ar' ? true : false;
    }

    initForm() {
        this.Form = this.formbuilder.group({
            dated: [null],
            ip: [''],
            userName: [''],
            contributionPeriod: ['', Validators.required],
        });
    }

    getDetail() {
        let url = `draft_contributions/summary`;
        let enc_payload;
        if (this.urlType != 'new') {
            url = 'contributions/summary';
            let data = { "csv_id": this.urlType };
            enc_payload = this.cryptoService.encryption(data);
            this.mainService.postData(url, { enc_payload }).then(response => {
                if (response.statusCode == 200) {
                    let result = this.cryptoService.decryption(response.data);
                    this.list = result.funds;
                    result.summary.dated = result.summary?.dated ? moment(result.summary?.dated).format(appConfig.dateformatCMS) : null;
                    this.summary = result.summary;
                    this.Form.patchValue(this.summary);
                    this.Form.get('userName').setValue(this.profileService.user.name);
                    console.log('summary', this.summary);
                }
                else {
                    if (localStorage.getItem('lang') == 'ar') {
                        this.alertService.alert('Error', response.error.message_ar);
                    }
                    else {
                        this.alertService.alert('Error', response.error.message);
                    }
                }
            })
        } else {
            this.mainService.postData(url, {}).then(response => {
                if (response.statusCode == 200) {
                    let result = this.cryptoService.decryption(response.data);
                    this.list = result.funds.filter(item => item.amount > 0);
                    result.summary.dated = result.summary?.dated ? moment(result.summary?.dated).format(appConfig.dateformatCMS) : null;
                    result.summary.contributionPeriod = null;
                    this.summary = result.summary;
                    this.Form.patchValue(this.summary);
                    this.Form.get('userName').setValue(this.profileService.user.name);
                }
                else {
                    if (localStorage.getItem('lang') == 'ar') {
                        this.alertService.alert('Error', response.error.message_ar);
                    }
                    else {
                        this.alertService.alert('Error', response.error.message);
                    }
                }
            })
        }
    }

    submitContributions() {
        if (!this.checkValidations()) {
            return;
        }
        this.alertService.alertAsk('Confirmation', 'Are you sure you want to submit?', 'Yes', 'No', false).then(res => {
            if (res) {
                let url = `draft_contributions/generate`;
                let data = {
                    contribution_period: this.Form.get('contributionPeriod').value
                }
                let enc_payload = this.cryptoService.encryption(data);
                console.log('submit contributions', data);
                this.mainService.postData(url, { enc_payload }).then(response => {
                    if (response.statusCode == 200) {
                        this.alertService.alert('Success', 'Contribution has been sent successfully', '');
                        this.route.navigateByUrl('/main/manage-contribution');
                    }
                    else {
                        if (localStorage.getItem('lang') == 'ar') {
                            this.alertService.alert('Error', response.error.message_ar);
                        }
                        else {
                            this.alertService.alert('Error', response.error.message);
                        }
                    }
                })
            }
        })
    }

    checkValidations() {
        if (this.Form.invalid) {
            this.Form.markAllAsTouched();
            return false;
        }
        return true;
    }

    openDatePicker() {
        console.log('period value', this.Form.get('contributionPeriod').value);
        this.picker.open();
    }

    chosenMonthHandler(date) {
        date = moment(date).format('YYYY-MM-01');

        this.Form.get('contributionPeriod').setValue(date);
        console.log('month handler', date);
        this.picker.close();
    }

    cancel() {
        this.route.navigateByUrl('/main/manage-contribution/add');
    }

    isArabicLang(): boolean {
        return localStorage.getItem('lang') === 'ar';
      }
}

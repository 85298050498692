<div>
  <div fxLayout="row" fxLayoutAlign="space-around center">
    <img style="width: 160px; height: 160px;" src="assets/images/lcd.svg" alt="">
  </div>

  <h2 class="mid-head mt-20">{{'Welcome to Thriftplan!' | translate}}</h2>
  <div class="mt-20" fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="80">
      <h5 class="label">{{'Your account has been successfully created and you are now ready to start rewarding your employees!' | translate}}</h5>
    </div>
  </div>

  <div class="mt-20" fxLayout="row" fxLayoutAlign="end center">
    <div fxFlex="70">
      <div class="btn-group">
        <button (click)="onGetStarted()" class="btn-submit" mat-raised-button>{{'Get started' | translate}}</button>
      </div>
    </div>
  </div>
</div>

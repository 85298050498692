import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';

@Component({
  selector: 'app-tp-notifications',
  templateUrl: './tp-notifications.component.html',
  styleUrls: ['./tp-notifications.component.scss']
})
export class TpNotificationsComponent implements OnInit {
  list: any = [];
  totalPages: number;
  pages: any;
  perPage: number = 10;
  index: any = 1;
  currentPage: any = 1;
  count: any;
  key: any;
  NotificationIds: any[] = [];

  pagination = {
    page: 1,
    pages: null,
    per_page: null,
    count: 0
  }

  constructor(
    public tr: TranslationService,
    public route: Router,
    protected mainService: MainService,
    protected dec_enc: DecEncService,
    protected paginationService: PaginationService,
    protected dialog: MatDialog,
    protected alertService: AlertService,
  ) {
    this.key = localStorage.getItem('password_token');
  }

  ngOnInit(): void {
    this.getall(1);
  }

  getall(index: any) {
    let url = 'home/notifications?per_page=' + this.perPage + '&page=' + index
    this.mainService.postData(url, {}).then(res => {
      if (res.statusCode == 200) {
        let result = this.dec_enc.decryption(res.data);
        this.list = result.notifications;
        this.pagination = result.pagination;
        this.countValue();
      }
    })
  }

  setPage(page) {
    this.pagination.page = page;
    this.getall(this.pagination.page);
  }

  SetPageSize(pageSize) {
    this.perPage = pageSize
    this.getall(this.pagination.page);
  }

  countValue() {
    this.NotificationIds = [];
    let url = 'home/read_notifications';
    this.list.forEach(element => {
      this.NotificationIds.push(element.id);
    });
    let data =
    {
      ids: this.NotificationIds.join(','),
      type: "notifications"
    }
    let payload = this.dec_enc.encryption(data);
    this.mainService.postData(url, { enc_payload: payload }).then(res => {
      if (res.statusCode = 200) {
        this.tr.setApiOnCall(1);
        this.NotificationIds = [];
      }
    });
  }

  markAsRead() {
    let body = {
      type: 'notifications'
    };
    let payload = this.dec_enc.encryption(body);
    this.mainService.readNotifications(`read_all_notifications`, { enc_payload: payload }).then(response => {
      if (response.statusCode == 200) {
        this.mainService.setApiOnCall(1);
        if (localStorage.getItem('lang') == 'ar') {
          this.alertService.toastSuccess('النجاح', 'تم تمييز جميع الإشعارات كمقروءة.');
        }
        else {
          this.alertService.toastSuccess('Success', 'All announcements have been marked as read.');
        }
        // this.alertService.toastSuccess('Success', 'All notifications have been marked as read.');
      }
      else {
        if (localStorage.getItem('lang') == 'ar') {
          this.alertService.alert('Error', response.error.message_ar);
        }
        else {
          this.alertService.alert('Error', response.error.message);
        }
      }
    })
  }
}



import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-welcome-after-accept-dialog',
    templateUrl: './welcome-after-accept-dialog.component.html',
    styleUrls: ['./welcome-after-accept-dialog.component.scss']
})
export class WelcomeAfterAcceptDialogComponent implements OnInit
{

    constructor(protected dialogRef: MatDialogRef<WelcomeAfterAcceptDialogComponent>,) { }

    ngOnInit(): void
    {
        
    }

    onContact()
    {
        
    }

    closeDialog()
    {
        this.dialogRef.close();
    }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogNew, AlertResult } from '../lib/alert-dialog-new/alert.dialog.new';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(protected dialog: MatDialog, protected toaster: ToastrService) { }

  alertAsk(heading: string, message: string, rightButton: string, leftButton: string, hasInput: boolean)
    {
        var promise = new Promise((resolve, reject) =>
        {
            let dialogRef = this.dialog.open(AlertDialogNew, { autoFocus: false });
            dialogRef.componentInstance.alertData = {
                heading: heading,
                message: message,
                hasArabic: localStorage.getItem('lang') == 'ar' ? true : false,
                hasInput: hasInput,
                positiveButton: {
                    text: rightButton,
                    class: 'btn-blue',
                    visibility: true
                },
                negativeButton: {
                    text: leftButton,
                    class: 'btn-white',
                    visibility: true
                },
                neutralButton: {
                    text: '',
                    class: 'btn-blue',
                    visibility: false
                },
                type: 'ask',
            };

            dialogRef.afterClosed().subscribe(result =>
            {
                resolve(result);
            })
        });
        return promise;
    }

    alertAskwithCancel(heading: string, message: string, positiveButton: string, negativeButton: string, neutralButton: string, hasInput: boolean)
    {
        var promise = new Promise((resolve, reject) =>
        {
            let dialogRef = this.dialog.open(AlertDialogNew, { autoFocus: false });
            dialogRef.componentInstance.alertData = {
                heading: heading,
                message: message,
                hasArabic: localStorage.getItem('lang') == 'ar' ? true : false,
                hasInput: hasInput,
                positiveButton: {
                    text: positiveButton,
                    class: 'btn-blue',
                    visibility: true
                },
                negativeButton: {
                    text: negativeButton,
                    class: 'btn-white',
                    visibility: true
                },
                neutralButton: {
                    text: neutralButton,
                    class: 'btn-blue',
                    visibility: neutralButton ? true : false
                },
                type: 'ask',
            };

            dialogRef.afterClosed().subscribe((result: AlertResult) =>
            {
                resolve(result);
            })
        });
        return promise;
    }

    alert(heading: string, message: string, btnClass?: string): any
    {
        var promise = new Promise((resolve, reject) =>
        {
            let dialogRef = this.dialog.open(AlertDialogNew, { autoFocus: false, backdropClass: btnClass == 'no-access-bg' ? btnClass : '' });
            dialogRef.componentInstance.alertData = {
                heading: heading,
                message: message,
                hasInput: false,
                hasArabic: false,
                positiveButton: {
                    text: '',
                    class: 'btn-red',
                    visibility: true
                },
                negativeButton: {
                    text: 'Ok',
                    class: btnClass,
                    visibility: true
                },
                neutralButton: {
                    text: '',
                    class: 'btn-blue',
                    visibility: false
                },
                type: 'success',
            };

            dialogRef.afterClosed().subscribe(result =>
            {
                resolve(result);
            })
        });
        return promise;
    }

    alertAr(heading: string,data:any, btnClass?: string): any
    {
        var promise = new Promise((resolve, reject) =>
        {
            let dialogRef = this.dialog.open(AlertDialogNew, { autoFocus: false });
            dialogRef.componentInstance.alertData = {
                heading: heading,
                message: localStorage.getItem('lang') == 'ar' ? data.message_ar : data.message,
                hasInput: false,
                hasArabic:true,
                positiveButton: {
                    text: '',
                    class: 'btn-ok',
                    visibility: true
                },
                negativeButton: {
                    text: 'Ok',
                    class: 'btn-ok-sec',
                    visibility: true
                },
                neutralButton: {
                    text: '',
                    class: 'btn-blue',
                    visibility: false
                },
                type: 'success',
            };

            dialogRef.afterClosed().subscribe(result =>
            {
                resolve(result);
            })
        });
        return promise;
    }

    toastSuccess(title, message)
    {
        this.toaster.success(message, title);
    }

    toastMessage(message)
    {
        this.toaster.success(message);
    }
}

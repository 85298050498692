import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';

@Component({
    selector: 'app-subscription-documents-dialog',
    templateUrl: './subscription-documents-dialog.component.html',
    styleUrls: ['./subscription-documents-dialog.component.scss']
})
export class SubscriptionDocumentsDialogComponent implements OnInit
{
    document = null;
    list = [];
    noOfFiles = 0;
    constructor(protected dialogRef: MatDialogRef<SubscriptionDocumentsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        protected apiService: MainService,
        protected alertService: AlertService,
        protected cryptoService: DecEncService
        ) 
    {
        
    }

    ngOnInit(): void
    {
        this.getDocs();
    }

    getDocs()
    {
        let url = `contributions/subscription_docs`;
        let body = {
            csv_id: this.data.id
        }
        let enc_payload = this.cryptoService.encryption(body);
        this.apiService.postData(url, {enc_payload}).then(response => {
            if (response.statusCode == 200)
            {
                let data = this.cryptoService.decryption(response.data);
                this.list = data.docs;
                this.noOfFiles = data.docs.length;
                // console.log('getdocs', data)
            }
            else
            {
                if (localStorage.getItem('lang') == 'ar') {
                    this.alertService.alert('Error', response.error.message_ar);
                }
                else {
                    this.alertService.alert('Error', response.error.message);
                }
            }
        })
    }

    openDoc(document)
    {
        let url = this.apiService.file_url + document;
        window.open(url, '_blank');
    }

    closeDialog()
    {
        this.dialogRef.close();
    }
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label, Color, BaseChartDirective } from 'ng2-charts';
import { AlertService } from 'src/app/services/alert.service';
import { CongratulationsDialogComponent } from '../congratulations-dialog/congratulations-dialog.component';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
    list: any = [];
    employer_contribution: any;
    employee_contribution: any;
    profit: any;
    vested: any;
    unvested: any;
    fund: any;
    dataA: any = [];
    dataB: any = [];
    dataC: any = [];
    sum_of_fund: number;
    divide: number;
    date: any = new Date().getFullYear();
    dateB: any = new Date().getFullYear();
    sortData: any = [];
    newlist: any;
    employeeaggregate: any;

    totalPortfolio: number = 0;
    riskTypeString: string = '';

    public lineChartData: ChartDataSets[] = [
        { data: [], label: 'High' },
        { data: [], label: 'Medium' },
        { data: [], label: 'Low' },
    ];
    lineChartLabels: Label[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    public lineChartOptions = {
        responsive: true,
        elements: {
            line: {
                tension: 0.5
            }
        },
        scales: {
            xAxes: [{
                gridLines: {
                    display: false
                },
                scaleLabel: {
                    display: true,
                    labelString: "",
                },
            }],
            yAxes: [{
                gridLines: {
                    display: false
                },
                scaleLabel: {
                    display: true,
                    labelString: "",
                },
                ticks: {
                    callback: function (value, index, values) {
                        return Number(value).toFixed(4);
                    }
                }
            }]
        },
        plugins: {
            tooltip: {
                backgroundColor: '#213060'
            },
        }
    };
    public lineChartLegend = false;
    lineChartColors: Color[] = [
        {
            borderColor: '#213060',
            backgroundColor: 'rgba(43, 84, 123, 0.1)',
        },
        {
            borderColor: '#79BBBF',
            backgroundColor: 'rgba(43, 84, 123, 0.1)',
        },
        {
            borderColor: '#CCCCCC',
            backgroundColor: 'rgba(43, 84, 123, 0.1)',
        },
    ];
    @ViewChild(BaseChartDirective) chart: BaseChartDirective;
    portfolioPieChartLabels: Label[] = [['Company Contribution'], ['Employee Contribution'], 'Profit/Loss'];
    portfolioPieChartData = [
        {
            data: [],
            backgroundColor: ['#213060', '#79BBBF', '#CCCCCC'],
        },
    ];

    vestingPieChartLabels: Label[] = [['Vested'], 'Unvested'];
    vestingPieChartData = [
        {
            data: [],
            backgroundColor: ['#213060', '#79BBBF', '#CCCCCC'],
        },
    ];

    contributionPieChartLabels: Label[] = [['High Risk'], ['Medium Risk'], 'Low Risk'];
    contributionPieChartData = [
        {
            data: [],
            backgroundColor: ['#213060', '#79BBBF', '#CCCCCC'],
        },
    ];

    public pieChartLegend = false;
    barChartOptions: ChartOptions = {
        responsive: true,
        scales: {
            xAxes: [{
                gridLines: {
                    display: false
                }
            }],
            yAxes: [{
                gridLines: {
                    display: false
                },
                ticks: {
                    callback: function (value, index, values) {
                        return Number(value).toFixed(4);
                    }
                }
            }]
        },
    };

    barChartLabels: Label[] = ['High Risk', 'Medium Risk', 'Low Risk', 'Total Contributions'];
    barChartLegend = false;

    barChartData: ChartDataSets[] = [
        {
            data: [70, 60, 37, 45], label: 'Risk Type',
            backgroundColor: ['#213060', '#79BBBF', '#CCCCCC'],
        }
    ];

    riskKeys = {
        "low": "LOW",
        "medium": "MEDIUM",
        "high": "HIGH",
    }

    riskValues = {
        low: 0,
        medium: 0,
        high: 0
    }

    chartTypes = {
        low: [],
        medium: [],
        high: []
    }
    isArabic = false;

    constructor(
        public tr: TranslationService,
        public route: Router,
        public dec_enc: DecEncService,
        protected mainService: MainService,
        public paginationService: PaginationService,
        public dialog: MatDialog,
        protected alertService: AlertService,
        protected profileService: ProfileService
    ) {
        this.isArabic = localStorage.getItem('lang') == 'ar' ? true : false;
        if (localStorage.getItem('lang') == 'ar') {
            this.barChartData[0].label = this.tr.translation.Risk_Type;
            this.barChartLabels = ['مخاطرة مرتفعة', 'مخاطرة متوسطة', 'مخاطرة منخفضة', 'إجمالي المساهمة'];
            this.portfolioPieChartLabels = [['مساهمة جهة العمل'], ['مساهمة الموظف'], 'الربح'];
            this.vestingPieChartLabels = [['مستحقة'], 'غير مستحقة'];
            this.contributionPieChartLabels = [['مخاطرة مرتفعة'], ['مخاطرة متوسطة'], 'مخاطرة منخفضة'];
            this.lineChartLabels = ['يناير', 'فبراير', 'مارس', 'ابريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'];
        }
    }

    ngOnInit(): void {
        this.addYear();
        this.getall();
        // this.getRisk();
        this.getContributionChart();
        console.log('profile', this.profileService.user)
        // if (this.profileService.user.welcome_company) {
        //     let dialogRef = this.dialog.open(CongratulationsDialogComponent, 
        //     {   
        //         autoFocus: false, 
        //         width: '40%', 
        //         height: 'auto', 
        //         disableClose: true, 
        //         hasBackdrop: true, 
        //         backdropClass: 'no-access-bg', 
        //         panelClass: !this.isArabic ? 'english-lang' : 'arabic-lang' 
        //     });
        //     dialogRef.afterClosed().subscribe(res => {
        //         this.getProfile();
        //     })
        // }
    }

    getProfile() {
        let url = `viewProfile`;
        this.mainService.postData(url, {}).then(res => {
            if (res.statusCode == 200) {
                let result = this.dec_enc.decryption(res.data);
                this.profileService.user = result.user;
                return;
            }
        },
            error => {
                this.alertService.alert('Error', this.tr.translation.serverError);
            }
        )
    }

    dashboardData: any;
    companyTableProps = {
        hasSearch: false,
        hasButton: false,
        headerActions: [],
        rowActions: [],
        colHeader: {
            fund_name: "Portfolio",
            asset_name: "Fund",
            total_avaliable: "Available",
            company_available: "Company Units",
            employee_available: "Employee Units",
            total_invested: "Invested"
        },
        columnTypes: {
            total_avaliable: "number-5",
            company_available: "number-5",
            employee_available: "number-5",
            total_invested: "number-4"
        },
        filterArray: []
    };

    getall() {
        this.mainService.postData("home/portfolio", {}).then(res => {
            if (res.statusCode == 200) {

                this.dashboardData = this.dec_enc.decryption(res.data);
                if (this.dashboardData.portfolio_assets.length > 0) {
                    this.dashboardData.portfolio_assets.forEach(item => {
                        item['total_avaliable'] = this.toNumber(item['total_avaliable']);
                        item['company_available'] = this.toNumber(item['company_available']);
                        item['employee_available'] = this.toNumber(item['employee_available']);
                        item['total_invested'] = this.toNumber(item['total_invested']);
                    });
                }
                console.log('portfolio = ', this.dashboardData);
                this.riskTypeString = this.riskKeys[this.dashboardData.company_fund.risk_type];
                this.riskValues = this.dashboardData.risks;
                this.dashboardData.summary.total_portfolio_balance = this.toNumber(this.dashboardData.summary.total_portfolio_balance);
                this.dashboardData.summary.company_contribution = this.toNumber(this.dashboardData?.summary?.company_contribution);
                this.dashboardData.summary.employee_contribution = this.toNumber(this.dashboardData?.summary?.employee_contribution);
                this.dashboardData.summary.profit = this.toNumber(this.dashboardData?.summary?.profit);
                this.dashboardData.summary.total_contribution = this.toNumber(this.dashboardData?.summary?.total_contribution);
                this.dashboardData.portfolio_breakdown.total_portfolio_balance = this.toNumber(this.dashboardData?.portfolio_breakdown?.total_portfolio_balance);
                this.dashboardData.portfolio_breakdown.employee_contribution = this.toNumber(this.dashboardData?.portfolio_breakdown?.employee_contribution);
                this.dashboardData.portfolio_breakdown.company_contribution = this.toNumber(this.dashboardData?.portfolio_breakdown?.company_contribution);
                this.dashboardData.portfolio_breakdown.profit = this.toNumber(this.dashboardData?.portfolio_breakdown?.profit);
                this.dashboardData.vest_breakdown.total_portfolio_balance = this.toNumber(this.dashboardData?.vest_breakdown?.total_portfolio_balance);
                this.dashboardData.vest_breakdown.vested = this.toNumber(this.dashboardData?.vest_breakdown?.vested);
                this.dashboardData.vest_breakdown.unvested = this.toNumber(this.dashboardData?.vest_breakdown?.unvested);
                this.riskValues.high = this.toNumber(this.riskValues.high);
                this.riskValues.medium = this.toNumber(this.riskValues.medium);
                this.riskValues.low = this.toNumber(this.riskValues.low);
                this.portfolioBreakdownChart();
                this.vestingChart();
                this.contributionBarChart();
                this.contributionPieChart();
                this.contributionLineChart();
            }
        },
            error => {
                this.alertService.alert('Error', this.tr.translation.serverError);
            }
        )
    }

    getRisk() {
        let enc_payload = this.dec_enc.encryption({ year: this.date });
        this.mainService.postData("home/funds_contributions_yearly", { enc_payload }).then(res => {
            if (res.statusCode == 200) {
                let result = this.dec_enc.decryption(res.data);
                console.log("getRisk", result);
                result.funds.forEach(fund => {
                    this.riskValues[fund.risk_rating] = fund?.contribution?.total_contributions_amount ? Number(fund?.contribution?.total_contributions_amount) : 0;
                })

                this.sum_of_fund = Number(this.riskValues.low) + Number(this.riskValues.medium) + Number(this.riskValues.high)
                this.divide = Number(this.sum_of_fund) / 5;

                this.contributionPieChart();
                this.contributionBarChart();
            }
        },
            error => {
                this.alertService.alert('Error', this.tr.translation.serverError);
            }
        )
    }

    getContributionChart() {
        this.lineChartData[0].data = [];
        this.lineChartData[1].data = [];
        this.lineChartData[2].data = [];
        let enc_payload = this.dec_enc.encryption({ year: this.dateB })
        this.mainService.postData('home/funds_contributions_monthly', { enc_payload }).then(res => {
            if (res.statusCode == 200) {
                let result = this.dec_enc.decryption(res.data);
                console.log("getContributionChart", result);
                result.funds.forEach(fund => {
                    if (fund?.contributions.length > 0) {
                        fund?.contributions?.forEach(con => {
                            this.chartTypes[fund.risk_rating].push(con.total_contributions_amount);
                        })
                    }
                    else {
                        this.chartTypes[fund.risk_rating] = [];
                    }
                })
                this.contributionLineChart();
            }
        },
            error => {
                this.alertService.alert('Error', this.tr.translation.serverError);
            }
        )
    }


    sort(event) {
        this.date = event
        this.getRisk()
    }

    sortB(key, value) {
        this.dateB = key;
        this.year = value;
        console.log('Year', this.year);

        this.getContributionChart();
    }

    year = 'Current Year';
    addYear() {
        let d = new Date();
        let year = d.getFullYear();
        this.sortData = [
            {
                value: 'Current Year',
                key: year
            },
            {
                value: year - 1,
                key: year - 1
            },
            {
                value: year - 2,
                key: year - 2
            },
            {
                value: year - 3,
                key: year - 3
            },
            {
                value: year - 4,
                key: year - 4
            },
            {
                value: year - 5,
                key: year - 5
            }
        ]
    }

    portfolioBreakdownChart() {
        let data = [];
        data.push(this.dashboardData.summary.company_contribution);
        data.push(this.dashboardData.summary.employee_contribution);
        data.push(this.dashboardData.summary.profit);
        this.portfolioPieChartData[0].data = data;
        this.chart.update();
    }

    vestingChart() {
        let data = [];
        data.push(this.dashboardData.summary.total_vested);
        data.push(this.dashboardData.summary.total_unvested);
        this.vestingPieChartData[0].data = data;
        this.chart.update();
    }

    contributionPieChart() {
        let data = [];
        data.push(this.riskValues.high);
        data.push(this.riskValues.medium);
        data.push(this.riskValues.low);
        this.contributionPieChartData[0].data = data;
        this.chart.update();
    }

    contributionBarChart() {
        let data = [];
        data.push(this.riskValues.high);
        data.push(this.riskValues.medium);
        data.push(this.riskValues.low);
        data.push(this.dashboardData.summary.total_contribution);
        this.barChartData[0].data = data;
        this.chart.update();
    }

    contributionLineChart() {
        this.lineChartData[0].data = this.chartTypes.high;
        this.lineChartData[1].data = this.chartTypes.medium;
        this.lineChartData[2].data = this.chartTypes.low;
        this.chart.update();
    }

    toNumber(value) {
        value = isNaN(Number(value)) ? 0 : Number(value);
        return value;
    }

    ngOnDestroy(): void { }
}
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { ProfileService } from 'src/app/services/profile.service';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate
{
    role: string = '';
    self_onboard = '0';
    routesAllowed = {
        manageAdmins: 'employer-manager',
        manageEmployees: 'manage_employee',
        companyProfile: 'manage_orgnazation',
        notifications: 'activity-notifications',
        announcements: 'notification',
        hrPolicy: 'hr-policy',
        home: 'home',
    }

    constructor(private router: Router,
        protected profileService: ProfileService,
        protected mainService: MainService,
        protected cryptoService: DecEncService,
        protected alertService: AlertService
        ) {}

    canActivate(route: ActivatedRouteSnapshot): Promise<boolean>
    {
        let roles = route.data["roles"] as Array<string>;
        return new Promise(res =>
        {
            if (this.role) {
                if (roles.includes(this.role) || roles.includes("all")) {
                    res(true);
                    this.handleFeatureAccess(route);
                }
                else {
                    this.router.navigate(['/main']);
                    res(false);
                }
            }
            else {
                this.mainService.postData('viewProfile', {}).then(response => {
                    if (response.statusCode == 200) {
                        let decryptedResponse = this.cryptoService.decryption(response.data);
                        this.role = decryptedResponse?.user?.role;
                        this.self_onboard = decryptedResponse?.user?.self_onboard;
                        localStorage.setItem('self_onboard', this.self_onboard);
                        console.log(" this.role", decryptedResponse)
                        this.profileService.user = decryptedResponse?.user;
                        if (this.role) {
                            if (roles.includes(this.role) || roles.includes("all")) {
                                res(true);
                                this.handleFeatureAccess(route);
                            }
                            else {
                                this.router.navigate(['/main']);
                                res(false);
                            }
                        }
                    }
                })
            }
        })
    }

    handleFeatureAccess(route)
    {

        if (this.self_onboard == '1') {
            let routeFound = false;
            for (const key in this.routesAllowed) {
                if (route.routeConfig.path.includes(this.routesAllowed[key])) {
                    routeFound = true;
                }
            }
            if (!routeFound) {
                this.alertService.alert('Reminder', `Sorry, you don't have access to this feature. Your account is still under verification.`, 'no-access-bg').then(res => {
                    this.router.navigateByUrl('/main/employer-manager');
                })
            }
        }
    }
}


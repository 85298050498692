<div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <h3 class="form-heading" *ngIf="routeType == 'add'">{{'Create_New_Admin' | translate}}</h3>
    <h3 class="form-heading" *ngIf="routeType == 'update'">{{'Update_Admin' | translate}}</h3>
    <h3 class="form-heading" *ngIf="routeType == 'view'">{{'View Admin' | translate}}</h3>

    <form [formGroup]="Form">
        <div fxLayout="row wrap" fxLayoutGap="10px" class="form-container">
            <mat-card fxFlex="31" class="card-selectable" [class.card-view-mode]="routeType == 'view'"
                [class.card-error]="Form.get('role').touched && Form.get('role').invalid"
                [class.selected-card]="role.maker" (click)="setRole('emp-maker', 'maker')">
                <h4 class="fw-b">{{'Emp_Maker' | translate}}</h4>
                <p>{{'Emp_Maker_Desc' | translate}}</p>
            </mat-card>

            <mat-card fxFlex="31" class="card-selectable" [class.card-view-mode]="routeType == 'view'"
                [class.card-error]="Form.get('role').touched && Form.get('role').invalid"
                [class.selected-card]="role.checker" (click)="setRole('emp-checker', 'checker')">
                <h4 class="fw-b">{{'Emp_Checker' | translate}}</h4>
                <p>{{'Emp_Checker_Desc' | translate}}</p>
            </mat-card>

            <mat-card fxFlex="31" class="card-selectable" [class.card-view-mode]="routeType == 'view'"
                [class.card-error]="Form.get('role').touched && Form.get('role').invalid"
                [class.selected-card]="role.finance" (click)="setRole('emp-finance', 'finance')">
                <h4 class="fw-b">{{'Emp_Finance' | translate}}</h4>
                <p>{{'Emp_Finance_Desc' | translate}}</p>
            </mat-card>

            <h3 fxFlex="100" class="form-heading-inside">{{'Basic_Information' | translate}}</h3>

            <mat-form-field fxFlex="48" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                appearance="outline">
                <mat-label>{{'Manager_Name' | translate}}</mat-label>
                <input autocomplete="off" type="text" matInput formControlName="name">
                <mat-icon class="mat-icon-error" *ngIf="getFieldValue('name')" #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('name', 'Manager_Name_Required')" [matTooltipDisabled]="!getFieldValue('name')"
                    matTooltipPosition="above" matSuffix>info
                </mat-icon>
            </mat-form-field>

            <mat-form-field fxFlex="48" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                appearance="outline">
                <mat-label>{{'Contact_Number' | translate}}</mat-label>
                <input dir="ltr" style="text-align: left;" autocomplete="off" type="text" matInput
                    formControlName="phone">
                <span *ngIf="!isArabic" matPrefix>+966</span>
                <span dir="ltr" *ngIf="isArabic" matSuffix>+966</span>
                <mat-icon class="mat-icon-error" *ngIf="getFieldValue('phone')" #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('phone', 'Contact_Number_Required')"
                    [matTooltipDisabled]="!getFieldValue('phone')" matTooltipPosition="above" matSuffix>info
                </mat-icon>
            </mat-form-field>

            <mat-form-field fxFlex="97" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                appearance="outline">
                <mat-label>{{'Address' | translate}}</mat-label>
                <input autocomplete="off" type="text" matInput formControlName="address">
                <mat-icon class="mat-icon-error" *ngIf="getFieldValue('address')" #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('address', 'Address_Required')"
                    [matTooltipDisabled]="!getFieldValue('address')" matTooltipPosition="above" matSuffix>info
                </mat-icon>
            </mat-form-field>

            <mat-form-field [fxFlex]="id ? 97 : 48" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                appearance="outline">
                <mat-label>{{'Email' | translate}}</mat-label>
                <input autocomplete="off" type="text" matInput formControlName="email">
                <mat-icon class="mat-icon-error" *ngIf="getFieldValue('email')" #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('email', 'Email_Required')" [matTooltipDisabled]="!getFieldValue('email')"
                    matTooltipPosition="above" matSuffix>info
                </mat-icon>
            </mat-form-field>

            <mat-form-field *ngIf="!id" fxFlex="24" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                appearance="outline">
                <mat-label>{{'Create_New_Password' | translate}}</mat-label>
                <input autocomplete="off" type="password" matInput formControlName="password">
                <mat-icon class="mat-icon-error" *ngIf="getFieldValue('password')" #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('password', 'New_Password_Required')"
                    [matTooltipDisabled]="!getFieldValue('password')" matTooltipPosition="above" matSuffix>info
                </mat-icon>
            </mat-form-field>

            <mat-form-field *ngIf="!id" fxFlex="23.3" style="width: 100%;" class="input-field mt-10"
                fxFlexAlign="center" appearance="outline">
                <mat-label>{{'Confirm_New_Password' | translate}}</mat-label>
                <input autocomplete="off" type="password" matInput formControlName="confirm_password">
                <mat-icon class="mat-icon-error" *ngIf="getFieldValue('confirm_password')" #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('confirm_password', 'Confirm_Password_Required')"
                    [matTooltipDisabled]="!getFieldValue('confirm_password')" matTooltipPosition="above" matSuffix>info
                </mat-icon>
            </mat-form-field>

            <h3 fxFlex="100" class="form-heading-inside">{{'ID_Information' | translate}}</h3>

            <!-- <mat-card class="card mt-20" fxFlex="48" fxFlex.sm="93.5" fxFlex.xs="97" fxLayout="row" fxLayout.xs="column"
                style="height: 162px; border-radius: 20px;" ngStyle.xs="height:280px;">
                <input style="display: none;" type="file" #attach (change)="getFile($event)" accept=".png, .jpg, .jpeg">
                <button [class.upload-view-mode]="routeType == 'view'"
                    [class.upload-view-mode-op]="routeType == 'view' && !imgToShow" mat-button class="upload-button"
                    (click)="attach.click()">
                    <ng-container *ngIf="!imgToShow">
                        <mat-icon>upload</mat-icon>
                        <h2>{{'Upload_Files' | translate}}</h2>
                        <h5>{{'File_Types' | translate}}</h5>
                    </ng-container>
                    <ng-container *ngIf="imgToShow">
                        <img style="width: 100%; height: 100%;" [src]="imgToShow">
                    </ng-container>
                </button>
                <span class="button-text" fxFlexOffset="6" ngStyle.xs="margin-left:22px;">
                    <h2>{{'National_Iqama_Scanned' | translate}}</h2>
                </span>
            </mat-card> -->

            <div fxLayout="row wrap" fxFlex="100" fxLayoutGap="10px">
                <mat-form-field fxFlex="48" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                    appearance="outline">
                    <mat-label>{{'National_Iqama_ID' | translate}}</mat-label>
                    <input dir="ltr" style="text-align: left;" autocomplete="off" type="text" matInput
                        formControlName="iqama_id">
                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue('iqama_id')" #tooltip="matTooltip"
                        [matTooltip]="getErrorMessage('iqama_id', 'Iqama_Required')"
                        [matTooltipDisabled]="!getFieldValue('iqama_id')" matTooltipPosition="above" matSuffix>info
                    </mat-icon>
                </mat-form-field>

                <mat-form-field fxFlex="48" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                    appearance="outline">
                    <mat-label>{{'Iqama_Expiry_Date' | translate}}</mat-label>
                    <!-- <input autocomplete="off" type="text" matInput formControlName="iqama_expiry"> -->

                    <input (click)="openDatePicker()" matInput type="text" formControlName="iqama_expiry"
                        [matDatepicker]="picker1" [min]="currentDate" readonly />
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>

                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue('iqama_expiry')" #tooltip="matTooltip"
                        [matTooltip]="getErrorMessage('iqama_expiry', 'Iqama_Expiry_Required')"
                        [matTooltipDisabled]="!getFieldValue('iqama_expiry')" matTooltipPosition="above" matPrefix>info
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="button-container mt-20" fxLayoutAlign="end" fxFlex="97" fxLayoutGap="10px">
                <button (click)="cancel()"
                    style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 25px; border-radius: 10px;"
                    mat-raised-button>{{'Cancel' | translate}}</button>
                <button *ngIf="routeType != 'view'" (click)="onSubmit()"
                    style="background-color: #213060; color: white; line-height: 34px; padding: 0 25px; border-radius: 10px;"
                    mat-raised-button>{{'Submit' |translate}}</button>
            </div>
        </div>
    </form>
</div>
<div class="main mx-5">

<div class=" pt-1">
<p>Home > Manage Withdrawal > View Withdrawal Request</p>
<h2>View Withdrawal Request</h2>
</div>
<mat-divider></mat-divider>

<div class="mt-4 profile-card">
    <mat-card>
        <div class="card-set">
        <h2>View Withdrawal Request</h2> 
        <form [formGroup]="Form">

   
      
            <div class="form-field">
              <mat-form-field >
                <mat-label>Withdrawal Date of Request</mat-label>
                <input matInput type="text" formControlName="on_date"[matDatepicker]="picker" readonly>
                <mat-datepicker-toggle matSuffix [for]="picker" disabled></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
    
              <mat-form-field>
                <mat-label>Withdrawal Unit</mat-label>
                <input matInput type="text" formControlName="units" readonly/>
              </mat-form-field>
              <!-- <mat-form-field>
                <mat-label>Notes</mat-label>
                <input matInput type="text" formControlName="notes" readonly/>
              </mat-form-field> -->
       
            </div>
        
          
          </form>
       </div>

       


    

    </mat-card>

  

   
</div>
</div>

<div>
  <h2>{{'Initiate the Termination Process' | translate}}</h2>
  <p>{{'You are about to submit a request to terminate an emplyee permanently and to withdraw any contributions and investments' | translate}} <br>
    {{'This action is irreversible once the Fund Manager has started processing it' | translate}}<br>{{'You may long cancel it as long as the request status is Termination Pending' | translate}}
  </p>
</div>
<div>
  <form [formGroup]="Form">
    <div>
      <mat-form-field appearance="outline" style="width:100% ;" class="input-field mt-10">
        <mat-label>{{'Reason for Termination' | translate}}</mat-label>
        <mat-select formControlName="type">
          <mat-option value="death">{{'Death' | translate}}</mat-option>
          <mat-option value="retired">{{'Retired' | translate}}</mat-option>
          <mat-option value="fired">{{'Fired' | translate}}</mat-option>
          <mat-option value="resigned">{{'Resigned' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 100%;" class="input-field mt-10">
        <mat-label>{{'Last Working Day' | translate}}</mat-label>
        <input matInput
               type="text"
               formControlName="last_date"
               [matDatepicker]="picker"
               readonly />
        <mat-datepicker-toggle matSuffix
                               [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div style="text-align:end; margin-bottom: 15px; margin-top: 10px;">
      <button (click)="onCancelClick()" style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px; outline: none;" mat-raised-button>{{'Cancel' | translate}}</button>
      <button (click)="onOkClick()" [disabled]="Form.invalid" style="margin-inline: 10px; outline: none;" class="btn-submit" mat-raised-button>{{'Continue and Verify' | translate}}</button>
      <!-- <button (click)="onCancelClick()"
              class="btn m-2 button">Cancel</button>

      <button (click)="onOkClick()"
              class="btn m-2"
              [disabled]="Form.invalid"
              [ngClass]="Form.invalid ? 'button-disabled' : 'button'">Continue and Verify</button> -->
    </div>

  </form>
</div>
<div *ngIf="showOtp">
  <p>{{'A One-Time 6 Digits Code has been sent to your Email' | translate}}</p>
  <form [formGroup]="FormA">
    <div>
      <mat-form-field appearance="outline" style="width: 100%;" class="input-field mt-10">
        <mat-label>{{'Enter OTP' | translate}}</mat-label>
        <input matInput
               type="text"
               formControlName="otp" />
      </mat-form-field>

    </div>
    <div style="margin-top:12px">
      <div fxLayoutAlign="center end"
           class="row wrap">
        <div fxFlex="100"
             style="text-align: center;">
          <b style="font-size: 14px; color:blue">{{timerValue}}</b>
        </div>
      </div>

      <div class="row-wrap"
           fxLayoutAlign="center center"
           style="margin-top: 12px;">
        <div fxFlex="100"
             style="text-align: center;">
          <a [ngStyle]="timerRunning?{'color': 'red'} : {'color': 'blue'}"
             (click)="onResend()"
             style="cursor: pointer;">
            <u>{{'Resend OTP' | translate}}</u>

          </a>
        </div>
      </div>
    </div>
    <div style="text-align:end; margin-top: 10px;">
      <button (click)="onCancelClick()" style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px; outline: none;" mat-raised-button>{{'Cancel' | translate}}</button>
      <button [disabled]="FormA.invalid" (click)="onValidateOTP()" style="margin-inline: 10px; outline: none;" class="btn-submit" mat-raised-button>{{'Submit' | translate}}</button>
      <!-- <button (click)="onCancelClick()"
              class="btn m-2 button">Cancel</button>
      <button (click)="onValidateOTP()"
              class="btn m-2"
              [disabled]="FormA.invalid"
              [ngClass]="FormA.invalid ? 'button-disabled' : 'button'">Submit</button> -->
    </div>
  </form>
</div>
<div>
    <h2 class="mid-head">{{'Onboard your employees' | translate}}</h2>

    <div fxLayout="row" fxLayoutAlign="space-around center">
        <!-- <div class="btn-links" fxFlex="53"> -->
        <div fxFlex="30" (click)="selectOption('invite')" class="small-Btn"
            [ngClass]="{ 'selectedBox': selectedOption == 'invite' }">{{'Invite' | translate}}</div>
        <div fxFlex="30" (click)="selectOption('copy')" class="small-Btn"
            [ngClass]="{ 'selectedBox': selectedOption == 'copy' }">{{'Copy Link' | translate}}</div>
        <div fxFlex="30" (click)="selectOption('manual')" class="small-Btn"
            [ngClass]="{ 'selectedBox': selectedOption == 'manual' }">{{'Manual' | translate}}</div>
        <!-- </div> -->
    </div>

    <h5 [style.text-align]="isArabic ? 'right' : 'left'" *ngIf="selectedOption == 'invite'" class="mt-20 label">{{'Enter email address of employees' | translate}}</h5>
    <h5 [style.text-align]="isArabic ? 'right' : 'left'" *ngIf="selectedOption == 'copy'" class="mt-20 label">{{'Please share this URL with employees' | translate}}</h5>

    <ng-container *ngIf="selectedOption == 'invite'">
        <div fxLayout="row wrap">
            <mat-form-field fxFlex="100" fxFlex.xs="100" style="width: 50%;" class="input-field" appearance="outline">
                <mat-label>{{'Email' | translate}}</mat-label>
                <input placeholder="name1@yourcompany.sa, name2@yourcompany.sa" [(ngModel)]="email"
                    (keyup)="checkMail($event)" autocomplete="off" type="text" matInput>
            </mat-form-field>
        </div>
        <div class="chip-list">
            <div style="display: flex;flex-wrap: wrap; gap: 5px;">
                <div *ngFor="let item of emailList">
                    <span class="chip">
                        {{ item }}
                        <mat-icon class="cp" (click)="remove(item)">cancel</mat-icon>
                    </span>
                </div>
            </div>
        </div>

        <!-- <h5 *ngIf="selectedOption == 'invite'" class="label mt-10">Please make sure that the email domain is correct: <br><span style="color:#EE5D50;">@yourcompany.sa</span></h5> -->
    </ng-container>

    <ng-container *ngIf="selectedOption == 'copy'">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="94">
                <div class="copyLink">
                    <div style="flex: 90; padding: 10px; overflow-x: auto; white-space: nowrap;">{{link}}</div>
                    <div style="border-left: 2px solid;padding: 10px;">
                        <img (click)="onRefresh()" src="assets/images/repeat.png" alt="">
                    </div>
                </div>
            </div>
            <div fxFlex="5">
                <img (click)="onCopy(link)" src="assets/images/copy.png">
            </div>
        </div>
    </ng-container>

    <div class="mt-20" fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="70">
            <div class="btn-group">
                <button (click)="onSkip()" class="btn-submit" mat-raised-button>{{'Skip for now' | translate}}</button>
                <button (click)="onInvite()" *ngIf="selectedOption == 'invite'" [disabled]="emailList.length == 0"
                    style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
                    mat-raised-button>{{'Invite now' | translate}}
                </button>
            </div>
        </div>
    </div>

    <div class="mt-20 info center">
        {{'Note: You can invite your employees anytime by logging in to the portal.' | translate}}
    </div>

</div>
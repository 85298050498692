<div fxLayout="row" fxLayoutAlign="space-between">
    <h3 style="font-weight: bold; margin: 10px 0 0 40px; color: #213060;">{{'View_Contributions' | translate}}</h3>
    <button *ngIf="viewStatus == 1" (click)="openDialog()" style="background-color: #213060; color: white;
    line-height: 34px; padding: 0 25px; border-radius: 10px; outline: none; margin-inline: 40px;"
        mat-raised-button>{{'View Subscription Form' | translate}}
    </button>
</div>

<form [formGroup]="Form">
    <div fxLayout="row wrap" fxLayoutGap="10px" style="margin: 20px 20px 20px 40px;" class="form-container">
        <mat-form-field fxFlex="48" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
            appearance="outline">
            <mat-label>{{'Contribution_ID' | translate}}</mat-label>
            <input readonly autocomplete="off" type="text" matInput formControlName="id">
        </mat-form-field>

        <mat-form-field fxFlex="48" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
            appearance="outline">
            <mat-label>{{'Employer_ID' | translate}}</mat-label>
            <input readonly autocomplete="off" type="text" matInput formControlName="employer_id">
        </mat-form-field>



        <mat-card class="card mt-10" fxFlex="48" fxFlex.sm="93.5" fxFlex.xs="97" fxLayout="row" fxLayout.xs="column"
            style="height: 162px; border-radius: 20px;" ngStyle.xs="height:280px;">
            <input style="display: none;" type="file" #attack (change)="getFile($event)" accept=".png, .jpg, .jpeg">
            <button mat-button class="upload-button upload-view-mode-op upload-view-mode" (click)="attack.click()">
                <ng-container *ngIf="!imgToShow">
                    <mat-icon>upload</mat-icon>
                    <h2>{{'Upload_Files' | translate}}</h2>
                    <h5>{{'File_Types' | translate}}</h5>
                </ng-container>
                <ng-container *ngIf="imgToShow">
                    <img style="width: 100%; height: 100%;" [src]="imgToShow">
                </ng-container>
            </button>
            <span class="button-text" fxFlexOffset="6" ngStyle.xs="margin-left:22px;">
                <h2 style="color: #213060; font-weight: bold;">{{'Contribution_Receipt' | translate}}</h2>
                <!-- <button (click)="uploadReceipt()" style="background-color: #213060; 
                    color: white; line-height: 34px; 
                    padding: 0 25px; border-radius: 10px; outline: none;" mat-raised-button>{{'upload' | translate}}
                </button> -->
            </span>
        </mat-card>

        <div fxLayout="row wrap" fxFlex="48" fxLayoutAlign="start">
            <mat-form-field fxFlex="100" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                appearance="outline">
                <mat-label>{{'Employer_Name' | translate}}</mat-label>
                <input readonly autocomplete="off" type="text" matInput formControlName="employer_name">
            </mat-form-field>

            <mat-form-field fxFlex="100" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                appearance="outline">
                <mat-label>{{'Created_At' | translate}}</mat-label>
                <input readonly autocomplete="off" type="text" matInput formControlName="created_at">
            </mat-form-field>
            <mat-form-field fxFlex="100" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                appearance="outline">
                <mat-label>{{'Status' | translate}}</mat-label>
                <input readonly autocomplete="off" type="text" matInput formControlName="status">
            </mat-form-field>
        </div>

        <h3 fxFlex="100" class="heading-below">{{'Contribution_Banks_Details' | translate}}</h3>

        <mat-form-field fxFlex="48" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
            appearance="outline">
            <mat-label>{{'Beneficiary_name' | translate}}</mat-label>
            <input [(ngModel)]="cont_beneficiary_name" [ngModelOptions]="{standalone: true}" readonly autocomplete="off"
                type="text" matInput>
        </mat-form-field>
        <mat-form-field fxFlex="48" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
            appearance="outline">
            <mat-label>{{'Account_number' | translate}}</mat-label>
            <input [(ngModel)]="cont_account_number" [ngModelOptions]="{standalone: true}" readonly autocomplete="off"
                type="text" matInput>
        </mat-form-field>
        <mat-form-field fxFlex="48" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
            appearance="outline">
            <mat-label>{{'IBAN_number' | translate}}</mat-label>
            <input [(ngModel)]="cont_iban_number" [ngModelOptions]="{standalone: true}" readonly autocomplete="off"
                type="text" matInput>
        </mat-form-field>
        <mat-form-field fxFlex="48" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
            appearance="outline">
            <mat-label>{{'SWIFT Number' | translate}}</mat-label>
            <input [(ngModel)]="cont_swift_number" [ngModelOptions]="{standalone: true}" readonly autocomplete="off"
                type="text" matInput>
        </mat-form-field>
        <mat-form-field fxFlex="48" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
            appearance="outline">
            <mat-label>{{'Name_of_bank' | translate}}</mat-label>
            <input [(ngModel)]="cont_bank_name" [ngModelOptions]="{standalone: true}" readonly autocomplete="off"
                type="text" matInput>
        </mat-form-field>

        <!-- <mat-card fxFlex="97" class="table-card" fxLayout="row wrap">
             <app-table #table fxFlex="100" [dataSource]="items" [columnTypes]="columnTypes"
                [colHeader]="colHeader">
            </app-table> 
        </mat-card>-->

        <app-table class="mt-20 mb-20" fxFlex="97" [tableProps]="tableProps" [dataSource]="items">
        </app-table>

        <div fxFlex="97" fxLayoutAlign="end none">
            <button (click)="back()" style="background-color: #E9EAEF; color: #213060;
            line-height: 34px; padding: 0 25px; border-radius: 10px; outline: none;" mat-raised-button>{{'Back' |
                translate}}</button>
        </div>
    </div>
</form>
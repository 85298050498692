<div id="content" *ngIf="!showPdf" class="main pt-1 mrl-20 mt-10" ngClass.sm="mrl-10" ngClass.xs="mrl-10"
  style="max-height: 100vh; overflow-y: auto;">
  <h2 style="font-weight: bolder; color: #213060; text-align: center;">
    {{'Confirm Disbursement' | translate}}
  </h2>
  <div class="container">
    <div class="label-column">
      <div class="label">{{'Total amount to be disbursed' | translate}}</div>
      <div class="label">{{'Selected amount' | translate}}</div>
      <div class="label">{{'Remaining balance' | translate}}</div>
    </div>
    <div class="value-column">
      <div class="value" style="color: #27274F; font-weight: bold;">{{totalAmount | number:'1.4-4'}}</div>
      <div class="value" style="color: #27274F; font-weight: bold;">{{selectedAmount | number:'1.4-4'}}</div>
      <div class="value" style="color: red; font-weight: bold;">{{remainingBalance | number:'1.4-4'}}</div>
    </div>
    <div class="button-column">
      <button (click)="downloadPDF()"
        style="background-color: #213060; color: white; border-radius: 10px; outline: none; height: 35px;"
        mat-raised-button>{{'Download PDF' | translate}}</button>
      <button (click)="downloadCSV()"
        style="background-color: #213060; color: white; border-radius: 10px; outline: none; height: 35px;"
        mat-raised-button>{{'Download CSV' | translate}}</button>
    </div>
  </div>
  <div class="main pt-1 mrl-5 mt-10" ngClass.sm="mrl-5" ngClass.xs="mrl-5">
    <app-table *ngIf="pdfDownload" fxFlex="100" [tableProps]="tableProps" [dataSource]="list"
      (setPage)="setPage($event)" (pageSize)="SetPageSize($event)" (emitCheckBox)="checkBox($event)">
    </app-table>
    <!-- <app-table *ngIf="!pdfDownload" fxFlex="100" [tableProps]="pdftableProps" [dataSource]="list"
      (setPage)="setPage($event)" (pageSize)="SetPageSize($event)" (emitCheckBox)="checkBox($event)">
    </app-table> -->
  </div>

  <div class="mt-20 mb-20" fxLayoutAlign="center center" fxLayoutGap="10px" id="!contentToPrint">
    <button (click)="onCancel()"
      style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;"
      mat-raised-button>{{'Cancel' | translate}}</button>
    <button class="btn-submit" [disabled]="btnDisabled" (click)="confirm()" mat-raised-button>{{'Confirm'
      | translate}}</button>
  </div>
</div>


<!--Print-->

<div *ngIf="showPdf" id="contentToExport" class="main pt-1 mrl-20 mt-10" ngClass.sm="mrl-10" ngClass.xs="mrl-10"
  style="max-height: 100vh; overflow-y: auto;margin:20px">
  <h2 style="font-weight: bolder; color:black; text-align: center;">
    {{'Confirm Disbursement' | translate}}
  </h2>
  <div class="container" style="display:flex;align-items: center; justify-content: center;">
    <div class="label-column">
      <div class="label" style="font-weight: bold; color:black;">{{'Total amount to be disbursed' | translate}}</div>
      <div class="label" style="font-weight: bold; color:black;">{{'Selected amount' | translate}}</div>
      <div class="label" style="font-weight: bold; color:black;">{{'Remaining balance' | translate}}</div>
    </div>
    <div class="value-column">
      <div class="value" style="color:black;">{{totalAmount | number:'1.4-4'}}</div>
      <div class="value" style="color:black;">{{selectedAmount | number:'1.4-4'}}</div>
      <div class="value" style="color:black;">{{remainingBalance | number:'1.4-4'}}</div>
    </div>
  </div>
  
  <div class="main pt-1 mrl-10 mt-10" ngClass.sm="mrl-5" ngClass.xs="mrl-5">
    <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px; margin-left: 10px;">
        
      <thead>
          <tr style="border-bottom: 1px solid;">
              <th style="padding: 7px; text-align: left;"><h5 style="font-weight:bolder">{{ 'Employee ID' | translate }}</h5></th>
              <th style="padding: 7px; text-align: left;"><h5 style="font-weight:bolder">{{ 'Employee Name' | translate }}</h5></th>
              <th style="padding: 7px; text-align: left;"><h5 style="font-weight:bolder">{{ 'Withdrawl Amount' | translate }}</h5></th>
              <th style="padding: 7px; text-align: left;"><h5 style="font-weight:bolder">{{ 'Fund fees (SAR)' | translate }}</h5></th>
              <th style="padding: 7px; text-align: left;"><h5 style="font-weight:bolder">{{ 'Actual Payout' | translate }}</h5></th>                  
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let record of list">
              <td style="margin-left:10px; text-align: left;padding: 10px;font-size: 12px;">{{ record.id }}</td>
              <td style="padding: 10px; text-align: left;font-size: 12px;">{{ record. employee_name}}</td>
              <td style="padding: 10px; text-align: left;font-size: 12px;">{{ record.amount }}</td>
              <td style="padding: 10px; text-align: left;font-size: 12px;">{{ record.fee }}</td>
              <td style="padding: 10px; text-align: left;font-size: 12px;">{{ record.actual}}</td>
          </tr>
      </tbody>
  </table>
  </div>
</div>


import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InviteEmployeeDialogComponent } from 'src/app/lib/invite-employee-dialog/invite-employee-dialog.component';
import { OnBoardCompleteDialogComponent } from 'src/app/lib/on-board-complete/on-board-complete-dialog.component';
import { TokenService } from 'src/app/lib/token/token.service';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-register-password',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit
{

    firstForm: FormGroup;
    secondForm: FormGroup;
    thirdForm: FormGroup;
    fourthForm: FormGroup;
    isLinear = false;
    currentIndexOfStepper: number = 0;
    LOGIN_KEY = 'login_status';
    selectedOption = 'noReward';
    noOfYears = 1;
    plan = 'monthly';
    formData = {};
    // siteKey = '6LdtCrInAAAAAO0up3TzpeEEKjc1IZrheq6sb-p4'; saad's
    siteKey = '6Le3T7InAAAAAHY1PwKrJtrekbbYc0k_OAkD9UEH';
    captchaVerified = false;
    isArabic = false;

    constructor(
        protected authService: AuthService,
        protected route: Router,
        protected alertService: AlertService,
        protected dialog: MatDialog,
        protected translateService: TranslateService,
        private _formBuilder: FormBuilder,
        public authorization: TokenService
        )
    {
        this.isArabic = localStorage.getItem('lang') == 'ar' ? true : false;
        this.firstForm = this._formBuilder.group({
            id: [null],
            cr_no: [null, [Validators.required, Validators.pattern('1[0-9]+[0-9]*'), Validators.maxLength(10), Validators.minLength(10)]],
            company_name: [null, Validators.required],
            // company_website: [null, [Validators.required, Validators.pattern('^(http[s]?:\\/\\/)([w]{3}\\.)?([a-zA-Z0-9.-]+)\\.([a-zA-Z]{2,6})([\\/\\w .-]*)*\\/?$')]],
            // company_website: [null, [Validators.required, Validators.pattern('^(https?://)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(/\S*)?$')]],
            // company_website: [null, [Validators.required, Validators.pattern('^(https?://)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,6})(/\S*)?$')]],
            // company_website: [null, [Validators.required, Validators.pattern('^(https?://|www\.)?[a-zA-Z0-9-]+\.(com|co|uk|pk|org|net|edu|gov|mil|info|biz)(/\S*)?$')]],
            // company_website: [null, [Validators.required, Validators.pattern('^(https?://|www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(/\S*)?$')]],
            company_website: [null, [Validators.required, Validators.pattern('^(https?://|www\.)?[a-zA-Z0-9-]+\.(com|co|io|org|net|edu|gov|mil|info|biz|sa|com\.sa)(/\S*)?$')]],
            no_of_employees: [null, Validators.required]
        })

        this.secondForm = this._formBuilder.group({
            company_email: [null, [Validators.required, Validators.email]],
            company_phone: [null, [Validators.required, Validators.pattern('[1-9]+[0-9]*'), Validators.minLength(9), Validators.maxLength(9)]],
            company_id: [null, [Validators.required, Validators.pattern('[0-9]+[0-9]*'), Validators.maxLength(10), Validators.minLength(10)]],
        })

        this.thirdForm = this._formBuilder.group({
            employee_reward: ['noReward'], //gradually, fixed, none
            employee_reward_year: [1, Validators.required],
            employee_reward_level: [0, Validators.required],
        })

        this.fourthForm = this._formBuilder.group({
            subscription: ['monthly', Validators.required], //monthly /annually
            accept_terms: [0, Validators.required],
            accept_privacy_policy: [0, Validators.required],
            accept_promotions: [1, Validators.required],
        })
        // employee_reward_level is conditional on employee_reward
        // If gradually then value will be 1year, 3year, 5year
        // If fixed then value will be 0 to 100%
        // If none then hide employee_reward_level

        this.startCall();
    }

    ngOnInit(): void
    {}

    onStepChange(event)
    {
        this.currentIndexOfStepper = event.selectedIndex;
    }

    onNext()
    {
        // if (this.currentIndexOfStepper < 3) {
        this.formData = { ...this.firstForm.value, ...this.secondForm.value, ...this.thirdForm.value, ...this.fourthForm.value }
        if (this.currentIndexOfStepper == 0) {
            if (!this.checkValidations(this.firstForm)) {
                return
            }
        }
        if (this.currentIndexOfStepper == 0 && !this.captchaVerified) { 
            return
        }
        else if (this.currentIndexOfStepper == 1) {
            if (!this.checkValidations(this.secondForm)) {
                return
            }
        }
        else if (this.currentIndexOfStepper == 2) {
            if (!this.checkValidations(this.thirdForm)) {
                return
            }

        }
        else if (this.currentIndexOfStepper == 3) {
            if (!this.checkValidations(this.fourthForm)) {
                return
            }

        }

        this.onBoarding();
        // }
    }

    checkValidations(form: FormGroup)
    {
        if (form.invalid) {
            form.markAllAsTouched()
            return false;
        }
        return true;
    }

    onBack()
    {
        if (this.currentIndexOfStepper > 0) {
            this.currentIndexOfStepper--;
        }
        else {
            this.route.navigateByUrl('auth/login');
        }
    }

    onBoarding()
    {
        console.log("onBoarding", this.formData)
        let payload =
        {
            enc_password: localStorage.getItem('password_token'),
            enc_payload: this.authService.encryptionAuth(this.formData)
        }

        this.authService.auth('onboarding', payload).then((res) =>
        {
            if (res.statusCode == 200) {
                let decrypted = this.authService.decryptionAuth(res.data);
                this.firstForm.patchValue(decrypted);
                console.log("onboarding > ", decrypted)
                console.log("onboarding > ", this.firstForm)
                if (this.currentIndexOfStepper < 3) {
                    this.currentIndexOfStepper++
                }
                if (decrypted.access_authorization)
                {
                    localStorage.removeItem(this.LOGIN_KEY);
                    localStorage.setItem('employer_token', decrypted.access_authorization);
                    this.authorization.setToken(decrypted.access_authorization);
                    this.authService.token = decrypted.access_authorization;
                    localStorage.setItem('employer_password_token', decrypted.password_token);
                    localStorage.setItem('password_token', decrypted.password_token);
                    localStorage.setItem('isLoggedIn', '1');
                    this.onInviteEmployees(decrypted.link_hash);
                }
                return
            }
            this.alertService.alert('Error', localStorage.getItem('lang') == 'ar' ? res.message_ar : res.message, '');
        })
    }

    onInviteEmployees(link)
    {
        let dialogRef = this.dialog.open(InviteEmployeeDialogComponent, { autoFocus: false, width: '40%', height: 'auto', hasBackdrop: false, data: link, panelClass: !this.isArabic ? 'english-lang' : 'arabic-lang' });
        let cm = dialogRef.componentInstance;
        dialogRef.afterClosed().subscribe(res =>
        {
            if (res.type == 'skip') {
                console.log(res.type, res.data)
            }
            if (res.type == 'invite') {
                console.log(res.type, res.data)
            }
            this.onCompleteOnBoarding();
        })
    }

    onCompleteOnBoarding()
    {
        let dialogRef = this.dialog.open(OnBoardCompleteDialogComponent, { autoFocus: false, width: '40%', height: 'auto', hasBackdrop: false, panelClass: !this.isArabic ? 'english-lang' : 'arabic-lang'});
        let cm = dialogRef.componentInstance;
        dialogRef.afterClosed().subscribe(res =>
        {
            if (res.type == 'complete') {
                console.log(res.type, res.data);
                window.location.reload();
                // this.route.navigate(['/main'])
            }
        })
    }

    selectOption(option)
    {
        this.selectedOption = option;
        this.thirdForm.get('employee_reward').setValue(option);
    }

    selectPlan(plan)
    {
        this.plan = plan;
        this.fourthForm.get('subscription').setValue(plan);
    }

    startCall()
    {
        localStorage.removeItem(this.LOGIN_KEY);
        this.authService.getStart('start').then((res) =>
        {
            if (res.statusCode == 200) {
                localStorage.setItem('password_token', res.data.access_token);
                return
            }
            console.log('start err', res.error);
        })
    }

    resolved(event) 
    {
        console.log('captcha event', event);
        if (event) {
            this.captchaVerified = true;
        }
        else {
            this.captchaVerified = false;
        }
    }

    getFieldValue(form, field) {
        if (form.get(field).touched) {
            if (form.get(field).invalid) {
                return true;
            }
        }
        return false;
    }

    getErrorMessage(form, control, message) {
        if (form.get(control).invalid) {
            if (form.get(control).hasError('required'))
            return message;
            if (form.get(control).hasError('pattern'))
            return 'Please type in the correct format.'
            if (form.get(control).hasError('minlength') && control == 'cr_no')
            return 'CR No. should be 10 characters long.'
            if (form.get(control).hasError('maxlength') && control == 'cr_no')
            return 'CR No. should be 10 characters long.'
            if (form.get(control).hasError('minlength') && control == 'company_phone')
            return 'Mobile Number should be 9 characters long.'
            if (form.get(control).hasError('maxlength') && control == 'company_phone')
            return 'Mobile Number should be 9 characters long.'
            if (form.get(control).hasError('email'))
            return 'Email should be in correct format.'
        }
    }
}

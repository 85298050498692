import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class TokenService
{
  selectedToken: Subject<any> = new Subject<any>();
  Token: any;

  constructor() 
  {
   
  }
 
  setToken(val)
  {
    //console.log("Token", val)
    this.Token = val;
    this.selectedToken.next(val);
    localStorage.setItem('employer_token', val);
  }

  getToken()
  {
    if (this.Token != null)
    {
      return this.Token;
    }
    else if (localStorage.getItem('employer_token'))
    {
      return localStorage.getItem('employer_token');
    }
    else
    {
      //console.log("No Token Found")
    }
    // tokem null hota hy to local sy nikalo gy
  }
 
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-change-password.component',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

    Form: FormGroup
    timer: any;
    timerRunning: boolean = true;
    maxTime: number = 60;
    timerValue: string = '1:00';
    otp: string = '';
    otpTries = 0;

    config = {
        allowNumbersOnly: true,
        length: 6,
        isPasswordInput: true,
        disableAutoFocus: false,
        placeholder: '',
        inputStyles: {
            'width': '50px',
            'height': '50px',
            'margin-right': '8px'
        }
    };

    constructor(
        protected authService: AuthService, 
        protected alertService: AlertService,
        protected router: Router,
        protected translateService: TranslateService)
    {
        this.Form = new FormGroup({
            newPassword: new FormControl(null, Validators.required),
            confirmPassword: new FormControl(null, Validators.required),
        })
    }

    ngOnInit(): void 
    {
        // this.otpTimerCheck();
        this.timerOTP(180);
    }

    navigateToLogin()
    {
        this.router.navigateByUrl('auth/login');
    }

    onOtpChange(otp)
    {
        this.otp = otp;
        if ((otp.toString().length == 6 && this.otpTries > 0))
        {
            // console.log('this.otpTries > 0', otp.length)
            return;
        }
        if (otp.toString().length < 6)
        {
            this.otpTries = 0;
        }
        if (otp.toString().length == 6 && this.otpTries == 0) 
        {
            this.otpTries++;
        }
    }

    timerOTP(remaining)
    {
        let m = Math.floor(remaining / 60);
        let s = remaining % 60;

        let min = m < 10 ? '0' + m : m;
        let sec = s < 10 ? '0' + s : s;
        this.timerValue = min + ':' + sec;
        remaining -= 1;

        if (remaining >= 0) {
            this.timer = setTimeout(x =>
            {
                this.timerRunning = true;
                this.timerOTP(remaining);
            }, 1000);
            return
        }
        else {
            this.timerRunning = false;
        }
    }

    // otpTimerCheck()
    // {
    //     this.timer = setTimeout(x => 
    //     {
    //         this.maxTime -= 1;
    //         if (this.maxTime < 10)
    //         {
    //             this.timerValue = '00:0' + this.maxTime;
    //         }
    //         if (this.maxTime > 10)
    //         {
    //             this.timerValue = '00:' + this.maxTime;
    //         }
    //         if (this.maxTime > 0)
    //         {
    //             this.timerRunning = true;
    //             this.otpTimerCheck();
    //         }
    //         else
    //         {
    //             this.timerRunning = false;
    //         }
    //     }, 1000);
    // }

    submit()
    {
        if (!this.checkValidations() || this.otp.length != 6)
        {
            return;
        }
        if (this.Form.get('newPassword').value != this.Form.get('confirmPassword').value)
        {
            this.alertService.alert('Error', 'Passwords do not match.', '');
            return;
        }
        let payload = {
            password: this.Form.get('newPassword').value,
            new_password: this.Form.get('confirmPassword').value,
            otp: this.otp,
            user: this.authService.decryptionAuth(localStorage.getItem('login_status'))
        };
        let data = {
            enc_password: localStorage.getItem('password_token'),
            enc_payload: this.authService.encryptionAuth(payload)
        }
        this.authService.auth('reset', data).then(response => {
            if (response.statusCode == 200)
            {
                localStorage.removeItem('login_status');
                this.router.navigateByUrl('auth/login');
                this.alertService.alert('Success', 'Password changed successfully', '');
            }
            else
            {
                if (localStorage.getItem('lang') == 'ar')
                {
                    this.alertService.alert('Error', response.message_ar);
                }
                else
                {
                    this.alertService.alert('Error', response.message);
                }
            }
        })
    }

    resendOTP()
    {
        // if (!this.timerRunning)
        // {
        //     this.timerRunning = true;
        //     this.otpTimerCheck();
        // }
        // this.maxTime = 60;
        // this.sendOTP();

        if (!this.timerRunning) {
            this.timerRunning = true;
            this.timerOTP(180);
        }
        this.sendOTP();
    }

    sendOTP()
    {
        let data = localStorage.getItem('forgot_data');
        this.authService.auth('forgot', JSON.parse(data)).then(response => {
            if (response?.statusCode == 200)
            {
                // let dec = this.cryptoService.decryptionAuth(response.data)
                // localStorage.setItem(this.LOGIN_KEY, response.data);
                // this.route.navigateByUrl('auth/reset-password');
            }
            else
            {
                if (localStorage.getItem('lang') == 'ar')
                {
                    this.alertService.alert('Error', response.message_ar);
                }
                else
                {
                    this.alertService.alert('Error', response.message);
                }
            }
        })
    }

    checkValidations() 
    {
        if (this.Form.invalid) 
        {
            this.Form.markAllAsTouched();
            return false;
        }
        return true;
    }

    getFieldValue(field) 
    {
        if (this.Form.get(field).touched) 
        {
            if (this.Form.get(field).invalid)
            {
                return true;
            }
        }
        return false;
    }

    getErrorMessage(control, fieldName, minMaxValue?)
    {
        if (this.Form.get(control).invalid)
        {
            if (this.Form.get(control).hasError('required'))
            {
                return this.translateService.instant(fieldName);
            }
            if (this.Form.get(control).hasError('email'))
            {
                return this.translateService.instant('Email_Format');
            }
        }
    }

    ngOnDestroy()
    {
        localStorage.removeItem('forgot_data');
    }
}

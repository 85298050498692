<div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">

  <section fxLayoutAlign="end">
    <button (click)="onBack()" class="btn-disabled">{{'Back' | translate}}</button>
  </section>
  <!-- cards -->
  <section fxLayout="row wrap" fxLayoutAlign="space-between">
    <app-card fxFlex="32" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99" img="assets/images/person-arrows.png"
      topText="{{'Total Employees' | translate}}" middleText="{{cardData?.Total ? cardData?.Total : '0'}}" bottomText=""
      [error]="false">
    </app-card>
    <app-card fxFlex="32" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99" img="assets/images/person-arrows.png"
      topText="{{'Accepted Employees' | translate}}" middleText="{{cardData?.Accepted ? cardData?.Accepted : '0'}}"
      bottomText="" [error]="false">
    </app-card>
    <app-card fxFlex="32" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99" img="assets/images/person-arrows.png"
      topText="{{'Pending Employees' | translate}}" middleText="{{cardData?.Pending ? cardData?.Pending : '0'}}"
      bottomText="" [error]="false">
    </app-card>
  </section>
  <!-- cards -->

  <!-- Table -->
  <section class="mt-20">
    <app-table fxFlex="100" [tableProps]="tableProps" [dataSource]="list" [pagination]="pagination"
      (setPage)="setPage($event)" (pageSize)="SetPageSize($event)" (emitFilters)="handleFilters($event)"
      (searchData)="onSearchData($event)" (outPutHeaders)="onHeaderButton($event)" (emitCheckBox)="onCheckBox($event)">
    </app-table>
  </section>
  <!-- Table -->
</div>
import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import * as CryptoJS from 'crypto-js';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialog } from 'src/app/lib/alert-dialog/alert.dialog';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { SubscriptionReallocateFormDialogComponent } from '../subscription-reallocate-form-dialog/subscription-reallocate-form-dialog.component';
import * as moment from 'moment';
@Component({
  selector: 'app-reallocate-view',
  templateUrl: './reallocate-view.component.html',
  styleUrls: ['./reallocate-view.component.scss']
})
export class ReallocateViewComponent implements OnInit
{
  Form: FormGroup;
  setPassword: FormGroup;
  failureAlert: boolean;
  alertMsg: any = "";
  sub: Subscription;
  id: any;
  path: any;
  hed: any;
  button: any;
  ut;
  emp_id: any;
  items: any;
  employer_id: any;
  status: any;
  constructor(public tr: TranslationService, protected formbuilder: FormBuilder, protected dialog: MatDialog, protected _route: ActivatedRoute, public dec_enc: DecEncService, protected mainService: MainService)
  {
    this._route.params.subscribe(params =>
    {
      this.id = params['id'];


    });
    this.emp_id = localStorage.getItem('id');
    this.Form = this.formbuilder.group({
      company_name: ['', [Validators.required]],
      id: ['', [Validators.required]],
      to_fund: ['', [Validators.required]],
      from_fund: ['', [Validators.required]],
      createdAt: ['', [Validators.required]],
      status: ['', [Validators.required]],
    });



  }

  ngOnInit(): void
  {
    this.getWithdrawls();

  }

  back()
  {
    window.history.back();
  }
  getWithdrawls()
  {
    // //console.log("id ",this.id)
    let enc_payload = this.dec_enc.encryption({ "id": this.id })

    let url = 'reallocations/details';
    this.mainService.postData(url, { enc_payload }).then(res =>
    {

      if (res.statusCode == 200)
      {
        let result;
        let data = res
        result = this.dec_enc.decryption(res.data);
        //console.log("reallocations/details ", result)
        this.items = result;
        this.Form.get('id').setValue(result.re_allocations[0].id);
        this.Form.get('company_name').setValue(result.re_allocations[0].employers_employee?.employer?.company_name);
        this.Form.get('to_fund').setValue(result.re_allocations[0].fund?.name);
        this.Form.get('from_fund').setValue(result.re_allocations[0].from_fund?.name);
        this.Form.get('createdAt').setValue( moment(result.re_allocations[0].created_at).format("dddd, MMMM Do YYYY, h:mm:ss a"));
        this.status=result.re_allocations[0].status;
        // this.bankstatus=result.re_allocations[0].status;
        // this.Form.get('status').setValue(result.re_allocations[0].status);
        if (result.re_allocations[0].status == 1)
        {
          this.Form.get('status').setValue('Accepted')
        }
        if (result.re_allocations[0].status == 2)
        {
          this.Form.get('status').setValue('Declined')
        }
        if (result.re_allocations[0].status == 0)
        {
          this.Form.get('status').setValue('Pending')
        }
        if (result.re_allocations[0].status == 3)
        {
          this.Form.get('status').setValue('Under-Process')
        }
      }

    },
      error =>
      {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = "Submitted Successfully";
        cm.cancelButtonText = 'Close';
        cm.type = 'error';

      }
    );
  }
 
  openDialog()
  {
    // //console.log("id",id)
    let dialogRef = this.dialog.open(SubscriptionReallocateFormDialogComponent,
      {
        autoFocus: true,
        maxHeight: '90vh',
        width: '80%',

      });
    let cm = dialogRef.componentInstance;
    dialogRef.componentInstance.resultId = this.id;

    dialogRef.afterClosed().subscribe(result =>
    {
      // this.getall();
    })
  }

}






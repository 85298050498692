<div style="margin-top: 20px;">
    <mat-card *ngIf="pagination.count == 0 && showErrorSelected == false"
        style="margin-inline: 10px; margin-bottom: 20px;" class="card-new font-raleway" fxLayout="row wrap">
        <h2 [style.text-align]="isEnglish ? 'left' : 'right'" fxFlex="53" fxFlex.xs="100"
            style="margin: 0; font-weight: bold; color: #213060;">{{'Create New Contribution' | translate}}</h2>
        <div fxFlex="47" fxFlex.sm="48" fxFlex.xs="100" fxLayoutAlign="end center">
            <button (click)="downloadCsv()" mat-raised-button
                style="background-color: #213060;
                color: white; border-radius: 18px; cursor: pointer;
                font-size: 12px; border: none; line-height: 25px; padding: 0 10px; margin-inline: 10px; outline: none;">
                {{'Download_as_CSV' | translate}}
            </button>
            <button (click)="submitClick()" mat-raised-button style="background-color: #213060;
            color: white; border-radius: 18px; cursor: pointer;
            font-size: 12px; border: none; line-height: 25px; padding: 0 10px; margin-inline: 10px; outline: none;">
                <input #attach type="file" style="display: none;" (change)="getFile($event)">
                {{'Upload_CSV' | translate}}
            </button>
        </div>
    </mat-card>
</div>

<mat-card *ngIf="pagination.count > 0 || showErrorSelected == true" class="card-new font-raleway" fxLayout="row wrap">
    <h2 [style.text-align]="isEnglish ? 'left' : 'right'" fxFlex="53" fxFlex.xs="100"
        style="margin: 0; font-weight: bold; color: #213060;">{{'Review_Contributions' | translate}}</h2>
    <div fxFlex="47" fxFlex.sm="48" fxFlex.xs="100" fxLayoutAlign="end center">
        <button (click)="downloadWhileEdit()" mat-raised-button style="background-color: #213060;
              color: white; border-radius: 18px; cursor: pointer; 
              font-size: 12px; border: none; line-height: 25px; padding: 0 10px; margin-inline: 10px; outline: none;">
            {{'Download_as_CSV' | translate}}
        </button>
        <button (click)="discardContributions()" mat-raised-button style="background-color: #213060;
          color: #213060; border-radius: 18px; cursor: pointer; 
          font-size: 12px; border: none; line-height: 25px; padding: 0 10px; margin-inline: 10px; outline: none; 
          background-color: #E9EAEF;">
            {{'Cancel_Submission' | translate}}
        </button>
        <button (click)="summaryContributions()" mat-raised-button style="background-color: #213060;
              color: white; border-radius: 18px; cursor: pointer; 
              font-size: 12px; border: none; line-height: 25px; padding: 0 10px; margin-inline: 10px; outline: none;">
            {{'Submit_Contribution' | translate}}
        </button>
    </div>

    <h4 fxFlex.xs="100" fxFlex.sm="100" fxFlex="37" [style.text-align]="isEnglish ? 'left' : 'right'"
        style="margin: 0; font-weight: bold; color: #213060; margin-top: 10px; margin-right: 15px;">
        {{'Contribution_Long_Text' | translate}}</h4>
    <app-card fxFlex="20" fxFlex.sm="48" fxFlex.xs="100" img="assets/images/person-arrows.png" [style.text-align]="isEnglish ? 'left' : 'right'"
        topText="{{'Total_Employees' | translate}}"
        middleText="{{summary.total}} {{summary.total > 1 ? ('Employees' | translate) : ('Employee' | translate)}}"
        bottomText="" [error]="false">
    </app-card>
    <app-card fxFlex="20" fxFlex.sm="48" fxFlex.xs="100" img="assets/images/person-arrows.png" [style.text-align]="isEnglish ? 'left' : 'right'"
        topText="{{'Records_with_Errors' | translate}}"
        middleText="{{summary.errors ? summary.errors : '0'}} {{'Records' | translate}}" bottomText=""
        [error]="summary.errors > 0 ? true : false">
    </app-card>
    <app-card fxFlex="21" fxFlex.sm="48" fxFlex.xs="100" img="assets/images/person-arrows.png" [style.text-align]="isEnglish ? 'left' : 'right'"
        topText="{{'Contribution_File_Status' | translate}}"
        middleText="{{summary.ok == summary.total ? ('No Errors Detected' | translate) : ('Errors Detected' | translate)}}"
        bottomText="" [error]="summary.errors > 0 ? true : false">
    </app-card>
</mat-card>

<div style="margin-top: 20px; margin-bottom: 20px; margin-inline: 10px;">
    <app-table *ngIf="pagination.count > 0 || showErrorSelected == true" [dataSource]="contributions"
        [tableProps]="tableProps" (actionClicked)="actionClicked($event)" (outPutHeaders)="headerButton($event)"
        (emitCheckBox)="checkBox($event)" [pagination]="pagination" (setPage)="setPage($event)"
        (pageSize)="SetPageSize($event)">
    </app-table>
</div>
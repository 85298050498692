<div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div fxLayoutAlign="center center">
        <div style="background-color: white; border-radius: 40px; padding: 5px 10px;">
            <div fxLayout="row" fxLayoutAlign="center center" class="lang-switch-container">
                <div (click)="onChangeList('portfolios')" class="lang-switch"
                    [class.lang-switch-active]="companyListing == 'portfolios'">
                    {{'By Portfolio' | translate}}
                </div>
                <div (click)="onChangeList('funds')" class="lang-switch"
                    [class.lang-switch-active]="companyListing == 'funds'">
                    {{'By Fund' | translate}}
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!showPdf">
        <div fxLayout="column" *ngIf="companyListing == 'portfolios'">
            <div fxFlex="100" class="info-box-container" style="background-color: white; margin-top: 20px;
            border-radius: 12px;">
                <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="90px" style="padding:20px">
                    <div fxFlex="100">
                        <h3 class="title">{{'Portfolio Holding Report' | translate}}</h3>
                        <div class="info-box">
                            <p class="info-text">
                                {{'This report intends to provide a snapshot of the current standing, performance and
                                diversification of the investments.' |
                                translate}}
                            </p>
                            <p class="info-text" style="padding-top: 0px !important">
                                {{'Please choose between the entire investment or portfolio wise breakdown for the type
                                of
                                report required.' |
                                translate}}
                            </p>
                        </div>
                        <div (click)="list?.length > 0 ? downloadPDF() : null"
                            [ngClass]="{'disabled': list?.length <= 0}" class="save-button">
                            {{ 'Save as PDF' | translate }}
                        </div>
                    </div>
                </div>
            </div>

            <app-table class="mt-20 mb-20" fxFlex="100" [start]="start_date" [tableProps]="portfolioTableProps"
                (searchData)="searchData($event)" [dataSource]="list" (outDateFilters)="onDateFilters($event)"
                (emitFilters)="onFilters($event)">
            </app-table>

            <!-- <mat-card class="summary-card">
                <div fxLayout="row" fxLayoutAlign="center">      
                    <div class="summary-values">
                        <h4 class="summary-title">{{ 'Total:' | translate }}</h4>
                    </div>       
                    <div class="summary-values">
                        <h5 class="balance-value" style="padding-top: 2px;">{{totalInvestedAmount | number:'1.4-4'}}</h5>
                    </div>          
                    <div  class="summary-values">
                        <h5 class="balance-value" style="padding-top: 2px;">{{totalPurchasedUnits | number:'1.4-4'}}</h5>
                    </div>           
                    <div class="summary-values">
                        <h5 class="balance-value" style="padding-top: 3px;">{{totalWithdrawnUnits | number:'1.4-4'}}</h5>
                    </div>             
                    <div class="summary-values">
                        <h5 class="balance-value" style="padding-top: 3px;">{{totalUnitsOnHand | number:'1.4-4'}}</h5>
                    </div>            
                    <div class="summary-values">
                        <h5 class="balance-value" style="padding-top: 3px;">{{totalCurrentValue | number:'1.4-4'}}</h5>
                    </div>           
                    <div class="summary-values">
                        <h5 class="balance-value" style="padding-top: 3px;">0.0000</h5>
                    </div>      
                    <div class="summary-values">
                        <h5 class="balance-value" style="padding-top: 3px;">0.0000</h5>
                    </div>        
                </div>
        
            </mat-card> -->

            <div class="balance-container">
                <div class="balance-box">
                    <h5 class="balance-label">{{'Total Asset Value (SAR)' | translate}}</h5>
                    <h5 class="balance-value">{{(totalCurrentValue ? totalCurrentValue : 0) |
                        number:'1.4-4'}}</h5>
                </div>
            </div>

            <div class="balance-container">
                <div class="balance-box">
                    <h5 class="balance-label">{{'Total Profit/Loss (SAR)' | translate}}</h5>
                    <h5 class="balance-value">{{(totalAbsoluteReturn ? totalAbsoluteReturn : 0) | number:'1.4-4'}}</h5>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!showPdf">
        <div fxLayout="column" *ngIf="companyListing == 'funds'">
            <div fxFlex="100" class="info-box-container" style="background-color: white; margin-top: 20px;
            border-radius: 12px;">
                <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="90px" style="padding:20px">
                    <div fxFlex="100">
                        <h3 class="title">{{'Portfolio Holding Report' | translate}}</h3>
                        <div class="info-box">
                            <p class="info-text">
                                {{'This report intends to provide a snapshot of the current standing, performance and
                                diversification of the investments.' |
                                translate}}
                            </p>
                            <p class="info-text" style="padding-top: 0px !important">
                                {{'Please choose between the entire investment or portfolio wise breakdown for the type
                                of
                                report required.' |
                                translate}}
                            </p>
                        </div>
                        <div (click)="list?.length > 0 ? downloadPDF() : null"
                            [ngClass]="{'disabled': list?.length <= 0}" class="save-button">{{'Save as PDF' |
                            translate}}</div>
                    </div>
                </div>
            </div>

            <app-table class="mt-20 mb-20" fxFlex="100" [start]="start_date" [tableProps]="fundsTableProps"
                [dataSource]="list" (emitFilters)="onFilters($event)" (outDateFilters)="onDateFilters($event)">
            </app-table>

            <div class="balance-container">
                <div class="balance-box">
                    <h5 class="balance-label">{{'Total Asset Value (SAR)' | translate}}</h5>
                    <h5 class="balance-value">{{(totalCurrentValue ? totalCurrentValue : 0) |
                        number:'1.4-4'}}</h5>
                </div>
            </div>

            <div class="balance-container">
                <div class="balance-pair">
                    <div class="balance-box">
                        <h5 class="balance-label">{{'Total Profit/Loss (SAR)' | translate}}</h5>
                        <h5 class="balance-value">{{(totalAbsoluteReturn ? totalAbsoluteReturn : 0) | number:'1.4-4'}}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div *ngIf="showPdf" style="display: flex; justify-content: center; align-items: center;">
        <div id="contentToExport" class="main pt-1 mrl-20 mt-10" ngClass.sm="mrl-10" ngClass.xs="mrl-10" style="direction:ltr">
            <table style="width:100%; max-width:700px; margin:0 auto; background-color:#fff; border-collapse:collapse;">
                <tbody style="margin: 0px 20px !important;">
                    <tr *ngIf="currentLang=='en'" style="display: flex; justify-content: space-between; align-items: flex-end; border-bottom: 1px solid #79C0C2;"
                        class="mrl-20">
                        <td style="padding-top:20px; text-align:left; flex: 1;" class="top-logo pr-10">
                            <img width="215" height="70" src="assets/thrift_logo.png" alt="Logo">
                        </td>
                        <td style="padding-top:20px; text-align: right; font-size:14px; color:#757575; flex: 1;">
                            <h3 style="margin: 0; font-weight: bolder; color: black;">{{companyListing === 'portfolios'
                                ? ('Portfolio Holding Report By Portfolio' | translate) : ('Portfolio Holding Report By
                                Fund' | translate)|
                                translate}}
                            </h3>
                        </td>
                    </tr>
                    <tr *ngIf="currentLang=='ar'" style="display: flex; justify-content: space-between; align-items: flex-end; border-bottom: 1px solid #79C0C2;"
                        class="mrl-20">
                        <td style="padding-top:20px; text-align:left; flex: 1;" class="top-logo pr-10">
                            <h3 style="margin: 0; font-weight: bolder; color: black;">{{companyListing === 'portfolios'
                                ? ('Portfolio Holding Report By Portfolio' | translate) : ('Portfolio Holding Report By
                                Fund' | translate)|
                                translate}}
                            </h3>
                        
                        </td>
                        <td style="padding-top:20px; text-align: right; font-size:14px; color:#757575; flex: 1;">
                            <img width="215" height="70" src="assets/thrift_logo.png" alt="Logo">
                        </td>
                    </tr>
                    <tr [style.direction]="currentLang == 'ar' ? 'rtl' : 'ltr'">
                        <td colspan="3" style="padding: 10px 20px;">
                            <div style="display: flex; justify-content: center; gap: 30px; margin:0px 15px 0px 15px">
                                <div style="flex: 1; font-size: 14px; color: #757575; text-align: left;">
                                    <h5
                                        style="display: flex; justify-content: space-between;color: #000000; font-size: 12px;">
                                        <strong>{{'Report as of' | translate}}</strong>
                                        <span>{{start_date | date:'dd-MM-yyyy'}}</span>
                                    </h5>
                                    <h5
                                        style="display: flex; justify-content: space-between;color: black; font-size: 12px;">
                                        <strong>{{'Currency' |translate}}</strong>
                                        <span>SAR</span>
                                    </h5>
                                    <h5
                                        style="display: flex; justify-content: space-between;color: black; font-size: 12px;">
                                        <strong>{{'IBAN' | translate}}</strong>
                                        <span>{{iBnNo}}</span>
                                    </h5>
                                </div>
                                <div style="flex: 1; font-size: 14px; color: #757575; text-align: right;">
                                    <h5
                                        style="display: flex; justify-content: space-between;color: black; font-size: 12px;">
                                        <strong>{{'Holding type' | translate}}</strong>
                                        <span>{{ companyListing === 'portfolios' ? ('Portfolio-wise' | translate) :
                                            ('Fund-wise' | translate) }} </span>
                                    </h5>
                                    <div *ngIf="companyListing == 'portfolios'">
                                        <h5
                                            style="display: flex; justify-content: space-between;color: black; font-size: 12px;">
                                            <strong>{{'Filter selection' | translate}}</strong>
                                            <span>{{selectedPortfolioLabel}}</span>
                                        </h5>
                                    </div>
                                    <div *ngIf="companyListing == 'funds'">
                                        <h5
                                            style="display: flex; justify-content: space-between;color: black; font-size: 12px;">
                                            <strong>{{'Fund Name' | translate}}</strong>
                                            <span>{{selectedfundLabel}}</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>


                    <tr [style.direction]="currentLang == 'ar' ? 'rtl' : 'ltr'">
                        <td colspan="3" style="padding: 0 20px;">
                            <table
                                style="width: 100%; border-collapse: collapse; border-bottom: 1px solid #79C0C2; margin-bottom: 20px;">
                                <thead>
                                    <tr style="border-bottom: 1px solid #79C0C2;">
                                        <th style="padding: 10px; text-align: left; font-size: 12px;">{{ 'Fund' |
                                            translate
                                            }}</th>
                                        <th style="padding: 10px; text-align: left; font-size: 12px;">{{ 'Contribution
                                            Amount (SAR)' |
                                            translate }}</th>
                                        <th style="padding: 10px; text-align: left; font-size: 12px;">{{ 'Purchased
                                            Units' |
                                            translate }}</th>
                                        <th style="padding: 10px; text-align: left; font-size: 12px;">{{ 'Withdrawn
                                            Units' |
                                            translate }}</th>
                                        <th style="padding: 10px; text-align: left; font-size: 12px;">{{ 'Units on Hand'
                                            |
                                            translate }}</th>
                                        <th style="padding: 10px; text-align: left; font-size: 12px;">{{ 'Current Value (SAR)'
                                            |
                                            translate }}</th>
                                        <th style="padding: 10px; text-align: left;font-size:12px">{{ 'Absolute Return (SAR)'
                                            |
                                            translate }}</th>
                                        <th style="padding: 10px; text-align: left;font-size:12px">{{ 'Return (%)' |
                                            translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let record of list; let last = last" 
                                    [ngStyle]="(last && companyListing === 'portfolios') ? {'font-weight': 'bold'} : {}">
                                        <td style="padding: 10px; text-align: left;font-size:11px">{{ record.fund_name
                                            }}
                                        </td>
                                        <td style="padding: 10px; text-align: left;font-size:11px;direction:ltr;">{{
                                            record.invested_amount | number:'1.4-4'
                                            }}</td>
                                        <td style="padding: 10px; text-align: left;font-size:11px;direction:ltr;">{{
                                            record.purchased_units | number:'1.4-4'
                                            }}</td>
                                        <td style="padding: 10px; text-align: left;font-size:11px;direction:ltr;">{{
                                            record.withdrawn_units | number:'1.4-4'
                                            }}</td>
                                        <td style="padding: 10px; text-align: left;font-size:11px;direction:ltr;">{{
                                            record.units_in_hand | number:'1.4-4'
                                            }}</td>
                                        <td style="padding: 10px; text-align: left;font-size:11px;direction:ltr;">{{
                                            record.current_value | number:'1.4-4'
                                            }}</td>
                                        <td style="padding: 10px; text-align: left;font-size:11px;direction:ltr;">{{
                                            record.absolute_return | number:'1.4-4'
                                            }}</td>
                                        <td style="padding: 10px; text-align: left;font-size:11px;direction:ltr;">{{
                                            record.return_percentage }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style="text-align: right; padding: 10px; margin-top: 5px; font-size:12px">
                                <span [style.margin-left]="currentLang == 'ar' ? '10px' : '' " style="color: black; font-weight: bolder; margin-right: 10px;">{{'Total Asset
                                    Value (SAR)' | translate}}</span>
                                <span dir="ltr">{{(totalCurrentValue ? totalCurrentValue : 0) | number:'1.4-4'}}</span>
                            </div>
                            <div style="text-align: right; padding: 10px; margin-top: 5px;font-size:12px">
                                <span [style.margin-left]="currentLang == 'ar' ? '10px' : '' " style="color: black; font-weight: bolder; margin-right: 10px;">{{'Total
                                    Profit/Loss (SAR)' | translate}}</span>
                                <span dir="ltr">{{summary.profit_loss | number:'1.4-4'}}</span>
                            </div>
                        </td>
                    </tr>

                   <tr [style.direction]="currentLang == 'ar' ? 'rtl' : 'ltr'" style="display:none" class="mrl-20">
                        <td colspan="3" style="padding: 0px 20px;">
                            <p id="pdfNote" style="color: black; font-size: 11px;">{{'PDF_Note' | translate}}</p>
                        </td>
                    </tr>

                    <tr class="mrl-20" style="display:none">
                        <td colspan="3" style="padding: 0px 20px;">
                            <p style="color: black;"><strong>Thriftplan.com.sa/Reports</strong></p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
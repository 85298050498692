<div class="main" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
  <div fxLayout="column" fxFlex="59">
    <img src="assets/back-img.png" alt="Back Ground Image" class="img" ngClass.sm="img-sm" ngClass.xs="img-xs">
    <span class="back-img" ngClass.sm="back-img-sm" ngClass.xs="back-img-xs" fxLayoutAlign="center">
      <span fxLayout="column" fxFlexOffset="8" fxFlexOffset.sm="0" fxLayoutGap="50">
        <h1>{{'Welcome to the future of employee benefits' | translate}}</h1>
      </span>
    </span>
  </div>

  <div class="login-form" ngClass.sm="login-form-sm" ngClass.xs="login-form-xs" fxLayout="column"
    fxLayoutAlign="center center" style="background: #F7F8F9;">

    <img src="assets/thrift_logo.png" alt="Thriftplan Logo" width="200px" fxFlexOffset.sm="-8" fxFlexOffset.xs="8">
    <h1 fxFlexOffset="3">{{'Create_New_Password' | translate}}</h1>
    <h6 fxFlexOffset="-3" fxFlexOffset.sm="0">{{'PleaseEnterNewPassword' | translate}}</h6>

    <form [formGroup]="Form" ngClass.xs="form-xs" ngClass.sm="form-sm" fxLayout="column" style="margin-block: 20px;">
      <mat-form-field class="input-field" fxFlexAlign="center" appearance="outline">
        <mat-label>{{'EnterNewPassword' | translate}}</mat-label>
        <input autocomplete="off" type="password" matInput formControlName="newPassword">
        <mat-icon *ngIf="getFieldValue('newPassword')" #tooltip="matTooltip"
          [matTooltip]="getErrorMessage('newPassword', 'New_Password_Required')"
          [matTooltipDisabled]="!getFieldValue('newPassword')" matTooltipPosition="above" style="color: #213060;"
          matSuffix>info
        </mat-icon>
      </mat-form-field>

      <mat-form-field style="margin-top: 10px;" class="input-field" fxFlexAlign="center" appearance="outline">
        <mat-label>{{'Confirm_New_Password' | translate}}</mat-label>
        <input autocomplete="off" type="password" matInput formControlName="confirmPassword">
        <mat-icon *ngIf="getFieldValue('confirmPassword')" #tooltip="matTooltip"
          [matTooltip]="getErrorMessage('confirmPassword', 'Confirm_Password_Required')"
          [matTooltipDisabled]="!getFieldValue('confirmPassword')" matTooltipPosition="above" style="color: #213060;"
          matSuffix>info
        </mat-icon>
      </mat-form-field>

        <ng-otp-input dir="ltr" style="margin-top: 20px;" fxFlexAlign="center" #ngOtpInput (onInputChange)="onOtpChange($event)"
          [config]="config">
        </ng-otp-input>
    </form>

    <div style="margin-top: 10px;" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
      <button (click)="submit()" class="btn-auth-screens" ngClass.sm="btnLogin-sm" ngClass.xs="btnLogin-xs"
        mat-raised-button>
        {{'Submit' | translate}}
      </button>
      <button (click)="navigateToLogin()" class="btn-auth-screens" ngClass.sm="btnLogin-sm" ngClass.xs="btnLogin-xs"
        mat-raised-button>
        {{'Cancel' | translate}}
      </button>
    </div>

    <div style="margin-top: 10px;">{{timerValue}}</div>
    <button (click)="resendOTP()" mat-button style="padding: 0; border: none; outline: none;">
      {{'ResendOTP' | translate}}
    </button>

    <div class="footer" ngClass.sm="footer-sm" ngClass.xs="footer-xs" fxLayoutAlign="space-between baseline">
      <p>© {{'2023 Thriftplan. All Rights Reserved' | translate}}</p>
    </div>
  </div>
</div>

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { Label, Color } from 'ng2-charts';
import { ChartDataSets } from 'chart.js';
import * as moment from 'moment';
import { appConfig } from 'src/config';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-add-employee-group',
    templateUrl: './add-employee-group.component.html',
    styleUrls: ['./add-employee-group.component.scss']
})
export class AddEmployeeGroupComponent implements OnInit, OnDestroy {
    employer: any = [];
    Form: FormGroup;
    group_id: any = null;
    heading: any = '';
    button: any = ""
    id: any = null;
    message: any;
    FundGroup: any[] = [];
    grouop: any;
    active: any;
    Onactive: boolean;
    modeselect: 'high';
    isEmployeeLinked: boolean = false;

    headerCheckBoxValue: any;
    checkBoxCount = 0;

    fundIds: any;
    empIds: any;
    sub: Subscription;

    lstEmployees = [];
    lstAssignedEmployees = [];

    lstFunds = [];
    lstAssignedFunds = [];
    sort_order: 'ASC';
    searchTimer: any;
    checkboxSelected: any = [];
    vestingType: any = 'participation';
    currentDate = new Date();
    showVestingType: any = 'Fixed Duration';
    periods: any = 0;
    vestingPercent: any = 0;
    repeatVestingStyle: any = 'Annually';
    currentVestingDate: any;

    switchOfListings = {
        portfolio: true,
        employees: false
    }

    tablePropsPortfolioTop = {
        heading: 'Funds_Assigned_To_Group',
        hasSearch: true,
        searchLabel: 'Search',
        hasButton: false,
        headerActions: [],
        rowActions: [
            { label: 'Remove', type: 'remove', visibility: true, background: '#4318FF0D', source: 'assets/table-deactivate.svg', iconName: 'arrow_forward' },
        ],
        colHeader: {
            id: 'Fund_ID',
            name: 'Fund_Name',
            bankName: 'Investment_Bank',
            risk_rating: 'Risk_Type',
            actions: 'Actions',
        },
        columnTypes: {
            risk_rating: 'translate',
            actions: 'actionsMenu',
        },
        filterArray: []
    };

    tablePropsPortfolioBottom = {
        heading: 'Funds_Available',
        hasSearch: true,
        searchLabel: 'Search',
        hasButton: true,
        headerActions: [
            {
                label: 'Add Selected',
                type: 'output',
                visibility: false,
                isCustom: false,
            },
        ],
        rowActions: [
            { label: 'Add', type: 'add', visibility: true, background: '#4318FF0D', source: 'assets/table-verify.svg', iconName: 'arrow_forward' },
        ],
        colHeader: {
            checked: '',
            id: 'Fund_ID',
            name: 'Fund_Name',
            bankName: 'Investment_Bank',
            risk_rating: 'Risk_Type',
            actions: 'Actions',
        },
        columnTypes: {
            risk_rating: 'translate',
            actions: 'actionsMenu',
        },
        filterArray: []
    };

    tablePropsEmpTop = {
        heading: 'added_employees_in_group',
        hasSearch: true,
        searchLabel: 'Search',
        hasButton: false,
        headerActions: [],
        rowActions: [
            { label: 'Remove', type: 'remove', visibility: true, background: '#4318FF0D', source: 'assets/table-deactivate.svg', iconName: 'arrow_forward' },
        ],
        colHeader: {
            id: 'ID',
            name: 'Employee_Name',
            email: 'Email',
            phone: 'Contact_Number',
            actions: 'Actions',
        },
        columnTypes: {
            email: 'email',
            statusToShow: 'errorColumn',
            actions: 'actionsMenu',
        },
        filterArray: []
    };

    tablePropsEmpBottom = {
        heading: 'add_employee_to_group',
        hasSearch: true,
        searchLabel: 'Search',
        hasButton: true,
        headerActions: [
            {
                label: 'Add Selected',
                type: 'output',
                visibility: false,
                isCustom: false,
            },
        ],
        rowActions: [
            { label: 'Add', type: 'add', visibility: true, background: '#4318FF0D', source: 'assets/table-verify.svg', iconName: 'arrow_forward' },
        ],
        colHeader: {
            checked: '',
            id: 'ID',
            name: 'Employee_Name',
            email: 'Email',
            phone: 'Contact_Number',
            actions: 'Actions',
        },
        columnTypes: {
            email: 'email',
            statusToShow: 'errorColumn',
            actions: 'actionsMenu',
        },
        filterArray: []
    };

    paginationFund = {
        page: 1,
        pages: null,
        per_page: 10,
        count: 0
    }

    paginationEmployee = {
        page: 1,
        pages: null,
        per_page: 10,
        count: 0
    }

    paginationFundAssigned = {
        page: 1,
        pages: null,
        per_page: 10,
        count: 0
    }

    paginationEmployeeAssigned = {
        page: 1,
        pages: null,
        per_page: 10,
        count: 0
    }

    searchAll: any = "";
    searchAssigned: any = "";

    public lineChartData: ChartDataSets[] = [
        { data: [12, 34, 56], label: '2022-12-09, 2023-01-09, 2023-02-09' }
    ];
    lineChartLabels: Label[] = [];

    public lineChartOptions = {
        responsive: true,
        scales: {
            xAxes: [{
                gridLines: {
                    display: false
                }
            }],
            yAxes: [{
                gridLines: {
                    display: false
                },
                ticks: {
                    userCallback: function (value, index, values) {
                        return Number(value).toFixed(2);
                    }
                }
            }]
        },
        plugins: {
            tooltip: {
                backgroundColor: '#213060'
            },
        }
    };
    public lineChartLegend = false;
    lineChartColors: Color[] = [
        {
            borderColor: '#213060',
            backgroundColor: 'rgba(43, 84, 123, 0.1)',
        },
        {
            borderColor: '#79BBBF',
            backgroundColor: 'rgba(43, 84, 123, 0.1)',
        },
        {
            borderColor: '#CCCCCC',
            backgroundColor: 'rgba(43, 84, 123, 0.1)',
        },
    ];

    fixedMatching = '';
    vesting_repeat: boolean = false;

    constructor(public tr: TranslationService,
        public route: Router,
        protected mainService: MainService,
        public dec_enc: DecEncService,
        protected paginationService: PaginationService,
        public dialog: MatDialog,
        public formbuilder: FormBuilder,
        protected _route: ActivatedRoute,
        protected cdr: ChangeDetectorRef,
        protected datePipe: DatePipe,
        protected alertService: AlertService) {
        this.sub = this._route.params.subscribe(params => {
            this.id = Number(params['id']);
            if (this.id) {
                this.heading = this.tr.translation.Update_Employees_Group;
                this.button = this.tr.translation.Update_Group
            }
            else {
                this.heading = this.tr.translation.Create_Employees_Group
                this.button = this.tr.translation.Create_Group
            }
        });

        this.Form = this.formbuilder.group({
            name: [null, Validators.required],
            is_default: ['1'],
            default_fund_id: [null],
            max_contribution: null,
            active: ['1'],
            type: [null, [Validators.required]],
            start_date: [moment(new Date()).format(appConfig.dateformatServer)],
            matching: [null, [Validators.pattern(/^[0-9]+([,.][0-9]+)?$/)]],
            initial_matching: [null, [Validators.pattern(/^[0-9]+([,.][0-9]+)?$/), Validators.min(0), Validators.max(100)]],
            matching_amount: ['5', [Validators.required]],
            months: [null, [Validators.pattern(/^[0-9]+([,.][0-9]+)?$/)]],
            max_cap: [null],
            vesting_type: ['participation'],
            vesting_period: ['1', [Validators.pattern(/^[0-9]+([,.][0-9]+)?$/)]],
            vesting_percentage: ['1', [Validators.pattern(/^[0-9]+([,.][0-9]+)?$/), Validators.min(0), Validators.max(100)]],
            vesting_date: [null],
            vesting_repeat: ['annually']

        });

        if (this.id) {
            this.Form.addControl('id', new FormControl(this.id));
            this.getGroupName();
        }
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    ngOnInit(): void {
        if (this.id) {
            this.getAll();
        }
        else {
            this.getAllFundsAdd(1);
            this.getAllEmployeesAdd(1);
        }
        this.Form.get('vesting_type').valueChanges.subscribe(value => {
            this.vestingType = value;
            this.showVestingType = this.vestingType == 'participation' ? 'Fixed Duration' : 'Fixed Date';
            this.clearControls();
        });
    }

    getAllFundsAdd(index) {
        let enc_payload = ''
        let body = {
            search: this.searchAll,
            availability: "not-assigned"
        }
        let url = 'funds_groups/funds?per_page=';
        enc_payload = this.dec_enc.encryption({ search: this.searchAll, "availability": "not-assigned" })
        let urldata = url + this.paginationFund.per_page + '&page=' + index;
        this.mainService.postData(urldata, { enc_payload }).then(res => {
            if (res.statusCode == 200) {
                let result = this.dec_enc.decryption(res.data);
                this.lstFunds = result.funds
                this.lstFunds.forEach(item => {
                    item.checked = false;
                })
                // console.log('List', this.lstFunds);
                this.afterList();
            }
        });
    }

    getAllEmployeesAdd(index) {
        let enc_payload = ''
        let url = 'funds_groups/employees?per_page=';
        enc_payload = this.dec_enc.encryption({ search: this.searchAll, "availability": "not-assigned" })
        let urldata = url + this.paginationEmployee.per_page + '&page=' + index;
        this.mainService.postData(urldata, { enc_payload }).then(res => {
            if (res.statusCode == 200) {
                let result = this.dec_enc.decryption(res.data);
                this.lstEmployees = result.employees;
                this.lstEmployees.forEach(item => {
                    item.checked = false;
                    item.add = true
                })
                // console.log('ListEmps', this.lstEmployees);
            }
        });
    }

    getAll() {
        this.lstEmployees = [];
        this.lstFunds = [];
        let enc_payload = ''
        let url = this.switchOfListings.employees ? 'funds_groups/employees?per_page=' : 'funds_groups/funds?per_page=';
        if (url == 'funds_groups/employees?per_page=') {
            enc_payload = this.dec_enc.encryption({ search: this.searchAll, "availability": "not-assigned" })
        }
        if (url == 'funds_groups/funds?per_page=') {
            enc_payload = this.dec_enc.encryption({ search: this.searchAll, "availability": "not-assigned" })
        }
        let urldata = this.switchOfListings.employees ? `${url}${this.paginationEmployee.per_page}&page=${this.paginationEmployee.page}` : `${url}${this.paginationFund.per_page}&page=${this.paginationFund.page}`;
        this.mainService.postData(urldata, { enc_payload }).then(res => {
            if (res.statusCode == 200) {
                let result = this.dec_enc.decryption(res.data);
                if (this.switchOfListings.employees) {
                    this.lstEmployees = result.employees;
                    this.lstEmployees.forEach(item => {
                        item.checked = false;
                    })
                    this.paginationEmployee = result.pagination;
                }
                else {
                    this.lstFunds = result.funds
                    this.lstFunds.forEach(item => {
                        item.checked = false;
                    })
                    this.paginationFund = result.pagination;
                }
                this.afterList();
            }
        });

        if (!!this.id) {
            this.getAssigned();
        }
    }

    onSearchAll(event) {
        this.searchAll = event;
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.getAll();
        }, 800);
    }

    getAssigned() {
        this.lstAssignedFunds = [];
        this.lstAssignedEmployees = [];
        this.id = this.id == undefined ? null : this.id;
        let enc_payload = '';
        let url = this.switchOfListings.employees ? 'funds_groups/employees?per_page=' : 'funds_groups/funds?per_page=';
        if (url == 'funds_groups/employees?per_page=') {
            enc_payload = this.dec_enc.encryption({ funds_groups_id: this.id, "availability": "", search: this.searchAssigned }) //not-assigned
        }
        if (url == 'funds_groups/funds?per_page=') {
            enc_payload = this.dec_enc.encryption({ funds_groups_id: this.id, "availability": "", search: this.searchAssigned }) //not-assigned
        }
        let urldata = this.switchOfListings.employees ? `${url}${this.paginationEmployeeAssigned.per_page}&page=${this.paginationEmployeeAssigned.page}` : `${url}${this.paginationFundAssigned.per_page}&page=${this.paginationFundAssigned.page}`;

        this.mainService.postData(urldata, { enc_payload }).then(res => {
            if (res.statusCode == 200) {
                let result = this.dec_enc.decryption(res.data);
                if (!this.switchOfListings.employees) {
                    this.lstAssignedFunds = result.funds;
                    this.lstAssignedFunds.forEach(item => {
                        item['Remove'] = true;
                        item['remove'] = true;
                    })
                    this.paginationFundAssigned = result.pagination;
                }
                else {
                    this.lstAssignedEmployees = result.employees;
                    this.lstAssignedEmployees.forEach(item => {
                        item['Remove'] = true;
                        item['remove'] = true;
                    })
                    this.paginationEmployeeAssigned = result.pagination;
                }
            }
        });
    }

    onSearchAssigned(event) {
        this.searchAssigned = event;
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.getAssigned();
        }, 800);
    }

    setPageFund(pageDate: any) {
        this.paginationFund.page = pageDate;
        this.getAll();
    }

    SetFundPageSize(pageSize) {
        this.paginationFund.per_page = pageSize;
        this.getAll();
    }

    setPageEmployee(pageDate: any) {
        this.paginationEmployee.page = pageDate;
        this.getAll();
    }

    setPageSizeEmployee(pageSize) {
        this.paginationEmployeeAssigned.per_page = pageSize
        this.getAll();
    }

    setPageFundAssigned(pageDate: any) {
        this.paginationFundAssigned.page = pageDate;
        this.getAssigned();
    }

    setPageSizeFundAssigned(pageSize) {
        this.paginationFundAssigned.per_page = pageSize
        this.getAssigned();
    }

    setPageEmployeeAssigned(pageDate: any) {
        this.paginationEmployeeAssigned.page = pageDate;
        this.getAssigned();
    }

    setPageSizeEmployeeAssigned(pageSize) {
        this.paginationEmployeeAssigned.per_page = pageSize
        this.getAssigned();
    }

    onItemAdd(index: number, row: []) {
        if (index != -1) {
            // let message = `Do you want to add ${this.switchOfListings.employees ? 'employee(s)' : 'fund(s)'} into this group`;
            let message = this.switchOfListings.employees ? 'Do you want to add employees to this group?' : 'Do you want to add a portfolio?';
            this.alertService.alertAsk('Confirmation', message, 'Yes', 'No', false).then(res => {
                if (res) {
                    if (this.id) {
                        let id = [];
                        id.push(row['id']);
                        let data;
                        if (this.switchOfListings.employees) {
                            data = { funds_groups_id: this.id, employee_ids: id }
                        }
                        else {
                            data = { funds_groups_id: this.id, fund_ids: id }
                        }
                        let enc_payload = this.dec_enc.encryption(data)
                        let url = this.switchOfListings.employees ? 'funds_groups/add_employee' : 'funds_groups/add_fund';
                        this.mainService.postData(url, { enc_payload }).then(res => {
                            if (res.statusCode == 200) {
                                if (!this.switchOfListings.employees) {
                                    this.lstAssignedFunds.push(row);
                                    this.lstFunds.splice(index, 1);
                                    this.getAll();
                                }
                                else {
                                    this.lstAssignedEmployees.push(row);
                                    this.lstEmployees.splice(index, 1);
                                    this.getAll();
                                }

                                // let messageSucc = `This ${this.switchOfListings.employees ? 'Employee' : 'Fund'} added successfully into group`;
                                let messageSucc = this.switchOfListings.employees ? 'This Employee added successfully into group' : 'The portfolio has been added successfully';
                                this.alertService.alert('Success', messageSucc);
                            }
                            else if (res.statusCode == 406) {
                                if (localStorage.getItem('lang') == 'ar') {
                                    this.alertService.alert('Error', res.error.message_ar);
                                }
                                else {
                                    this.alertService.alert('Error', res.error.message);
                                }
                            }
                            else {
                                // let messageErr = `This ${this.switchOfListings.employees ? 'Employee' : 'Fund'} is already exists in another Fund Group`;
                                let messageErr = this.switchOfListings.employees ? 'This Employee is already exists in another Fund Group' : 'This Portfolio has already been assigned';
                                this.alertService.alert('Error', messageErr);
                            }
                        })
                    }
                    else {
                        row['remove'] = true;
                        if (!this.switchOfListings.employees) {
                            this.lstAssignedFunds.push(row);
                            this.lstAssignedFunds = [].concat(this.lstAssignedFunds);

                            let index = this.lstFunds.findIndex(elem => elem.id == row['id']);
                            if (index != -1) {
                                this.lstFunds.splice(index, 1);
                            }
                            this.lstFunds = [].concat(this.lstFunds);
                            // console.log(this.lstAssignedFunds, this.lstFunds)
                            let ids = this.lstAssignedFunds.map((item) => item.id);
                            this.fundIds = ids.join(',');
                        }
                        else {
                            this.lstAssignedEmployees.push(row);
                            this.lstAssignedEmployees = [].concat(this.lstAssignedEmployees);
                            let index = this.lstEmployees.findIndex(elem => elem.id == row['id']);
                            if (index != -1) {
                                this.lstEmployees.splice(index, 1);
                            }
                            this.lstEmployees = [].concat(this.lstEmployees);
                            let ids = this.lstAssignedEmployees.map((item) => item.id);
                            this.empIds = ids.join(',');
                        }

                        let msg = !this.switchOfListings.employees ? this.tr.translation.fund_not_added : this.tr.translation.employee_not_added;
                        this.alertService.alert('Success', msg);
                    }
                }
            })
        }
    }

    onItemAddSelected(row) {
        // let message = `Do you want to add into ${this.isEmployeeLinked ? 'employees' : 'fund'} into this group?`;
        let message = this.isEmployeeLinked ? 'Do you want to add employees to this group?' : 'Do you want to add a portfolio?';
        this.alertService.alertAsk('Confirmation', message, 'Yes', 'No', false).then(res => {
            if (res) {
                //console.log("alert result", result)
                if (this.id) {
                    let data;
                    if (this.isEmployeeLinked) {
                        data = { funds_groups_id: this.id, employee_ids: row }
                        // console.log('Payload', data);
                    }
                    else {
                        data = { funds_groups_id: this.id, fund_ids: row }
                        //   console.log('Payload', data);
                    }
                    // console.log("add", data);
                    let enc_payload = this.dec_enc.encryption(data)
                    let url = this.isEmployeeLinked ? 'funds_groups/add_employee' : 'funds_groups/add_fund';
                    this.mainService.postData(url, { enc_payload }).then(res => {
                        if (res.statusCode == 200) {
                            //console.log("alert result 2222", res)
                            if (!this.isEmployeeLinked) {
                                this.getAll();
                            }
                            else {
                                this.getAll();
                            }
                            let messageSucc = `This ${this.isEmployeeLinked ? 'Employee' : 'Fund'} added successfully into group`;
                            this.alertService.alert('Success', messageSucc);
                        }
                        else if (res.statusCode == 406) {
                            if (localStorage.getItem('lang') == 'ar') {
                                this.alertService.alert('Error', res.error.message_ar);
                            }
                            else {
                                this.alertService.alert('Error', res.error.message);
                            }
                        }
                        else {
                            // let msg = `This ${this.isEmployeeLinked ? 'Employee' : 'Fund'} is already exists in another Fund Group`;
                            let msg = this.isEmployeeLinked ? 'This Employee is already exists in another Fund Group' : 'This Portfolio has already been assigned';
                            this.alertService.alert('Error', msg);
                        }
                    })
                }
                else {
                    if (!this.isEmployeeLinked) {
                    }
                    else {
                    }
                    let msgSuc = !this.isEmployeeLinked ? this.tr.translation.fund_not_added : this.tr.translation.employee_not_added;
                    this.alertService.alert('Success', msgSuc);
                }
            }
        })
    }

    addSelected() {
        if (this.id) {
            if (this.isEmployeeLinked) {
                let empIds = [];
                this.lstEmployees.forEach((item) => {
                    if (item.checked) {
                        empIds.push(item.id);
                    }
                })
                let ids = this.lstAssignedEmployees.map((item) => item.id);
                this.empIds = ids.join(',');
                // this.getAll(1);
                this.onAllChecked();
                this.onItemAddSelected(empIds);
            }
            else {
                let fundId = [];
                this.lstFunds.forEach((item) => {
                    if (item.checked) {
                        fundId.push(item.id);
                    }
                })
                let ids = this.lstAssignedFunds.map((item) => item.id);
                this.fundIds = ids.join(',');
                // this.getAll(1);
                this.onAllChecked();
                this.onItemAddSelected(fundId);
            }
        }
        else {
            if (this.isEmployeeLinked) {
                // let message = `Do you want to add ${this.switchOfListings.employees ? 'employee(s)' : 'fund(s)'} into this group?`;
                let message = this.switchOfListings.employees ? 'Do you want to add employees to this group?' : 'Do you want to add a portfolio?';
                this.alertService.alertAsk('Confirmation', message, 'Yes', 'No', false).then(res => {
                    if (res) {
                        this.lstEmployees.forEach((item) => {
                            if (item.checked) {
                                this.lstAssignedEmployees.push(item);
                            }
                        })
                        this.lstAssignedEmployees.forEach((item) => {
                            item.checked = false;
                            item.remove = true;
                            let index = this.lstEmployees.findIndex(elem => elem.id == item.id);
                            if (index != -1) {
                                this.lstEmployees.splice(index, 1);
                            }
                        })
                        let ids = this.lstAssignedEmployees.map((item) => item.id);
                        this.empIds = ids.join(',');
                        this.lstAssignedEmployees = [].concat(this.lstAssignedEmployees);
                        this.lstEmployees = [].concat(this.lstEmployees);
                    }
                })
            }
            else {
                // let message = `Do you want to add ${this.switchOfListings.employees ? 'employee(s)' : 'fund(s)'} into this group?`;
                let message = this.switchOfListings.employees ? 'Do you want to add employees to this group?' : 'Do you want to add a portfolio?';
                this.alertService.alertAsk('Confirmation', message, 'Yes', 'No', false).then(res => {
                    if (res) {
                        this.lstFunds.forEach((item) => {
                            if (item.checked) {
                                this.lstAssignedFunds.push(item);
                            }
                        })
                        this.lstAssignedFunds.forEach((item) => {
                            item.checked = false;
                            item.remove = true;
                            let index = this.lstFunds.findIndex(elem => elem.id == item.id);
                            if (index != -1) {
                                this.lstFunds.splice(index, 1);
                            }
                        })
                        let ids = this.lstAssignedFunds.map((item) => item.id);
                        this.fundIds = ids.join(',');
                        this.lstAssignedFunds = [].concat(this.lstAssignedFunds);
                        this.lstFunds = [].concat(this.lstFunds);
                    }
                })
            }
        }
    }

    onItemRemove(index: number, row: []) {
        if (index != -1) {
            let messageTop = `Do you want to remove this ${this.switchOfListings.employees ? 'employee' : 'fund'} from this group`;
            this.alertService.alertAsk('Confirmation', messageTop, 'Yes', 'No', false).then(res => {
                if (res) {
                    if (this.id) {
                        let data;
                        if (this.switchOfListings.employees) {
                            data = { funds_groups_id: this.id, employee_id: row['id'] }
                        }
                        else {
                            data = { funds_groups_id: this.id, fund_id: row['id'] }
                        }
                        //console.log("remove", data);
                        let enc_payload = this.dec_enc.encryption(data);
                        let url = this.switchOfListings.employees ? 'funds_groups/remove_employee' : 'funds_groups/remove_fund';
                        this.mainService.postData(url, { enc_payload }).then(res => {
                            if (res.statusCode == 200) {
                                if (!this.switchOfListings.employees) {
                                    this.lstFunds.push(row);
                                    this.lstAssignedFunds.splice(index, 1);
                                    this.getAll();
                                }
                                else {
                                    this.lstEmployees.push(row);
                                    this.lstAssignedEmployees.splice(index, 1);
                                    this.getAll();
                                }
                            }
                            else {
                                if (localStorage.getItem('lang') == 'ar') {
                                    this.alertService.alert('Error', res.error.message_ar);
                                }
                                else {
                                    this.alertService.alert('Error', res.error.message);
                                }
                            }
                        })
                    }
                    else {
                        if (!this.switchOfListings.employees) {
                            this.lstFunds.push(row);
                            this.lstFunds.sort(function (a, b) { return b.id - a.id });
                            this.lstFunds = [].concat(this.lstFunds);
                            let index = this.lstAssignedFunds.findIndex(elem => elem.id == row['id']);
                            if (index != -1) {
                                this.lstAssignedFunds.splice(index, 1);
                            }
                            this.lstAssignedFunds = [].concat(this.lstAssignedFunds);
                            let ids = this.lstAssignedFunds.map((item) => item.id);
                            this.fundIds = ids.join(',');
                            // console.log('RemoveFunds', this.lstFunds);
                        }
                        else {
                            this.lstEmployees.push(row);
                            this.lstEmployees.sort(function (a, b) { return b.id - a.id });
                            this.lstEmployees = [].concat(this.lstEmployees);
                            let index = this.lstAssignedEmployees.findIndex(elem => elem.id == row['id']);
                            if (index != -1) {
                                this.lstAssignedEmployees.splice(index, 1);
                            }
                            this.lstAssignedEmployees = [].concat(this.lstAssignedEmployees);
                            let ids = this.lstAssignedEmployees.map((item) => item.id);
                            this.empIds = ids.join(',');
                        }
                    }
                }
            })
        }
    }

    onLinkEmployees(islink) {
        // Is link true mean that you are in link employees listing
        //console.log("islink",islink)
        // if (this.switchOfListings.employees != islink) {
        this.isEmployeeLinked = islink;
        this.switchOfListings.employees = islink;
        if (this.id) {
            this.lstAssignedEmployees = [];
            this.lstAssignedFunds = [];
            this.getAll();
        }
        // }
        // console.log('onlink', this.isEmployeeLinked)
    }

    back() {
        this.route.navigateByUrl(`/main/investment-funds`);
    }

    getGroupName() {
        this.FundGroup = [];
        let url = 'funds_groups'
        this.mainService.postData(url, {}).then(res => {
            if (res.statusCode == 200) {
                let result = this.dec_enc.decryption(res.data);
                this.grouop = result.funds_groups;
                this.getAssigned();
                this.grouop.forEach(obj => {
                    obj['matching_amount'] = obj['matching_amount'] != null ? parseInt(obj['matching_amount'], 10) : obj['matching_amount'];
                    obj['matching'] = obj['matching'] != null ? parseInt(obj['matching'], 10) : obj['matching'];
                    obj['initial_matching'] = obj['initial_matching'] != null ? parseInt(obj['initial_matching'], 10) : obj['initial_matching'];
                    obj['vesting_percentage'] = obj['vesting_percentage'] != null ? parseInt(obj['vesting_percentage'], 10) : obj['vesting_percentage'];
                    if (this.id === obj.id) {
                        this.vesting_repeat = obj.vesting_repeat == 'as_before' ? true : false;
                        this.repeatVestingStyle = this.vesting_repeat ? 'On same terms as before' : 'Annually';
                        this.Form.patchValue(obj);
                        console.log('Obj', obj);
                        const formattedDate = this.datePipe.transform(obj.vesting_date, 'yyyy-MM-dd');
                        this.Form.get('vesting_date').setValue(formattedDate);
                        this.currentVestingDate = formattedDate;
                        this.getMatchingGraphData();
                    }
                });
            }
        },
            error => {
                this.alertService.alert('Error', 'Server Error');
            }
        )
    }

    addGroup() {
        console.log('Value~*', this.Form.value);

        if (!this._checkValidations(this.Form)) {
            return
        }
        let url;
        if (this.id) {
            url = "funds_groups/update";
            this.message = this.tr.translation.groupUpdated;
        }
        else {
            url = "funds_groups/create";
            this.message = this.tr.translation.groupCreated;
        }

        if (this.Form.get("name").value == null || this.Form.get("default_fund_id").value == null) {
            let message = this.Form.get("name").value == null ? this.tr.translation.group_name_error : this.tr.translation.select_default_portfolio_error;
            this.alertService.alert('Error', message);
            return;
        }

        if (this.id) {
            this.Form.removeControl('fundIds');
            this.Form.removeControl('empIds');
        }
        else {
            this.Form.addControl('fundIds', new FormControl(this.fundIds));
            this.Form.addControl('empIds', new FormControl(this.empIds));
        }
        // 12/12/22
        // change according to farhan backend (farhan said to send initial_matching value into matching key )
        let enc_payload = this.dec_enc.encryption(this.Form.value);
        this.mainService.postData(url, { enc_payload }).then(result => {
            let data = this.dec_enc.decryption(result.data);
            if (result.statusCode == 200) {
                this.alertService.alert('Success', this.message);
                if (data.fund_group) {
                    this.route.navigateByUrl(`/main/investment-funds`);
                }
            }
            else {
                if (localStorage.getItem('lang') == 'ar') {
                    this.alertService.alert('Error', result.error.message_ar);
                }
                else {
                    this.alertService.alert('Error', result.error.message);
                }
            }
        },
            error => {
                this.alertService.alert('Error', 'Internal Server error');
            });
    }

    checkBoxHeader(event) {
        if (this.switchOfListings.employees) {
            if (event.checked) {
                this.lstEmployees.forEach(item => {
                    item.checked = true;
                });
            }
            else {
                this.lstEmployees.forEach(item => {
                    item.checked = false;
                });
                this.headerCheckBoxValue = false;
            }
        }
        else {
            if (event.checked) {
                this.lstFunds.forEach(item => {
                    item.checked = true;
                });
            }
            else {
                this.lstFunds.forEach(item => {
                    item.checked = false;
                });
                this.headerCheckBoxValue = false;
            }
        }
        this.headerCheckBoxValue = true;
        this.onAllChecked();
        // console.log(event);
    }

    isChecked() {
        if (this.headerCheckBoxValue)
            return true;
        return false;
    }

    onAllChecked() {
        this.checkBoxCount = 0;
        let checkedCount = 0;
        let dataLength = 0;
        if (this.switchOfListings.employees) {
            this.lstEmployees.forEach(element => {
                if (element['checked']) {
                    checkedCount++;
                }
            });
            dataLength = this.lstEmployees.length;
        }
        else {
            this.lstFunds.forEach(element => {
                if (element['checked']) {
                    checkedCount++;
                }
            });
            dataLength = this.lstFunds.length;
        }
        this.headerCheckBoxValue = (dataLength > 0 && dataLength == checkedCount) ? true : false;
        this.checkBoxCount = checkedCount;
    }

    onSelectedCheckbox(item): void {
        if (!this.checkboxSelected.includes(item.id)) {
            this.checkboxSelected.push(item.id);
        } else {
            var index = this.checkboxSelected.indexOf(item.id);
            if (index > -1) {
                this.checkboxSelected.splice(index, 1);
            }
        }
    }

    afterList() {
        if (this.switchOfListings.portfolio) {
            this.lstFunds.forEach(item => {
                item['Add'] = true;
                item['add'] = true;
                item['bankName'] = item.bank?.name ? item?.bank?.name : 'N/A';
            });
        }
        else {
            this.lstEmployees.forEach(item => {
                item['Add'] = true;
                item['add'] = true;
            });
        }
    }

    getMatchingGraphData() {
        let url = `employees/matching_calender`;
        let payload = {
            initial_matching: this.Form.get('initial_matching').value,

            type: this.Form.get('type').value,
            months: this.Form.get('months').value
        }
        let encPayload = this.dec_enc.encryption(payload);
        let body = {
            enc_payload: encPayload
        }
        this.mainService.postData(url, body).then(response => {
            if (response.statusCode == 200) {
                let decResponse = this.dec_enc.decryption(response.data);
                this.lineChartLabels = [];
                this.lineChartData = [{ data: [], label: null }];
                decResponse.calender.forEach((item, index) => {
                    item.matching = item.matching ? Number(item.matching).toFixed(2) : item.matching;
                    this.lineChartLabels.push(index + 1);
                    this.lineChartData[0].data.push(item.matching);
                    this.lineChartData[0].label = item.matching;
                })
            }
            else {
                console.log('GraphData Failed', response.error.message);
            }
        })
    }

    matchingFieldChange() {
        if (this.fixedMatching == 'variable') {
            if ((this.Form.get('months').value && this.Form.get('months').valid) && (this.Form.get('initial_matching').value && this.Form.get('initial_matching').valid)) {
                this.getMatchingGraphData();
            }
        }
        else {
            if (this.Form.get('initial_matching').value && this.Form.get('initial_matching').valid) {
                this.getMatchingGraphData();
            }
        }
    }

    // getMatching()
    // {
    //     let url = `funds_groups/get_matching`;
    //     let payload = {
    //         funds_groups_id: this.id
    //     }
    //     let encPayload = this.dec_enc.encryption(payload);
    //     let body = {
    //         enc_payload: encPayload
    //     }
    //     this.mainService.postData(url, body).then(response => {
    //         if (response.statusCode == 200)
    //         {
    //             let decData = this.dec_enc.decryption(response.data);
    //             this.Form.patchValue(decData.address);
    //             this.matchingFieldChange();
    //             console.log('getMatching', decData);
    //         }
    //         else
    //         {}
    //     })
    // }

    matchingSubmit() {
        let url = 'funds_groups/add_matching';
        let payload = this.dec_enc.encryption(this.Form.value);
        let body = {
            enc_payload: payload
        }
        this.mainService.postData(url, body).then(response => {
            if (response.statusCode == 200) {
                let decResponse = this.dec_enc.decryption(response.data);
                this.alertService.alert('Success', response.message);
            }
            else {
                if (localStorage.getItem('lang') == 'ar') {
                    this.alertService.alert('Error', response.error.message_ar);
                }
                else {
                    this.alertService.alert('Error', response.error.message);
                }
            }
        })
    }

    clearControls() {
        if (this.vestingType === 'participation') {
            this.Form.get('vesting_date').setValue('');
            this.periods = 0;
        } else if (this.vestingType === 'fixed_date' && this.showVestingType == 'Fixed Date') {
            this.Form.get('vesting_period').setValue('');
            const formattedDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
            this.Form.get('vesting_date').setValue(formattedDate);
            this.currentVestingDate = formattedDate;
        }
    }

    matchingType() {
        if (this.fixedMatching == 'variable') {
            this.Form.get('matching').setValue(null);
            this.Form.get('matching').clearValidators();
            this.Form.get('months').setValidators([Validators.required]);
            this.Form.get('initial_matching').setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
            this.Form.get('matching').updateValueAndValidity();
            this.Form.get('months').updateValueAndValidity();
            this.Form.get('initial_matching').updateValueAndValidity();
        }
        else {
            this.Form.get('months').setValue(null);
            this.Form.get('initial_matching').setValue(null);
            this.Form.get('matching').setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
            this.Form.get('matching').updateValueAndValidity();
            this.Form.get('months').updateValueAndValidity();
            this.Form.get('initial_matching').updateValueAndValidity();
        }
        this.getMatchingGraphData();
    }

    checkBox(event) {
        this.checkBoxCount = event.checkCount;
        if (this.checkBoxCount > 0) {
            if (this.switchOfListings.portfolio) {
                this.tablePropsPortfolioBottom.headerActions[0].visibility = true;
            }
            else {
                this.tablePropsEmpBottom.headerActions[0].visibility = true;
            }
        }
        else {
            if (this.switchOfListings.portfolio) {
                this.tablePropsPortfolioBottom.headerActions[0].visibility = false;
            }
            else {
                this.tablePropsEmpBottom.headerActions[0].visibility = false;
            }
        }
        // console.log('List', this.lstFunds, this.checkBoxCount)
    }

    changeButton(item) {
        this.searchAll = '';
        this.searchAssigned = '';
        if (item == 'portfolio') {
            this.switchOfListings.employees = false;
            this.switchOfListings.portfolio = true;
            this.searchAll = '';
            this.searchAssigned = '';
            this.onLinkEmployees(false);
        }
        else {
            this.switchOfListings.portfolio = false;
            this.switchOfListings.employees = true;
            this.searchAll = '';
            this.searchAssigned = '';
            this.onLinkEmployees(true);
        }
        this.switchOfListings[item] = true;
    }

    headerButton(event) {
        if (event.label = 'Add Selected') {
            this.addSelected();
        }
        // console.log('headerbutton', event)
    }

    actionClicked(event) {
        // console.log(event)
        if (event.action.label == 'Add') {
            this.onItemAdd(event.index, event.element)
        }
        if (event.action.label == 'Remove') {
            this.onItemRemove(event.index, event.element)
        }
    }

    getErrorMessage(form, control, fieldName, minVal?, maxVal?) {
        if (form.get(control).invalid) {
            if (form.get(control).hasError('required')) {
                return `${fieldName} is a required field.`
            }
            if (form.get(control).hasError('min')) {
                return `${fieldName} should be 0-100 only.`
            }
            if (form.get(control).hasError('max')) {
                return `${fieldName} should be 0-100 only.`
            }
        }
    }

    getFieldValue(form, field) {
        if (form.get(field).touched) {
            if (form.get(field).invalid) {
                return true;
            }
        }
        return false;
    }

    _checkValidations(form: FormGroup) {
        if (form.invalid) {
            form.markAllAsTouched();
            return false;
        }
        return true;
    }

    _findInvalidControls(form) {
        const invalid = [];
        const controls = form.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        return invalid;
    }

    onToggleChange(event: any) {
        this.vesting_repeat = event.checked;
        this.Form.get('vesting_repeat')?.setValue(this.vesting_repeat ? 'as_before' : 'annually');
        let vesting_value = this.Form.get('vesting_repeat').value;
        this.repeatVestingStyle = vesting_value == 'as_before' ? 'On same terms as before' : 'Annually';
    }
    isArabicLang(): boolean {
        return localStorage.getItem('lang') === 'ar';
      }
}

<h3 class="form-heading"
[ngStyle]="isArabicLang() ? {'text-align': 'right', 'margin-right.px': 25} : {}">
  {{!id ? ('Create_Employees_Group' | translate) : ('Update_Employees_Group' | translate)}}
</h3>

<mat-card style="margin: 20px 20px; padding: 16px;">
  <h3 [ngStyle]="isArabicLang() ? {'text-align': 'right'} : {}"
   style="margin-left: 0; font-weight: bold; color: #213060;">
    {{'Group_Details' | translate}}
  </h3>
  <form [formGroup]="Form">
    <div fxLayout="row wrap" fxLayoutGap="10px">
      <div fxFlex="48" fxLayout="column" fxLayoutGap="5px" class="form-container">
        <mat-form-field fxFlex="100" fxFlex.xs="100" class="input-field" fxFlexAlign="center" appearance="outline"
          style="width: 100%;">
          <mat-label>{{'Group_Name' | translate}}</mat-label>
          <input autocomplete="off" type="text" matInput formControlName="name">
        </mat-form-field>
        <mat-form-field *ngIf="switchOfListings.portfolio" class="input-field" fxFlex="100" fxFlex.sm="46"
          fxFlex.xs="100" appearance="outline" style="width: 100%;">
          <mat-label>{{'default_portfolio' | translate}}</mat-label>
          <mat-select formControlName="default_fund_id">
            <mat-option *ngFor="let item of lstAssignedFunds" [value]="item.id">{{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <mat-form-field fxFlex="100" fxFlex.xs="100" class="input-field" fxFlexAlign="center" appearance="outline"
          style="width: 100%;">
          <mat-label>{{'Monthly Contribution Amount (%)' | translate}}</mat-label>
          <input formControlName="matching_amount" type="number" maxlength="3" autocomplete="off" type="text" matInput>
          <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'matching_amount')" #tooltip="matTooltip"
          [matTooltip]="getErrorMessage(Form, 'matching_amount', 'Monthly Contribution (%)')"
          [matTooltipDisabled]="!getFieldValue(Form, 'matching_amount')" matTooltipPosition="above" matSuffix>info
        </mat-icon>
        </mat-form-field> -->
        <mat-form-field class="input-field" fxFlex="100" fxFlex.sm="46" fxFlex.xs="100" appearance="outline"
          style="width: 100%;">
          <mat-label>{{'Matching_Rule' | translate}}</mat-label>
          <mat-select (selectionChange)="matchingType()" [(ngModel)]="fixedMatching" formControlName="type">
            <mat-option value="fixed">{{'Fixed' | translate}}</mat-option>
            <mat-option value="variable">{{'Gradually Increasing' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="fixedMatching == 'fixed'" fxFlex="100" fxFlex.xs="100" class="input-field"
          fxFlexAlign="center" appearance="outline" style="width: 100%;">
          <mat-label>{{'Matching' | translate}}</mat-label>
          <input (change)="matchingFieldChange()" type="number" maxlength="3" formControlName="matching"
            autocomplete="off" type="text" matInput>
          <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'matching')" #tooltip="matTooltip"
            [matTooltip]="getErrorMessage(Form, 'matching', 'Matching (%)')"
            [matTooltipDisabled]="!getFieldValue(Form, 'matching')" matTooltipPosition="above" matSuffix>info
          </mat-icon>
        </mat-form-field>
        <mat-form-field *ngIf="fixedMatching == 'variable'" fxFlex="100" fxFlex.xs="100" class="input-field"
          fxFlexAlign="center" appearance="outline" style="width: 100%;">
          <mat-label>{{'Initial_Matching' | translate}}</mat-label>
          <input (change)="matchingFieldChange()" type="number" maxlength="3" formControlName="initial_matching"
            autocomplete="off" type="text" matInput>
          <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'initial_matching')" #tooltip="matTooltip"
            [matTooltip]="getErrorMessage(Form, 'initial_matching', 'Initial Matching (%)')"
            [matTooltipDisabled]="!getFieldValue(Form, 'initial_matching')" matTooltipPosition="above" matSuffix>info
          </mat-icon>
        </mat-form-field>
        <mat-form-field *ngIf="fixedMatching == 'variable'" fxFlex="100" fxFlex.xs="100" class="input-field"
          fxFlexAlign="center" appearance="outline" style="width: 100%;">
          <mat-label>{{'Months_Till_Hundred_Percent' | translate}}</mat-label>
          <input (change)="matchingFieldChange()" formControlName="months" autocomplete="off" type="text" matInput>
        </mat-form-field>
        <mat-form-field fxFlex="100" fxFlex.xs="100" class="input-field" fxFlexAlign="center" appearance="outline"
          style="width: 100%;">
          <mat-label>{{'Max Contribution CAP' | translate}}</mat-label>
          <input formControlName="max_cap" autocomplete="off" type="text" matInput>
        </mat-form-field>

        <mat-form-field class="input-field" fxFlex="100" fxFlex.sm="46" fxFlex.xs="100" appearance="outline">
          <mat-label>{{ 'Vesting_Type' | translate }}</mat-label>
          <mat-select [disabled]="!!id" formControlName="vesting_type">
            <mat-option value="participation">{{ 'Fixed Duration' | translate }}</mat-option>
            <mat-option value="fixed_date">{{ 'Fixed Date' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="vestingType == 'participation'" class="input-field" fxFlex="100" fxFlex.sm="46"
          fxFlex.xs="100" appearance="outline">
          <mat-label>{{ 'Periods' | translate }}</mat-label>
          <input [readonly]="!!id" formControlName="vesting_period" [(ngModel)]="periods" autocomplete="off" type="text"
            matInput>
        </mat-form-field>
        <mat-form-field *ngIf="vestingType == 'fixed_date'" fxFlex="23.3" fxFlex.xs="100" style="width: 100%;"
          class="input-field mt-10" fxFlexAlign="center" appearance="outline">
          <mat-label>{{'Date' | translate}}</mat-label>
          <input matInput type="text" [min]="currentDate" [(ngModel)]="currentVestingDate"
            formControlName="vesting_date" [matDatepicker]="picker1" readonly />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <!-- <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'date_of_birth')" #tooltip="matTooltip"
            [matTooltip]="getErrorMessage(Form, 'date_of_birth', 'DOB_Required')"
            [matTooltipDisabled]="!getFieldValue(Form, 'date_of_birth')" matTooltipPosition="above" matPrefix>info
          </mat-icon> -->
        </mat-form-field>
        <mat-form-field *ngIf="vestingType == 'participation'  || vestingType == 'fixed_date'" class="input-field"
          fxFlex="100" fxFlex.sm="46" fxFlex.xs="100" appearance="outline">
          <mat-label>{{ 'Vesting %' | translate }}</mat-label>
          <input [readonly]="!!id" formControlName="vesting_percentage" [(ngModel)]="vestingPercent" maxlength="3"
            autocomplete="off" type="text" matInput>
          <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'vesting_percentage')" #tooltip="matTooltip"
            [matTooltip]="getErrorMessage(Form, 'vesting_percentage', 'Vesting Percentage (%)')"
            [matTooltipDisabled]="!getFieldValue(Form, 'vesting_percentage')" matTooltipPosition="above" matSuffix>info
          </mat-icon>
        </mat-form-field>
        <p class="mt-2 ml-2 vesting-info-text"  [ngStyle]="isArabicLang() ? {'text-align': 'right'} : {}">
          {{ 'How will the vesting be applied in the next cycle of the program?' | translate }}</p>
        <mat-card-content class="vesting-cycle">
          <h4 class="vesting-cycle-title" [ngClass]="{
                'font-size-selected': Form.get('vesting_repeat')?.value === 'annually', 
                'font-size-unselected': Form.get('vesting_repeat')?.value !== 'annually'
              }">
            {{ 'Annually' | translate }}
          </h4>
          <mat-slide-toggle class="vesting-cycle-toggle" [(ngModel)]="vesting_repeat"
            [ngModelOptions]="{standalone: true}" [checked]="vesting_repeat" (change)="onToggleChange($event)">
          </mat-slide-toggle>
          <h5 class="vesting-cycle-description" [ngClass]="{
                'font-size-selected': Form.get('vesting_repeat')?.value === 'as_before',
                'font-size-unselected': Form.get('vesting_repeat')?.value !== 'as_before'
              }">
            {{ 'On same terms as before' | translate }}
          </h5>
        </mat-card-content>

      </div>
      <div fxFlex="48" fxLayout="column" fxLayoutAlign="center start" style="margin-top: 20px;">
        <canvas baseChart [chartType]="'line'" [datasets]="lineChartData" [labels]="lineChartLabels"
          [options]="lineChartOptions" [legend]="lineChartLegend" [colors]="lineChartColors" height="100">
        </canvas>
        <div fxLayout="column" fxLayoutAlign="start start" style="margin-top: 10px; width: 100%;">
          <h4 class="vesting-heading">{{ 'How the Company’s Vesting Policy Works:' | translate }}</h4>
          <!-- <h4 class="vesting-text">
            Based on the <span class="highlight">{{showVestingType}}</span> goal of <span class="highlight">
              {{ showVestingType === 'Fixed Date' ? (currentVestingDate | date:'d MMMM yyyy') : periods }}</span>
            months, you will vest <span class="highlight">{{vestingPercent}}%</span> of the matched amount to the
            employee. After the first vesting, this cycle will repeat <span
              class="highlight">{{repeatVestingStyle}}.</span>
          </h4> -->
          <h4 class="vesting-text" [ngStyle]="isArabicLang() ? {'text-align': 'right'} : {}">
            {{'Based on the vesting type of' | translate}} <span class="highlight">{{showVestingType | translate}}</span>, {{'After' | translate}}
            <span class="highlight">{{ showVestingType === 'Fixed Date' ? (currentVestingDate | date:'d MMMM yyyy') : (periods + ' ' + ('months' | translate)) }}</span>
            {{'the employee will vest' |translate}}  <span class="highlight">{{vestingPercent}}%</span>
            {{'of the matched amounts'| translate}}. {{'After the first vesting, this cycle will repeat' | translate}}  
            <span class="highlight">{{repeatVestingStyle | translate}}.</span></h4>
        </div>
      </div>
    </div>
  </form>
</mat-card>

<!-- <mat-card *ngIf="id" style="margin: 20px 20px; padding: 16px;">
    <h3 style="margin-left: 0; font-weight: bold; color: #213060;">Matching Details</h3>
    <form [formGroup]="matchingForm">
        <div>
            <div fxFlex="48" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="space-between" class="form-container">
                
            </div>
            
            
        </div>

        <div fxLayoutAlign="end center" style="margin-top: 10px;">
            <button (click)="matchingSubmit()" class="btn-submit" mat-raised-button>Submit</button>
        </div>
    </form>
</mat-card> -->

<!-- toggle -->
<div fxLayoutAlign="center center" style="margin-top: 20px;">
  <div style="background-color: white; border-radius: 40px; padding: 5px 10px;">
    <div fxLayout="row" fxLayoutAlign="center center" class="lang-switch-container">
      <div (click)="changeButton('portfolio')" class="lang-switch"
        [class.lang-switch-active]="switchOfListings.portfolio">{{'Link_Portfolio' | translate}}</div>
      <div (click)="changeButton('employees')" class="lang-switch"
        [class.lang-switch-active]="switchOfListings.employees">{{'Link_Employees' | translate}}</div>
    </div>
  </div>
</div>

<ng-container *ngIf="switchOfListings.portfolio">
  <div style="margin: 20px 10px; padding: 16px;">
    <app-table #table fxFlex="100" [tableProps]="tablePropsPortfolioTop" [dataSource]="lstAssignedFunds"
      (actionClicked)="actionClicked($event)" [pagination]="paginationFundAssigned"
      (setPage)="setPageFundAssigned($event)" (pageSize)="setPageSizeFundAssigned($event)"
      (searchData)="onSearchAssigned($event)">
    </app-table>
  </div>

  <div style="margin: 20px 10px; padding: 16px;">
    <app-table #table fxFlex="100" [tableProps]="tablePropsPortfolioBottom" (outPutHeaders)="headerButton($event)"
      [dataSource]="lstFunds" (emitCheckBox)="checkBox($event)" (actionClicked)="actionClicked($event)"
      [pagination]="paginationFund" (pageSize)="SetFundPageSize($event)" (setPage)="setPageFund($event)"
      (searchData)="onSearchAll($event)">
    </app-table>
  </div>
</ng-container>

<ng-container *ngIf="switchOfListings.employees">

  <div style="margin: 20px 10px; padding: 16px;">
    <app-table #table fxFlex="100" [tableProps]="tablePropsEmpTop" [dataSource]="lstAssignedEmployees"
      (actionClicked)="actionClicked($event)" [pagination]="paginationEmployeeAssigned"
      (setPage)="setPageEmployeeAssigned($event)" (pageSize)="setPageSizeEmployeeAssigned($event)"
      (searchData)="onSearchAssigned($event)">
    </app-table>
  </div>

  <div style="margin: 20px 10px; padding: 16px;">
    <app-table #table fxFlex="100" [tableProps]="tablePropsEmpBottom" (outPutHeaders)="headerButton($event)"
      [dataSource]="lstEmployees" (emitCheckBox)="checkBox($event)" (actionClicked)="actionClicked($event)"
      [pagination]="paginationEmployee" (setPage)="setPageEmployee($event)" (pageSize)="setPageSizeEmployee($event)"
      (searchData)="onSearchAll($event)">
    </app-table>
  </div>
</ng-container>

<div style="margin: 10px 25px;" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
  <button (click)="back()"
    style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 25px; border-radius: 10px; outline: none;"
    mat-raised-button>{{'Cancel' | translate}}</button>
  <button (click)="addGroup()"
    style="background-color: #213060; color: white; line-height: 34px; padding: 0 25px; border-radius: 10px; outline: none;"
    mat-raised-button>{{'Submit' | translate}}</button>
</div>
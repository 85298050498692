import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    LOGIN_KEY = 'login_status';
    Form: FormGroup
    constructor(
        protected authService: AuthService,
        protected route: Router,
        protected alertService: AlertService,
        protected translateService: TranslateService)
    {
        this.Form = new FormGroup({
            email: new FormControl(null, [Validators.required, Validators.email])
        })
    }

    ngOnInit(): void {
    }

    sendOTP()
    {
        if (!this.checkValidations())
        {
            return;
        }
        let url = `forgot`;
        let payload = {
            username: this.Form.get('email').value,
            portalID: "employerPortal"
        }
        let data = {
            enc_payload: this.authService.encryptionAuth(payload),
            enc_password: localStorage.getItem('password_token'),
        }
        localStorage.setItem('forgot_data', JSON.stringify(data));
        this.authService.auth(url, data).then(response => {
            if (response?.statusCode == 200)
            {
                // let dec = this.cryptoService.decryptionAuth(response.data)
                localStorage.setItem(this.LOGIN_KEY, response.data);
                this.route.navigateByUrl('auth/reset-password');
            }
            else
            {
                if (localStorage.getItem('lang') == 'ar')
                {
                    this.alertService.alert('Error', response.message_ar);
                }
                else
                {
                    this.alertService.alert('Error', response.message);
                }
            }
        })
    }

    getFieldValue(field) 
    {
        if (this.Form.get(field).touched) 
        {
            if (this.Form.get(field).invalid)
            {
                return true;
            }
        }
        return false;
    }

    getErrorMessage(control, fieldName, minMaxValue?)
    {
        if (this.Form.get(control).invalid)
        {
            if (this.Form.get(control).hasError('required'))
            {
                return this.translateService.instant(fieldName);
            }
            if (this.Form.get(control).hasError('email'))
            {
                return this.translateService.instant('Email_Format');
            }
        }
    }

    checkValidations() 
    {
        if (this.Form.invalid) 
        {
            this.Form.markAllAsTouched();
            return false;
        }
        return true;
    }
}

import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { FormBuilder } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { HttpClient } from '@angular/common/http';
import { EditDialogComponent } from '../edit-dialog/edit-dialog.component';
import { AlertService } from 'src/app/services/alert.service';
import * as moment from 'moment';
@Component({
    selector: 'app-contribution-form',
    templateUrl: './contribution-form.component.html',
    styleUrls: ['./contribution-form.component.scss']
})
export class ContributionFormComponent implements OnInit {

    contributions = [];
    allContributions = [];
    filteredContributions = [];
    summary = {
        total: 0,
        errors: 0,
        ok: 0
    }
    showErrorSelected = false;
    checkBoxCount = 0;

    @ViewChild('attach') attach: ElementRef;

    tableProps = {
        heading: 'Total_Contributions',
        hasSearch: false,
        hasButton: true,
        headerActions: [
            {
                label: 'Delete Selected',
                type: 'output',
                visibility: false,
                isCustom: false,
            },
            {
                label: 'Show_Rows_Errors',
                type: 'output',
                visibility: true,
                isCustom: false,
            },
            {
                label: 'New_Contribution_Row',
                type: 'output',
                visibility: true,
                isCustom: false,
                icon: 'add',
            },
        ],
        rowActions: [
            { label: 'Edit', visibility: true, background: '#79BBBF0D', source: 'assets/new-icons/edit.svg' },
            { label: 'Delete', visibility: true, background: '#4318FF0D', source: 'assets/images/table-delete.png' },
        ],
        colHeader: {
            checkbox: '',
            no: 'Number',
            employee_id: 'Employee_ID',
            iqama_id: 'National_Iqama_ID',
            name: 'Employee_Name',
            employer_contribution_amount: 'Company Contribution (SAR)',
            employee_contribution_amount: 'Employee Contribution (SAR)',
            error: 'Error_Message',
            actions: 'Actions',
        },
        columnTypes: {
            employer_contribution_amount: 'columnWarning',
            employee_contribution_amount: 'columnWarning',
            error: 'errorColumn',
            actions: 'actionsSeperate',
        },
        subColumnTypesWarning: {
            employer_contribution_amount: 'number-4',
            employee_contribution_amount: 'number-4',
        },
        filterArray: [],
    };
    isEnglish: boolean = true;

    pagination = {
        page: 1,
        pages: null,
        per_page: 10,
        count: 0
    }

    constructor(public tr: TranslationService,
        public route: Router,
        protected formbuilder: FormBuilder,
        protected dialog: MatDialog,
        protected _route: ActivatedRoute,
        public dec_enc: DecEncService,
        protected mainService: MainService,
        public http: HttpClient,
        protected alertService: AlertService,
        protected changeDetectorRef: ChangeDetectorRef) {
        this.isEnglish = localStorage.getItem('lang') == 'ar' ? false : true;
    }

    ngOnInit(): void {
        this.getDraftContributions();
    }

    submitContributions() {
        this.alertService.alertAsk('Confirmation', 'Are you sure you want to submit?', 'Yes', 'No', false).then(res => {
            if (res) {
                let url = `draft_contributions/generate`;
                this.mainService.postData(url, {}).then(response => {
                    if (response.statusCode == 200) {
                        this.alertService.alert('Success', 'Contribution has been sent successfully', '');
                        this.route.navigateByUrl('/main/manage-contribution');
                    }
                    else {
                        let message = !this.isEnglish ? response.error.message_ar : response.error.message;
                        this.alertService.alert('Error', message);
                    }
                })
            }
        })
    }

    discardContributions() {
        this.alertService.alertAsk('Warning', 'Are you sure you want to discard?', 'Yes', 'No', false).then(res => {
            if (res) {
                let url = `draft_contributions/discard`;
                this.mainService.postData(url, {}).then(response => {
                    if (response.statusCode == 200) {
                        this.getDraftContributions();
                    }
                    else { }
                })
            }
        })
    }

    ConvertToCSV(objArray) {
        let _array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        str = 'employee_id,name,iqama_id,employee_contribution_amount,employer_contribution_amount';
        let cols = str.split(',');

        let lines = [];
        lines.push(cols);
        for (let r = 0; r < _array.length; r++) {
            let row = _array[r];
            let line = [];
            cols.forEach(c => {
                line.push(row[c]);
            })
            lines.push(line.join(','));
        }
        str = lines.join('\r\n');
        return str;
    }

    downloadFile(data, filename = 'data') {
        let csvData = this.ConvertToCSV(data);
        let blob = new Blob(['\ufeff' + csvData], {
            type: 'text/csv;charset=utf-8;'
        });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    getDraftContributions(customURL?) {
        let count = this.pagination.per_page * (this.pagination.page - 1) + 1;
        // let url = customURL ? customURL : `draft_contributions?sort_order=ASC`;
        let url = `draft_contributions?sort_order=ASC&per_page=${this.pagination.per_page}&page=${this.pagination.page}`;
        this.mainService.postData(url, {}).then(res => {
            if (res.statusCode == 200) {
                let dec = this.dec_enc.decryption(res.data);
                console.log('contribtuions', dec)
                this.contributions = dec.draft_contributions;
                for (let item of this.contributions) {
                    item['no'] = count;
                    item['error'] = null;
                    item['created_at'] = moment(item['created_at']).format('DD/MM/YYYY');
                    let errorFound = false;
                    item['employer_contribution_amount'] = !item['employer_contribution_amount'] || isNaN(item['employer_contribution_amount']) ? 0 : Number(item['employer_contribution_amount']);
                    item['employee_contribution_amount'] = !item['employee_contribution_amount'] || isNaN(item['employee_contribution_amount']) ? 0 : Number(item['employee_contribution_amount']);
                    // if (isNaN(item['employer_contribution_amount'])) {
                    //     item['employer_contribution_amount'] = null;
                    // }
                    // if (isNaN(item['employee_contribution_amount'])) {
                    //     item['employee_contribution_amount'] = null;
                    // }
                    if (item['error_message']) {
                        for (let key in item['error_message']) {
                            errorFound = true;
                            if (!this.isEnglish) {
                                if (item['error'] == null) {
                                    item['error'] = item['error_message'][key]['message_ar'];
                                }
                                else {
                                    item['error'] = `${item['error']}, ${item['error_message'][key]['message_ar']}`;
                                }
                            }
                            else {
                                if (item['error'] == null) {
                                    item['error'] = item['error_message'][key]['message'];
                                }
                                else {
                                    item['error'] = `${item['error']}, ${item['error_message'][key]['message']}`;
                                }
                            }
                        }
                    }

                    if (!errorFound) {
                        // item['error'] = 'Verified';
                        item['error'] = 'No Errors';
                    }
                    count++;
                }
                this.allContributions = this.contributions.map(object => ({ ...object }))
                this.summary = dec.summary;
                this.pagination = dec.pagination;
                this.afterList();
            }
        })
    }

    filterErrors() {
        this.showErrorSelected = !this.showErrorSelected;
        if (this.showErrorSelected) {
            this.filteredContributions = this.contributions.filter(item => {
                if (item.error != 'No Errors') {
                    return item;
                }
            })
            this.contributions = this.filteredContributions;
        }
        else {
            this.contributions = this.allContributions;
        }
    }

    submitClick() {
        this.attach.nativeElement.click();
    }

    getFile(event) {
        if (event.target.files && event.target.files.length) {
            let file = event.target.files[0];
            this.importCSV(file);
        }
    }

    downloadWhileEdit() {
        let arr = this.allContributions;
        arr.forEach(item => {
            delete item['created_at'];
            delete item['employee_fund_id'];
            delete item['employer_fund_id'];
            delete item['employer_id'];
            delete item['error'];
            delete item['error_message'];
            delete item['error_status'];
            delete item['id'];
            delete item['updated_at'];
        })
        this.downloadFile(arr);
    }

    importCSV(event) {
        let data = new FormData();
        data.append('csv', event);
        let url = `draft_contributions/import`
        this.mainService.postData(url, data).then(response => {
            if (response.statusCode == 200) {
                this.getDraftContributions();
                return
            }
            let message = !this.isEnglish ? response.error.message_ar : response.error.message;
            this.alertService.alert('Error', message);
        })
    }

    downloadCsv() {
        let url = `draft_contributions/download_sample?active=1`;
        this.mainService.postData(url, {}).then(res => {
            if (res) {
                let decrypted = this.dec_enc.decryption(res.data);
                this.downloadFile(decrypted);
                return
            }
            let message = !this.isEnglish ? res.error.message_ar : res.error.message;
            this.alertService.alert('Error', message);
        })
    }

    openDialog() {
        const dialogRef = this.dialog.open(EditDialogComponent, {
            data: 'New'
        });
        dialogRef.afterClosed().subscribe(res => {
            if (res.event == 'created') {
                this.getDraftContributions();
                return;
            }
            if (res.event == 'closed') {
                return;
            }
        })
    }

    editDialog(data?) {
        const dialogRef = this.dialog.open(EditDialogComponent, {
            data: data,
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(res => {
            if (res.event == 'updated') {
                this.getDraftContributions();
                return;
            }
            if (res.event == 'closed') {
                return;
            }
        })
    }

    actionClicked(event) {
        if (event.action.label == 'Edit') {
            this.editDialog(event.element);
        }
        else {
            this.alertService.alertAsk('Confirmation', 'Are you sure you want to remove?', 'Yes', 'No', false).then(res => {
                if (res) {
                    this.deleteContribution(event.element.id);
                }
            })
        }
    }

    headerButton(event) {
        if (event.label == 'Delete Selected') {
            this.deleteSelected();
        }
        if (event.label == 'New_Contribution_Row') {
            this.openDialog();
        }
        if (event.label == 'Show_Rows_Errors') {
            this.filterErrors();
        }
    }

    checkBox(event) {
        this.checkBoxCount = event.checkCount;
        if (this.checkBoxCount) {
            this.tableProps.headerActions[0].visibility = true;
        }
        else {
            this.tableProps.headerActions[0].visibility = false;
        }
    }

    deleteSelected() {
        this.alertService.alertAsk('Confirmation', 'Are you sure you want to delete the selected items?', 'Yes', 'No', false).then(res => {
            if (res) {
                let ids = [];
                let url = `draft_contributions/remove`
                this.contributions.forEach(item => {
                    if (item.checked) {
                        ids.push(item.id);
                        if (ids.length == 1) {
                            url = `${url}?id=${item.id}`;
                        }
                        else {
                            url = `${url}&id=${item.id}`;
                        }
                    }
                })
                this.mainService.postData(url, {}).then(response => {
                    if (response.statusCode == 200) {
                        this.getDraftContributions();
                        this.checkBoxCount = 0;
                        this.showErrorSelected = false;
                        return
                    }
                    let message = !this.isEnglish ? response.error.message_ar : response.error.message;
                    this.alertService.alert('Error', message);
                })
            }
        })
    }

    deleteContribution(id) {
        let url = `draft_contributions/remove?id=${id}`;
        this.mainService.postData(url, {}).then(response => {
            if (response.statusCode == 200) {
                this.getDraftContributions();
                return
            }
            let message = !this.isEnglish ? response.error.message_ar : response.error.message;
            this.alertService.alert('Error', message);
        })
    }

    summaryContributions() {
        if (this.summary.errors) {
            this.alertService.alert('Warning', 'Please fix all the errors first.')
        }
        else {
            this.route.navigate(['main/manage-contribution/view/summary/new']);
        }
    }

    afterList() {
        this.contributions.forEach(item => {
            item.edit = true;
            item.delete = true;
            if (item.info_message?.length != 0) {
                item['columnWarningemployer_contribution_amount'] = null;
                item['columnWarningemployee_contribution_amount'] = null;
                let data = Object.keys(item.info_message);
                data.forEach(key => {
                    item['columnWarning' + key] = this.isEnglish ? item.info_message[key].message : item.info_message[key].message_ar;
                })
            }

        })
    }

    setPage(page) {
        this.pagination.page = page;
        this.getDraftContributions(this.pagination.page);
    }

    SetPageSize(pageSize) {
        this.pagination.per_page = pageSize
        this.getDraftContributions(this.pagination.page);
    }
}






import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { Subscription } from 'rxjs';
import { SubscriptionDocumentsDialogComponent } from './subscription-documents-dialog/subscription-documents-dialog.component';
import { AlertService } from 'src/app/services/alert.service';

@Component({
    selector: 'app-subscription-form-dialog',
    templateUrl: './view-subscription-form-dialog.component.html',
    styleUrls: ['./view-subscription-form-dialog.component.scss']
})
export class ViewSubscriptionFormDialogComponent implements OnInit
{

    creatForm: FormGroup;
    list = [];
    items: any = [];
    id: any;
    csv_id: any;
    resultId: any;
    date: any;
    assets: any = [];
    grandTotal: any = 0;
    sub: Subscription;
    newId: any;
    panelOpenState = true;
    noOfFiles = 0;
    lstCombined = [];
    totalAmount = 0;
    constructor(public tr: TranslationService, protected formbuilder: FormBuilder,
        protected _route: ActivatedRoute, public mainService: MainService, public route: Router,
        public dec_enc: DecEncService, protected dialog: MatDialog,
        protected alertService: AlertService
        )
    {
        this.date = new Date();
    }

    ngOnInit(): void
    {
        this.id = this.resultId;
        this.getAll();
        this.getDocs();
    }

    getAll(): void
    {
        let url = 'contributions/view_subscription';
        let body = {
            id: this.id
        }
        let enc_payload = this.dec_enc.encryption(body);
        this.mainService.postData(url, { enc_payload }).then(res =>
        {
            if (res.statusCode == 200) {
                let result;
                let data = res.data
                result = this.dec_enc.decryption(data)
                this.list = result?.subscription;
                if (this.list.length > 0)
                {
                    this.list.forEach((item, index) => {
                        if (!item.fundAmount)
                        {
                            this.list.splice(index, 1);
                        }
                    });
                    this.list.forEach(subscription => {
                        subscription?.assets?.forEach(asset => {
                            if (asset.contributions_subscriptions.length > 0)
                            {
                                if (!Number(asset.contributions_subscriptions[0].amount_sar) && !Number(asset.contributions_subscriptions[0].fee))
                                {
                                    asset.contributions_subscriptions.splice(0,1);
                                }
                            }
                        });
                    });
                }
                console.log("data---", result);
                
                this.formatData();
                // if (this.lstCombined.length > 0)
                // {
                //     this.totalAmount = this.lstCombined.map(item => item.amount_sar).reduce((acc, val) => acc + val)
                //     // this.lstCombined.push({
                //     //     id: this.isArabic ? 'إجمالي المبلغ المستثمر (ريال)' : 'Total Invested Amount (SAR)', amount_sar: this.lstCombined.map(item => item.amount_sar).reduce((acc, val) => acc + val)
                //     // })
                // }
                this.grandTotal = result?.grand_sum;
                // console.log('listcombined', this.lstCombined)
            }
        })
    }

    formatData()
    {
        console.log('list', this.list)
        this.list.forEach(subscription => {
            if(subscription.fundAmount)
            {
                subscription['showHidePortfolio'] = true;
            }
            else
            {
                subscription['showHidePortfolio'] = false
            }
            subscription?.assets?.forEach(asset => {
                if (asset?.contributions_subscriptions.length > 0)
                {
                    let contribution = null;
                    // if (!Number(asset.contributions_subscriptions[0].amount_sar) && !Number(asset.contributions_subscriptions[0].fee))
                    // {
                    //     contribution = asset?.contributions_subscriptions[1];
                    // }
                    // else
                    // {
                    //     contribution = asset?.contributions_subscriptions[0];
                    // }
                    // asset.contributions_subscriptions.forEach((element, index) => {
                    //     if (Number(element.amount_sar) && Number(element.fee))
                    //     {
                    //         contribution = asset.contributions_subscriptions[index];
                    //     }
                    // });
                    contribution = asset?.contributions_subscriptions[0];
                    console.log('Contribution', contribution)
                    contribution.amount_sar = Number(contribution.amount_sar).toFixed(4) || Number(0).toFixed(4);
                    asset['amount_sar'] = Number(contribution.amount_sar).toFixed(4) || Number(0).toFixed(4);
                    asset['no_of_units'] = Number(contribution?.no_of_units).toFixed(5) || Number(0).toFixed(5);
                    asset['fee'] = Number(contribution.fee).toFixed(4) || Number(0).toFixed(4);
                    asset['nav_per_unit'] = Number(contribution.nav_per_unit).toFixed(5) || Number(0).toFixed(5);
                    contribution.nav_per_unit = Number(contribution.nav_per_unit) || 0;
                    contribution.no_of_units = Number(contribution.no_of_units).toFixed(5) || Number(0).toFixed(5);
                    contribution.currency_price = Number(contribution.currency_price) || 0;
                    
                }
                else
                {
                    asset?.contributions_subscriptions.push({
                        amount_sar: Number(0).toFixed(4),
                        nav_per_unit: Number(0).toFixed(5),
                        no_of_units: Number(0).toFixed(5),
                        fee: Number(0).toFixed(4),
                        currency_price: 0
                    })
                    asset['amount_sar'] = Number(0).toFixed(4);
                    asset['fee'] = Number(0).toFixed(4);
                    asset['no_of_units'] = Number(0).toFixed(5);
                    asset['nav_per_unit'] = Number(0).toFixed(5);
                }
                this.validateCombined(asset);
            });
        });
        if (this.lstCombined.length > 0)
                {
                    // this.totalAmount = this.lstCombined.map(item => item.amount_sar).reduce((acc, val) => acc + val)
                    this.totalAmount = 0;
                    for (let item of this.lstCombined) {
                        if (item['nav_per_unit'])
                        {
                            this.totalAmount = this.totalAmount + (Number(item['amount_sar']) + Number(item['fee']));
                        }
                    }
                    // this.lstCombined.push({
                    //     id: this.isArabic ? 'إجمالي المبلغ المستثمر (ريال)' : 'Total Invested Amount (SAR)', amount_sar: this.lstCombined.map(item => item.amount_sar).reduce((acc, val) => acc + val)
                    // })
                }
    }

    validateCombined(asset)
    {
        let filter = this.lstCombined.filter(item => item.id == asset.id);
        if (filter.length == 0) {
            this.lstCombined.push({ ...asset });
        } else {
            filter[0].nav_per_unit = +(filter[0].nav_per_unit) + +(asset.nav_per_unit);
            filter[0].amount_sar = +(filter[0].amount_sar) + +(asset.amount_sar);
            filter[0].no_of_units = +(filter[0].no_of_units) + +(asset.no_of_units);
            filter[0].fee = +(filter[0].fee) + +(asset.fee);
        }
        this.lstCombined = [].concat(this.lstCombined);
        // console.log('listcombined', this.lstCombined)
    }

    back()
    {
        this.dialog.closeAll();
    }

    openFileDialog()
    {
        let data = {
            id: this.id,
            // complete: this.complete
        }
        const dialogRef = this.dialog.open(SubscriptionDocumentsDialogComponent, {
            data: data,
            // autoFocus: false,
            width: '40%',
            maxHeight: '90%',
            // panelClass: this.isArabic ? 'arabic-lang' : 'english-lang'
        });
    }

    getDocs()
    {
        let url = `contributions/subscription_docs`;
        let body = {
            csv_id: this.id
        }
        let enc_payload = this.dec_enc.encryption(body);
        this.mainService.postData(url, {enc_payload}).then(response => {
            if (response.statusCode == 200)
            {
                let data = this.dec_enc.decryption(response.data);
                this.noOfFiles = data.docs.length;
            }
            else
            {
                if (localStorage.getItem('lang') == 'ar') {
                    this.alertService.alert('Error', response.error.message_ar);
                }
                else {
                    this.alertService.alert('Error', response.error.message);
                }
            }
        })
    }
}

<api-loader></api-loader>
<mat-card class="scrollmenu main-card mt-50 mb-50">
    <div fxLayout="row" fxLayoutAlign="space-between">
        <h1 class="top-heading">
            {{'Terms_Conditions' | translate}}
        </h1>

        <!-- <div fxLayout="row" fxLayoutAlign="center center" class="lang-switch-container">
            <div (click)="switchLang('en')" class="lang-switch" [class.lang-switch-active]="isEnglish">English</div>
            <div (click)="switchLang('ar')" class="lang-switch" [class.lang-switch-active]="!isEnglish">Arabic</div>
        </div> -->
    </div>
    <p class="updated-time">{{'Last Updated' | translate}} {{updatedAt}}</p>
    <div fxLayout="row wrap" style="height: 87%;"
        fxLayoutAlign="space-between center">
        <div fxFlex="100" class="editor-content">
            <div class="editor">
                <div id="editorjs"></div>
            </div>
        </div>
        <div style="width: 100%; height: 6%;">
            <p *ngIf="!isAdminApproved" class="error-para">{{'Terms & Conditions have changed, the main account admin should sign in and accept the T&Cs to proceed.' | translate}}
            </p>
        </div>
        <div fxFlex="100" fxLayoutGap="10px" style="text-align:end; height: 8%;" >
            <button (click)="cancelTerms()" class="cancel-btn" mat-raised-button>
                {{'Cancel' | translate}}
            </button>
            <button [class.btn-disabled]="!isAdminApproved" (click)="AcceptTerms()" class="accept-btn" mat-raised-button>
                {{'Accept Terms & Conditions to Continue' | translate}}
            </button>
        </div>
    </div>
</mat-card>
<h2 style="color: #213060; font-weight: bold; text-align: start;">{{data == 'New' ? ('Create Contribution' | translate) : ('Modify Contribution' | translate)}}</h2>

<div fxLayout="row" fxLayoutGap="10px" style="text-align: start;">
    <p [fxFlex]="flex">{{'Employee_ID' | translate}}</p>
    <ng-container *ngIf="data != 'New'">
        <p fxFlex="16">{{'National_Iqama_ID' | translate}}</p>
        <p fxFlex="16">{{'Name' | translate}}</p>
    </ng-container>
    <p [fxFlex]="flex">{{'Employee Contribution (SAR)' | translate}}</p>
    <p [fxFlex]="flex">{{'Company Contribution (SAR)' | translate}}</p>
</div>

<mat-divider></mat-divider>

<form [formGroup]="Form">
    <div fxLayout="row" fxLayoutGap="10px" style="font-size: 12px; margin-top: 10px;">
        <mat-form-field appearance="legacy" [fxFlex]="flex">
            <mat-label></mat-label>

            <input matInput type="number" autocomplete="off" formControlName="employee_id">

            <mat-icon *ngIf="getFieldValue('employee_id')" 
            #tooltip="matTooltip"
            [matTooltip]="getErrorMessage('employee_id')"
            [matTooltipDisabled]="!getFieldValue('employee_id')"
            matTooltipPosition="above"
            style="color: #213060;" matSuffix>info</mat-icon>
        </mat-form-field>
        <ng-container *ngIf="data != 'New'">
            <mat-form-field floatLabel='never' appearance="legacy" fxFlex="16">
                <mat-label></mat-label>
                <input matInput autocomplete="off" formControlName="iqama_id">
            </mat-form-field>
            <mat-form-field floatLabel='never' appearance="legacy" fxFlex="16">
                <mat-label></mat-label>
                <input matInput autocomplete="off" formControlName="name">
            </mat-form-field>
        </ng-container>
    
        

        <mat-form-field appearance="legacy" [fxFlex]="flex">
            <mat-label></mat-label>
            <div style="width: 100%;" [ngClass]="editModes.employee_contribution_amount ? 'hide' : 'show'"
            [style.color]="employeeAmount || employeeAmount == 0 ? 'black' : 'transparent'"
                (click)="setMode('employee_contribution_amount', true)" (focus)="setMode('employee_contribution_amount', true)">
                <!-- {{employeeAmount || employeeAmount == 0 ? (employeeAmount | number:'1.4-4') : '0'}} -->

                <span>{{employeeAmount | number:'1.4-4' | slice:0:-4}}</span>
                <span style="font-size: 11px;">{{employeeAmount | number:'1.4-4' | slice:-4}}</span>
            </div>

            <input [(ngModel)]="employeeAmount" matInput type="number" autocomplete="off" formControlName="employee_contribution_amount"
                [ngClass]="editModes.employee_contribution_amount == true ? 'show' : 'hide-input'"
                (focusout)="setMode('employee_contribution_amount', false, true)" (focus)="setMode('employee_contribution_amount', true)">

            <mat-icon *ngIf="getFieldValue('employee_contribution_amount')"
            #tooltip="matTooltip"
            [matTooltip]="getErrorMessage('employee_contribution_amount')"
            [matTooltipDisabled]="!getFieldValue('employee_contribution_amount')"
            matTooltipPosition="above" 
            style="color: #213060;" matSuffix>info</mat-icon>
        </mat-form-field>
    
        <mat-form-field appearance="legacy" [fxFlex]="flex">
            <mat-label></mat-label>
            <div style="width: 100%;" [ngClass]="editModes.employer_contribution_amount ? 'hide' : 'show'"
                [style.color]="employerAmount || employerAmount == 0 ? 'black' : 'transparent'"
                (click)="setMode('employer_contribution_amount', true)" (focus)="setMode('employer_contribution_amount', true)">
                <!-- {{employerAmount || employerAmount == 0 ? (employerAmount | number:'1.4-4') : '0'}} -->

                <span>{{employerAmount | number:'1.4-4' | slice:0:-4}}</span>
                <span style="font-size: 11px;">{{employerAmount | number:'1.4-4' | slice:-4}}</span>
            </div>

            <input [(ngModel)]="employerAmount" matInput type="number" autocomplete="off" formControlName="employer_contribution_amount"
                [ngClass]="editModes.employer_contribution_amount == true ? 'show' : 'hide-input'"
                (focusout)="setMode('employer_contribution_amount', false, true)" (focus)="setMode('employer_contribution_amount', true)">

            <mat-icon *ngIf="getFieldValue('employer_contribution_amount')"
            #tooltip="matTooltip"
            [matTooltip]="getErrorMessage('employer_contribution_amount')"
            [matTooltipDisabled]="!getFieldValue('employer_contribution_amount')"
            matTooltipPosition="above" 
            style="color: #213060;" matSuffix>info</mat-icon>
        </mat-form-field>
    </div>
</form>

<div *ngIf="modifyErrorMessage" fxLayout="row" fxLayoutGap="10px" style="margin-top: 10px;">
    <!-- <mat-icon class="error-action-icon"  [ngStyle]="{'background-color': element[tableData] == 'Verified' ? '#05CD99' : '#EE5D50'}">{{element[tableData] == 'Verified' ? 'done' : 'close'}}</mat-icon> -->
    <mat-icon class="error-action-icon" style="background-color: #EE5D50;">close</mat-icon>
    <p style="margin-bottom: 0;">Error: {{modifyErrorMessage}}</p>
</div>

<div fxLayoutAlign="end center" style="font-size: 12px; margin-top: 10px;">
    <button (click)="closeDialog()" style="background-color: #E9EAEF; color: #213060; line-height: 28px; padding: 0 25px;" mat-raised-button>{{'Cancel' | translate}}</button>
    <button (click)="submit()" style="background-color: #213060; color: white; line-height: 28px; padding: 0 25px; margin-inline: 10px;" mat-raised-button>{{'Submit' | translate}}</button>
</div>
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sorting',
  templateUrl: './sorting.component.html',
  styleUrls: ['./sorting.component.scss']
})
export class SortingComponent implements OnInit
{
  status: any;
  @Output() sortValue = new EventEmitter();
  @Output() sortValueB = new EventEmitter();
  @Input() sortData;
  @Input() sortName;

  constructor() { }

  ngOnInit(): void
  {
    
  }
  sort(event)
  {
    this.status = event.target.value;
    this.sortValue.emit(this.status);
    this.sortValueB.emit(this.status);


  }
  sortB(event)
  {
    this.status = event.target.value;
    this.sortValueB.emit(this.status);

  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertDialog } from 'src/app/lib/alert-dialog/alert.dialog';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';

@Component({
  selector: 'app-tp-logs',
  templateUrl: './tp-logs.component.html',
  styleUrls: ['./tp-logs.component.scss']
})
export class TpLogsComponent implements OnInit {
  list: any = [];
  key: any;
  log: any;
  totalPages: number;
  pages: any;
  perPage: number = 10;
  index: any = 1;
  currentPage: any = 1;
  count: any;
  logpanitaion: any;
  logDecripted: any;

  pagination = {
    page: 1,
    pages: null,
    per_page: null,
    count: 0
  }

  constructor(public tr: TranslationService,
    public route: Router,
    protected mainService: MainService,
    protected dec_enc: DecEncService,
    protected paginationService: PaginationService,
    protected dialog: MatDialog,
    protected alertService: AlertService) {
    this.key = localStorage.getItem('password_token');
  }

  ngOnInit(): void {
    this.getLogs(1);
  }

  getLogs(index: any) {

    let data =
    {
      type: "logs"
    }
    let payload = this.dec_enc.encryption(data);
    let url = 'home/logs?per_page=' + this.perPage + '&page=' + index
    this.mainService.postData(url, { enc_payload: payload })
      .then(result => {
        if (result.statusCode == 200) {
          this.logDecripted = this.dec_enc.decryption(result.data);
          this.log = this.logDecripted.logs;
          this.pagination = this.logDecripted.pagination;
        }
        else {
          let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
          let cm = dialogRef.componentInstance;
          cm.heading = this.tr.translation.error;
          cm.message = this.tr.translation.serverError;
          cm.cancelButtonText = this.tr.translation.okay;
          cm.type = 'error';
        }
      });


  }

  shortTitle(desc): any {
    if (desc.length > 100) {
      desc = desc.split('","').join(', ').replace('["', "").replace('"]', "");
      // var shortname = desc.substring(0, 110) + " ............";
      return desc;
    }
    else {
      return desc;
    }
  }

  markAsRead() {
    let body = {
      type: 'activity'
    };
    let payload = this.dec_enc.encryption(body);
    this.mainService.readNotifications(`read_all_notifications`, { enc_payload: payload }).then(response => {
      if (response.statusCode == 200) {
        this.mainService.setApiOnCall(1);
        if (localStorage.getItem('lang') == 'ar') {
          this.alertService.toastSuccess('النجاح', 'تم تمييز جميع السجلات كمقروءة.');
        }
        else {
          this.alertService.toastSuccess('Success', 'All logs have been marked as read.');
        }
        // this.alertService.toastSuccess('Success', 'All logs have been marked as read.');
      }
      else {
        if (localStorage.getItem('lang') == 'ar') {
          this.alertService.alert('Error', response.error.message_ar);
        }
        else {
          this.alertService.alert('Error', response.error.message);
        }
      }
    })
  }

  setPage(page) {
    this.pagination.page = page;
    this.getLogs(this.pagination.page);
  }

  SetPageSize(pageSize) {
    this.perPage = pageSize
    this.getLogs(this.pagination.page);
  }
}


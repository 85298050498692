import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
    selector: 'app-employers-manager',
    templateUrl: './employers-manager.component.html',
    styleUrls: ['./employers-manager.component.scss']
})
export class EmployersManagerComponent implements OnInit {
    emp: boolean = true;
    employer_id: any;
    list: any = [];
    data: any;
    totalPages: number;
    pages: any;
    perPage: number = 10;
    index: any = 1;
    currentPage: any = 1;
    count: any;
    searchTimer: any;
    search: any = '';
    role: any;
    showCreateBtn: boolean = true;
    showEditIcon: boolean = true;
    showViewIcon: boolean = true;
    statusIcon: boolean = true;
    msgStatus: string;

    tableProps = {
        heading: 'Manage_Admins',
        hasSearch: true,
        searchLabel: 'Search_Name_Email',
        hasButton: true,
        headerActions: [
            {
                label: 'Create_New_Manager',
                route: 'employer-manager/add/new',
                type: 'link',
                visibility: true,
                isCustom: false,
                icon: 'person_add',
            },
        ],
        rowActions: [
            { label: 'Edit', visibility: true, background: '#79BBBF0D', source: 'assets/new-icons/edit.svg' },
            { label: 'View', visibility: true, background: '#4318FF0D', source: 'assets/table-view.svg' },
            { label: 'Activate', type: 'activate', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/table-verify.svg' },
            { label: 'Deactivate', type: 'deactivate', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/table-deactivate.svg' },
        ],
        colHeader: {
            id: 'ID',
            name: 'Name',
            email: 'Email',
            iqama_id: 'National_Iqama_ID',
            role: 'Manager_Role',
            statusToShow: 'Status',
            actions: 'Actions',
        },
        columnTypes: {
            email: 'email',
            statusToShow: 'errorColumn',
            role: 'translate',
            actions: 'actionsMenu',
        },
        filterArray: []
    };

    pagination = {
        page: 1,
        pages: null,
        per_page: 10,
        count: 0
    }
    constructor(public tr: TranslationService,
        public route: Router,
        protected _route: ActivatedRoute,
        protected mainService: MainService,
        protected dec_enc: DecEncService,
        protected paginationService: PaginationService,
        protected dialog: MatDialog,
        protected alertService: AlertService,
        protected profileService: ProfileService) {

        // this.role = localStorage.getItem('employer_role');
        this.role = profileService.user.role;

        if (this.role == 'employer') {
            this.showCreateBtn = true;
            this.showEditIcon = true;
            this.statusIcon = true;
            this.showViewIcon = true;
        }
        else {
            this.showCreateBtn = false;
            this.showEditIcon = false;
            this.statusIcon = false;
            this.showViewIcon = true;
        }
    }

    ngOnInit(): void {
        this._route.params.subscribe(params => {
            this.employer_id = params['id'];
        });
        this.getall(1);
    }

    getall(index: any) {
        let body = {
            employer_id: this.employer_id,
            search: this.search
        }
        let enc_payload = this.dec_enc.encryption(body)
        let url = 'emp-managers?per_page=' + this.perPage + '&page=' + index
        this.mainService.postData(url, { enc_payload }).then(res => {
            if (res.statusCode == 200) {
                let result = this.dec_enc.decryption(res.data);
                this.list = result.emp_managers;
                this.pagination = result.pagination;
                this.afterList();
            }
        })
    }

    afterList() {
        this.list.forEach(item => {
            item['active'] = item.active == 1 ? true : false;
            item['activate'] = item['active'] ? false : true;
            item['deactivate'] = item['active'] ? true : false;
            item['statusToShow'] = item['active'] ? 'Activated' : 'Deactivated';
            item['View'] = true;
            item['Edit'] = true;
        });
    }

    setPage(page) {
        this.pagination.page = page;
        this.getall(this.pagination.page);
    }

    SetPageSize(pageSize) {
        this.perPage = pageSize
        this.getall(this.pagination.page);
    }

    manager() {
        this.emp = true
    }

    ManageManager() {
        this.emp = false
    }

    creatmanager() {
        localStorage.setItem('id', this.employer_id);
        this.route.navigate(['main/employer-manager/add/new'])
    }

    goback() {
        window.history.back();
    }

    toggle(event, tp_role, id) {
        let url = 'emp-managers/update';
        let Active = '';
        if (event === true) {
            Active = '1';
            if (tp_role == 'emp-maker') {
                this.msgStatus = 'EMP-Maker account has been activated successfully'
            }
            if (tp_role == 'emp-checker') {
                this.msgStatus = 'EMP-Checker account has been activated successfully.'
            }
            if (tp_role == 'emp-finance') {
                this.msgStatus = 'EMP-Finance account has been activated successfully.'
            }

        }
        else {
            Active = '0';
            if (tp_role == 'emp-maker') {
                this.msgStatus = 'EMP-Maker account has been de-activated successfully'
            }
            if (tp_role == 'emp-checker') {
                this.msgStatus = 'EMP-Checker account has been de-activated successfully.'
            }
            if (tp_role == 'emp-finance') {
                this.msgStatus = 'Emp-Finance account has been de-activated successfully.'
            }
        }

        let body = {
            id: id,
            role: tp_role,
            active: Active,
            employer_id: this.employer_id
        };

        let payload = this.dec_enc.encryption(body);
        let formData = new FormData();

        formData.append("enc_payload", payload);
        this.mainService.postData(url, formData).then(result => {
            if (result.statusCode == 200) {
                this.alertService.alert('Success', this.msgStatus);
                this.getall(1);
            }
            else {
                if (localStorage.getItem('lang') == 'ar') {
                    this.alertService.alert('Error', result.error.message_ar);
                }
                else {
                    this.alertService.alert('Error', result.error.message);
                }
            }
        });
    }

    viewManager(id) {
        this.route.navigate(['main/employer-manager/view/' + id])
    }

    edit(id) {
        this.route.navigate(['main/employer-manager/update/' + id])
    }

    onSearch() {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.getall(1);
        }, 200);
    }

    searchData(event) {
        this.search = event;

        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.getall(1);
        }, 800);
    }

    spellChange(text) {
        if (text == "emp-maker") {
            return "EMP-Maker"
        }

        if (text == "emp-checker") {
            return "EMP-Checker"
        }

        if (text == "emp-finance") {
            return "EMP-Finance"
        }
    }

    actionClicked(event) {
        if (event.action.label == 'Edit') {
            this.route.navigate(['main/employer-manager/update/' + event.element.id]);
        }
        if (event.action.label == 'View') {
            this.route.navigate(['main/employer-manager/view/' + event.element.id]);
        }
        if (event.action.label == 'Activate') {
            this.toggle(true, event.element.role, event.element.id);
        }
        if (event.action.label == 'Deactivate') {
            this.toggle(false, event.element.role, event.element.id);
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent implements OnInit {
  Form: FormGroup;
  list: any = [];
  search: any;
  body: any;
  searchTimer: any;
  redemptionId: any;
  summary: any;
  history: any;
  showPdf: boolean = false;
  fee: any;
  sumOfFees: any = 0;
  totalWithdraw: any = 0;

  constructor(protected dec_enc: DecEncService, protected mainService: MainService, protected paginationService: PaginationService, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, public route: Router) {
    this.activatedRoute.paramMap.subscribe(params => {
      this.redemptionId = params.get('id');
    });
    this.Form = this.formBuilder.group({
      created_at: [],
      initiated_by: [],
      withdraw_processed: [this.list?.length],
    })
  }

  tableProps = {
    heading: 'Transaction history',
    hasSearch: true,
    searchLabel: 'Search',
    hasButton: false,
    headerActions: [
    ],
    rowActions: [],
    colHeader: {
      employee_id: 'Employee ID',
      amount: 'Requested amount (SAR)',
      fee: 'Transaction fees (SAR)',
      actual: 'Withdrawn amount (SAR)',
      status: 'Status',
    },
    columnTypes: {
      created_at: 'date',
      status: 'errorColumn',
    },
    filterArray: [],
    dateFilter: {},

  };

  ngOnInit(): void {
    this.getAll(1);
    this.afterList();
  }

  downloadPDF() {
    this.showPdf = true;
    setTimeout(() => {
      const data = document.getElementById('contentToExport');
      if (data) {
        // Increase scale to 2 for better resolution
        html2canvas(data, { scale: 4 }).then(canvas => {
          const imgWidth = 210; // A4 width in mm
          const pageHeight = 295; // A4 height in mm
          const imgHeight = canvas.height * imgWidth / canvas.width;
          let heightLeft = imgHeight;
          let position = 0;

          const imgData = canvas.toDataURL('image/png', 1.0); // Highest quality PNG

          const pdf = new jsPDF('p', 'mm', 'a4');

          // Set PDF compression to none for best quality
          pdf.addImage(imgData, 'PNG', (pdf.internal.pageSize.getWidth() - imgWidth) / 2, position, imgWidth, imgHeight, undefined, 'FAST');

          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', (pdf.internal.pageSize.getWidth() - imgWidth) / 2, position, imgWidth, imgHeight, undefined, 'FAST');
            heightLeft -= pageHeight;
          }
          pdf.save('Transaction History.pdf');
        });
      }
    }, 500);

    setTimeout(() => {
      this.showPdf = false;
    }, 500);
  }

  pagination = {
    page: 1,
    pages: null,
    per_page: 10,
    count: 0
  }

  getAll(index) {
    this.body = {
      search: this.search,
      redemption_id: this.redemptionId,
    }
    let enc_payload = this.dec_enc.encryption(this.body);
    let url = '/redemptions/history';
    this.mainService.postData(url, { enc_payload }).then(res => {
      if (res.statusCode == 200) {
        let result = this.dec_enc.decryption(res.data);
        this.list = result.history;
        this.list.forEach(item => {
          item['amount'] = parseFloat(item['amount']).toFixed(4);
          item['fee'] = parseFloat(item['fee']).toFixed(4);
          this.sumOfFees += parseFloat(item['fee']);
          // item['employee_amount'] = parseFloat(item['employee_amount']).toFixed(4);
        });
        this.Form.get('withdraw_processed').setValue(this.list.length);
        this.fee = this.list.reduce((sum, item) => sum + parseFloat(item.fee), 0);
        this.summary = result.summary;
        this.totalWithdraw = this.summary?.withdraw_amount - this.sumOfFees;
        this.Form.patchValue(this.summary);
        // this.pagination = result?.pagination;
        this.afterList();
        // this.count = result.pagination.count;
        // this.currentPage = result.pagination.page;
        // this.pages = this.paginationService.setPagination(result.pagination.count, this.currentPage, this.perPage);
        // this.totalPages = this.pages.totalPages;
      }
    })
  }

  afterList() {
    this.list.forEach(item => {
      if (this.summary.status == 1) {
        item['status'] = 'Accepted';
      }
      if (this.summary.status == 2) {
        item['status'] = 'Under Process';
      }
      if (this.summary.status == 0) {
        item['status'] = 'Pending';
      }
      if (this.summary.status == 3) {
        item['status'] = 'Declined';
      }
    });
  }

  searchData(event) {
    this.search = event;
    console.log('Event~*', event);

    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.getAll(1);
    }, 800);
  }

  setPage(page) {
    this.pagination.page = page;
    this.getAll(this.pagination.page);
  }

  SetPageSize(pageSize) {
    this.pagination.per_page = pageSize
    this.getAll(this.pagination.page);
  }

  onBack() {
    this.route.navigate(['main/redemption-instructions'])
  }

}

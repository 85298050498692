import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { appConfig } from 'src/config';

@Component({
    selector: 'app-terms-hr-policy-',
    templateUrl: './terms-hr-policy.component.html',
    styleUrls: ['./terms-hr-policy.component.scss']
})
export class TermsHrPolicyComponent implements OnInit {

    tableProps = {
        heading: 'HR Policy',
        hasSearch: false,
        hasButton: true,
        headerActions: [
            {
                label: 'View Audit',
                route: 'hr-policy/audit/list/view',
                type: 'link',
                visibility: true,
                isCustom: false,
            },
            {
                label: 'Create New',
                route: 'hr-policy/add/new',
                type: 'link',
                visibility: true,
                isCustom: false,
                icon: 'add',
            }
        ],
        rowActions: [
            { label: 'Edit', type: 'edit', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/new-icons/edit.svg', iconName: 'arrow_forward' },
            { label: 'View', visibility: true, background: '#4318FF0D', source: 'assets/table-view.svg', iconName: 'arrow_forward' },
        ],
        colHeader: {
            id: 'ID',
            created_at: 'Date Created',
            updated_at: 'Date Updated',
            force_accept: 'Enforce Acceptance',
            statusToShow: 'Status',
            actions: 'Actions'
        },
        columnTypes: {
            created_at: 'date',
            updated_at: 'date',
            statusToShow: 'errorColumn',
            actions: 'actionsSeperate',
        },
        filterArray: []
    };

    pagination = {
        page: 1,
        pages: null,
        per_page: 10,
        count: 0
    }

    list = [];

    constructor(protected mainService: MainService,
        protected cryptoService: DecEncService,
        public router: Router) {

    }

    ngOnInit(): void {
        this.getAll();
    }

    getAll() {
        this.mainService.postData(`home/terms/all?per_page=${this.pagination.per_page}&page=${this.pagination.page}`, {}).then(response => {
            if (response.statusCode == 200) {
                let decryptedResponse = this.cryptoService.decryption(response.data);
                this.list = decryptedResponse.terms;
                this.pagination = decryptedResponse.pagination;
                this.afterList();
                return
            }
        })
    }

    afterList() {
        this.list.forEach(item => {
            item['force_accept'] = item['force_accept'] == 0 ? 'Yes' : 'No';
            item['statusToShow'] = item['active'] == 0 ? 'Deactivated' : 'Activated';
            item['edit'] = item.active == "1" ? true : false;
            item['audit'] = item.active == "1" ? true : false;
            item['created_at'] = item.created_at ? moment(item.created_at).format(appConfig.dateTimeformatCMS) : null;
            item['updated_at'] = item.updated_at ? moment(item.updated_at).format(appConfig.dateTimeformatCMS) : null;
        })
    }

    setPage(page) {
        this.pagination.page = page;
        this.getAll();
    }


    SetPageSize(pageSize) {
        this.pagination.per_page = pageSize;
        this.getAll();
    }

    actionClicked(event) {
        if (event.action.label == 'Edit') {
            this.router.navigate([`main/hr-policy/update/${event.element.id}`]);
        }
        else if (event.action.label == 'View') {
            this.router.navigate([`main/hr-policy/view/${event.element.id}`]);
        }
        else if (event.action.label == 'View Unseen') {
            this.router.navigate([`main/hr-policy/unseen/list/new`]);
        }
        else if (event.action.type == 'audit') {
            this.router.navigate([`main/hr-policy/audit/list/${event.element.id}`]);
        }
    }
}

<!-- <select class="form-control"
        [(ngModel)]="engArr"
        (change)="onChange()">
    <option *ngFor="let val of keyValue"
            [value]="val.key">{{val.title}}</option>
   
</select> -->
<div class="result">
<mat-card style="border-radius: 50px;">
        <mat-card-content>
     
      
          <section class="example-section" style="height: 11px;margin-top: -14px;padding-top: 5px;">
            English
            <mat-slide-toggle [(ngModel)]="engArr" (change)="toggle($event)" style="height: 10px;">
              
         
            </mat-slide-toggle>
            العربية
          </section>
        </mat-card-content>
      </mat-card>
</div> 
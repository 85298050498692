import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';

@Component({
  selector: 'app-vesting',
  templateUrl: './vesting.component.html',
  styleUrls: ['./vesting.component.scss']
})
export class VestingComponent {

  list: any = [];
  searchTimer: any;
  search: any = "";
  sort_by: any = '';
  sort_order = 'ASC'
  body: any;
  count = 0;
  listData: any;
  totalPages: number;
  pages: any;
  perPage: number = 10;
  currentPage: any = 1;
  vestingCards: any;
  sortData: { value: any; key: string; }[];
  tablePropsEmp = {
    heading: 'Vesting Schedule',
    hasSearch: true,
    searchLabel: 'Search by Employee Id',
    hasButton: true,
    headerActions: [
      {
        label: 'Archive',
        type: 'output',
        disabled: false,
        visibility: true,
        isCustom: false,
      },
    ],
    rowActions: [
      { label: 'Edit', visibility: true, type: 'edit', background: '#79BBBF0D', source: 'assets/new-icons/edit.svg' },
      { label: 'Resume', visibility: true, type: 'resume', isCustom: true, iconSize: true, background: '#fcedec', source: 'assets/new-icons/resume.png' },
      { label: 'Pause', visibility: true, type: 'pause', isCustom: true, iconSize: true, background: '#F3F9F9', source: 'assets/new-icons/pause.svg' },
    ],
    colHeader: {
      employee_id: 'ID',
      name: 'Name',
      till_vesting: 'Month till vesting',
      to_be_vested: 'Unvested balance',
      group_name: 'Company Group',
      status: 'Status',
      next_vesting: 'Vesting Point',
      actions: 'Actions',
    },
    columnTypes: {
      statusActive: 'errorColumn',
      next_vesting: 'date',
      actions: 'actionsSeperate',
      status: 'status',
    },
    filterArray: [],
  };

  pagination = {
    page: 1,
    pages: null,
    per_page: 10,
    count: 0
  }

  constructor(protected route: Router, protected dec_enc: DecEncService, protected mainService: MainService, protected paginationService: PaginationService) { }

  ngOnInit(): void {
    this.getAll(1);
    this.afterList();
  }

  getAll(index) {
    this.body = {
      sort_by: this.sort_by,
      sort_order: this.sort_order
    };
    console.log('body', this.body);
    let enc_payload = this.dec_enc.encryption(this.body);
    let url = 'vesting';
    this.mainService.postData(url, { enc_payload }).then(res => {
      if (res.statusCode === 200) {
        let result = this.dec_enc.decryption(res.data);
        this.listData = result.data;
        this.updatePageData();
        this.vestingCards = result.summary;
        this.afterList();
      }
    });
  }

  updatePageData() {
    const start = (this.pagination.page - 1) * this.pagination.per_page;
    const end = start + this.pagination.per_page;
    this.list = this.listData.slice(start, end);
    if (this.search !== "" && this.search !== null) {
      let hasMatch = false;
      this.list.forEach(item => {
        if (item.employee_id == this.search) {
          this.pagination.page = 1;
          this.pagination.per_page = 10;
          this.list = [];
          this.list.push(item);
          this.listData = this.list;
          hasMatch = true;
        }
      });
      if (!hasMatch) {
        this.listData = this.list;
        this.list = [];
      }
    } else {
      const start = (this.pagination.page - 1) * this.pagination.per_page;
      const end = start + this.pagination.per_page;
      this.list = this.listData.slice(start, end);
    }
    this.count = this.listData.length;
    this.pagination.count = this.count;
    this.pagination.pages = Math.ceil(this.pagination.count / this.pagination.per_page);
  }

  sort(event) {
    if (event == '') {
      this.sort_order = '';
      this.sort_by = '';
    }
    else {
      this.sort_order = 'ASC'
      this.sort_by = event;
    }
    this.getAll(1);
  }

  portfolio(id) {
    this.route.navigate(['main/manage_employee/manage_portfolio/' + id]);
  }

  setPage(page) {
    this.pagination.page = page;
    this.getAll(this.pagination.page);
  }

  SetPageSize(pageSize) {
    this.pagination.per_page = pageSize
    this.getAll(this.pagination.page);
  }

  searchData(event) {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.onSearch(event);
    }, 800);
  }

  onSearch(event) {
    this.search = event;
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.getAll(1);
    }, 800);
  }


  actionClicked(event) {
    const actionLabel = event.action.label;
    if (actionLabel === 'Pause' || actionLabel === 'Resume') {
      this.actions(event, actionLabel);
    }
    if (event.action.label == 'Edit') {
      this.portfolio(event.element.employee_id);
    }
  }

  actions(event, actionLabel) {
    const url = actionLabel === 'Resume' ? 'vesting/resume' : 'vesting/pause';
    const body = {
      employee_id: event.element.employee_id
    };
    const enc_payload = this.dec_enc.encryption(body);
    this.mainService.postData(url, { enc_payload }).then(res => {
      if (res.statusCode === 200) {
        this.getAll(1);
        this.afterList();
      }
    });
  }

  headerButton(event) {
    if (event.label == 'Archive') {
      this.route.navigate(['/main/vesting/archive']);
    }
  }

  afterList() {
    if (this.list) {
      this.list.forEach(item => {
        item['resume'] = item['vesting_status'] != '1';
        item['pause'] = item['vesting_status'] != '0';
        item['status'] = item.vesting_status == 1 ? 'Active' : 'Pause';
      });
    }
  }

}
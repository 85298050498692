import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { appConfig } from 'src/config';

@Component({
    selector: 'app-hr-audit',
    templateUrl: './hr-audit.component.html',
    styleUrls: ['./hr-audit.component.scss']
})
export class HRAuditComponent implements OnInit {

    tableProps = {
        heading: 'HR Policy Acceptance Audit',
        hasSearch: true,
        searchLabel: 'Search',
        hasButton: true,
        headerActions: [
            {
                label: 'Notify Selected',
                type: 'output',
                visibility: true,
                disabled: true,
                selected: false,
            },
            {
                label: 'Show Accepted Only',
                type: 'output',
                visibility: true,
                disabled: false,
                selected: false,
            },
            {
                label: 'Show Pending Only',
                type: 'output',
                visibility: true,
                disabled: false,
                selected: false,
            },
        ],
        colHeader: {
            checkbox: '',
            id: 'ID',
            name: 'Name',
            iqama_id: 'National_Iqama_ID',
            email: 'Email',
            last_login_at: 'Last Login',
            terms_action_on: 'Date of Acceptance',
            statusToShow: 'Status',
        },
        columnTypes: {
            last_login_at: 'date',
            terms_action_on: 'date',
            statusToShow: 'errorColumn'
        },
        filterArray: []
    };

    pagination = {
        page: 1,
        pages: null,
        per_page: 10,
        count: 0
    }

    list = [];
    cardData = {
        Total: null,
        Accepted: null,
        Pending: null
    };
    body = {
        search: '',
        term_status: ''
    }
    toggleButtons = {
        pending: false,
        accepted: false
    }
    searchTimer = null;
    checkBoxCount = 0;

    constructor(protected mainService: MainService,
        protected cryptoService: DecEncService,
        protected router: Router,
        protected alertService: AlertService) { }

    ngOnInit(): void {
        delete this.tableProps.colHeader['checkbox'];
        this.getAll();
    }

    getAll() {
        let url = `home/terms/audit?per_page=${this.pagination.per_page}&page=${this.pagination.page}`;
        let enc_payload = this.cryptoService.encryption(this.body);
        this.mainService.postData(url, { enc_payload }).then(response => {
            if (response.statusCode == 200) {
                let decryptedResponse = this.cryptoService.decryption(response.data);
                this.list = decryptedResponse.users;
                this.cardData = decryptedResponse.counts;
                this.pagination = decryptedResponse.pagination;
                this.afterList();
            }
            else { }
        })
    }

    afterList() {
        this.list.forEach(item => {
            item['statusToShow'] = item['terms_status'] == '1' ? 'Accepted' : 'Pending';
            item['last_login_at'] = item['last_login_at'] ? moment(item.last_login_at).format(appConfig.dateTimeformatCMS) : 'N/A';
            item['terms_action_on'] = item['terms_action_on'] ? moment(item.terms_action_on).format(appConfig.dateTimeformatCMS) : 'N/A';
        })
    }

    notifySelected() {
        let ids = [];
        this.list.forEach(item => {
            if (item.checked) {
                ids.push(item.id);
            }
        });
        if (ids.length == 0) {
            this.alertService.alert('Error', 'No record is selected.')
            return;
        }
        this.alertService.alertAsk('Confirmation', 'Are you sure you want to notify the selected?', 'Yes', 'No', false).then(res => {
            if (res) {
                let url = `home/terms/notify`
                let body = {
                    ids: ids
                }
                let enc_payload = this.cryptoService.encryption(body);
                this.mainService.postData(url, { enc_payload }).then(response => {
                    if (response.statusCode == 200) {
                        this.alertService.alert('Success', 'Emails will be sent to the selected employees shortly.');
                        this.getAll();
                        this.uncheckSelectedAfterSend();
                    }
                    else {
                        if (localStorage.getItem('lang') == 'ar') {
                            this.alertService.alert('Error', response.error.message_ar);
                        }
                        else {
                            this.alertService.alert('Error', response.error.message);
                        }
                    }
                })
            }
        })
    }

    setPage(page) {
        this.pagination.page = page;
        this.getAll();
    }

    SetPageSize(pageSize) {
        this.pagination.per_page = pageSize
        this.getAll();
    }

    handleFilters(event) {
        if (this.body.term_status == event.selected) {
            return;
        }
        this.body.term_status = event.selected;
        this.getAll();
    }

    uncheckSelectedAfterSend() {
        this.checkBoxCount = 0;
        for (let item of this.list) {
            item.checked = false;
        }
    }

    colHeaderChange() {
        this.tableProps.colHeader =
        {
            checkbox: '',
            id: 'ID',
            name: 'Name',
            iqama_id: 'National ID/Iqama',
            email: 'Email',
            last_login_at: 'Last Login',
            terms_action_on: 'Date of Acceptance',
            statusToShow: 'Status',
        }
    }

    onBack() {
        this.router.navigateByUrl("main/hr-policy");
    }

    onSearchData(event) {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.body.search = event;
            this.getAll();
        }, 500);
    }

    onHeaderButton(event) {
        if (event.label == "Notify Selected") {
            this.notifySelected();
            return;
        }

        if (event.label == "Show Pending Only") {
            if (this.body.term_status == '0') {
                this.body.term_status = '';
                delete this.tableProps.colHeader['checkbox'];
            }
            else {
                this.colHeaderChange();
                this.body.term_status = '0';
            }
        }
        else if (event.label == "Show Accepted Only") {
            if (this.body.term_status == '1') {
                this.body.term_status = '';
            }
            else {
                delete this.tableProps.colHeader['checkbox'];
                this.body.term_status = '1';
            }
        }
        if (this.body.term_status != '0') {
            this.tableProps.headerActions[0].disabled = true;
        }
        this.tableProps.headerActions[1].selected = this.body.term_status == '1';
        this.tableProps.headerActions[2].selected = this.body.term_status == '0';
        this.getAll();
    }

    onCheckBox(event) {
        this.checkBoxCount = event.checkCount;
        this.tableProps.headerActions[0].disabled = !(event && event.checkCount > 0);
    }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MainGuard implements CanActivate {
  
  constructor(public route: Router) {}

  canActivate() {
    let token = localStorage.getItem('employer_token');
    let terms_status = localStorage.getItem('terms_status');
    if (token == void 0 || terms_status == void 0 || terms_status == '0') 
    {
      this.route.navigate(['/auth/login']);
      return false;
    }
    else 
    {
      return true; // server main routes
    }
  }
}


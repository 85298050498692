import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-instruction-confirmation',
  templateUrl: './instruction-confirmation.component.html',
  styleUrls: ['./instruction-confirmation.component.scss']
})
export class InstructionConfirmationComponent implements OnInit {
  summaryForm: FormGroup;
  summary: any = [];
  search: any;
  instructionSummary: any = [];
  funds: any = [];
  withdrawal_ids: any = [];
  body: any;
  redemptionId: any;
  currentLang: any;
  iBnNo: any;
  headings: string[] = ['Heading 1', 'Heading 2', 'Heading 3', 'Heading 4', 'Heading 5'];

  dataRows: string[][] = [
    ['Data 1-1', 'Data 1-2', 'Data 1-3', 'Data 1-4', 'Data 1-5'],
    ['Data 2-1', 'Data 2-2', 'Data 2-3', 'Data 2-4', 'Data 2-5']
  ];

  constructor(private fb: FormBuilder,
    public tr: TranslationService,
    public route: Router,
    public dec_enc: DecEncService,
    protected mainService: MainService,
    public paginationService: PaginationService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    protected alertService: AlertService, private activatedRoute: ActivatedRoute, private profileService: ProfileService) {
    this.activatedRoute.paramMap.subscribe(params => {
      this.redemptionId = params.get('id');
    });
    this.currentLang = localStorage.getItem('lang')
    this.summaryForm = this.fb.group({
      termsAccepted: [false],
      date_requested: [null],
      date_processed: [null],
      status: [this.profileService.user.name],
    });
  }

  pagination = {
    page: null,
    pages: null,
    per_page: null,
    count: 0
  }

  ngOnInit(): void {
    this.getAll(1);
    this.getBankDetail();
  }

  getAll(index) {
    this.body = {
      redemption_id: this.redemptionId
    }
    let enc_payload = this.dec_enc.encryption(this.body);
    let url = 'redemptions/instructions';
    this.mainService.postData(url, { enc_payload }).then(res => {
      if (res.statusCode == 200) {
        let result = this.dec_enc.decryption(res.data);
        this.instructionSummary = result;
        result.redemption['created_at'] = this.datepipe.transform(result.redemption['created_at'], 'dd-MM-yyyy');
        result.redemption['updated_at'] = this.datepipe.transform(result.redemption['updated_at'], 'dd-MM-yyyy');
        this.summaryForm.get('date_requested').setValue(result.redemption.created_at);
        this.summaryForm.get('date_processed').setValue(result.redemption.updated_at);
        this.summary = this.instructionSummary.summary;
        this.funds = this.instructionSummary.portfolios;
        if (result.redemption.status == 1) {
          this.summaryForm.get('status').setValue('Accepted');
        }
        if (result.redemption.status == 2) {
          this.summaryForm.get('status').setValue('Under Process');
        }
        if (result.redemption.status == 0) {
          this.summaryForm.get('status').setValue('Pending');
        }
        if (result.redemption.status == 3) {
          this.summaryForm.get('status').setValue('Declined');
        }
      }
    })
  }

  print(): void {
    const divId = 'printable-div';
    const divContents = document.getElementById(divId)?.innerHTML;
    const printWindow = window.open('', '', 'height=600,width=800');
    if (printWindow) {
      printWindow.document.write('<html><head><title>Print</title>');
      printWindow.document.write('<style>body{font-family: Arial, sans-serif;} table{width:100%; border-collapse:collapse;} td{padding:8px;}</style>');
      printWindow.document.write('</head><body >');
      printWindow.document.write(divContents || '');
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
    }
  }

  onBack() {
    this.route.navigate(['main/redemption-instructions'])
  }
  getBankDetail() {
    let url = 'home/manage-organization';
    let employer_id = localStorage.getItem('employer_id');
    let enc_payload = this.dec_enc.encryption({ employer_id });

    this.mainService.postData(url, { enc_payload }).then(result => {
      if (result.statusCode === 200) {

        let data = this.dec_enc.decryption(result.data);
        if (data.bank_details != null) {
          this.iBnNo = data.bank_details.iban_number;
        }
        return;
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import * as CryptoJS from 'crypto-js';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialog } from 'src/app/lib/alert-dialog/alert.dialog';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { DecEncService } from 'src/app/services/dec-enc';
@Component({
  selector: 'app-terminate-detail-view',
  templateUrl: './terminate-detail-view.component.html',
  styleUrls: ['./terminate-detail-view.component.scss']
})
export class TerminateDetailViewComponent implements OnInit {
  Form: FormGroup;
  setPassword: FormGroup;
  failureAlert: boolean;
  alertMsg: any = "";
  sub: Subscription;
  id: any;
  path: any;
  hed: any;
  button: any;
  ut;
  constructor(public tr: TranslationService, protected formbuilder: FormBuilder, protected dialog: MatDialog, protected _route: ActivatedRoute, protected mainService:MainService, public dec_enc:DecEncService) {
    this._route.params.subscribe(params => {
      this.id = Number(params['id']);
   

    });
    this.Form = this.formbuilder.group({
      // emp_id: ['', [Validators.required]],
      // withdrawl_id: ['', [Validators.required]],
      units: ['', [Validators.required]],
      on_date: ['', [Validators.required]],
      active: ['1', [Validators.required]],
      // notes: ['', [Validators.required]],


    });



  }

  ngOnInit(): void {
    this.getall();
  }
 
  getall() {
    let url = 'withdrawals'
    this.mainService.postData(url, {}).then(res => {
      if (res.statusCode == 200) {


        let result;
        let data = res.data

        result = this.dec_enc.decryption(data);
        let list=result.withdrawals;
        list.forEach(obj => {
          if(this.id===obj.id)
          {
            this.Form.patchValue(obj)
          }
        });


      }

    },
    error=>{
      let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
      let cm = dialogRef.componentInstance;
      cm.heading = this.tr.translation.error;
      cm.message = "Server Error";
     cm.cancelButtonText = this.tr.translation.okay;
      cm.type = 'error';
    }
    )
  }


}

export const english = {
    //auth
    sign_in: 'Login',
    admin: 'Employer',
    portal: 'Portal',
    welcome: 'Welcome to',
    thrift: 'Thrift',
    tp: 'ThriftPlan',
    plan: 'Plan',
    secure: 'This is a secure website',
    request: 'Please enter your username and password to log in',
    username: 'Username',
    password: 'Password',
    forgot: 'Forgot Password',
    send_otp: 'Confirm OTP',
    otp_pl: 'Enter 6 Digit Code',
    otp_req: 'Please enter your 6 digit code here to login',
    forgot_password: 'Forgot Password',
    email_req: 'Please enter your email address to',
    reqover_req: 'reset your password.',
    email_address: 'Enter email address',
    reqover_password: 'Send OTP',
    rec_req: 'Please enter the 6-digit code sent',
    rec_email: 'to your Email Address.',
    confirm_otp: 'Confirm',
    creat_new: 'Create New Password',
    req: 'Please enter your new password.',
    rec_password: 'Enter New Password',
    con_password: 'Confirm New Password',
    done: 'Done',

    //dashboard
    thrift_hed: 'thrift',
    plan_hed: 'plan',
    home: 'Home',
    profile: 'My Profile',
    mo: 'Company Profile',
    me: 'Manage Employees',
    mif: 'Investment Portfolios',
    mr: 'Manage Redemptions',
    mw: 'Withdrawal Requests',
    mc: 'Contribution Requests',
    reports: 'Reports',
    noti: 'Notifications',
    log: 'Logs',
    actvenoti: 'Activity-Notifications',
    MA: 'Manage Admins',
    settings: 'Settings',
    AC: 'Allocate Contribution',
    my_profile: 'My Profile',
    logout: 'Log Out',
    all_logs: 'All Logs',
    //Home
    Manage_Portfolio: 'Manage Portfolio',
    Portfolio_Balance: 'Portfolio Balance',
    Employer_Risk_Type: 'Employer Risk Type',
    My_Contribution: 'Company Contribution',
    Employee_Contribution: 'Employee Contribution',
    Profit: 'Profit',
    Total_Withdrawal_Amount: 'Total Withdrawal Amount',
    Withdrawal_Amount: 'Withdrawal Amount',
    fby: 'Filter By Year',
    Contributions: 'Contributions',

    Available_for_Withdrawal: 'Available for Withdrawal',
    Total_Contributions: 'Total Contributions',
    Total_Balance: 'Total Balance',
    ProfitLoss: 'Total Profit/Loss',
    Total_Withdrawn_Amount: 'Total Withdrawn Amount',
    Employee_Contribution_Detail: 'Employee Contribution Detail',
    Invested_Amount: 'Invested Amount',
    Average_Return: 'Average Return',
    Employer_Contribution_Detail: 'Company Contribution Detail',
    Transfer_to_Vested_Account: 'Transfer to Vested Account',
    Total_Unvested_Amount: 'Total Unvested Amount %',
    Total_vested_Amount: 'Total vested Amount %',
    Enter_Amount: 'Enter Amount in Percentage',
    vestd: 'Vested',
    unves: 'Unvested',
    Sort_By_Month: 'Sort By Month',
    Sort_By_Year: 'Sort By Year',
    //Manage Orgnazation,
    Manage_Organization: 'Company Profile',
    Employer_Identification: 'Employer Identification',
    Admin_Details: 'Admin Details',
    Commercial_Registration_Details: 'Commercial Registration Details',
    Employer_Representative_Details: 'Employer Representative Details',
    Banks_Details: 'Bank Account Details',
    Company_ID: 'Company ID',
    Company_Name: 'Company Name',
    Preferred_Language: 'Preferred Language',
    No_of_Employees: 'No of Employees',
    Industry_Classification: 'Industry Classification',
    Type_of_Activity: 'Type of Activity',
    Country_of_Activity: 'Country of Activity',
    Other_Country_of_Activities: 'Other Country of Activities',
    Name_of_Other_Countries: 'Name of Other Countries',
    Portfolio_Currency: 'Portfolio Currency',
    Is_This_Listed_Company: 'Is This Listed Company',
    Address: 'Address',
    Admin_Name: 'Admin Name',
    Phone_Number: 'Phone Number',
    Email_ID: 'Email ID',
    Iqama_ID_National_ID: 'National ID/Iqama',
    Iqama_ID_National_ID_Expiry_Date: 'National ID/National ID/Iqama Expiry',
    iqama_national_ID_Scaned_Copy: 'National ID/Iqama Scanned Copy',
    CR_No: 'CR No.',
    CR_Expiry_Date: 'CR Expiry Date',
    CR_Issue_date: 'CR Issue Date',
    CR_Date_of_incorporation: 'CR Date of Incorporation',
    CR_Country_of_first_registration: 'CR Country of First Registration',
    CR_Issue_place: 'CR Issue Place',
    CR_Taxation_Identification_number: 'CR Taxation Identification Number',
    CR_Global_Intermediary_Identification_Number: 'CR Global Intermediary Identification Number',
    CR_unified_number: 'CR Unified Number',
    Contact_First_name: 'Contact First Name',
    Contact_Middle_name: 'Contact Middle Name',
    Contact_Last_name: 'Contact Last Name',
    Contact_Position_in_company: 'Contact Designation',
    Contact_number: 'Phone Number',
    Contact_email: 'Contact Email ID',
    Contact_Preferred_mode_of_communication: 'Contact Preferred Mode of Communication',
    Investment_Bank_Authorization: 'Investment Bank Authorization',
    Back_up_Contact_Name: 'Backup Contact Name',
    Back_up_Person_Position: 'Backup Contact Designation',
    Back_up_Person_Phone: 'Backup Contact Phone Number',
    Back_up_Person_Email: 'Backup Contact Email ID ID',
    Beneficiary_name: 'Beneficiary Name',
    Account_number: 'Account Number',
    IBAN_number: 'IBAN',
    SWIFT_number: 'SWIFT Number',
    Name_of_bank: 'Name of Bank',
    Address_of_bank: 'Address of Bank',
    Document_Check_List: 'Documents Checklist',
    NDA_Agreement: 'Non-Disclosure Agreement (NDA)',
    Registration_form: 'Registration Form',
    Copy_CR: 'Commercial Registration (CR) Copy',
    Trade_Licence: 'trade licence',
    Objective_Form: 'Objective Form',
    Authority_Matrix_for_Portal_Access: 'Authority Matrix for Portal Access',
    Terms_Conditions: 'Terms & Conditions',
    Documentation_checklist: 'Documentation Checklist',
    COMMENT: 'COMMENT',
    Amounts: 'Amount',
    times: 'Time',
    Previous: 'Previous',
    Next: 'Next',

    //my profile
    path: 'Home > My Profile',
    myprofile: 'My Profile',
    updateprofile: 'Update Profile',
    name: 'Name',
    email: 'Email ID',
    contact: 'Phone Number',
    iqama_id: 'National ID/Iqama',
    national_id: 'National ID',
    set_password: 'Create Password',
    confirm_password: 'Confirm Password',
    update_password: 'Update Password',
    btn_text: 'Update Profile',
    Iqama_Expiry: 'Iqama Expiry',
    //TP Managers
    tmpath: 'Home > Manage TP Managers',
    managermng: 'Manage TP Managers',
    search: 'Search',
    searchNameEmail: 'Search by Name/Email ID',
    manager_role: 'Manager Role',
    action: 'Actions',
    manager_btn_text: 'Create New Admin',
    //creat tp Manager
    creat_tp: 'Create TP Managers',
    update_tp: 'Update TP Managers',
    manager_name: 'Admin Name',
    m_role: 'Manager Role',
    id_num: 'National ID/Iqama',
    cnp: 'Create New Password',
    cp: 'Confirm Password',
    b1: 'Back',
    b2: 'Cancel',
    b3: 'Create New Admin',
    update: 'Update',
    //employers
    emp_path: 'Home > Manage Employers',
    manage_employers: 'Manage Employers',
    cne: 'Create New Employer',
    el: 'Employers Listing',
    rav: 'Reviewers and Validation',
    sbs: 'Sort by Status',
    cn: 'Company Name',
    status: 'Status',
    detail: 'Detail',
    //tabels
    Manage_Admins: 'Manage Admins',
    cnm: 'Create New Admin',
    ID: 'ID',
    Name: 'Name',
    Manager_Role: 'Manager Role',
    Actions: 'Actions',
    No_Record_Found: 'No Record Found',
    Iqama_expiry_Date: 'National ID/Iqama Expiry',
    Create_New_Admin: 'Create New Admin',
    update_admin: 'Update Admin',
    Manage_Employers: 'Manage Employers',
    Employers_Mangers: 'Employers Mangers',
    View_Manage_Admins: 'View Manage Admins',
    Investment_Funds: 'Investment Portfolio',
    Update_Group: 'Update Group',
    Create_Group: 'Create Group',
    Manage_Group_Funds_Linking: 'Manage Group Portfolios Linking',
    Create_New_Group: 'Create New Group',
    Group_ID: 'Group ID',
    Group_Name: 'Group Name',
    No_of_Funds: 'No of Portfolios',
    Update_Employees_Group: 'Update Employees Group',
    Create_Employees_Group: 'Create Employees Group',
    Group_Details: 'Group Settings',
    Added_Employees_in_Group: 'Added Employees in Group',
    Employee_Name: 'Employee Name',
    Employee_Contact: 'Employee Contact',
    Position: 'Position',
    Nationality: 'Nationality',
    Employee_Since: 'Employee Since',
    Add_Employees_to_Group: 'Add Employees to Group',
    Groups_Funds_Linking: 'Groups Funds Linking',
    Funds_Assigned_To_Group: 'Added Portfolios in Group',
    Funds_Available: 'Add Portfolio to Group',
    Fund_ID: 'Portfolio ID',
    Fund_Name: 'Portfolio Name',
    Investment_Bank: 'Investment Bank',
    Fund_Code: 'Portfolio Code',
    Risk_Type: 'Risk Type',
    Notifications: 'Notifications',
    All_Notifications: 'All Notifications',
    Sort_By_Owner: 'Sort By Owner',
    Sort_By_Status: 'Sort By Status',
    Manage_Withdrawal: 'Manage Withdrawal',
    Withdrawal_ID: 'Withdrawal ID',
    Employee_ID: 'Employee ID',
    Withdrawls_Amount: 'Withdrawals Amount',
    Created_At: 'Created At',
    Status: 'Status',
    download_pdf: 'Download Pdf',
    //Employer contributions
    Manage_Contributions: 'Contribution Requests',
    Total_Contributions_heading: 'Total Contributions',
    Contribution_ID: 'Contribution ID',
    Total_Contribution_Amount: 'Total Contribution Amount',
    Contribution_Amount: 'Contribution Amount',
    Create_Contributions: 'Create New Contribution Request',
    Download_Sample_CSV: 'Download Sample CSV',
    Upload_CSV: 'Upload CSV',
    Select_Employee: 'Select Employee',
    Employee_Contribution_Amount: 'Employee Contribution Amount',
    Employer_Contribution_Amount: 'Company Contribution Amount',
    Employer_Contribution: 'Company Contribution',
    Vested: 'Vested %',
    stf: 'Send To Fund Manager',
    Employee: 'Employee',
    View_Contributions: 'View Contributions',
    Employer_ID: 'Company ID',
    Employer_Name: 'Comapny Name',
    Upload_Document: 'Upload Contribution Receipt',

    //view Employer contributions
    Funds: 'Funds',
    Employee_Count: 'Employee Contribution',
    Employer_Count: 'Company Contribution',
    Amount: 'Amount',
    total_amount: 'Total Amount',
    View_Subscription_Form: 'View Subscription Form',
    upload: 'Upload Receipt',

    Allocate_Contributions: 'Allocate Contributions',
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH',
    Expected_Yield: 'Expected Yield',
    Liquidity_Type: 'Liquidity Type',
    Learn_more: 'Learn more',

    Risk_Appetite_Survey: 'Risk Appetite Survey',
    Public_Equity: 'Public Equity',
    Money_Markeet_Investment: 'Money Market Investment',
    Fixed_Income_Investment: 'Fixed Income Investment',
    Real_Estate: 'Real Estate',
    //employees
    Manage_Employees: 'Manage Employees',
    Create_New_Employee: 'Add New Employee',
    Create_New_Employee_CSV: 'Create Employees via CSV',
    Employees_Listing: 'Employees Listing',
    Reviewers_And_Validations: 'Review And Validation',

    Employee_Basic_Details: 'Employee Basic Details',
    Next_of_Kin_Information: 'Next of Kin Information',
    Plan_Specific_details: 'Plan Specific Details',
    Tax_Residency_Information: 'Tax Residency Information',
    Phone: 'Phone Number',
    Email: 'Email ID',
    Gender: 'Gender',
    Date_of_Birth: 'Date of Birth',
    Iqama_Id_National_Id: 'National ID/Iqama',
    Iqama_National_ID_expiry: 'National ID/Iqama Expiry',
    Are_You_Dual_Nationality_Holder: 'Dual Nationality Holder?',

    //Investment portfolio
    Link_Portfolio: 'Link Portfolio',
    Link_Employees: 'Link Employees',

    RelationshiptoNextofKin: 'Relationship to Next of Kin',
    FirstName: 'First Name',
    LastName: 'Last Name',
    Contactdetails: 'Contact Details',
    DoesanyoneholdaPowerofattorneyforyou: ' Does anyone hold a Power of attorney for you?',
    MonthlyContributionAmount: 'Monthly Contribution Amount',
    Matching: 'Matching (%)',
    Matching_Rule: 'Matching Rule',

    Tax: 'Tax',
    TaxSSNNo: 'Tax SSN No',
    TaxITINNo: 'Tax ITIN No',
    Confirm_Password: 'Confirm Password',
    Create_Employee: 'Create Employee',
    Update_Employee: 'Update Employee',
    next: 'Next',
    save: 'Save',
    View_Employee_Detail: 'View Employee Details',
    View_Employee: 'View Employees',
    emp_contri: 'Total Comapny<br>Contribution',
    total_portFolio: 'Total <br>Portfolio',
    totalPortFolio: 'Total Portfolio',
    Vestedd: 'Vested',
    Unvested: 'Unvested',
    high_risk: 'High Risk',
    medium_risk: 'Medium Risk',
    low_risk: 'Low Risk',
    Medium_risk: "Medium Risk",
    total_contribution: 'Total Contribution',
    currunt_year: 'Current Year',
    by_status: 'By Status',
    default: 'Default',
    default_portfolio: 'Default Portfolio',
    Activity_Notification: 'Activity Notification',
    All_Activity_Notification: 'All Activity Notification',

    //testing by yousif
    accepted: 'Accepted',
    completed: 'Completed',
    waiting: 'Waiting For Bank Approval',
    pending: 'Pending',
    declined: 'Declined',
    rejected: 'Rejected',
    Under_Process: 'Under Process',
    declinedAdmin: 'Declined By Admin',
    declinedEmployer: 'Declined By Company',
    declinedBank: 'Declined By Bank',
    low: 'LOW',
    medium: 'MEDIUM',
    high: 'HIGH',
    profit: 'Profit/Loss',
    UsernamePassword: 'Incorrect username or password entered, Please check and try again.',
    AuthenticationFailure: 'Authentication Failure',
    VerificationFailure: 'Verification Failure',
    error: 'Error',
    okay: 'Okay',
    serverError: 'Server Error',
    view: 'View All',
    success: 'Success',
    passwordUpdated: 'Password has been updated successfully',
    profileUpdated: 'Profile has been updated successfully',

    PortfolioBreakdown: 'Portfolio Breakdown',
    VestingBreakdown: 'Vesting Breakdown',
    Contributionbreakdownbyrisk: 'Contribution Breakdown by Risk',
    Passwordandconfirm: 'Password and confirm password must be same',

    errorMsgFund: "This employee cannot be removed from the group as he is investing in a fund unique to this group",
    errorMsgFundGroup: "You cannot remove this fund from the group as employees are currently investing in this fund",

    riskProfile: "You have already selected your risk profile",
    riskFundSelect: "Do you want to select this fund",
    yes: "Yes",
    no: "No",
    amountShifted: "Amount Shifted Successfully",
    amountShiftedError: "Vasted amount percentage must be valid",

    managerCreated: "Manager Created Successfully",
    managerUpdated: "Manager updated successfully",

    ContributionCreated: "Contribution created successfully",
    ContributionUpdated: "Contribution updated successfully",

    groupCreated: "Employees portfolio group has been created successfully.",
    groupUpdated: "Employees portfolio group has been updated successfully.",

    questionAsk: "Do you want to send these requests for validation?",
    updatedSuccessfully: "Updated successfully",

    addFund: 'Do you want to add this fund?',
    removeFund: 'Do you want to remove this fund?',

    questionAskAccept: 'Do you want to accept this request?',
    questionAskReject: 'Do you want to reject this request?',

    addEmployee: 'Do you want to add this Employee from this Group?',
    removeEmployee: 'Do you want to remove this Employee from this Group?',

    add_selected: 'Add Selected',

    alert: 'Alert',
    company_name: 'Company',
    added_employees_in_group: "Added Employees in Group",
    add_employee_to_group: "Add Employee to Group",
    select_default_portfolio_error: "Please select default portfolio",
    group_name_error: "Group name is required.",
    reallocate_funds: 'Reallocate Portfolio',
    choose_file: 'Choose File',
    fund_added_success: 'Fund added successfully',
    added_success: 'Added successfully',
    fund_not_added: 'Fund has been added successfully',
    employee_not_added: 'Employee has been added successfully',
    son: 'Son',
    wife: 'Wife',
    husband: 'Husband',
    father: 'Father',
    mother: 'Mother',
    brother: 'Brother',
    daughter: 'Daughter',
    Withdrawal_Units: 'Withdrawal Units',
    employee_detail_added: 'Employee detail has been added successfully',
    Available_units: 'Available Units',
    paraA: 'Based on your risk tolerance your savings are invested in a combination of Money Market , highly liquid short term investments like US Treasury Bills and commercial papers Fixed Income Investment long term debt issues such as bonds and Equity investment ownership stakes in companies',
    paraBA: 'Your risk tolerance is the level of volatility in your returns you are happy to accept, higher volatility means higher chance of returns, but also higher chance of losses. Your current level is',
    paraBB: ', you can change this by retaking the risk tolerance survey to let us help youdetermine which level of risk is right for you.',
    Investment: 'Investment',
    Risk: 'Risk',
    empMarker: 'EMP - Maker',
    empChecker: 'EMP - Checker',
    empFinance: 'EMP - Finance',
    empMarkerDesc: 'EMP maker will manage all the request generation.',
    empCheckerDesc: 'EMP checker will validate and approve the request.',
    empFinanceDesc: 'EMP Finance will view all the request.',
    male: 'Male',
    Female: 'Female',
    ExpectedReturn: 'Expected Return',
    RequestDate: 'Request Date',
    SAR: 'SAR',
    confirmPasswordValidation: 'Password and confirm password are not matched',
    employeeDetail: 'Employee Detail',
    totalUnits: 'Total No. Units',
    submit: 'Submit',
    TerminateEmployee: 'Terminate Employee',
    EmployeeDetails: 'Employee Details',
    Designation: 'Designation',
    EmployeeJoiningDate: 'Employee Joining Date',
    EmployeeGroup: 'Employee Group',
    SettlementSummary: 'Settlement Summary',
    BasedOn: 'Based on Current NAV Prices',
    TotalSettlementAmount: 'Total Settlement Amount (SAR)',
    EmployeeSettlementAmount: 'Employee Settlement',
    EmployerContributionsProfitLoss: 'Company Contributions + Profit/Loss (SAR)',
    EmployeeContributionsProfitLoss: 'Employee Contributions + Profit/Loss (SAR)',
    Portfolio: 'Portfolio',
    PortfolioSumery: 'Below is a snapshot of the employee portfolio as it currently stands with existing NAV prices.The acutal settlement will be reflected at the NAV prices prevailing at the time of divestment',
    TotalEmployeeCount: 'Total Employee Contribution',
    TotalEmployerCount: 'Total Company Contribution',
    riskprofile: 'Risk Profile',
    employeeportfolio: 'Employee Portfolio ',
    employerportfolio: 'Company Portfolio ',

    company_cr_number: 'Company CR number',
    company_website: 'Company Website',

    //terms and conditions
    termsCondions: 'Terms of Service',
    termsDetail: 'This Agreement is entered into by and between  ThriftPlan, a company existent and governed under the laws of the Kingdom of Saudi Arabia with commercial registration no. 1010749140 dated 01/01/2022[G], with its registered office at “3998 Anas bin Malik, al Yasmeen  Dist., 13326 Riyadh”, Kingdom of Saudi Arabia (hereinafter referred to as “ThriftPlan” or “Administrator” or “us” or  “we”; and the corporate partner subscribed to “ThriftPlan”  hereinafter referred to as the "company"; the individual employed by the "company" and who has been enrolled into the program through the "company" (hereinafter referred to as the “Employee”.',
    tH1: '1. Enrollment and due diligence',

    t1: '1.1 - Employees may be enrolled in the plan through their employer either through:',
    t2: '1.1.1 – Auto-enrollment, the employee will become a participant in the plan as of his/ her date of commencing employment; or',
    t3: '1.1.2 - Retrospective auto-enrollment, employees are enrolled in the plan when the employer becomes a member of the ThriftPlan community. His/ Her start date will be the date of his/her company joining ThriftPlan; or',
    t4: '1.1.3 - Voluntary enrollment, the employer offers enrollment into the plan to the company’s employees, and joining the plan is based on employees’ preferences.',
    t5: '1.2 - The employer is responsible to submit the required documents on the behalf of the employee. If ThriftPlan is satisfied with the data provided and the requirements are fulfilled, including due diligence that may be performed on the client and subject to all regulatory requirements, Thrift',
    t6: 'The plan shall grant access to the employee portal and open an account for the employee.',
    t7: '1.3 - Upon granting portal access the employee is required to sign into the portal, set his/her security details, confirm registered data (and request relevant changes to incorrect or outdated data), and agree to:',
    t8: '1.3.1 - Terms of Service',
    t9: '1.3.2 - Trust deed',
    t10: '1.3.3 - Self-certification for Tax purposes',
    t11: '1.4 - Upon acceptance, thereof these Terms of Service shall constitute a legally binding document, which will govern the',
    t12: '2',
    t13: 'The basis on which ThriftPlan will provide its services to the employees. These terms will also apply to each transaction and communication between the parties of this document.',
    t14: '1.5 - In the event, that this Agreement conflicts with the terms of any other agreement entered into with Thrift Plan, the stricter rules of the agreements shall prevail, subject always to compliance with all applicable CMA regulations.',
    t15: '1.6 - The employee subscription with ThriftPlan shall be continuous until one of the following events comes into effect:',
    t16: '1.6.1 - When the employee is terminated from his/ her employment and all benefits have been redeemed with the employer; or',
    t17: '1.6.2 - When the member chooses to disassociate with the program, subject to lock up period and redemption cycle; or',
    t18: '1.6.3 - When the employer chooses to disassociate with the program, subject to lock up period and redemption cycle; or',
    t19: '1.6.4 - When the member dies',
    t20: '1.7 - The employer membership with ThriftPlan shall be continuous until one of the following events comes into effect:',
    t21: '1.7.1 - The employer chooses to disassociate with the program, subject to lock up period and redemption cycle; or',
    t22: '1.7.2 - The underlying company ceases operations',
    t23: '1.7.3 - The underlying company cannot be served due to regulatory filings, blacklisting, or AML restrictions',

    tH2: '2. - Administration of Employer-sponsored Saving Plans',

    t24: '2.1 - Upon enrollment ThriftPlan will maintain two accounts for each Employee; the purpose of each account is to reflect the continued contribution stream and the return received through investment products and the vested amount of the co-matched contributions from the employer.',
    t25: '2.1.1 - The accounts will be credited with:',
    t26: '2.1.1.1 - Employee contributions',
    t27: '2.1.1.2 - Vested Contributions of employer',
    t28: '2.1.1.3 - Return received on investment',
    t29: '2.1.1.4 - Any sum paid in the form of commission, bonus, or other contribution from either employee or employer.',
    t30: '2.1.2 - The account will be debited with:',
    t31: '2.1.2.1 - Any withdrawals approved subject to lock up period, withdrawal limits, and employer approval',
    t32: '2.1.2.2 - Negative investment return',
    t33: '2.1.2.3 - Charges representing the member’s share of fees, expenses, or indemnified losses 2.1.2.4 - Amounts payable to the employer at the end of the employees’ service period.',
    t34: '2.2 - Employee contributions are directly deducted from his/ her salary, and payment of the relevant contribution is the responsibility of the employer within 14 days of the salary having been paid to the employee.',
    t35: '2.2.1 - Any contributions that are not initiated within this time frame will be deducted from the employer’s unvested account and credited to the employee account.',
    t36: '2.3 - Employer contributions are separated into vested and unvested funds, vesting of funds will occur through 2.3.1 - Vesting amount due according to employer specified vesting schedule',
    t37: '2.3.2 - Submission of employee evaluation and percentage achievement of KPIs achieved by employees, subject to a specified vesting period',
    t38: '2.3.3 - Deductions to unvested account due to failure to transfer employee contributions in a timely fashion',
    t39: '2.4 - Employers will be able to specify the allocation and risk settings for all funds within the vested and unvested funds.  The preferences must be submitted upon first registration with ThriftPlan, to be submitted through the Platform, but can be changed any time, subject to a cut of dates for the quarter, if the employer fails to specify investment directives the Default Investment Directive will be used.',
    t40: '2.4.1 - Specifications required upon registration include, but are not limited to:',
    t41: '2.4.1.1 - Contribution Caps',
    t42: '2.4.1.2 - Matching rules',
    t43: '2.4.1.3 - Vesting criteria',
    t44: '2.4.1.4 - Withdrawal restrictions',
    t45: '2.4.1.5 - Risk allocation for Vested/ Unvested fund',
    t46: '2.4.1.6 - Allocation restrictions',
    t47: '2.4.1.7 - Allocation requirements',
    t48: '2.5 - Redemption will be based on employer specifications see section 6.',
    t49: '2.6 - ThriftPlan may use third-party providers and fund managers to provide the most suitable service to the client.  In doing so the client will receive full disclosure of the fund manager and any incentive or fee structure that may be applicable. ThriftPlan will always be able to recommend or advise the service providers and offer additional guidance if a change in investment manager is requested.',
    t50: '2.7 - If employees are at liberty to choose their investment directive for their contribution share, ThriftPlan will not vary the directives provided by the employee unless:',
    t51: '2.7.1 - The employer changes the policy and disables employee choices',
    t52: '2.7.2 - The employee is incapable of managing his/ her affairs (without having any person appointed by Power of attorney to manage his or financial affairs on his behalf)',

    tH3: '3.0 - Contributions',






};
export const arabic = {
    termsCondions: 'شروط الخدمة',
    termsDetail: '',
    tH1: '1 .التسجیل والعنایة الواجبة',
    t1: '1.1 - یمكن تسجیل الموظفین في الخطة من خلال صاحب العمل إما من خلال:',
    t2: '1.1.1 - التسجیل التلقائي ، سیصبح الموظف مشاركا في الخطة اعتبارا من تاریخ بدء العمل ؛ أو',


    Portfolio: 'Portfolio',
    PortfolioSumery: 'Below is a snapshot of the employee portfolio as it currently stands with existing NAV prices.The acutal settlement will be reflected at the NAV prices prevailing at the time of divestment',
    EmployerContributionsProfitLoss: 'Company Contributions + Profit/Loss (SAR)',
    EmployeeContributionsProfitLoss: 'Employee Contributions + Profit/Loss (SAR)',
    BasedOn: 'Based on Current NAV Prices',
    TotalSettlementAmount: 'Total Settlement Amount (SAR)',
    EmployeeSettlementAmount: 'Employee Settlement',
    SettlementSummary: 'Settlement Summary',
    TerminateEmployee: 'Terminate Employee',
    EmployeeDetails: 'Employee Details',
    Designation: 'Designation',
    EmployeeJoiningDate: 'Employee Joining Date',
    EmployeeGroup: 'Employee Group',
    submit: 'حفظ',
    employeeDetail: 'Employee Detail',
    totalUnits: 'Total No. Units',
    confirmPasswordValidation: 'كلمة المرور وتأكيد كلمة المرور غير متطابقين',
    SAR: 'ريال',
    RequestDate: 'تاريخ الطلب',
    ExpectedReturn: 'العائد المتوقع',
    AuthenticationFailure: 'فشل المصادقة',
    male: 'ذكر',
    Female: 'أنثى',

    empMarker: 'مدخل بيانات',
    empChecker: 'مدقق بيانات',
    empFinance: 'مسؤول مالي',
    empMarkerDesc: 'سيقوم مدخل البيانات بإدارة جميع عمليات إنشاء الطلبات.',
    empCheckerDesc: 'سيقوم مدقق البيانات بالتحقق من صحة الطلبات والموافقة عليها.',
    empFinanceDesc: 'القدرة على عرض جميع الطلبات فقط.',

    Investment: 'استثمار',
    Risk: 'مخاطرة',
    paraA: 'بناءً على قدرتك على تحمل المخاطر ، يتم استثمار مدخراتك في مزيج من أسواق المال والاستثمارات قصيرة الأجل عالية السيولة مثل سندات الخزانة الأمريكية والأوراق التجارية ؛ استثمار الدخل الثابت ، إصدارات الديون طويلة الأجل مثل السندات ؛ والاستثمار في الأسهم وحصص الملكية في الشركات.',
    paraBA: 'تحملك للمخاطرة هو مستوى التقلب في عائداتك الذي يسعدك قبوله ، التقلب العالي يعني فرصة أكبر للعوائد ، ولكن أيضًا فرصة أكبر للخسارة. مستواك الحالي هو',
    paraBB: 'يمكنك تغيير هذا من خلال إعادة إجراء استطلاع لتحمل المخاطر للسماح لنا بمساعدتك في تحديد المستوى المناسب للمخاطر بالنسبة ل',
    Available_units: 'الوحدات المتاحة',
    Withdrawal_Units: 'وحدات السحب',
    son: 'ابن',
    wife: 'زوجة',
    husband: 'زوج',
    father: 'أب',
    mother: 'أم',
    brother: 'أخ',
    daughter: 'ابنة',
    employee_detail_added: 'تمت إضافة معلومات الموظف بنجاح',

    fund_added_success: 'تمت إضافة الصندوق بنجاح',
    added_success: 'اضيف بنجاح',
    fund_not_added: 'لم تتم إضافة الصندوق',
    employee_not_added: 'الموظف غير مضاف',

    choose_file: 'اختر ملف',
    reallocate_funds: 'إعادة تخصيص المحفظة',
    riskProfile: "لقد قمت بالفعل بتحديد ملف تعريف المخاطر الخاص بك",
    riskFundSelect: "هل تريد تحديد هذا الصندوق",
    yes: "نعم",
    no: "لا",
    alert: 'انذار',
    amountShifted: "تم تغيير المبلغ بنجاح",
    amountShiftedError: "يجب أن تكون النسبة المئوية للمبلغ الضخم صالحة",

    managerCreated: "تم إنشاء المدير بنجاح",
    managerUpdated: "تم تحديث المدير بنجاح",

    ContributionCreated: "تم إنشاء المساهمة بنجاح",
    ContributionUpdated: "تم تحديث المساهمة بنجاح",

    groupCreated: "تم إنشاء المجموعة بنجاح",
    groupUpdated: "تم تحديث المجموعة بنجاح",

    questionAsk: "هل تريد إرسال هذا الطلب للتحقق؟",
    updatedSuccessfully: 'تم التحديث بنجاح',

    addFund: 'هل تريد إضافة هذا الصندوق؟',
    removeFund: 'هل تريد إزالة هذا الصندوق؟',

    addEmployee: 'هل تريد إضافة هذا الموظف في هذه المجموعة؟',
    removeEmployee: 'هل تريد إزالة هذا الموظف في هذه المجموعة؟',

    questionAskAccept: 'هل تريد قبول هذا الطلب؟',
    questionAskReject: 'هل تريد رفض هذا الطلب؟',

    errorMsgFund: "لا يمكن حذف هذا الموظف من المجموعه ، الموظف يستثمر في صندوق تابع لهذه المجموعه",
    errorMsgFundGroup: "لا يمكن إلغاء هذا الصندوق من المجموعه، الموظفين مساهمين في هذا الصندوق حاليا",
    PortfolioBreakdown: 'تفاصيل المحفظة',
    VestingBreakdown: 'تفاصيل المستحقات المالية',

    Contributionbreakdownbyrisk: 'تفاصيل المساهمة حسب المخاطرة',
    Passwordandconfirm: 'كلمة المرور غير متطابقه',

    profileUpdated: 'تم تحديث الملف الشخصي بنجاح',
    passwordUpdated: 'تم تحديث كلمة السر بنجاح',
    success: 'النجاح',
    view: 'مشاهدة الكل',
    serverError: 'حدث خطأ داخلي في الخادم',
    UsernamePassword: 'اسم المستخدم أو كلمة المرور المدخلة غير صحيحة',
    error: 'خطأ',
    okay: 'حاول مجدداً',
    low: 'منخفضة',
    medium: 'متوسطة',
    high: 'مرتفعة',
    profit: 'الربح/ الخسارة',
    //auth
    Amounts: 'الكمية',
    times: 'زمن',
    Previous: 'سابق',
    Next: 'التالي',

    sign_in: 'تسجيل الدخول',
    admin: 'صاحب العمل',
    portal: 'بوابة ',
    welcome: 'مرحبا بك في',
    thrift: 'ثرفت ',
    plan: 'بلان',
    tp: 'التوفير',
    secure: 'هذا الموقع آمن',
    request: 'من فضلك أدخل إسم المستخدم و كلمة المرور الخاصة بك للتسجيل',
    username: 'اسم المستخدم',
    password: 'كلمه المرور',
    forgot: 'هل نسيت كلمة المرور',
    send_otp: 'تأكيد OTP',
    otp_pl: 'أدخل كود6 أرقام',
    otp_req: 'الرجاء إدخال الرمز المكون من 6 أرقام هنا لتسجيل الدخول',
    forgot_password: 'نسيت كلمة المرور',
    email_req: 'الرجاء ادخال عنوان البريد الإلكتروني او رقم الهاتف الخاص بك',
    reqover_req: ' لاستعادة كلمة المرو',
    email_address: 'أدخل عنوان البريد الإلكتروني أو رقم الهاتف',
    reqover_password: 'استعادة كلمة المرور',
    rec_req: 'الرجاء إدخال الرمز المكون من 6 أرقام المرسل',
    rec_email: 'إلى عنوان بريدك الإلكتروني.',
    confirm_otp: 'تأكيد',
    creat_new: 'إنشاء كلمة مرور جديدة',
    req: 'الرجاء إدخال كلمة المرور الجديدة.',
    rec_password: 'أدخل كلمة مرور جديدة',
    con_password: 'كلمة المرور',
    done: 'تم',

    //dashboard
    thrift_hed: 'thrift',
    plan_hed: 'plan',
    home: 'الصفحة الرئيسية',
    profile: 'ملفي الشخصي',
    mo: 'إدارة التنظيم',
    me: 'إدارة الموظفين',
    mif: 'المحافظ الاستثمارية',
    mr: 'إدارة عمليات الاسترداد',
    mw: 'إدارة عمليات السحب',
    mc: 'إدارة طلبات المساهمة',
    reports: 'لتقارير',
    noti: 'التنبيهات',
    log: 'السجلات',
    actvenoti: 'تنبيهات النشاط',
    MA: 'إدارة المسؤولين',
    settings: 'إعدادات',
    AC: 'تخصيص المساهمة',
    my_profile: 'ملفي الشخصي',
    logout: 'تسجيل خروج',
    //Home
    // Manage_Portfolio: "إدارة المحفظة",
    Manage_Portfolio: "المحفظة الاستثمارية",
    Portfolio_Balance: "رصيد المحفظة",
    // Employer_Risk_Type: "نوع مخاطر صاحب العمل",
    Employer_Risk_Type: "مستوى المخاطرة",
    My_Contribution: 'مساهمة الشركة',
    Employee_Contribution: 'مساهمة الموظف',
    Profit: 'الربح',
    // Profit: 'ربح',
    Total_Withdrawal_Amount: 'إجمالي مبالغ السحب',
    Withdrawal_Amount: 'مبلغ السحب',
    fby: 'تصفية حسب السنة',
    Contributions: 'المساهمات',

    Available_for_Withdrawal: "متاح للسحب",
    Total_Contributions: "إجمالي المساهمات",
    Total_Balance: "إجمالي الرصيد",
    ProfitLoss: "ربح / خسارة",
    Total_Withdrawn_Amount: "إجمالي المبلغ المسحوب",
    Employee_Contribution_Detail: "تفاصيل مساهمة الموظف",
    Invested_Amount: "المبلغ المستثمر",
    Average_Return: "متوسط ​​العائد",
    Employer_Contribution_Detail: "تفاصيل مساهمة جهة العمل",
    Transfer_to_Vested_Account: "تحويل إلى حساب مستحق",
    Total_Unvested_Amount: "إجمالي المبلغ غير المكتسب%",
    Total_vested_Amount: "إجمالي المبلغ المستحق٪",
    Enter_Amount: "أدخل المبلغ",
    vestd: 'مكتسبة',
    unves: 'غير مكتسب',
    Sort_By_Month: "فرز حسب الشهر",
    Sort_By_Year: "فرز حسب السنة",

    //Manage Orgnazation
    Manage_Organization: 'إدارة المنظمة',
    Employer_Identification: "معلومات جهة العمل",
    Admin_Details: "معلومات المسؤول",
    Commercial_Registration_Details: "معلومات السجل التجاري",
    Employer_Representative_Details: "معلومات ممثل الشركة",
    Banks_Details: "معلومات البنك",
    Company_ID: "معرّف الشركة",
    Company_Name: "اسم الشركة (English)",
    Preferred_Language: "اللغة المفضلة",
    No_of_Employees: "عدد الموظفين",
    Industry_Classification: "تصنيف قطاع العمل",
    Type_of_Activity: "نوع النشاط",
    Country_of_Activity: "بلد النشاط",
    Other_Country_of_Activities: "البلدان الأخرى للنشاط",
    Name_of_Other_Countries: "أسماء الدول الأخرى",
    Portfolio_Currency: "عملة المحفظة",
    Is_This_Listed_Company: "هل هذه شركة مدرجة",
    Address: 'العنوان',
    Admin_Name: "اسم المسؤول",
    Phone_Number: "رقم الهاتف",
    Email_ID: "معرف البريد الإلكتروني",
    Iqama_ID_National_ID: "رقم الهوية الوطنية/الإقامة",
    Iqama_ID_National_ID_Expiry_Date: "تاريخ إنتهاء الإقامة/الهوية",
    iqama_national_ID_Scaned_Copy: "يرجى إرفاق صورة من الهوية الوطنية/الإقامة",
    CR_No: "رقم السجل التجاري",
    CR_Expiry_Date: "تاريخ انتهاء السجل التجاري",
    CR_Issue_date: "تاريخ إصدار السجل التجاري",
    CR_Date_of_incorporation: "تاريخ تأسيس السجل التجاري",
    CR_Country_of_first_registration: "بلد التسجيل الأول للسجل التجاري",
    CR_Issue_place: "مكان إصدار السجل التجاري",
    CR_Taxation_Identification_number: 'رقم التعريف الضريبي',
    CR_Global_Intermediary_Identification_Number: "رقم تعريف الوسيط العالمي CR",
    CR_unified_number: "الرقم الموحد للسجل التجاري ",
    Contact_First_name: "الاسم الأول لجهة الاتصال",
    Contact_Middle_name: "الاسم الأوسط لجهة الاتصال",
    Contact_Last_name: "الاسم الأخير لجهة الاتصال",
    Contact_Position_in_company: "المسمى الوظيفي لجهة الاتصال",
    Contact_number: "رقم التواصل",
    Contact_email: "البريد الإلكتروني لجهة الاتصال",
    Contact_Preferred_mode_of_communication: "طريقة التواصل المفضلة لجهة الاتصال",
    Investment_Bank_Authorization: "ترخيص بنك الاستثمار",
    Back_up_Contact_Name: "اسم جهة الاتصال الاحتياطية",
    Back_up_Person_Position: "المسمى الوظيفي للشخص الاحتياطي",
    Back_up_Person_Phone: "رقم التواصل للشخص الاحتياطي",
    Back_up_Person_Email: "البريد الإلكتروني للشخص الاحتياطي",
    Beneficiary_name: "اسم المستفيد",
    Account_number: "رقم الحساب",
    IBAN_number: "الآيبان (IBAN)",
    SWIFT_number: "رقم SWIFT",
    Name_of_bank: "اسم البنك",
    Address_of_bank: "عنوان البنك",
    Document_Check_List: "قائمة تدقيق الوثائق",
    NDA_Agreement: "اتفاقية عدم الإفصاح (NDA)",
    Registration_form: "إستمارة التسجيل",
    Copy_CR: "نسخة من السجل التجاري",
    Trade_Licence: "الرخصة التجارية",
    Objective_Form: "استمارة الأهداف",
    Authority_Matrix_for_Portal_Access: "مستوى السلطة للوصول إلى البوابة",
    Terms_Conditions: "الشروط والأحكام",
    Documentation_checklist: "قائمة تدقيق الوثائق",
    COMMENT: 'تعليق',
    //my profile

    path: 'الصفحة الرئيسية > ملفي الشخصي',
    myprofile: 'ملفي الشخصي',
    updateprofile: 'ملفي الشخصي',
    name: 'اسم',
    email: 'البريد الإلكتروني',
    contact: 'رقم التواصل',
    iqama_id: 'معرف الإقامة',
    national_id: 'الهوية الوطنية',
    set_password: 'أنشئ كلمة مرور',
    confirm_password: 'تأكيد كلمة المرور',
    update_password: 'تطوير كلمة السر',
    btn_text: 'تحديث الملف',
    Iqama_Expiry: 'انتهاء الإقامة',
    //TpManagers
    tmpath: 'الصفحة الرئيسية> إدارة مديري TP',
    managermng: 'إدارة مديري TP',
    search: 'بحث',
    searchNameEmail: 'بحث',
    manager_role: 'دور المدير',
    action: 'الإجراءات',
    manager_btn_text: 'إنشاء مسؤول جديد',
    //Creat new manager
    creat_tp: 'إنشاء مديري TP',
    update_tp: 'تحديث مديري TP',
    manager_name: 'اسم المسؤول',
    m_role: 'دور المدير',
    id_num: 'هوية الإقامة / الهوية الوطنية',
    cnp: 'إنشاء كلمة مرور جديدة',
    cp: 'تأكيد كلمة المرور',
    b1: 'رجوع',
    b2: 'إلغاء',
    b3: 'إنشاء مسؤول جديد',
    update: 'تحديث',

    //Investment portfolio
    Link_Portfolio: 'ربط المحافظ',
    Link_Employees: 'ربط الموظفين',

    //employers
    emp_path: 'الصفحة الرئيسية> إدارة أصحاب العمل',
    manage_employers: 'إدارة أصحاب العمل',
    cne: 'إنشاء صاحب عمل جديد',
    el: 'قائمة أصحاب العمل',
    rav: 'المراجعون والتحقق من الصحة',
    sbs: 'فرز حسب الحالة',
    cn: 'اسم الشركة (English)',
    status: 'الحالة',
    detail: 'التفاصيل',
    //tabels

    Manage_Admins: "إدارة المسؤولين",
    cnm: "إنشاء مسؤول جديد",
    ID: 'الرقم التسلسلي',
    Name: 'الاسم',
    Manager_Role: "دور المسؤول",
    Actions: "الإجراءات",
    No_Record_Found: "لم يتم العثور على أي سجل",
    Iqama_expiry_Date: 'تاريخ انتهاء الهوية الوطنية/الإقامة',
    Create_New_Admin: "إنشاء مسؤول جديد",
    Manage_Employers: "إدارة أصحاب العمل",
    Employers_Mangers: "مديرو أرباب العمل",
    update_admin: "تحديث المسؤول",
    View_Manage_Admins: "عرض إدارة المسؤولين",
    Investment_Funds: 'مجموعات الموظفين',
    Create_New_Group: 'إنشاء مجموعة جديدة',
    Manage_Group_Funds_Linking: 'إدارة ربط حافظات المجموعة',
    Group_ID: 'الرقم التسلسلي',
    Group_Name: 'اسم المجموعة',
    No_of_Funds: 'عدد المحافظ',
    Update_Employees_Group: 'تحديث مجموعة الموظفين',
    Create_Employees_Group: 'إنشاء مجموعة موظفين',
    Update_Group: "تحديث المجموعة",
    Create_Group: "إنشاء مجموعة",
    Group_Details: 'إعدادات المجموعة',
    added_Employees_in_Group: "الموظفين المضافين في المجموعة",
    Employee_Name: "اسم جهة العمل",
    Employee_Contact: "اتصال الموظف",
    Position: "المسمى الوظيفي",
    Nationality: 'الجنسية',
    Employee_Since: 'موظف منذ',
    Add_Employees_to_Group: "إضافة موظفين إلى المجموعة",
    Groups_Funds_Linking: "ربط أموال المجموعات",
    Funds_Assigned_To_Group: "المحافظ المضافة في المجموعة",
    Funds_Available: "إضافة المحفظة إلى المجموعة",
    Fund_ID: "معرّف المحفظة",
    Fund_Name: "اسم المحفظة",
    Investment_Bank: "بنك الاستثمار",
    Fund_Code: "كود المحفظة",
    Risk_Type: "نوع المخاطرة",
    Notifications: 'الإشعارات',
    All_Notifications: 'كل التنبيهات',

    Manage_Withdrawal: 'إدارة عمليات السحب',
    Withdrawal_ID: 'معرف السحب',
    Employee_ID: 'معرّف الموظف',
    Withdrawls_Amount: 'المبلغ المسحوب',
    Created_At: 'تاريخ الإنشاء',
    Status: 'الحالة',
    download_pdf: 'تحميل PDF',
    Sort_By_Owner: 'فرز حسب المالك',
    Sort_By_Status: 'فرز حسب الحالة',
    Manage_Contributions: "إدارة طلبات المساهمة",
    Total_Contributions_heading: 'إجمالي المساهمات',
    Contribution_ID: "معرِّف المساهمة",
    Total_Contribution_Amount: 'إجمالي مبالغ المساهمة',
    Contribution_Amount: "مبلغ المساهمة",
    Create_Contributions: "إنشاء مساهماتإنشاء طلب مساهمة جديد",

    Download_Sample_CSV: "تنزيل نموذج CSV",
    Upload_CSV: "تحميل ملف CSV",
    Select_Employee: "حدد الموظف",
    Employee_Contribution_Amount: "مبلغ مساهمة الموظف",
    Employer_Contribution_Amount: "مبلغ مساهمة صاحب العمل",
    Vested: "مكتسبة٪",
    stf: "إرسال إلى مدير الصندوق",
    Employee: 'الموظف',
    View_Contributions: 'عرض تفاصيل المساهمة',
    Employer_ID: 'معرف صاحب العمل',
    Employer_Name: 'اسم جهة العمل',
    Upload_Document: 'تحميل إيصال المساهمة',


    Allocate_Contributions: 'تخصيص المساهمات',
    LOW: 'منخفظة',
    MEDIUM: 'وسط',
    HIGH: 'متوسط',
    Expected_Yield: 'العائد المتوقع',
    Liquidity_Type: 'نوع السيولة',
    Learn_more: 'تعلم أكثر',

    Risk_Appetite_Survey: "استبيان مدى تقبل المخاطر",
    Public_Equity: "حقوق الملكية العامة",
    Money_Markeet_Investment: "استثمار أسواق المال",
    Fixed_Income_Investment: "استثمار الدخل الثابت",
    Real_Estate: "عقارات",
    //employees
    Manage_Employees: "إدارة الموظفين",
    Create_New_Employee: "إضافة موظف جديد",
    Employees_Listing: "قائمة الموظفين",
    Reviewers_And_Validations: "المراجعة والمصادقة",

    //Employer contribuction view
    Funds: 'أموال',
    Employee_Count: 'مساهمة الموظف',
    Employer_Count: 'مساهمة جهة العمل',
    Amount: 'إجمالي المبلغ',
    total_amount: 'المبلغ الإجمالي',
    View_Subscription_Form: 'عرض نموذج الاشتراك',
    upload: 'تحميل الإيصال',


    Employee_Basic_Details: 'المعلومات الأساسية للموظف',
    Next_of_Kin_Information: 'معلومات قريب من الدرجة الأولى',
    Plan_Specific_details: 'التفاصيل المحددة للخطة',
    Tax_Residency_Information: 'معلومات الإقامة الضريبية',
    Phone: 'رقم التواصل',
    Email: 'البريد الإلكتروني',
    Gender: 'الجنس',
    Date_of_Birth: 'تاريخ الميلاد ',
    Iqama_Id_National_Id: 'رقم الهوية الوطنية/الإقامة',
    Iqama_National_ID_expiry: 'تاريخ إنتهاء الإقامة/الهوية',
    Are_You_Dual_Nationality_Holder: 'هل تحمل جنسية أخرى؟',
    Salary: 'مرتب',

    RelationshiptoNextofKin: 'صلة القرابة',
    FirstName: 'الاسم الأول',
    LastName: 'الاسم الأخير',
    Contactdetails: 'رقم التواصل',
    DoesanyoneholdaPowerofattorneyforyou: 'هل يمتلك أي شخص توكيل رسمي نيابة عنك؟',
    MonthlyContributionAmount: 'مبلغ المساهمة الشهرية',
    Matching: 'قيمة المطابقة (%)',
    Matching_Rule: 'قاعدة المطابقة',

    Tax: 'ضريبة',
    TaxSSNNo: 'ضريبة SSN لا',
    TaxITINNo: 'ضريبة ITIN لا',
    Confirm_Password: 'تأكيد كلمة المرور',
    Create_Employee: 'إضافة موظف جديد',
    Update_Employee: 'تحديث معلومات الموظف',
    next: 'التالي',
    save: 'يحفظ',
    View_Employee_Detail: 'عرض تفاصيل الموظف',
    View_Employee: 'عرض معلومات الموظف',
    emp_contri: 'إجمالي مساهمة صاحب العمل',
    total_portFolio: 'إجمالي رصيد المحفظة',
    totalPortFolio: 'إجمالي رصيد المحفظة',
    Vestedd: 'مكتسب',
    Unvested: 'غير مكتسب',
    Employer_Contribution: 'مساهمة جهة العمل ',
    high_risk: "مخاطر عالية",
    Medium_risk: "مخاطر متوسطة",
    low_risk: "مخاطر منخفضة",
    total_contribution: 'إجمالي المساهمات',
    currunt_year: 'السنة الحالية',
    by_status: 'حسب الحالة',
    default: 'تقصير',
    default_portfolio: 'المحفظة الافتراضية',
    all_logs: 'كل السجلات',
    Activity_Notification: 'تنبيهات النشاط',
    All_Activity_Notification: 'كل تنبيهات النشاط',

    //yousif testing
    accepted: 'مقبول',
    completed: "مكتمل",
    waiting: 'في انتظار موافقة البنك',
    pending: 'قيد الانتظار',
    declined: 'رفض',
    rejected: 'مرفوض',
    Under_Process: 'قيد المعالجة',
    declinedAdmin: 'رفضه المسؤول',
    declinedEmployer: 'رفضه صاحب العمل',
    declinedBank: 'مرفوض من قبل البنك',
    company_name: 'شركة',
    added_employees_in_group: "الموظفين المضافين في المجموعة",
    add_employee_to_group: "إضافة موظف إلى المجموعة",
    select_default_portfolio_error: "يرجى إضافة المحفظة إلى المجموعة واختيار الصندوق الافتراضي",
    group_name_error: "اسم المجموعة مطلوب",

    company_cr_number: 'رقم السجل التجاري للشركة',
    company_website: 'موقع الشركة',
};






<main class="main pt-1 mrl-20 mt-30 mb-20 bg">
    <section fxLayout="row" fxLayoutAlign="space-between center">
        <h3 class="m-0" style="color: #213060; font-weight: bold;">{{'View Subscription Form' | translate}}</h3>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
            <div style="border-radius: 10px; background-color: white; padding: 10px 20px;" fxLayoutAlign="center center" fxLayoutGap="30px">
                <div>
                    <h4 style="color: #213060; margin: 0;">{{'Subscription Documents' | translate}}</h4>
                    <p style="margin: 0; font-size: 12px; font-weight: bold;">Files Count: {{noOfFiles}}</p>
                </div>
                <button (click)="openFileDialog()" style="background-color: #3F638F; color: white; line-height: 32px; border-radius: 10px; font-size: 13px; height: 32px;" mat-raised-button>{{'View' | translate}}
                </button>
            </div>
        </div>
    </section>

    <mat-accordion class="expension-panel" [multi]="true">
        <ng-container *ngFor="let item of list; let index = index; first as isFirst;">
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                *ngIf="item.showHidePortfolio"
                [expanded]="isFirst" style="margin-top: 20px;" [style.background-color]="item?.hasError ? '#FFCDD2' : ''">
                <mat-expansion-panel-header>
                    <div fxLayoutAlign="space-between center" fxFlex="100">
                        <h3 style="margin: 0; color: #213060; font-weight: bold;">{{item?.name}}</h3>
                    </div>
                </mat-expansion-panel-header>
                <section>
                    <mat-card class="mt-20" *ngFor="let element of item?.assets; let odd=odd; let even=even ">
                        <div fxLayoutAlign="space-between">
                        </div>
                        <div fxLayout="row wrap" fxLayoutGap="10px">
                            <div class="card" fxFlex="36" fxLayout="row">
                                <div fxFlex="75" fxLayoutAlign="center start" fxLayout="column" style="background-color: #DADFEC; border-radius: 10px 0 0 10px;">
                                    <h3 class="m-0" style="color: #2F2F2F; margin-left: 10px !important;">{{element.asset_name}}</h3>
                                    <p class="m-0 fs-12" style="color: #707070; margin-left: 10px !important;">Fund ID: {{element.id}}</p>
                                </div>
                                <div style="background-color: #213060; border-radius: 0 10px 10px 0;" fxFlex="25" fxLayout="column" fxLayoutAlign="center center">
                                    <p class="m-0" style="color: white; text-align: center;">Target Allocation</p>
                                    <h3 class="m-0" style="color: #C853FF;">{{element.funds_assets.allocation}}%</h3>
                                </div>
                            </div>
                            <div style="background-color: #E9EAEF;" class="card" fxFlex="15" fxLayout="row wrap" fxLayoutAlign="center center">
                                <p style="color: #213060; font-size: 12px; font-weight: bold; text-align: center;" fxFlex="100">NAV per Unit (SAR)</p>
                                <mat-form-field appearance="legacy" fxFlex="75">
                                    <mat-label></mat-label>
                                    <div style="font-weight: bold; font: size 16px; width: 100%; text-align: center;" class="show">
                                        <!-- {{element.nav_per_unit | number:'1.4-4'}} -->

                                        <span>{{element.nav_per_unit | number:'1.5-5' | slice:0:-5}}</span>
                                        <span style="font-size: 11px;">{{element.nav_per_unit | number:'1.5-5' | slice:-5}}</span>
                                    </div>
                                    <input style="font-weight: bold; font: size 16px; text-align: center;" class="hide-input" [(ngModel)]="element.nav_per_unit" matInput type="number" autocomplete="off" readonly>
                                </mat-form-field>
                            </div>
                            <div style="background-color: #E9EAEF;" class="card" fxFlex="15" fxLayout="row wrap" fxLayoutAlign="center center">
                                <p style="color: #213060; font-size: 12px; font-weight: bold; text-align: center;" fxFlex="100">Total Amount (SAR)</p>
                                <mat-form-field appearance="legacy" fxFlex="75">
                                    <mat-label></mat-label>
                                    <div style="font-weight: bold; font: size 16px; width: 100%; text-align: center;" class="show">
                                        <!-- {{element.amount_sar | number:'1.4-4'}} -->

                                        <span>{{element.amount_sar | number:'1.4-4' | slice:0:-4}}</span>
                                        <span style="font-size: 11px;">{{element.amount_sar | number:'1.4-4' | slice:-4}}</span>
                                    </div>
                                    <input class="hide-input" style="font-weight: bold; font: size 16px; text-align: center;" readonly [(ngModel)]="element.contributions_subscriptions[0].amount_sar" matInput type="number" autocomplete="off">
                                </mat-form-field>
                            </div>
                            <div style="background-color: #E9EAEF;" class="card" fxFlex="15" fxLayout="row wrap" fxLayoutAlign="center center">
                                <p style="color: #213060; font-size: 12px; font-weight: bold; text-align: center;" fxFlex="100">Fees (SAR)</p>
                                <mat-form-field appearance="legacy" fxFlex="75">
                                    <mat-label></mat-label>
                                    <div style="font-weight: bold; font: size 16px; width: 100%; text-align: center;" class="show">
                                        <!-- {{element?.fee | number:'1.4-4'}} -->

                                        <span>{{element?.fee | number:'1.4-4' | slice:0:-4}}</span>
                                        <span style="font-size: 11px;">{{element?.fee | number:'1.4-4' | slice:-4}}</span>
                                    </div>
                                    <input class="hide-input" style="font-weight: bold; font: size 16px; text-align: center;" [(ngModel)]="element.contributions_subscriptions[0].fee" matInput type="number" autocomplete="off" readonly>
                                </mat-form-field>
                            </div>
                            <div style="background-color: #213060;" class="card" fxFlex="15" fxLayout="row wrap" fxLayoutAlign="center center">
                                <p style="color: white; font-size: 12px; font-weight: bold; text-align: center;" fxFlex="100">Total No. of Units</p>
                                <mat-form-field appearance="legacy" fxFlex="75">
                                    <mat-label></mat-label>
                                    <div style="font-weight: bold; font: size 16px; width: 100%; text-align: center; color: #79C0C2;" class="show">
                                        <!-- {{element.no_of_units | number:'1.4-4'}} -->

                                        <span>{{element.no_of_units | number:'1.5-5' | slice:0:-5}}</span>
                                        <span style="font-size: 11px;">{{element.no_of_units | number:'1.5-5' | slice:-5}}</span>
                                    </div>
                                    <input class="hide-input" style="font-weight: bold; font: size 16px; text-align: center; color: #79C0C2;" [(ngModel)]="element.contributions_subscriptions[0].no_of_units" matInput type="number" autocomplete="off" readonly>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card>
                </section>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>

    <section class="mb-20 mt-20">
        <mat-card>
            <div fxLayoutAlign="none center" fxLayout="row wrap">
                <h3 fxFlex="30" style="color: #213060; margin: 0;">Subscription Summary</h3>
                <p class="summary-amount-bubble">Total Requested Contribution Amount (SAR) : {{grandTotal | number:'1.4-4'}}</p>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-around" style="border-radius: 10px; background-color: #E9EAEF; margin-top: 10px;">
                <p fxFlex="25" style="color: #62687D; font-size: 12px; margin-top: 12px; margin-left: 10%;">Fund ID</p>
                <p fxFlex="25" style="color: #62687D; font-size: 12px; margin-top: 12px;">Fund Name</p>
                <p fxFlex="25" style="color: #62687D; font-size: 12px; margin-top: 12px;">Total Amount (SAR)</p>
                <p fxFlex="25" style="color: #62687D; font-size: 12px; margin-top: 12px;">Fees (SAR)</p>
                <p fxFlex="25" style="color: #62687D; font-size: 12px; margin-top: 12px;">Total No. of Units</p>
            </div>
            <div *ngFor="let item of lstCombined" fxLayout="row" fxLayoutAlign="space-around" style="border-radius: 10px; background-color: #E9EAEF; margin-top: 10px;">
                <p fxFlex="25" style="color: #212121; font-size: 12px; font-weight: bold; margin-top: 12px; margin-left: 10%;">{{item.id}}</p>
                <p fxFlex="25" style="color: #212121; font-size: 12px; font-weight: bold; margin-top: 12px;">{{item.asset_name}}</p>
                <p fxFlex="25" style="color: #212121; font-size: 12px; font-weight: bold; margin-top: 12px;">
                    <!-- {{item.amount_sar | number:'1.4-4'}} -->

                    <span style="font-size: 14px;">{{item.amount_sar | number:'1.4-4' | slice:0:-4}}</span>
                    <span style="font-size: 12px;">{{item.amount_sar | number:'1.4-4' | slice:-4}}</span>
                </p>
                <p fxFlex="25" style="color: #212121; font-size: 12px; font-weight: bold; margin-top: 12px;">
                    <!-- {{item.fee | number:'1.4-4'}} -->

                    <span style="font-size: 14px;">{{item.fee | number:'1.4-4' | slice:0:-4}}</span>
                    <span style="font-size: 12px;">{{item.fee | number:'1.4-4' | slice:-4}}</span>
                </p>
                <p fxFlex="25" style="color: #212121; font-size: 12px; font-weight: bold; margin-top: 12px;">
                    <!-- {{item.no_of_units | number:'1.5-5'}} -->

                    <span style="font-size: 14px;">{{item.no_of_units | number:'1.5-5' | slice:0:-5}}</span>
                    <span style="font-size: 12px;">{{item.no_of_units | number:'1.5-5' | slice:-5}}</span>
                </p>
            </div>
            <div fxLayout="row" style="border-radius: 10px; background-color: #3F638F; margin-top: 10px;">
                <p style="color: white; font-size: 14px; font-weight: bold; margin-left: 5%; margin-top: 12px;">Total Invested Amount (SAR)</p>
                <p style="color: white; font-size: 14px; font-weight: bold; margin-left: 30px; margin-top: 12px;">
                    <!-- {{totalAmount | number:'1.4-4'}} -->

                    <span style="font-size: 14px;">{{totalAmount | number:'1.4-4' | slice:0:-4}}</span>
                    <span style="font-size: 12px;">{{totalAmount | number:'1.4-4' | slice:-4}}</span>
                </p>
            </div>
        </mat-card>
    </section>

    <section fxLayoutAlign="end" fxLayoutGap="10px">
        <button (click)="back()"
            style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 25px; border-radius: 10px;"
            mat-raised-button>{{'Back' | translate}}
        </button>
    </section>
</main>
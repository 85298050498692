<div class="main pt-1 mrl-20 mt-30" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
  <div fxLayout="column">
    <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="margin-inline: 20px;">
      <h2 *ngIf="type == 'update'" class="top-heading">{{'Edit' | translate}}</h2>
      <h2 *ngIf="type == 'view'" class="top-heading">{{'View' | translate}}</h2>
      <h2 *ngIf="type == 'add'" class="top-heading">{{'Add HR Policy' | translate}}</h2>
      <div fxLayoutGap="10px" style="margin-right: 10px;">
        <button (click)="back()" class="btn-disabled">{{'Back' | translate}}</button>
        <button *ngIf="type != 'view'" (click)="onSave()"
          class="btn-active">{{type == 'update' ? ('Update' | translate) : type == 'view' ? ('View' | translate) : ('Add' | translate)}}</button>
      </div>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
      <h2 class="terms-heading">{{'Enforce Acceptance' | translate}} <mat-slide-toggle [(ngModel)]="force_accept" (change)="toggle($event)"
          [disabled]="type == 'view'" style="margin: 0 25px 0 25px; height: 10px;"></mat-slide-toggle>
      </h2>
    </div>
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <h2 class="download-heading">English</h2>
      <a href="assets/hr-english.docx" target="_blank" download="Standard HR Policy.docx">
        <button class="btn-download">{{'Download Standard HR Policy' | translate}}</button>
      </a>
    </div>
    <div class="editor">
      <div id="editor_en"></div>
    </div>
    <div dir="rtl" style="display: flex; justify-content: space-between; align-items: center; margin-top: 20px;">
      <h2 class="download-heading" dir="rtl" style="direction: rtl; text-align: right;font-family: cairo !important; ">العربية</h2>
      <a href="assets/hr-arabic.docx" target="_blank" download="Standard HR Policy(Arabic).docx">
        <button class="btn-download">تنزيل سياسة الموارد البشرية الموحدة</button>
      </a>
    </div>
    <div class="editor" style="margin-bottom: 50px;">
      <div id="editor_ar" style="text-align: right;"></div>
    </div>
  </div>
</div>

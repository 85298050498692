import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';

@Component({
    selector: 'app-edit-dialog',
    templateUrl: './edit-dialog.component.html',
    styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent implements OnInit {
    Form: FormGroup;
    flex = '';
    modifyErrorMessage = null;
    employerAmount: number = 0;
    employeeAmount: number = 0;

    editModes = {
        employee_id: false,
        employee_contribution_amount: false,
        employer_contribution_amount: false,
    }
    isArabic = false;

    constructor(protected dialogRef: MatDialogRef<EditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        protected formbuilder: FormBuilder,
        protected mainService: MainService,
        protected cryptoService: DecEncService,
        protected alertService: AlertService) {
        dialogRef.disableClose = true;
        this.isArabic = localStorage.getItem('lang') == 'ar' ? true : false;
    }

    ngOnInit(): void {
        this.flex = this.data == 'New' ? '32' : '16';
        this.initForm();
        this.checkErrorOnEdit()
        if (this.data != 'New') {
            this.Form.patchValue(this.data);
            this.employeeAmount = Number(this.data.employee_contribution_amount);
            this.employerAmount = Number(this.data.employer_contribution_amount);
        }
    }

    initForm() {
        if (this.data == 'New') {
            this.Form = this.formbuilder.group({
                employee_id: new FormControl(null, [Validators.required, Validators.min(0)]),
                employee_contribution_amount: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(1000000)]),
                employer_contribution_amount: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(1000000)]),
            });
        }
        else {
            this.Form = this.formbuilder.group({
                id: new FormControl(null),
                iqama_id: new FormControl({ value: null, disabled: true }),
                name: new FormControl({ value: null, disabled: true }),
                employee_id: new FormControl(null, [Validators.required, Validators.min(0)]),
                employee_contribution_amount: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(1000000)]),
                employer_contribution_amount: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(1000000)]),
            });
        }
    }

    closeDialog() {
        this.dialogRef.close({ event: 'closed' });
    }

    checkErrorOnEdit() {
        if (this.data.error_message) {
            for (let key in this.data.error_message) {
                if (localStorage.getItem('lang') == 'ar') {
                    if (this.modifyErrorMessage == null) {
                        this.modifyErrorMessage = this.data.error_message[key]['message_ar'];
                    }
                    else {
                        this.modifyErrorMessage = `${this.modifyErrorMessage}, ${this.data.error_message[key]['message_ar']}`;
                    }
                }
                else {
                    if (this.modifyErrorMessage == null) {
                        this.modifyErrorMessage = this.data.error_message[key]['message'];
                    }
                    else {
                        this.modifyErrorMessage = `${this.modifyErrorMessage}, ${this.data.error_message[key]['message']}`;
                    }
                }
            }
        }
    }

    submit() {
        if (!this.checkValidations()) {
            return;
        }
        if (this.data == 'New') {
            this.newRecord();
        }
        else {
            this.modifyRecord();
        }
    }

    newRecord() 
    {
        this.modifyErrorMessage = null;
        let encrypted = this.cryptoService.encryption(this.Form.getRawValue());
        let obj = {
            enc_payload: encrypted
        }
        let url = `draft_contributions/insert`;
        this.mainService.postData(url, obj).then(response => {
            if (response.statusCode == 200) 
            {
                let dec = this.cryptoService.decryption(response.data);
                // console.log('modifyRecord success', dec);
                this.dialogRef.close({ event: 'created' });
            }
            else 
            {
                let decError = this.cryptoService.decryption(response.error.error);
                if (response.error.message) 
                {
                    for (let key in response.error.message)
                    {
                        if (localStorage.getItem('lang') == 'ar') 
                        {
                            this.modifyErrorMessage = response.error.message_ar;
                        }
                        else 
                        {
                            this.modifyErrorMessage = response.error.message;
                        }
                    }
                    // for (let key in response.error.message)
                    // {
                    //     if (this.modifyErrorMessage == null)
                    //     {
                    //         this.modifyErrorMessage = response.error.message[key];    
                    //     }
                    //     else
                    //     {
                    //         this.modifyErrorMessage += `${this.modifyErrorMessage}, ${response.error.message[key]}`;
                    //     }
                    // }
                }
                else {
                    this.modifyErrorMessage = 'Error';
                }
                // this.alertService.alert('Error', response.message, '');
            }
        })
    }

    modifyRecord() {
        this.modifyErrorMessage = null;
        let encrypted = this.cryptoService.encryption(this.Form.getRawValue());
        // console.log('modifyRecord payload', this.Form.getRawValue());
        let obj = {
            enc_payload: encrypted
        }
        let url = `draft_contributions/modify`;
        this.mainService.postData(url, obj).then(response => {
            if (response.statusCode == 200) {
                this.modifyErrorMessage = null;
                let dec = this.cryptoService.decryption(response.data);
                // console.log('modifyRecord success', dec);
                this.dialogRef.close({ event: 'updated' });
            }
            else {
                let decError = this.cryptoService.decryption(response.error.error);
                // this.modifyErrorMessage = response.error.message;
                if (response.error.message) {
                    for (let key in response.error.message)
                    {
                        if (localStorage.getItem('lang') == 'ar') 
                        {
                            this.modifyErrorMessage = response.error.message_ar;
                        }
                        else 
                        {
                            this.modifyErrorMessage = response.error.message;
                        }
                    }
                }
                else {
                    this.modifyErrorMessage = 'Error';
                }
                // console.log('modifyRecord', decError, response);
            }
        })
    }

    checkValidations() {
        if (this.Form.invalid) {
            this.Form.markAllAsTouched();
            return false;
        }
        return true;
    }

    getFieldValue(field) {
        if (this.Form.get(field).touched) {
            if (this.Form.get(field).invalid) {
                return true;
            }
        }
        return false;
    }

    getErrorMessage(control) {
        if (control == 'employee_id' && this.Form.get(control).invalid) {
            if (this.Form.get(control).hasError('min')) {
                return 'Employee ID must be greater than 0.'
            }
            else {
                return this.isArabic ? 'مطلوب رمز هوية الموظف' : 'Employee ID is required'
            }
        }
        if (control == 'employee_contribution_amount' && this.Form.get(control).invalid) {
            if (this.Form.get(control).hasError('min')) {
                return 'Employee Contribution Amount must be greater than 0.'
            }
            if (this.Form.get(control).hasError('max')) {
                return 'Employee Contribution Amount must be less than 1,000,000.'
            }
            else {
                return 'Employee Contribution Amount is required.'
            }
        }
        if (control == 'employer_contribution_amount' && this.Form.get(control).invalid) {
            if (this.Form.get(control).hasError('min')) {
                return 'Company Contribution Amount must be greater than 0.'
            }
            if (this.Form.get(control).hasError('max')) {
                return 'Company Contribution Amount must be less than 1,000,000.'
            }
            else {
                return 'Company Contribution Amount is required.'
            }
        }
    }

    setMode(control, mode, focusOut?) {
        this.editModes[control] = mode;
        if (focusOut && !this.Form.get(control).value) {
            this.Form.get(control).setValue(0);
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InviteEmployeeLoggedComponent } from '../manage-employee/invite-employee-logged/invite-employee-logged.component';
import { MainService } from 'src/app/services/main.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
    selector: 'app-congratulations-dialog',
    templateUrl: './congratulations-dialog.component.html',
    styleUrls: ['./congratulations-dialog.component.scss']
})
export class CongratulationsDialogComponent implements OnInit
{

    isArabic = false;
    constructor(
        protected dialog: MatDialog,
        protected dialogRef: MatDialogRef<CongratulationsDialogComponent>,
        protected apiService: MainService,
        protected alertService: AlertService
    ) 
    {
        this.isArabic = localStorage.getItem('lang') == 'ar' ? true : false; 
    }

    ngOnInit(): void
    {

    }

    onInvite()
    {
        this.onInviteEmployees();
    }
    
    onInviteEmployees()
    {
        let dialogRef = this.dialog.open(InviteEmployeeLoggedComponent, { autoFocus: false, width: '35%', height: 'auto', hasBackdrop: false, panelClass: this.isArabic ? 'arabic-lang' : 'english-lang' });
        let cm = dialogRef.componentInstance;
        dialogRef.afterClosed().subscribe(res =>
        {
            this.thankyouCall();
            this.dialogRef.close();
        })
    }

    thankyouCall()
    {
        this.apiService.postData('home/thank-you', {}).then(response => {
            if (response.statusCode == 200) {

            }
            else {
                if (localStorage.getItem('lang') == 'ar') {
                    this.alertService.alert('Error', response.error.message_ar);
                }
                else {
                    this.alertService.alert('Error', response.error.message);
                }
            }
        })
    }

    closeDialog()
    {
        this.thankyouCall();
        this.dialogRef.close();
    }
}

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import * as printJS from 'print-js';
import { AlertResult } from 'src/app/lib/alert-dialog-new/alert.dialog.new';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { ProfileService } from 'src/app/services/profile.service';
import { appConfig } from 'src/config';

@Component({
    selector: 'app-employee',
    templateUrl: './employee.component.html',
    styleUrls: ['./employee.component.scss']
})

export class EmployeeComponent implements OnInit {
    lis: any = true;
    id: any;
    list: any = [];
    printList: any = [];
    body: any;
    searchTimer: any;
    search: any = "";
    totalPages: number;
    pages: any;
    perPage: number = 10;
    currentPage: any = 1;
    count: any;
    userArray: any = []
    sort_by: any = ''
    sort_order = 'ASC'
    sortData: { value: any; key: string; }[];
    role: any;
    showCreateBtn: boolean = true;
    showEditIcon: boolean = true;
    showViewIcon: boolean = true;
    statusIcon: boolean = true;
    portfolioIcon: boolean = true;
    forwardEmploye: boolean = true;
    reviewTab: boolean = true;
    msgStatus: string;
    checkTab: any;
    activeTab: boolean = true;
    lastUrl: string;
    checkboxSelected: any = [];
    allDays: any = false;
    showButton: boolean = false;
    employeeListing = true;
    filteredArray: any = [];
    status_of_type = '';
    status_of_active = '';
    emailList: any = [];
    employeeStats = {
        'Total Employees': null,
        'Active': null,
        'Inactive': null,
        'Non-Verified': null,
        'Rejected': null,
        'Terminated': null,
        'Verified': null
    }
    isCheckboxDisabled: boolean = false;
    employeeStatsArray = [];

    tablePropsEmp = {
        heading: 'Manage Employees',
        hasSearch: true,
        searchLabel: 'Search_Name_Email',
        hasButton: true,
        hasButtonsMenu: true,
        headerActions: [
            {
                label: 'Send_for_Review',
                type: 'output',
                visibility: true,
                isCustom: false,
                disabled: true,
                // icon: 'rate_review',
                icon: 'done',
            },
            {
                label: 'Create_Emp_CSV',
                route: 'manage_employee/create_via_csv',
                type: 'link',
                visibility: true,
                isCustom: false,
                icon: 'add',
            },
            // {
            //     label: 'Create_New_Employee',
            //     route: 'manage_employee/add/new',
            //     type: 'link',
            //     visibility: true,
            //     isCustom: false,
            //     icon: 'add',
            // },
            {
                label: 'Invite Employees',
                type: 'output',
                visibility: true,
                isCustom: false,
                disabled: true,
            },
        ],
        rowActions: [
            { label: 'Edit', visibility: true, type: 'ShowEdit', isCustom: true, background: '#79BBBF0D', source: 'assets/new-icons/edit.svg' },
            { label: 'View', visibility: true, background: '#4318FF0D', source: 'assets/table-view.svg' },
            { label: 'Send_for_Review', type: 'sendForReview', isCustom: true, visibility: true, background: '#4318FF0D', source: 'assets/ic_check_circle_-1.png' },
            { label: 'Manage Portfolio', type: 'managePortfolio', isCustom: true, visibility: true, background: '#79BBBF0D', source: 'assets/new-icons/manage-portfolio.png' },
            { label: 'Activate User', type: 'activate', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/table-verify.svg' },
            { label: 'Deactivate User', type: 'deactivate', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/new-icons/deactivate-user.png' },
            { label: 'Terminate Employee', type: 'terminate', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/new-icons/terminated-employees.png' },
            { label: 'Delete Employee', type: 'delete', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/table-deactivate.svg' },
            { label: 'Unblock', type: 'unblock', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/table-verify.svg' },
            { label: 'Termination Summary', visibility: true, type: 'terminationSummary', isCustom: true, background: '#79BBBF0D', source: 'assets/images/table-edit.png' },
        ],
        colHeader: {
            checkBox: '',
            id: 'ID',
            name: 'Name',
            email: 'Email',
            position: 'Position',
            iqama_id: 'National_Iqama_ID',
            employeeSince: 'Employee_Since',
            fundAssigned: 'Portfolio Assigned',
            fundGroup: 'Employee Group',
            statusToShow: 'Verification Status',
            statusActive: 'Activation Status',
            actions: 'Actions'
        },
        columnTypes: {
            email: 'email',
            employeeSince: 'date',
            statusToShow: 'errorColumn',
            statusActive: 'errorColumn',
            fundAssigned: 'infoColumn',
            actions: 'actionsMenu',
        },
        filterArray: [
            {
                label: 'Filter by Verification Status',
                type: 'filter_list',
                key: 'status',
                visibility: true,
                selected: 'all',
                options: [
                    {
                        value: 'all',
                        label: 'All',
                        key: 'status',
                    },
                    {
                        value: 'verified',
                        label: 'Verified',
                        key: 'status',
                    },
                    {
                        value: 'non-verified',
                        label: 'Non_Verified',
                        key: 'status',
                    },
                ],
            },
            {
                label: 'Filter by Activation Status',
                type: 'filter_list',
                key: 'active',
                visibility: true,
                selected: 'all',
                options: [
                    {
                        value: 'all',
                        label: 'All',
                        key: 'status',
                    },
                    {
                        value: 'activated',
                        label: 'Activated',
                        key: 'status',
                    },
                    {
                        value: 'deactivated',
                        label: 'Deactivated',
                        key: 'status',

                    },
                ],
            },
        ],
    };

    tablePropsReview = {
        heading: 'Reviewers_And_Validations',
        hasSearch: true,
        searchLabel: 'Search_Name_Email',
        hasButton: true,
        headerActions: [
            {
                label: 'Validate',
                type: 'output',
                disabled: true,
                visibility: true,
                isCustom: false,
            },
            {
                label: 'Create_Emp_CSV',
                route: 'manage_employee/create_via_csv',
                type: 'link',
                visibility: true,
                isCustom: false,
                icon: 'add',
            },
            // {
            //     label: 'Create_New_Employee',
            //     route: 'manage_employee/add/new',
            //     type: 'link',
            //     visibility: true,
            //     isCustom: false,
            //     icon: 'add',
            // },
        ],
        rowActions: [
            { label: 'View', type: 'view', visibility: true, background: '#4318FF0D', source: 'assets/table-view.svg', iconName: 'arrow_forward' },
            { label: 'Accept', visibility: true, background: '#4318FF0D', source: 'assets/table-verify.svg', iconName: 'arrow_forward' },
            { label: 'Reject', visibility: true, background: '#4318FF0D', source: 'assets/table-deactivate.svg', iconName: 'arrow_forward' },
        ],
        colHeader: {
            checkbox: '',
            id: 'ID',
            name: 'Name',
            email: 'Email',
            position: 'Position',
            iqama_id: 'National_Iqama_ID',
            employeeSince: 'Employee_Since',
            fundAssigned: 'Portfolio Assigned',
            fundGroup: 'Employee Group',
            statusToShow: 'Verification Status',
            statusActive: 'Activation Status',
            actions: 'Actions',
        },
        columnTypes: {
            email: 'email',
            statusToShow: 'errorColumn',
            statusActive: 'errorColumn',
            fundAssigned: 'infoColumn',
            actions: 'actionsMenu',
        },
        filterArray: [],
    };

    pagination = {
        page: null,
        pages: null,
        per_page: null,
        count: 0
    }

    checkBoxCount = 0;
    checkBoxReviewCount = 0;
    empIds = null;

    EmployerAssetslist: any = [];
    EmployeeAssetslist: any = [];
    newlist: any;
    employeeaggregate: any;
    employer_contribution: any;
    employee_contribution: any;
    profit: any;
    profitEmee: any;
    EmployerVested: number;
    investedEmployer: number;
    investedEmployee: number;
    emeeWithdrawalAmount: number;
    dashboardData: any;

    headersPrintCompanyPortfolio = [
        { colHeader: 'Portfolio', colKey: 'fund_name', colType: '' },
        { colHeader: 'Fund', colKey: 'asset_name', colType: '' },
        { colHeader: 'Risk Type', colKey: 'risk_type', colType: '' },
        { colHeader: 'No of Units', colKey: 'company_availble_units', colType: 'number-5' },
        { colHeader: 'Invested', colKey: 'company_invested_amount', colType: 'number-4' },
    ];

    headersPrintEmployeePortfolio = [
        { colHeader: 'Portfolio', colKey: 'fund_name', colType: '' },
        { colHeader: 'Fund', colKey: 'asset_name', colType: '' },
        { colHeader: 'Risk Type', colKey: 'risk_type', colType: '' },
        { colHeader: 'No of Units', colKey: 'availble_units', colType: 'number-5' },
        { colHeader: 'Invested', colKey: 'employee_invested_amount', colType: 'number-4' },
    ];
    isArabic = false;

    constructor(public tr: TranslationService,
        public route: Router,
        protected mainService: MainService,
        protected dec_enc: DecEncService,
        public paginationService: PaginationService,
        public dialog: MatDialog,
        private http: HttpClient,
        protected _route: ActivatedRoute,
        protected alertService: AlertService,
        protected profileService: ProfileService) {
        this.role = profileService.user.role;
        this.isArabic = localStorage.getItem('lang') == 'ar' ? true : false;

        if (this.role == 'employer') {
            this.showCreateBtn = true;
            this.showEditIcon = true;
            this.showViewIcon = true;
            this.statusIcon = true;
            this.portfolioIcon = true;
            this.forwardEmploye = true;
            this.reviewTab = true;
        }
        else if (this.role == 'emp-maker') {
            this.showCreateBtn = true;
            this.showEditIcon = true;
            this.showViewIcon = true;
            this.statusIcon = true;
            this.portfolioIcon = true;
            this.forwardEmploye = true;
            this.reviewTab = false;
        }
        else if (this.role == 'emp-checker') {
            this.showCreateBtn = false;
            this.showEditIcon = false;
            this.showViewIcon = true;
            this.statusIcon = false;
            this.portfolioIcon = false;
            this.forwardEmploye = false;
            this.reviewTab = true;
        }
        else if (this.role == 'emp-finance') {
            this.showCreateBtn = false;
            this.showEditIcon = false;
            this.showViewIcon = true;
            this.statusIcon = false;
            this.portfolioIcon = true;
            this.forwardEmploye = false;
            this.reviewTab = false;
        }
    }

    ngOnInit(): void {
        this.getEmployeeStats();
        this._route.params.subscribe(params => {
            this.lastUrl = window.location.hash.substring(window.location.hash.lastIndexOf("/") + 1, window.location.hash.length);
            this.getall(1);
        });

        this.readcsv();
        this.id = localStorage.getItem('employer_id');
        this.body = {
            employer_id: this.id,
            search: this.search,
            is_under_review: '0'
        }
        this.tr.selectedApp.subscribe((state) => {
            this.tr.translation = state;
            this.sortOrder();
        })
        this.sortOrder();
    }

    sortOrder() {
        this.sortData = [
            {
                value: this.tr.translation.default,
                key: '',
            },
            {
                value: this.tr.translation.by_status,
                key: 'status',
            }]
    }

    createmp() {
        this.route.navigate(['main/manage_employee/add/new']);
    }

    portfolio(id) {
        this.route.navigate(['main/manage_employee/manage_portfolio/' + id]);
    }

    onChangeList(item) {
        this.employeeListing = item;
        this.getall(1)
    }

    getall(index: any) {
        this.body = {
            employer_id: this.id,
            search: this.search,
            is_under_review: this.employeeListing ? '0' : '1',
            status: this.status_of_type,
            active: this.status_of_active,
        }
        let enc_payload = this.dec_enc.encryption(this.body);
        let url = 'employees?per_page=' + this.perPage + '&page=' + index + '&sort_by=' + this.sort_by + '&sort_order=DESC';
        this.mainService.postData(url, { enc_payload }).then(res => {
            if (res.statusCode == 200) {
                this.getEmployeeStats();
                let result = this.dec_enc.decryption(res.data);
                this.list = result.employees;
                console.log('Result~*', result);

                this.afterList();
                this.tablePropsEmp.headerActions[0].disabled = true;
                this.tablePropsEmp.headerActions[2].disabled = true;
                this.filteredArray = this.list.filter(item =>
                    (item.employers_employee.self_onboard == 0 && item.statusActive === 'Deactivated' && item.statusToShow === 'Verified') ||
                    (item.employers_employee.self_onboard == 0 && item.statusActive === 'Deactivated' && item.statusToShow === 'Non_Verified')
                );
                let hasDeactivatedVerified = false;
                let hasDeactivatedNonVerified = false;
                this.filteredArray.forEach(item => {
                    if (item.statusActive === 'Deactivated' && item.statusToShow === 'Verified') {
                        hasDeactivatedVerified = true;
                    } else if (item.statusActive === 'Deactivated' && item.statusToShow === 'Non_Verified') {
                        hasDeactivatedNonVerified = true;
                    }
                });
                if (hasDeactivatedVerified && hasDeactivatedNonVerified) {
                    this.isCheckboxDisabled = true;
                }
                else if (this.filteredArray.length === 0) {
                    this.isCheckboxDisabled = true;
                }
                else {
                    this.isCheckboxDisabled = false;
                }
                this.pagination = result.pagination;
                this.count = result.pagination.count;
                this.currentPage = result.pagination.page;
                this.pages = this.paginationService.setPagination(result.pagination.count, this.currentPage, this.perPage);
                this.totalPages = this.pages.totalPages;
            }
        },
            error => {
                this.alertService.alert('Error', 'Server error not get employees');
            }
        );
    }

    afterList() {
        this.list.forEach(item => {
            item.active = item.active == 1 ? true : false;
            if (this.employeeListing) {
                item['terminate'] = item['status'] != 2 && item['status'] != 0 && item.termination_status != 2 ? true : false;
                item['managePortfolio'] = this.portfolioIcon ? true : false;
                item['dontShowCB'] = item['status'] != 0 || !item['group_assigned'] == true ? true : false;
                item['sendForReview'] = item['status'] == 0 || item['status'] == 2 ? true : false;
                item['unblock'] = item['blocked'] == '1' ? true : false;
                item['deactivate'] = item['active'] == true && item.status != 0 && item['employers_employee']['self_onboard'] == 1 ? true : false;
                item['activate'] = item['active'] == false && item.status != 0 && item['employers_employee']['self_onboard'] == 1 ? true : false;
                item['delete'] = item.status == 0 ? true : false;
                // item['sendForReview'] = item.status == 2 ? true : false;
                item['ShowEdit'] = item.termination_status == 2 && (item.status == 0 || item.status == 1) ? false : true;
                // Old
                if (item.status == 1 && item.termination_status != 2) {
                    item['statusToShow'] = 'Verified';
                }
                else if (item.status == 0) {
                    item['statusToShow'] = 'Non_Verified';
                }
                else if (item.status == 2) {
                    item['statusToShow'] = 'Rejected';
                }
                else if (item.termination_status == 1) {
                    item['terminationSummary'] = true;
                }
                else if (item.termination_status == 2 && (item.status == 0 || item.status == 1)) {
                    item['statusToShow'] = 'Terminated';
                    item['managePortfolio'] = false;
                    item['terminationSummary'] = true;
                    item['deactivate'] = false;
                    item['activate'] = false;
                }
                else {
                    item['statusToShow'] = 'Not Available';
                }
            }
            else {
                item['view'] = true;
                if (item.status == 1) {
                    item['statusToShow'] = 'Verified';
                }
                else if (item.status == 0) {
                    item['statusToShow'] = 'Non_Verified';
                }
                else if (item.status == 2) {
                    item['statusToShow'] = 'Rejected';
                }
                else {
                    item['statusToShow'] = 'Not Available';
                }
                item['dontShowCB'] = !item.group_assigned && !this.employeeListing ? true : false;
            }

            item['fundGroup'] = item.employers_employee?.funds_groups_employees[0]?.funds_group?.name ? item.employers_employee?.funds_groups_employees[0]?.funds_group?.name : 'N/A';
            item['statusActive'] = (item.termination_status == 2 && item.status == 1) || item.status == 0 ? 'Deactivated' : item['active'] == true && item['employers_employee']['self_onboard'] == 1 ? 'Activated' : 'Deactivated';
            if (item['statusToShow'] == 'Verified' && item['statusActive'] == 'Deactivated') {
                item['dontShowCB'] = item['employers_employee']['self_onboard'] == 1;
                item['activate'] = item['employers_employee']['self_onboard'] == 1;
            }
            item['fundAssigned'] = item.group_assigned ? 'yes' : 'no';
            item['infoColumn'] = item.group_assigned ? 'Funds Assigned' : 'Please add this employee to funds group for further validation';
            item['position'] = item.employers_employee?.employees_identification?.position ? item.employers_employee?.employees_identification?.position : 'Not Available';
            item['employeeSince'] = item.employers_employee?.employees_identification?.employee_since ? moment(item.employers_employee?.employees_identification?.employee_since).format(appConfig.dateformatCMS) : 'Not Available';
        });
    }

    getEmployeeStats() {
        this.mainService.postData(`employees/stats`, {}).then(response => {
            if (response.statusCode == 200) {
                this.employeeStatsArray = [];
                let decrypted = this.dec_enc.decryption(response.data);
                this.employeeStats = decrypted;
                for (let key in this.employeeStats) {
                    this.employeeStats[key] = this.employeeStats[key] || this.employeeStats[key] == 0 ? this.employeeStats[key] : '0';
                }
                this.employeeStatsArray.push({ title: 'Total Employees', count: this.employeeStats['Total Employees'] });
                this.employeeStatsArray.push({ title: 'Active', count: this.employeeStats.Active });
                this.employeeStatsArray.push({ title: 'Inactive', count: this.employeeStats.Inactive });
                this.employeeStatsArray.push({ title: 'Verified', count: this.employeeStats.Verified });
                this.employeeStatsArray.push({ title: 'Non_Verified', count: this.employeeStats['Non-Verified'] });
                this.employeeStatsArray.push({ title: 'Rejected', count: this.employeeStats.Rejected });
                this.employeeStatsArray.push({ title: 'Terminated', count: this.employeeStats.Terminated });
            }
            else { }
        })
    }

    setPage(page) {
        this.pagination.page = page;
        this.getall(this.pagination.page);
    }

    SetPageSize(pageSize) {
        this.perPage = pageSize
        this.getall(this.pagination.page);
    }

    edit(id) {
        this.route.navigate(['main/manage_employee/update/' + id]);
    }

    toggle(event, id) {
        let url = event == true ? 'employees/activate-employee' : 'employees/deactivate-employee';
        let Active = '';
        if (event) {
            Active = '1';
            this.msgStatus = 'Employee account has been activated successfully.'
        }
        else {
            Active = '0';
            this.msgStatus = 'Employee account has been de-activated successfully.'
        }

        let body = {
            id: id,
        };

        let payload = {
            enc_payload: this.dec_enc.encryption(body),
        }

        this.mainService.postData(url, payload).then(result => {
            if (result.statusCode == 200) {
                this.alertService.alert('Success', this.msgStatus).then(result => {
                    this.getall(1);
                });
            }
            else {
                if (localStorage.getItem('lang') == 'ar') {
                    this.alertService.alert('Error', result.error.message_ar);
                }
                else {
                    this.alertService.alert('Error', result.error.message);
                }
            }
        });
    }

    onDelete(id) {
        let url = 'employees/delete';
        let body = {
            employee_id: id
        };

        this.alertService.alertAsk('Confirmation', 'Are you sure you want to delete?', 'Yes', 'No', false).then(result => {
            if (result) {
                let enc_payload = this.dec_enc.encryption(body);
                this.mainService.postData(url, { enc_payload }).then(result => {
                    if (result.statusCode == 200) {
                        this.alertService.alert('Success', 'Employee account has been deleted successfully.').then(result => {
                            this.getall(1);
                        });
                    }
                    else {
                        if (localStorage.getItem('lang') == 'ar') {
                            this.alertService.alert('Error', result.error.message_ar);
                        }
                        else {
                            this.alertService.alert('Error', result.error.message);
                        }
                    }
                });
            }
        })
    }

    onUnblock(id) {
        let url = 'employees/unblock';
        let body = {
            id: id
        };

        this.alertService.alertAsk('Confirmation', 'Are you sure you want to unblock?', 'Yes', 'No', false).then(result => {
            if (result) {
                let enc_payload = this.dec_enc.encryption(body);
                this.mainService.postData(url, { enc_payload }).then(result => {
                    if (result.statusCode == 200) {
                        this.alertService.alert('Success', 'Employee account has been unblocked successfully.').then(result => {
                            this.getall(1);
                        });
                    }
                    else {
                        if (localStorage.getItem('lang') == 'ar') {
                            this.alertService.alert('Error', result.error.message_ar);
                        }
                        else {
                            this.alertService.alert('Error', result.error.message);
                        }
                    }
                });
            }
        })
    }

    onSelectedCheckbox(item): void {
        if (!this.checkboxSelected.includes(item.id)) {
            this.checkboxSelected.push(item.id);
            this.showButton = true;
        } else {
            var index = this.checkboxSelected.indexOf(item.id);
            if (index > -1) {
                this.checkboxSelected.splice(index, 1);
                this.showButton = false;
            }
            if (this.allDays) {
                this.showButton = true;
            }
        }
    }

    onAllDay(lis, event): void {
        if (event.checked == true) {
            this.checkboxSelected = [];
            lis.forEach(element => {
                if (element.status == "0") {
                    element.checked = true;
                    this.checkboxSelected.push(element.id);
                }
            });
            if (this.checkboxSelected.length > 0) {
                this.showButton = true;
            }
        }
        else {
            lis.forEach(element => {
                element.checked = false;
            });
            this.showButton = false;
            this.checkboxSelected = [];
        }
    }

    addIdsToSend() {
        this.empIds = [];
        this.list.forEach(item => {
            if (item.checked) {
                this.empIds.push(item.id);
            }
        });
    }

    addEmailsToSend() {
        this.emailList = [];
        this.list.forEach(item => {
            if (item.checked) {
                this.emailList.push(item.email);
            }
        });
    }

    send() {
        this.alertService.alertAsk(this.tr.translation.alert, this.tr.translation.questionAsk, 'Yes', 'No', false).then(result => {
            if (result == true) {
                this.addIdsToSend();
                if (this.empIds.length <= 0) {
                    this.alertService.alert('Error', 'Please select non verified checkbox');
                }
                else {
                    let url = 'employees/send_under_review';
                    let body = {
                        ids: this.empIds,
                        is_under_review: '1'
                    };
                    let payload = this.dec_enc.encryption(body);
                    let formData = new FormData();
                    formData.append("enc_payload", payload);
                    let msg = 'Employee(s) profile has been sent for further validation process';
                    this.mainService.postData(url, formData).then(result => {
                        if (result.statusCode == 200) {
                            this.alertService.alert('Success', msg);
                            this.getall(this.currentPage);
                        }
                        else {
                            if (localStorage.getItem('lang') == 'ar') {
                                this.alertService.alert('Error', result.error.message_ar);
                            }
                            else {
                                this.alertService.alert('Error', result.error.message);
                            }
                        }
                    });
                }
            }
        })
    }

    sendSingle(item) {
        this.alertService.alertAsk(this.tr.translation.alert, this.tr.translation.questionAsk, 'Yes', 'No', false).then(result => {
            if (result == true) {
                let url = 'employees/send_under_review';
                let body = {
                    ids: [item],
                    is_under_review: '1'
                };
                let payload = this.dec_enc.encryption(body);
                let formData = new FormData();
                formData.append("enc_payload", payload);
                this.mainService.postData(url, formData).then(result => {
                    if (result.statusCode == 200) {
                        this.alertService.alert('Success', 'Employee profile has been sent for further validation process.');
                        this.getall(this.currentPage);
                    }
                    else {
                        if (localStorage.getItem('lang') == 'ar') {
                            this.alertService.alert('Error', result.error.message_ar);
                        }
                        else {
                            this.alertService.alert('Error', result.error.message);
                        }
                    }
                });
            }
        })
    }

    onRejectAccept(id, val) {
        this.alertService.alertAsk('Comment', '', 'Yes', 'No', true).then((result: AlertResult) => {
            if (result) {
                let url = val == '1' ? 'employees/review_accept' : 'employees/review_reject';
                let payload = {
                    additional_notes: result.text,
                    ids: [id],
                }
                this.onValidateApi(url, payload);
            }
        })
    }

    onValidateEmployees() {
        this.addIdsToSend();
        if (this.empIds.length <= 0) {
            this.alertService.alert('Error', 'Please select non verified checkbox');
        }
        this.alertService.alertAskwithCancel('Comment', '', 'Accept', 'Cancel', 'Reject', true).then((result: AlertResult) => {
            if (result) {
                let url = result.result ? 'employees/review_accept' : 'employees/review_reject';
                let payload = {
                    additional_notes: result.text,
                    ids: this.empIds,
                }
                this.onValidateApi(url, payload);
            }
        })
    }

    onValidateApi(url, payload) {
        // console.log("payload", payload, url);
        let enc_payload = this.dec_enc.encryption(payload);
        this.mainService.postData(url, { enc_payload }).then(response => {
            if (response.statusCode == 200) {
                let dec = this.dec_enc.decryption(response.data);
                console.log('decrypted response', dec);
                let message = url == 'employees/review_accept' ? 'Employee account has been verified successfully.' : 'Employee account has been rejected successfully.';
                // if (url == 'employees/review_accept' && dec.welcome_employee) {
                //     let dialogRef = this.dialog.open(WelcomeAfterAcceptDialogComponent, 
                //     {   
                //         autoFocus: false, 
                //         width: '40%', 
                //         height: 'auto', 
                //         disableClose: true, 
                //         hasBackdrop: true, 
                //         backdropClass: 'no-access-bg', 
                //         panelClass: !this.isArabic ? 'english-lang' : 'arabic-lang' 
                //     });
                // }
                // else {
                this.alertService.alert('Success', message).then(res => {
                    this.getall(this.currentPage);
                });
                // }
                return;
            }
            let message = localStorage.getItem('lang') == 'ar' ? response.error.message_ar : response.error.message;
            this.alertService.alert('Error', message);
        })
    }

    view(id, item) {
        this.route.navigate(['main/manage_employee/view/' + id]);
    }

    Terminate(id) {
        this.route.navigate(['main/terminate_employee/' + id]);
    }

    sort(event) {
        if (event == '') {
            this.sort_order = '';
            this.sort_by = '';
        }
        else {
            this.sort_order = 'ASC'
            this.sort_by = event;
        }
        this.getall(1);
    }

    readcsv() {
        this.http.get('assets/employers.csv', { responseType: 'text' })
            .subscribe(
                data => {
                    let csvToRowArray = data.split("\n");
                    for (let index = 1; index < csvToRowArray.length - 1; index++) {
                        let row = csvToRowArray[index].split(",");
                        this.userArray.push(new Employee(row[0], row[1], row[2], row[3], row[4], row[5], row[6], row[7], row[8], row[9], row[10], row[11], row[12], row[13]));
                    }
                },
                error => {
                }
            );
    }

    onSearch() {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.body.search = this.search
            this.getall(1);
        }, 800);
    }

    checkBox(event) {
        this.checkBoxCount = event.checkCount;
        let hasDeactivatedVerified = false;
        let hasDeactivatedNonVerified = false;
        this.filteredArray.forEach(item => {
            if (item.statusActive === 'Deactivated' && item.statusToShow === 'Verified') {
                hasDeactivatedVerified = true;
            } else if (item.statusActive === 'Deactivated' && item.statusToShow === 'Non_Verified') {
                hasDeactivatedNonVerified = true;
            }
        });
        if (event.rowData) {
            const { statusActive, statusToShow } = event.rowData;
            const isSelectAll = event.selectAll || this.checkBoxCount === this.list.length;
            if (this.filteredArray.length == this.checkBoxCount) {
                if (this.filteredArray.every(item => item.statusActive === 'Deactivated' && item.statusToShow === 'Verified')) {
                    this.tablePropsEmp.headerActions[0].disabled = true;
                    this.tablePropsEmp.headerActions[2].disabled = false;
                }
                if (this.filteredArray.every(item => item.statusActive === 'Deactivated' && item.statusToShow === 'Non_Verified')) {
                    this.tablePropsEmp.headerActions[0].disabled = false;
                    this.tablePropsEmp.headerActions[2].disabled = true;
                }
            }
            else {
                if (this.checkBoxCount > 0 && hasDeactivatedVerified && hasDeactivatedNonVerified) {
                    this.isCheckboxDisabled = false;
                }
                if (this.checkBoxCount > 0) {
                    if (this.employeeListing) {
                        if (isSelectAll || (statusActive === 'Deactivated' && statusToShow === 'Verified')) {
                            this.tablePropsEmp.headerActions[0].disabled = true;
                            this.tablePropsEmp.headerActions[2].disabled = false;
                            this.filteredArray.forEach(item => {
                                item['dontShowCB'] = isSelectAll ? false : (item['statusActive'] === 'Deactivated' && item['statusToShow'] === 'Verified') ? false : true;
                            });
                        }
                        if (isSelectAll || (statusActive === 'Deactivated' && statusToShow === 'Non_Verified')) {
                            this.tablePropsEmp.headerActions[0].disabled = false;
                            this.tablePropsEmp.headerActions[2].disabled = true;
                            this.filteredArray.forEach(item => {
                                item['dontShowCB'] = isSelectAll ? false : (item['statusActive'] === 'Deactivated' && item['statusToShow'] === 'Non_Verified') ? false : true;
                            });
                        }
                    }
                }
                else {
                    if (this.employeeListing) {
                        if (this.checkBoxCount == 0 && hasDeactivatedVerified && hasDeactivatedNonVerified) {
                            this.isCheckboxDisabled = true;
                        }
                        else {
                            this.isCheckboxDisabled = false;
                        }
                        this.tablePropsEmp.headerActions[0].disabled = true;
                        this.tablePropsEmp.headerActions[2].disabled = true;
                        this.filteredArray.forEach(item => {
                            item['dontShowCB'] = item['status'] != 0 || !item['group_assigned'] == true ? true : false;
                            if (item['statusToShow'] === 'Verified' && item['statusActive'] === 'Deactivated' && item['employers_employee']['self_onboard'] == 0) {
                                item['dontShowCB'] = false;
                            }
                        });
                    }
                    // else
                    // {
                    //     if (this.checkBoxCount == 0 && hasDeactivatedVerified && hasDeactivatedNonVerified)
                    //     {
                    //         this.isCheckboxDisabled = true;
                    //     }
                    //     else
                    //     {
                    //         this.isCheckboxDisabled = false;
                    //     }
                    //     this.tablePropsReview.headerActions[0].disabled = true;
                    //     this.tablePropsEmp.headerActions[2].disabled = true;
                    //     this.filteredArray.forEach(item =>
                    //     {
                    //         // item['dontShowCB'] = item['status'] != 0 || !item['group_assigned'] == true ? true : false;
                    //         // if (item['statusToShow'] === 'Verified' && item['statusActive'] === 'Deactivated' && item['active'] === false)
                    //         // {
                    //         //     item['dontShowCB'] = false;
                    //         // }
                    //     });
                    // }
                }
            }
        }
    }

    checkBoxReview(event) {
        this.checkBoxReviewCount = event.checkCount;
        if (this.checkBoxReviewCount > 0) {
            if (!this.employeeListing) {
                this.tablePropsReview.headerActions[0].disabled = false;
            }
            else {
                this.tablePropsReview.headerActions[0].disabled = true;
            }
        }
        else {
            if (!this.employeeListing) {
                this.tablePropsReview.headerActions[0].disabled = true;
            }
            else {
                this.tablePropsReview.headerActions[0].disabled = true;
            }
        }
    }

    onPrint(): void {
        const printContent = document.getElementById('print');
        printJS({
            printable: printContent.innerHTML,
            type: 'raw-html'
        });
    }

    printTerminationSummary(id) {
        let enc_payload = this.dec_enc.encryption({ employee_id: id });
        this.mainService.postData('terminations/summary', { enc_payload }).then(res => {
            if (res.statusCode == 200) {
                let result = this.dec_enc.decryption(res.data);
                this.dashboardData = this.dec_enc.decryption(res.data);
                if (this.dashboardData.employee_assets.length > 0) {
                    this.dashboardData.employee_assets.forEach(item => {
                        item.availble_units = isNaN(Number(item.availble_units)) ? Number(0).toFixed(5) : Number(item.availble_units).toFixed(5);
                        item.employee_invested_amount = isNaN(Number(item.employee_invested_amount)) ? Number(0).toFixed(4) : Number(item.employee_invested_amount).toFixed(4);
                        item['risk_type'] = item['risk_type'] ? item['risk_type'].charAt(0).toUpperCase() + item['risk_type'].slice(1) : 'N/A';
                    });
                    this.EmployeeAssetslist = this.dashboardData.employee_assets;
                }
                if (this.dashboardData.company_assets.length > 0) {
                    this.dashboardData.company_assets.forEach(item => {
                        item.company_availble_units = isNaN(Number(item.company_availble_units)) ? Number(0).toFixed(5) : Number(item.company_availble_units).toFixed(5);
                        item.company_invested_amount = isNaN(Number(item.company_invested_amount)) ? Number(0).toFixed(4) : Number(item.company_invested_amount).toFixed(4);
                        item['risk_type'] = item['risk_type'] ? item['risk_type'].charAt(0).toUpperCase() + item['risk_type'].slice(1) : 'N/A';
                    });
                    this.EmployerAssetslist = this.dashboardData.company_assets;
                }
                console.log("printTerminationSummary", result);
                setTimeout(() => {
                    this.onPrint();
                }, 800);
            }
            else {
                if (localStorage.getItem('lang') == 'ar') {
                    this.alertService.alert('Error', res.error.message_ar);
                }
                else {
                    this.alertService.alert('Error', res.error.message);
                }
            }
        })
    }

    actionClicked(event) {
        if (this.employeeListing) {
            if (event.action.label == 'Activate User') {
                this.toggle(true, event.element.id);
            }
            if (event.action.label == 'Deactivate User') {
                this.toggle(false, event.element.id);
            }
            if (event.action.label == 'Terminate Employee') {
                this.Terminate(event.element.id);
            }
            if (event.action.label == 'Manage Portfolio') {
                this.portfolio(event.element.id);
            }
            if (event.action.label == 'Edit') {
                this.edit(event.element.id);
            }
            if (event.action.label == 'View') {
                this.view(event.element.id, 1);
            }
            if (event.action.label == 'Send_for_Review') {
                if (!event.element.group_assigned) {
                    this.alertService.alert('Error', 'Please link fund group first then proceed further.');
                    return;
                }
                this.sendSingle(event.element.id);
            }
            if (event.action.label == 'Delete Employee') {
                this.onDelete(event.element.id);
            }
            if (event.action.label == 'Unblock') {
                this.onUnblock(event.element.id);
            }
            if (event.action.label == 'Termination Summary') {
                this.printTerminationSummary(event.element.id);
            }
        }
        else {
            if (event.action.label == 'View') {
                this.view(event.element.id, 2);
            }
            if (event.action.label == 'Accept') {
                if (event.element.hr_policy == true) {
                    this.onRejectAccept(event.element.id, '1');
                }
                else {
                    this.alertService.alertAsk(this.tr.translation.alert, 'An active HR Policy should be added before activating employee.', 'Add HR Policy', 'Cancel', false).then(result => {
                        if (result) {
                            this.route.navigateByUrl("main/hr-policy/add/new");
                        }
                    })
                }
            }
            if (event.action.label == 'Reject') {
                this.onRejectAccept(event.element.id, '2');
            }
        }
    }

    onInviteEmployees() {
        // let dialogRef = this.dialog.open(InviteEmployeeLoggedComponent, { autoFocus: false, width: '35%', height: 'auto', hasBackdrop: false, panelClass: this.isArabic ? 'arabic-lang' : 'english-lang' });
        // let cm = dialogRef.componentInstance;
        // dialogRef.afterClosed().subscribe(res =>
        // {
        //     if (res.type == 'complete')
        //     {
        //         console.log(res.type, res.data);
        //         window.location.reload();
        //         // this.route.navigate(['/main'])
        //     }
        // })

    }

    onInvite() {
        let body = {
            emails: this.emailList
        }
        let enc_payload = this.dec_enc.encryption(body);
        this.alertService.alertAsk(this.tr.translation.alert, 'Do you want to send these requests for invitation?', 'Yes', 'No', false).then(result => {
            if (result == true) {
                this.mainService.postData('employees/send-invitation', { enc_payload }).then(response => {
                    if (response.statusCode == 200) {
                        let message = localStorage.getItem('lang') == 'ar' ? response.message_ar : response.message;
                        this.alertService.alert('Invites sent!', 'Invitation emails with onboarding instructions have been sent successfully to employees.').then(res => {
                            this.getall(1)
                        });
                        return;
                    }
                    let message = localStorage.getItem('lang') == 'ar' ? response.error.message_ar : response.error.message;
                    this.alertService.alert('Error', message);
                })
            }
        })
    }

    headerButton(event) {
        if (event.label == 'Send_for_Review') {
            this.send();
        }
        else if (event.label == 'Invite Employees') {
            this.addEmailsToSend();
            this.onInvite();
        }
        else if (event.label == 'Validate') {
            this.onValidateEmployees();
        }
    }

    conditions(activeTab) {
        if (this.checkTab != null) {
            if (this.checkTab == 1) {
                return activeTab == true;
            }
            else if (this.checkTab == 2) {
                return activeTab == false;
            }
        }
        else {
            return activeTab == false;
        }
    }

    createViaCSV() {
        this.route.navigateByUrl('main/manage_employee/create_via_csv');
    }

    searchData(event) {
        this.search = event;
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.onSearch();
        }, 800);
    }

    onFilters(event: any) {
        if (event.key == 'status') {
            if (event.selected == 'all') {
                this.status_of_type = '';
            }
            if (event.selected == 'verified') {
                this.status_of_type = '1';
            }
            if (event.selected == 'non-verified') {
                this.status_of_type = '0';
            }
            this.getall(1);
        }
        if (event.key == 'active') {
            if (event.selected == 'all') {
                this.status_of_active = '';
            }
            if (event.selected == 'activated') {
                this.status_of_active = '1';
            }
            if (event.selected == 'deactivated') {
                this.status_of_active = '0';
            }
            this.getall(1);
        }
    }
}

export class Employee {
    name: any;
    phone: any;
    email: any;
    gender: any;
    date_of_birth: any;
    address: any;
    iqama_id: any;
    iqama_expiry: any;
    role: any;
    nationality: any;
    employee_since: any;
    position: any;
    are_you_dual_nationality_holder: any;
    basic_salary: any;
    constructor(name: any, phone: any, email: any, gender: any, date_of_birth: any, address: any, iqama_id: any, iqama_expiry: any, role: any, nationality: any, employee_since: any, position: any, are_you_dual_nationality_holder: any, basic_salary: any) {
        this.name = name
        this.phone = phone
        this.email = email
        this.gender = gender
        this.date_of_birth = date_of_birth
        this.address = address
        this.iqama_id = iqama_id
        this.iqama_expiry = iqama_expiry
        this.role = role
        this.nationality = nationality
        this.employee_since = employee_since
        this.position = position
        this.are_you_dual_nationality_holder = are_you_dual_nationality_holder
        this.basic_salary = basic_salary
    }
}
